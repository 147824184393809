import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import confirm from 'antd/lib/modal/confirm'
import { v4 as uuidv4 } from 'uuid'
import pick from 'lodash/pick'
import { Form, Select, Skeleton, UploadFile } from '~/core-components'
import { Col, DrawerForm, EmSelect, ErrorDisplay, Row } from '~/components'
import { EmPublicPerson } from '~/features/employee'
import { usePermissionGate } from '~/features/iam'
import { emptyGuid, LveApprovalStatus, LveSysLeaveType, Permission, PermissionAction } from '~/constants'
import { formatDateRange, getBaseUrl } from '~/utils'
import { ActionResult, Errors } from '~/types/store'
import { useFocus } from '~/hooks/use-focus'
import { dispatch } from '~/stores/store'
import { apiGetEmSelect } from '../../../api/employee.api'
import { ILeaveApply, LeaveRecordDtlState } from '../../../types'
import { applyLeaveRecord, deleteLeaveRecord } from '../../../actions'
import {
  useEntitledLeaveTypes,
  useLeaveApplyFormInfo,
  useLeaveEntitlement,
  useLeaveRecord,
  useLeaveRecordApprovalHistories,
  useLeaveRecordAttachments,
  useLeaveRecordDtls,
  useLeaveType
} from '../../../hooks'
import { LeaveTypeName } from '../../LeaveEntitlement/components/LeaveTypeName'
import { LeaveApplyForm, LEAVE_APPLY_EMPTY_FORM_DATA } from './LeaveApplyForm'
import { LeaveStatusTag } from '../../LeaveStatusTag/LeaveStatusTag'
import { LeaveCurrentApprover } from '../../LeaveCurrentApprover/LeaveCurrentApprover'
import { LeaveRecordApprovalHistories } from '../../LeaveRecordApprovalHistories/LeaveRecordApprovalHistories'
import './MutateLeaveRecordDrawer.less'

interface MutateLeaveRecordDrawerProps {
  visible: boolean
  id?: string
  employeeId?: string
  editing?: boolean
  onClose: (action: 'saved' | 'cancelled') => void
}

const NON_EDITABLE_STATUS = [LveApprovalStatus.rejected, LveApprovalStatus.cancelled]

const baseUrl = getBaseUrl('/leave')

export const MutateLeaveRecordDrawer: FC<MutateLeaveRecordDrawerProps> = ({
  visible,
  id,
  employeeId,
  editing,
  onClose
}) => {
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState<ILeaveApply>(LEAVE_APPLY_EMPTY_FORM_DATA)
  const [errors, setErrors] = useState<Errors>()
  const [focusRef, setFocus] = useFocus(visible)
  const [isEditing, setIsEditing] = useState(false)
  const [dtls, setDtls] = useState<LeaveRecordDtlState[]>([])
  const [conflicts, setConflicts] = useState<LeaveRecordDtlState[]>([])
  const errorRef = useRef<any>(null)
  const isSubmit = dtls && dtls.length > 0
  const formId = useMemo(() => `form-leave-record-${uuidv4()}`, [])

  const [entitledLeaveTypes, entitledLeaveTypesLoading] = useEntitledLeaveTypes(formData?.employeeId || '')
  const [leaveType] = useLeaveType(formData.leaveTypeId)
  const sysLeaveTypeCode = leaveType?.sysLeaveTypeCode
  const [leaveRecord, fetching] = useLeaveRecord(id)
  const [leaveRecordAttachments] = useLeaveRecordAttachments(id)
  const [leaveRecordDtls] = useLeaveRecordDtls(id)
  const [leaveRecordApprovalHistories] = useLeaveRecordApprovalHistories(id)
  const hasPayroll = leaveRecordDtls.some(d => d.payRunPeriod !== null)
  const [leaveEntitlement] = useLeaveEntitlement(formData.employeeId, formData.leaveTypeId)
  const maxPerTime = leaveEntitlement?.maxPerTime

  const isNew = !id
  const [formInfo] = useLeaveApplyFormInfo(formData.leaveTypeId, formData.employeeId)
  const formInfoRefDate = formInfo && formInfo['ref_date_label']
  const refDateValue = formInfoRefDate?.value
  const canModify =
    usePermissionGate(Permission.lveRecord, PermissionAction.Modify) &&
    !leaveRecordDtls.some(d => NON_EDITABLE_STATUS.includes(d.approvalStatus))

  useEffect(() => {
    setTimeout(() => visible && setFocus(), 100)
    setErrors(undefined)
    setDtls([])
    setConflicts([])
  }, [visible, setFocus])

  useEffect(() => {
    if (visible) {
      setIsEditing(!!editing)
    }
  }, [visible, editing])

  const handleFormDataChange = useCallback((updates: { [field: string]: any }) => {
    setErrors(undefined)
    setDtls([])
    setConflicts([])
    setFormData(formData => ({ ...formData, ...updates }))
  }, [])

  useEffect(() => {
    if (isNew) {
      const isAdp = sysLeaveTypeCode === LveSysLeaveType.adp
      const isMat = sysLeaveTypeCode === LveSysLeaveType.mat
      const isPat = sysLeaveTypeCode === LveSysLeaveType.pat
      const updates = {
        takenDuration: isAdp || isMat || isPat ? maxPerTime || 0 : 0,
        referenceDate: refDateValue || ''
      }
      setFormData(formData => ({ ...formData, ...updates }))
    }
  }, [isNew, maxPerTime, sysLeaveTypeCode, refDateValue])

  useEffect(() => {
    if (leaveRecord) {
      const {
        employeeId,
        leaveTypeId,
        leaveGrantId,
        startDate,
        endDate,
        startTime,
        endTime,
        startDuration,
        endDuration,
        referenceId,
        referenceDate,
        referenceName,
        referenceSource,
        notes,

        // jsonb
        isShared,
        sharedQuantity,
        takenDuration,
        relationship
      } = leaveRecord

      let attachments: UploadFile[] = []
      let file: UploadFile | undefined
      leaveRecordAttachments.forEach(att => {
        file = {
          uid: att?.fileId || '',
          name: att?.fileName || '',
          size: att?.fileSize || 0,
          type: att?.fileFormat || '',
          url: `${baseUrl}/leaverecord/${id}/attachment/${att?.id}/downloadfile`
        }
        attachments.push(file)
      })

      setFormData({
        employeeId,
        leaveTypeId,
        leaveGrantId,
        startDate,
        endDate,
        startTime,
        endTime,
        startDuration,
        endDuration,
        referenceId,
        referenceDate,
        referenceName,
        referenceSource,
        notes,

        // jsonb
        isShared,
        sharedQuantity,
        takenDuration,
        relationship,

        attachments,
        deletedAttachmentIds: []
      })
    } else {
      setFormData({ ...LEAVE_APPLY_EMPTY_FORM_DATA, employeeId: employeeId || '' })
    }
  }, [leaveRecord, leaveRecordAttachments, id, employeeId])

  const handleOk = useCallback(async () => {
    let result: ActionResult | undefined
    setLoading(true)
    try {
      formData.isSubmit = isSubmit
      result = await dispatch(applyLeaveRecord(id ? id : emptyGuid, formData))
    } finally {
      setLoading(false)
    }

    if (result?.errors) {
      setErrors(result.errors)
      errorRef.current?.scrollIntoView()
    }

    if (result?.result?.dtls) {
      setDtls(result.result.dtls)
    }
    if (result?.result?.conflicts) {
      setConflicts(result.result.conflicts)
    }

    if (!result?.errors && !result?.result?.dtls) {
      typeof onClose === 'function' && onClose('saved')
      setFormData(LEAVE_APPLY_EMPTY_FORM_DATA)
    }
  }, [id, formData, isSubmit, onClose])

  const handleDelete = useCallback(() => {
    if (leaveRecord) {
      const { id, startDate, endDate } = leaveRecord

      confirm({
        title: `Delete "${leaveType?.name}"`,
        content: `Do you want to delete "${leaveType?.name}" on ${formatDateRange(startDate, endDate)}?`,
        onOk: async () => {
          const result: ActionResult | undefined = await dispatch(deleteLeaveRecord(id))
          if (result?.errors) {
            setErrors(result.errors)
          }
          if (!result?.errors) {
            typeof onClose === 'function' && onClose('saved')
          }
        },
        okText: 'Delete',
        okType: 'danger'
      })
    }
  }, [leaveType, leaveRecord, onClose])

  const handleFetchEmployees = useCallback(async () => {
    const { status, result } = await apiGetEmSelect('past3mth')
    if (status) {
      return result
    }
    return []
  }, [])

  const handleToggleEdit = useCallback(() => {
    setIsEditing(isEditing => !isEditing)
  }, [])

  return (
    <DrawerForm
      className="mutate-leave-record-drawer"
      open={visible}
      title={id ? (isEditing ? 'Edit leave record' : 'Leave record') : 'Add leave record'}
      okText={canModify && !hasPayroll ? (!isEditing ? 'Edit' : 'Save') : 'Close'}
      onOk={!canModify || hasPayroll ? () => onClose('cancelled') : undefined}
      onClose={() => onClose('cancelled')}
      confirmLoading={loading}
      width={500}
      showDelete={id && isEditing ? true : false}
      onDelete={handleDelete}
      formId={canModify && !hasPayroll ? formId : undefined}
    >
      <Form id={formId} onFinish={!isEditing ? handleToggleEdit : handleOk}>
        {fetching ? (
          <Skeleton active />
        ) : (
          <>
            <Row>
              <Col span={24}>
                {employeeId ? (
                  <Form.Item label="">
                    <EmPublicPerson id={employeeId} />
                  </Form.Item>
                ) : (
                  <Form.Item
                    label="Employee"
                    validateStatus={errors?.employeeId ? 'error' : ''}
                    help={errors?.employeeId}
                  >
                    <EmSelect
                      ref={focusRef}
                      value={formData.employeeId}
                      onFetch={handleFetchEmployees}
                      onChange={(value: string) =>
                        handleFormDataChange({ ...LEAVE_APPLY_EMPTY_FORM_DATA, employeeId: value })
                      }
                    />
                  </Form.Item>
                )}
              </Col>
            </Row>
            <Row>
              <Col span={isNew ? 24 : 12}>
                <Form.Item
                  label="Leave type"
                  validateStatus={errors?.leaveTypeId ? 'error' : ''}
                  help={errors?.leaveTypeId}
                >
                  {!isEditing || !isNew ? (
                    <LeaveTypeName id={formData.leaveTypeId} hideNewTab />
                  ) : (
                    <Select
                      showSearch
                      allowClear={false}
                      optionFilterProp="title"
                      loading={entitledLeaveTypesLoading}
                      value={formData.leaveTypeId}
                      onChange={(value: string) =>
                        handleFormDataChange({
                          ...LEAVE_APPLY_EMPTY_FORM_DATA,
                          employeeId: formData.employeeId,
                          leaveTypeId: value
                        })
                      }
                    >
                      {entitledLeaveTypes?.map(data => (
                        <Select.Option
                          key={data?.leaveTypeId}
                          value={data?.leaveTypeId || ''}
                          title={data?.leaveTypeName}
                        >
                          {data?.leaveTypeName}
                        </Select.Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              {!isNew && (
                <Col span={12} style={{ textAlign: 'right' }}>
                  <LeaveStatusTag statuses={leaveRecord?.approvalStatuses || []} />
                </Col>
              )}
            </Row>
            <ErrorDisplay ref={errorRef} errors={errors} />
            <LeaveApplyForm
              id={id}
              leaveType={leaveType}
              formData={formData}
              availableDates={dtls}
              conflictDates={conflicts}
              errors={errors}
              readOnly={!isEditing}
              onChange={handleFormDataChange}
            />
            <LeaveCurrentApprover
              statuses={leaveRecord?.approvalStatuses || []}
              approvers={leaveRecord?.currentApprovers || []}
              hidden={isEditing || isNew}
            />
            <LeaveRecordApprovalHistories
              histories={leaveRecordApprovalHistories}
              statuses={leaveRecord?.approvalStatuses || []}
              hidden={isEditing || isNew}
              {...pick(leaveRecord!, 'creatorName', 'createdDate')}
            />
          </>
        )}
      </Form>
    </DrawerForm>
  )
}
