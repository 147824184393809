import React, { FC, useCallback, useState } from 'react'
import { EditOutlined } from '@ant-design/icons'
import { Card, ColumnsType, PageHeader, SecondaryLink, Space, Switch, Table } from '~/core-components'
import { DocumentTitle } from '~/components'
import { usePermissionGate } from '~/features/iam'
import { Permission, PermissionAction } from '~/constants'
import { dispatch } from '~/stores/store'
import { SETTINGS_ROUTES } from '~/routes/routes'
import { updateCfConfig } from '../../actions'
import { useCfConfigs } from '../../hooks'
import { CfConfigState } from '../../types'
import { EditCfConfigDrawer } from './EditCfConfigDrawer'
import './CfConfigs.less'

type CfConfigTable = CfConfigState

const routes = [
  {
    path: SETTINGS_ROUTES.main,
    breadcrumbName: 'Settings'
  },
  {
    path: '',
    breadcrumbName: 'Overview'
  }
]

interface DrawerState {
  visible: boolean
  data?: CfConfigState
}
const DEFAULT_DRAWER_STATE: DrawerState = { visible: false }

interface CfConfigsProps {}

export const CfConfigs: FC<CfConfigsProps> = () => {
  const [cfConfigs, loading] = useCfConfigs()
  const [updatingVisible, setUpdatingVisible] = useState<string>()
  const [drawerState, setDrawerState] = useState<DrawerState>(DEFAULT_DRAWER_STATE)
  const canModify = usePermissionGate(Permission.claType, PermissionAction.Modify)

  const handleVisibleChange = useCallback(async (cfConfig: CfConfigState, isShown: boolean) => {
    setUpdatingVisible(cfConfig.code)
    try {
      await dispatch(updateCfConfig(cfConfig.code, cfConfig, { ...cfConfig, isShown }))
    } finally {
      setUpdatingVisible(undefined)
    }
  }, [])

  const handleEditName = useCallback((cfConfig: CfConfigState) => {
    setDrawerState({ visible: true, data: cfConfig })
  }, [])

  const handleCloseDrawer = useCallback(() => {
    setDrawerState(DEFAULT_DRAWER_STATE)
  }, [])

  const columns: ColumnsType<CfConfigTable> = [
    {
      title: 'Name',
      key: 'name',
      dataIndex: 'name',
      render: (value: string, record) => (
        <Space>
          {value}
          <SecondaryLink onClick={() => handleEditName(record)}>
            <EditOutlined />
          </SecondaryLink>
        </Space>
      )
    },
    {
      title: 'Visible',
      key: 'isShown',
      dataIndex: 'isShown',
      align: 'center',
      width: 100,
      render: (value: boolean, record) => (
        <Switch
          checkedChildren="Shown"
          unCheckedChildren="Hidden"
          checked={record.isShown}
          loading={updatingVisible === record.code}
          disabled={!canModify}
          onChange={(checked: boolean) => {
            handleVisibleChange(record, checked)
          }}
        />
      )
    }
  ]

  return (
    <div id="cf-configs" className="cf-configs">
      <DocumentTitle title="Custom Fields" />
      <PageHeader title="Custom fields" containerId="cf-configs" breadcrumb={{ routes }} />
      <div className="cf-configs__body">
        <Card table>
          <Table
            rowKey="code"
            dataSource={cfConfigs}
            pagination={false}
            columns={columns}
            loading={loading}
            rowClassName={record => (record.isShown ? 'cf-configs__table-row--shown' : '')}
          />
        </Card>
      </div>
      {canModify && <EditCfConfigDrawer {...drawerState} onClose={handleCloseDrawer} />}
    </div>
  )
}
