import { Operation } from 'fast-json-patch'
import { request, getBaseUrl, timeout } from '~/utils'
import { DateRange, Pagination } from '~/types/common'
import { ClockRecordState, ClockRecordViewState, ISaveClockRecord } from '../types'

const baseUrl = getBaseUrl('/attendance/clockrecord')

export const apiGetClockRecordsView = (viewId: string, params: Pagination & DateRange) =>
  request<ClockRecordViewState>('get', `${baseUrl}/view/${viewId}`, params)

export const apiGetClockRecordsExcel = (viewId: string, params: DateRange) =>
  request('get', `${baseUrl}/view/${viewId}/excel`, params, { responseType: 'blob', timeout })

export const apiGetClockRecordsByDaily = (dailyRecordId: string) =>
  request<ClockRecordState[]>('get', `${baseUrl}/daily/${dailyRecordId}`)

export const apiAddClockRecord = (clockRecord: ISaveClockRecord) =>
  request<{ id: string }>('post', baseUrl, clockRecord)

export const apiUpdateClockRecord = (id: string, clockRecord: Operation[]) =>
  request<void>('patch', `${baseUrl}/${id}`, clockRecord)

export const apiDeleteClockRecord = (id: string) => request<void>('delete', `${baseUrl}/${id}`)
