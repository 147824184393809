import React, { CSSProperties, FC, useEffect, useMemo } from 'react'
import { Card, ColumnsType, Space, Table } from '~/core-components'
import { PersonAvatar } from '~/components'
import { fetchLeaveTypes, LeaveTypeName, LeaveStatusTag } from '~/features/leave'
import { SSCalendarLeaveRecordState } from '~/features/my/types'
import { formatDateRange, getBaseUrl } from '~/utils'
import { dispatch } from '~/stores/store'
import { useMyCalendarLeaveRecords } from '~/features/my/hooks/leave/use-my-calendar-leave-records'
import './MyCalendarLeaveRecords.less'

interface MyCalendarLeaveRecordsProps {
  viewAs: string
  startDate: string
  endDate: string
}

const cardStyle: CSSProperties = { borderRadius: 5 }
const cardBodyStyle: CSSProperties = { padding: 0 }
const baseUrl = getBaseUrl('/filestore')

export const MyCalendarLeaveRecords: FC<MyCalendarLeaveRecordsProps> = ({ viewAs, startDate, endDate }) => {
  const [data, loading] = useMyCalendarLeaveRecords(viewAs, startDate, endDate)

  useEffect(() => {
    dispatch(fetchLeaveTypes({ strategy: 'when-empty' }))
  }, [])

  const columns = useMemo(() => {
    let columns: ColumnsType<SSCalendarLeaveRecordState> = [
      {
        title: 'Employee',
        key: 'employeeId',
        dataIndex: 'employeeId',
        width: 150,
        render: (value: string, record) => {
          return (
            <Space>
              <PersonAvatar
                photo={record.photoId && `${baseUrl}/file/${record.photoId}/thumbnailphoto/20`}
                size={20}
                loading={loading}
              />
              {record.employeeName}
            </Space>
          )
        }
      },
      {
        title: 'Leave',
        key: 'leaveTypeId',
        dataIndex: 'leaveTypeId',
        render: (value: string, record) => (
          <>
            <LeaveTypeName id={value} />
            <div>{`${formatDateRange(record.startDate, record.endDate)} (${
              record.leaveTotal
            } ${record.unit.toLowerCase()})`}</div>
          </>
        )
      },
      {
        title: 'Status',
        key: 'approvalStatuses',
        dataIndex: 'approvalStatuses',
        width: 160,
        render: (value: string[]) => <LeaveStatusTag statuses={value} />
      }
    ]

    return columns
  }, [loading])

  return (
    <div className="my-calendar-leave-records">
      <h3 style={{ marginBottom: 15 }}>{formatDateRange(startDate, endDate)}</h3>
      <Card style={cardStyle} bodyStyle={cardBodyStyle}>
        <Table
          rowKey="id"
          dataSource={data}
          columns={columns}
          loading={loading}
          scroll={{ y: '72vh' }}
          pagination={false}
        />
      </Card>
    </div>
  )
}
