import React, { FC, memo, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, ColumnsType, Link, Space, Spin, Table, Tag, Tooltip } from '~/core-components'
import { Col, Row } from '~/components'
import { isEditablePayTranSource } from '~/features/payroll'
import { usePermissionGate } from '~/features/iam/hooks'
import { PayItemId, PayRunStatus, PayTranGroup, PayTranSource, Permission, PermissionAction } from '~/constants'
import { formatMoney } from '~/utils'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { fetchPayTransSg, processPayroll } from '../../actions'
import { selectPayRunById } from '../../reducers'
import { selectPayRecordById, selectPayRecordsTrans } from '../../selectors'
import { PayRecordSgState, PayTranSgState } from '../../types'
import { EditPayTranEntryDrawer } from '../PayRecord/EditPayTranEntryDrawer'
import { EditPayTranImportDrawer } from '../PayRecord/EditPayTranImportDrawer'
import { EditPayRecurringDrawer } from '../PayRecord/EditPayRecurringDrawer'
import { EditPayTranAdjDrawer } from '../PayRecord/EditPayTranAdjDrawer'
import { AddPayTranDrawer } from '../PayRecord/AddPayTranDrawer'
import { PayItemName } from '~/features/master'

export interface PayRecordsTransProps {
  payRunId: string
  payRecordId: string
}

type PayTranSgTable = PayTranSgState

interface DrawerState {
  visible: boolean
  payTran?: PayTranSgState
}

interface AdjDrawerState {
  visible: boolean
  payRunId: string
  employeeId: string
  originalAmount: number
  adjustmentRefCode: string
}

interface AddPayTranDrawerState {
  visible: boolean
  payRecord?: PayRecordSgState
}

const DEFAULT_DRAWER_STATE: DrawerState = { visible: false }
const DEFAULT_ADJ_DRAWER_STATE: AdjDrawerState = {
  visible: false,
  payRunId: '',
  employeeId: '',
  originalAmount: 0,
  adjustmentRefCode: ''
}
const DEFAULT_ADD_PAY_TRAN_DRAWER_STATE: AddPayTranDrawerState = { visible: false }

const AdditionSign = memo(() => (
  <Tooltip title="Allowance">
    <PlusOutlined style={{ color: 'green' }} />
  </Tooltip>
))

export const PayRecordsTrans: FC<PayRecordsTransProps> = ({ payRunId, payRecordId }) => {
  const [entryDrawerState, setEntryDrawerState] = useState<DrawerState>(DEFAULT_DRAWER_STATE)
  const [importDrawerState, setImportDrawerState] = useState<DrawerState>(DEFAULT_DRAWER_STATE)
  const [recurringDrawerState, setRecurringDrawerState] = useState<DrawerState>(DEFAULT_DRAWER_STATE)
  const [adjDrawerState, setAdjDrawerState] = useState<AdjDrawerState>(DEFAULT_ADJ_DRAWER_STATE)
  const payTrans = useSelector(selectPayRecordsTrans)(payRecordId)
  const payRecord = useSelector(selectPayRecordById)(payRunId, payRecordId)
  const processing = useSelector((state: StoreState) => state.payroll.payrollLoading)
  const loading = useSelector((state: StoreState) => state.payroll.payTransLoading[payRecordId])
  const payRun = useSelector((state: StoreState) => selectPayRunById(state, payRunId))
  const [addPayTranDrawerState, setAddPayTranDrawerState] = useState<AddPayTranDrawerState>(
    DEFAULT_ADD_PAY_TRAN_DRAWER_STATE
  )
  const canModify = usePermissionGate(Permission.payRun, PermissionAction.Modify)
  const employeeId = payRecord?.employeeId || ''

  useEffect(() => {
    if (!processing && payRecordId) {
      dispatch(fetchPayTransSg(payRecordId))
    }
  }, [payRecordId, processing])

  const handleCloseEntryDrawer = useCallback(
    (action: 'save' | 'cancel') => {
      if (action === 'save' && payRunId && employeeId) {
        dispatch(processPayroll(payRunId, [employeeId]))
      }
      setEntryDrawerState(DEFAULT_DRAWER_STATE)
    },
    [payRunId, employeeId]
  )

  const handleCloseImportDrawer = useCallback(
    (action: 'save' | 'cancel') => {
      if (action === 'save' && payRunId && employeeId) {
        dispatch(processPayroll(payRunId, [employeeId]))
      }
      setImportDrawerState(DEFAULT_DRAWER_STATE)
    },
    [payRunId, employeeId]
  )

  const handleCloseRecurringDrawer = useCallback(
    (action: 'save' | 'cancel') => {
      if (action === 'save' && payRunId && employeeId) {
        dispatch(processPayroll(payRunId, [employeeId]))
      }
      setRecurringDrawerState(DEFAULT_DRAWER_STATE)
    },
    [payRunId, employeeId]
  )

  const handleCloseAdjDrawer = useCallback(
    (action: 'save' | 'cancel') => {
      if (action === 'save' && payRunId && employeeId) {
        dispatch(processPayroll(payRunId, [employeeId]))
      }
      setAdjDrawerState(DEFAULT_ADJ_DRAWER_STATE)
    },
    [payRunId, employeeId]
  )

  const handleTranEditClick = useCallback((payTran: PayTranSgTable) => {
    const payRunId = payTran?.payRunId || ''
    const employeeId = payTran?.employeeId || ''
    const adjustmentRefCode = payTran?.payItemId || ''

    if (payTran.source === PayTranSource.entry) {
      setEntryDrawerState({ visible: true, payTran })
    } else if (payTran.source === PayTranSource.import) {
      setImportDrawerState({ visible: true, payTran })
    } else if (payTran.source === PayTranSource.recurring) {
      setRecurringDrawerState({ visible: true, payTran })
    } else if (payTran.source === PayTranSource.fund) {
      setAdjDrawerState({
        visible: true,
        payRunId,
        employeeId,
        originalAmount: payTran.amount,
        adjustmentRefCode: adjustmentRefCode
      })
    } else if (payTran.source === PayTranSource.pyCpf) {
      setAdjDrawerState({
        visible: true,
        payRunId,
        employeeId,
        originalAmount: payTran.amount,
        adjustmentRefCode: `${payTran.id}_adj`
      })
    }
  }, [])

  const handleAddPayTran = useCallback(() => {
    if (payRecord) {
      setAddPayTranDrawerState({ visible: true, payRecord })
    }
  }, [payRecord, setAddPayTranDrawerState])

  const handleCloseAddPayTranDrawer = useCallback(
    (action: 'save' | 'cancel') => {
      if (action === 'save') {
        dispatch(processPayroll(payRunId, [employeeId]))
      }
      setAddPayTranDrawerState(DEFAULT_ADD_PAY_TRAN_DRAWER_STATE)
    },
    [payRunId, employeeId]
  )

  const columns: ColumnsType<PayTranSgTable> = [
    {
      title: 'Pay item',
      key: 'payItemId',
      dataIndex: 'payItemId',
      width: 350,
      render: (value: string, record: PayTranSgTable) => {
        return value ? (
          <PayItemName id={value} children={<Tag>{record.groupName}</Tag>} />
        ) : (
          <Space align="start">
            {record.id === PayTranGroup.basicPay && <AdditionSign />}
            <div>{record.groupName}</div>
          </Space>
        )
      }
    },
    {
      title: 'Amount',
      key: 'amount',
      dataIndex: 'amount',
      width: 150,
      align: 'right',
      render: (value: number) => {
        return formatMoney(value, 2)
      }
    },
    {
      key: 'action',
      align: 'right',
      render: (value: any, record: PayTranSgTable) => {
        if (!canModify || payRun?.status === PayRunStatus.completed || !!payRecord?.lockedBy) {
          return
        }

        if (isEditablePayTranSource(record.source)) {
          if (record.payItemId === PayItemId.BasicPay && record.source === PayTranSource.basicSalary) {
            return
          }

          return (
            <Link size="small" onClick={() => handleTranEditClick(record)}>
              edit
            </Link>
          )
        }
      }
    }
  ]

  return (
    <Row>
      <Col>
        {loading ? (
          <Spin indicator={<LoadingOutlined spin />} />
        ) : (
          <>
            <Table
              rowKey="id"
              dataSource={payTrans}
              pagination={false}
              showHeader={false}
              columns={columns}
              className="payrecords__trans-table"
            />
            {canModify && payRun?.status !== PayRunStatus.completed && !payRecord?.lockedBy && (
              <>
                <Button type="dashed" block className="payrecords__trans-add" onClick={handleAddPayTran}>
                  Add payroll item
                </Button>
                <AddPayTranDrawer {...addPayTranDrawerState} onClose={handleCloseAddPayTranDrawer} />
              </>
            )}
          </>
        )}
        {canModify && <EditPayTranEntryDrawer id="" {...entryDrawerState} onClose={handleCloseEntryDrawer} />}
        {canModify && <EditPayTranImportDrawer id="" {...importDrawerState} onClose={handleCloseImportDrawer} />}
        {canModify && <EditPayRecurringDrawer id="" {...recurringDrawerState} onClose={handleCloseRecurringDrawer} />}
        {canModify && <EditPayTranAdjDrawer id="" {...adjDrawerState} onClose={handleCloseAdjDrawer} />}
      </Col>
    </Row>
  )
}
