import { showError, showSuccess } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiUpdateCtPolicy } from '../api/ct-policy.api'
import { setCtPolicy } from '../reducers'
import { CtPolicyState } from '../types'
import { fetchCtPolicyEt } from './fetch-ct-policy-et'

export const updateCtPolicy = (claimTypeId: string, id: string, ctPolicy: CtPolicyState): ThunkResult<void> => {
  return async dispatch => {
    if (!claimTypeId || !id) return

    const { status, errors, message, errorData } = await apiUpdateCtPolicy(claimTypeId, id, ctPolicy)
    if (status) {
      if (claimTypeId && id) {
        dispatch(fetchCtPolicyEt(claimTypeId, id))
      }

      dispatch(setCtPolicy({ claimTypeId, data: ctPolicy }))
      showSuccess('Saved')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
