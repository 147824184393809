import { Operation } from 'fast-json-patch'
import { KeyValue, Search, SearchLimit } from '~/types/common'
import { request, getBaseUrl } from '~/utils/request'
import { UploadFile } from '~/core-components'
import { EmSelectState } from '~/components'
import { EmployeeState, EmHireState, EmNamePhotoState, INewEmployee, ICopyEmployee, EmNameDescState } from '../types'

const baseUrl = getBaseUrl('/employee/employee')

export const apiGetEmployees = () => request<EmployeeState[]>('get', baseUrl)

export const apiGetEmSelect = (type: 'past3mth' | 'resigned' | 'active') =>
  request<EmSelectState[]>('get', `${baseUrl}/emselect`, { type })

export const apiGetEmployeeKeyValues = () => request<KeyValue[]>('get', `${baseUrl}/keyvalues`)

export const apiGetEmployee = (id: string) => request<EmployeeState>('get', `${baseUrl}/${id}`)

export const apiSearchEmployee = (params: Search & SearchLimit) =>
  request<EmSelectState[]>('get', `${baseUrl}/search`, params)

export const apiGetEmHires = (id: string) => request<EmHireState[]>('get', `${baseUrl}/${id}/hire`)

export const apiGetEmNameDesc = (ids: string[]) => request<EmNameDescState[]>('get', `${baseUrl}/namedesc`, { ids })

export const apiGetEmNamePhoto = (params: { employeeIds: string[] }) =>
  request<EmNamePhotoState[]>('get', `${baseUrl}/photoids`, params)

export const apiGetEmNamePhotoByTaxNos = (params: { employeeTaxNos: string[] }) =>
  request<EmNamePhotoState[]>('get', `${baseUrl}/photoids-by-taxno`, params)

export const apiGetLatestEmployeeNo = () => request<string>('get', `${baseUrl}/latest-employee-no`)

export const apiRefreshEmployee = () => request<void>('get', `${baseUrl}/refresh`)

export const apiGetPermittedEmployeePay = () => request<string[]>('get', `${baseUrl}/permitted-pay`)

export const apiGetPermittedEmployeeLve = () => request<string[]>('get', `${baseUrl}/permitted-lve`)

export const apiAddEmployee = (employee: INewEmployee) =>
  request<{ id: string; emCompanyId: string; retireAge: number; retireDate: string }>('post', baseUrl, employee)

export const apiCopyEmployee = (copyEmployee: ICopyEmployee) =>
  request<{ id: string }>('post', `${baseUrl}/copy`, copyEmployee)

export const apiCopyEmployeeValidate = (copyEmployee: ICopyEmployee) =>
  request('post', `${baseUrl}/copy/validate`, copyEmployee)

export const apiUpdateEmployeeBasic = (id: string, employee: Operation[]) =>
  request<void>('patch', `${baseUrl}/${id}/basic`, employee)

export const apiUpdateEmployeePersonal = (id: string, employee: Operation[]) =>
  request<{ age?: string; retireDate?: string }>('patch', `${baseUrl}/${id}/personal`, employee)

export const apiUpdateEmployeeHire = (id: string, employee: Operation[]) =>
  request<{
    emCompanyId?: string
    companyId?: string
    serviceLength?: string
    initialServiceLength?: string
    emStatus?: string
    terminationReasonId?: string
    terminationNotes?: string
  }>('patch', `${baseUrl}/${id}/hire`, employee)

export const apiUpdateEmployeeProbation = (id: string, employee: Operation[]) =>
  request<{ isConfirmed?: boolean }>('patch', `${baseUrl}/${id}/probation`, employee)

export const apiUpdateEmployeeRetirement = (id: string, employee: Operation[]) =>
  request<void>('patch', `${baseUrl}/${id}/retirement`, employee)

export const apiUpdateEmployeePayment = (id: string, employee: Operation[]) =>
  request<void>('patch', `${baseUrl}/${id}/payment`, employee)

export const apiUpdateEmployeeStatutory = (id: string, employee: Operation[]) =>
  request<void>('patch', `${baseUrl}/${id}/statutory`, employee)

export const apiUpdateEmployeeAttendance = (id: string, employee: Operation[]) =>
  request<void>('patch', `${baseUrl}/${id}/attendance`, employee)

export const apiUpdateEmployeeEmergency = (id: string, employee: Operation[]) =>
  request<void>('patch', `${baseUrl}/${id}/emergency`, employee)

export const apiDeleteEmployee = (id: string) => request<void>('delete', `${baseUrl}/${id}`)

export const apiDeleteEmployeePhoto = (id: string) => request<void>('post', `${baseUrl}/${id}/removephoto`)

export const apiUploadEmployeePhoto = (id: string, file: UploadFile) => {
  let formData = new FormData()
  formData.append('file', file.xhr, file.name)

  return request<{ photoId: string }>('post', `${baseUrl}/${id}/uploadphoto`, formData)
}
