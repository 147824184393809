const FieldIconMap: { [fieldId: string]: string } = {
  '0daeed08-a9b0-4c7a-b73c-8c6e5364e1c4': 'fa-id-card', // first name
  '76457bd8-ec68-4666-8e26-e482f05829e7': 'fa-id-card', // last name
  'f407fe2c-d712-452f-a727-a604f30ca747': 'fa-id-card', // full name
  '7ca1888c-5621-4129-ac13-445dabcd1e2d': 'fa-id-card', // preferred name
  'd6b3e3c6-b80c-4af4-b501-ad5c17e985db': 'fa-phone', // work phone
  '19db9056-5bec-476f-801c-7e036f96e1a9': 'fa-phone', // personal phone
  '915d4c53-b6a0-431b-b451-d3cc26e89bc0': 'fa-envelope', // email
  'f0c5eee6-4879-4116-9b44-7234db15a1c7': 'fa-id-badge', // employee number
  '557d5006-0298-47ef-8969-a1d6dad00209': 'fa-sign-in', // hire date
  '2a38fab8-06f8-4cc3-a161-f8d2016eb775': 'fa-sign-out', // end of employment date
  '9e8f1397-8535-42a7-b555-6a3ca8e2db9f': 'fa-calendar', // employment period
  '131a7ab9-8f8a-4c69-a5ac-42c8e5482f09': 'fa-clock', // length of service
  '6d5deff3-d069-42ba-8bc2-8aa426126a8b': 'fa-briefcase-blank', // company
  '7e542580-d355-4c0d-9d43-ccba15b51cd5': 'fa-sitemap', // department
  '34c7a098-d1ab-4e0e-b484-2c665768ec78': 'fa-id-badge', // job title
  '7e370fff-a63f-42f1-ae34-a3a4c922470d': 'fa-book-user', // reporting to
  '2a1b0b05-76dd-4d54-8ca1-a32744e48e57': 'fa-users', // team
  '49c64756-0a69-4b8a-9df3-42f18990f5da': 'fa-location-dot', // office
  '90ae4c2e-eb68-4a50-9aa5-afc0ff5543fe': 'fa-chart-pie-simple' // cost centre
}

export class FieldIcon {
  static get(id: string) {
    const Icon = FieldIconMap[id]
    if (Icon) return Icon
  }
}

export class CriteriaOperator {
  static get eq() {
    return 'eq'
  }
  static get not_eq() {
    return 'not_eq'
  }
  static get gt() {
    return 'gt'
  }
  static get lt() {
    return 'lt'
  }
  static get gt_eq() {
    return 'gt_eq'
  }
  static get lt_eq() {
    return 'lt_eq'
  }
  static get in() {
    return 'in'
  }
  static get not_in() {
    return 'not_in'
  }
  static get not_null() {
    return 'not_null'
  }
  static get null() {
    return 'null'
  }
  static get between() {
    return 'between'
  }

  // date
  static get today() {
    return 'today'
  }
  static get yesterday() {
    return 'yesterday'
  }
  static get tomorrow() {
    return 'tomorrow'
  }
  static get before_n_today() {
    return 'before_n_today'
  }
  static get after_today() {
    return 'after_today'
  }
  static get next_7_day() {
    return 'next_7_day'
  }
  static get last_7_day() {
    return 'last_7_day'
  }
  static get this_week() {
    return 'this_week'
  }
  static get last_week() {
    return 'last_week'
  }
  static get next_week() {
    return 'next_week'
  }
  static get this_month() {
    return 'this_month'
  }
  static get last_month() {
    return 'last_month'
  }
  static get next_month() {
    return 'next_month'
  }
  static get this_year() {
    return 'this_year'
  }
  static get last_year() {
    return 'last_year'
  }
  static get next_year() {
    return 'next_year'
  }
  static get this_quarter() {
    return 'this_quarter'
  }
  static get last_quarter() {
    return 'last_quarter'
  }
  static get next_quarter() {
    return 'next_quarter'
  }
}

export class LogicalOperator {
  static get and() {
    return 'and'
  }
  static get or() {
    return 'or'
  }
}

export class CriteriaType {
  static get criteria() {
    return 'criteria'
  }
  static get group() {
    return 'group'
  }
}

export class CriteriaValueType {
  static get date() {
    return 'date'
  }
  static get number() {
    return 'number'
  }
  static get option() {
    return 'option'
  }
  static get text() {
    return 'text'
  }
  static get yymm() {
    return 'yymm'
  }
}

export class FilterEmStatus {
  static get all() {
    return 'all'
  }
  static get existing() {
    return 'existing'
  }
}

export enum CriteriaFieldId {
  EmStatus = '3867fdd9-4c67-432b-91ec-285064926f62'
}
