import {
  IEmployeePayment,
  IEmployeeHire,
  IEmployeeBasic,
  IEmployeePersonal,
  IEmployeeProbation,
  IEmployeeRetirement,
  IEmployeeStatutory,
  IEmployeeEmergency,
  IEmployeeAttendance
} from './employee.dto'
import { EmployeeState } from './employee.state'

export const mapEmployeeStateToEmployeeInfo = (employee: EmployeeState): IEmployeeBasic => ({
  title: employee.title,
  firstName: employee.firstName,
  lastName: employee.lastName,
  fullName: employee.fullName,
  preferredName: employee.preferredName,
  email: employee.email,
  workPhone: employee.workPhone,
  employeeNo: employee.employeeNo
})

export const mapEmployeeStateToEmployeePersonal = (employee: EmployeeState): IEmployeePersonal => ({
  birthDate: employee.birthDate,
  gender: employee.gender,
  maritalStatus: employee.maritalStatus,
  marriageDate: employee.marriageDate,
  raceId: employee.raceId,
  religionId: employee.religionId,
  nationalityId: employee.nationalityId,
  age: employee.age,
  birthPlace: employee.birthPlace,
  personalEmail: employee.personalEmail,
  personalPhone: employee.personalPhone,
  bio: employee.bio
})

export const mapEmployeeStateToEmployeeEmploymentPeriod = (
  employee: EmployeeState,
  companyId: string
): IEmployeeHire => ({
  companyId,
  hireDate: employee.hireDate,
  hiringType: employee.hiringType,
  initialHireDate: employee.initialHireDate,
  employmentEndDate: employee.employmentEndDate,
  terminationNoticeDate: employee.terminationNoticeDate,
  terminationReasonId: employee.terminationReasonId,
  terminationNotes: employee.terminationNotes,
  serviceLength: employee.serviceLength,
  initialServiceLength: employee.initialServiceLength,
  emStatus: employee.emStatus
})

export const mapEmployeeStateToEmployeeProbation = (employee: EmployeeState): IEmployeeProbation => ({
  probationPeriod: employee.probationPeriod,
  probationEndDate: employee.probationEndDate,
  probationNotes: employee.probationNotes,
  confirmationDate: employee.confirmationDate
})

export const mapEmployeeStateToEmployeeRetirement = (employee: EmployeeState): IEmployeeRetirement => ({
  retireAge: employee.retireAge,
  retireDate: employee.retireDate
})

export const mapEmployeeStateToEmployeePayment = (employee: EmployeeState): IEmployeePayment => ({
  payMethod: employee.payMethod,
  paymentNotes: employee.paymentNotes,
  bankCode: employee.bankCode,
  bankBranchCode: employee.bankBranchCode,
  bankAccountNo: employee.bankAccountNo,
  bankAccountName: employee.bankAccountName,
  bankCode2: employee.bankCode2,
  bankBranchCode2: employee.bankBranchCode2,
  bankAccountNo2: employee.bankAccountNo2,
  bankAccountName2: employee.bankAccountName2,
  paymentNotes2: employee.paymentNotes2,
  bankAllocation: employee.bankAllocation,
  bankAmount2: employee.bankAmount2,
  bankPercentage2: employee.bankPercentage2
})

export const mapEmployeeStateToEmployeeStatutory = (employee: EmployeeState): IEmployeeStatutory => ({
  fundCode: employee.fundCode,
  fundCode2: employee.fundCode2,
  fundExcludeDate: employee.fundExcludeDate
})

export const mapEmployeeStateToEmployeeAttendance = (employee: EmployeeState): IEmployeeAttendance => ({
  badgeNo: employee.badgeNo
})

export const mapEmployeeStateToEmployeeEmergency = (employee: EmployeeState): IEmployeeEmergency => ({
  emergencyName: employee.emergencyName,
  emergencyRelation: employee.emergencyRelation,
  emergencyPhone: employee.emergencyPhone,
  emergencyName2: employee.emergencyName2,
  emergencyRelation2: employee.emergencyRelation2,
  emergencyPhone2: employee.emergencyPhone2
})
