import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit'
import persistReducer from 'redux-persist/es/persistReducer'
import { CalendarEvent, KeyValue } from '~/types/common'
import {
  MyRootState,
  myPersistConfig,

  // employee
  SSEmployeeState,
  SSBirthdayState,
  SSUpcomingEventState,

  // leave
  SSCalendarLeaveRecordState,
  SSCalendarViewerState,
  SSLeaveApplyFormInfoState,
  SSLeaveBalanceState,
  SSLeaveEntitlementState,
  SSLeaveGrantState,
  SSLeaveRecordState,
  SSLeaveRecordViewState,
  SSLeaveRecordDtlState,
  SSLeaveRecordAttachmentState,
  SSLeaveRecordCommentState,
  SSLeaveRecordApprovalHistoryState,
  SSLeaveTaskState,
  SSOthersLeaveRecordState,
  SSOthersBackInState,
  ROLeavePeriodYearState,
  ROLeaveEntitlementViewState,
  ROLeEarnedState,
  ROLeaveRecordViewState
} from './types'
import { SSRealTimeAttendanceState, SSRealTimeSummaryState } from './types/ssclock-record.state'

// employee
const birthdaysAdapter = createEntityAdapter<SSBirthdayState>()
const upcomingEventsAdapter = createEntityAdapter<SSUpcomingEventState>()
const myCalendarViewersAdapter = createEntityAdapter<SSCalendarViewerState>({ selectId: model => model.category })
const myCalViewAsAdapter = createEntityAdapter<KeyValue>({ selectId: model => model.key })

// leave
const myLeaveApplyFormInfoAdapter = createEntityAdapter<SSLeaveApplyFormInfoState>({ selectId: model => model.code })
const myLeaveBalancesAdapter = createEntityAdapter<SSLeaveBalanceState>()
const myLeaveEntitlementsAdapter = createEntityAdapter<SSLeaveEntitlementState>()
const myLeaveGrantsAdapter = createEntityAdapter<SSLeaveGrantState>()
const myLeaveCalendarEventsAdapter = createEntityAdapter<CalendarEvent>()
const myLeaveRecordsAdapter = createEntityAdapter<SSLeaveRecordState>()
const myLeaveRecordDtlsAdapter = createEntityAdapter<SSLeaveRecordDtlState>()
const myLeaveRecordApprovalHistoriesAdapter = createEntityAdapter<SSLeaveRecordApprovalHistoryState>()
const myLeaveRecordAttachmentsAdapter = createEntityAdapter<SSLeaveRecordAttachmentState>()
const myLeaveRecordCommentsAdapter = createEntityAdapter<SSLeaveRecordCommentState>()
const myLeaveTasksAdapter = createEntityAdapter<SSLeaveTaskState>()
const othersLeaveRecordsAdapter = createEntityAdapter<SSOthersLeaveRecordState>()
const othersBackInDaysAdapter = createEntityAdapter<SSOthersBackInState>()
const myCalendarLeaveEventsAdapter = createEntityAdapter<CalendarEvent>()
const myCalendarLeaveRecordsAdapter = createEntityAdapter<SSCalendarLeaveRecordState>()
const roLeavePeriodYearAdapter = createEntityAdapter<ROLeavePeriodYearState>({ selectId: model => model.periodYear })
const roLeaveEntitlementViewAdapter = createEntityAdapter<ROLeaveEntitlementViewState>({
  selectId: model => model.viewId
})
const roLeEarnedAdapter = createEntityAdapter<ROLeEarnedState>()
const roLeaveRecordViewAdapter = createEntityAdapter<ROLeaveRecordViewState>({
  selectId: model => model.viewId
})

// attendance
const realTimeAttendanceAdapter = createEntityAdapter<SSRealTimeAttendanceState>({
  selectId: model => model.employeeId
})

// employee
const birthdaysInitialState = birthdaysAdapter.getInitialState()
const upcomingEventsInitialState = upcomingEventsAdapter.getInitialState()
const myCalendarViewersInitialState = myCalendarViewersAdapter.getInitialState()
const myCalViewAsInitialState = myCalViewAsAdapter.getInitialState()

// leave
const myLeaveApplyFormInfoInitialState = myLeaveApplyFormInfoAdapter.getInitialState()
const myLeaveBalancesInitialState = myLeaveBalancesAdapter.getInitialState()
const myLeaveEntitlementsInitialState = myLeaveEntitlementsAdapter.getInitialState()
const myLeaveGrantsInitialState = myLeaveGrantsAdapter.getInitialState()
const myLeaveCalendarEventsInitialState = myLeaveCalendarEventsAdapter.getInitialState()
const myLeaveRecordsInitialState = myLeaveRecordsAdapter.getInitialState()
const myLeaveRecordApprovalHistoriesInitialState = myLeaveRecordApprovalHistoriesAdapter.getInitialState()
const myLeaveRecordAttachmentsInitialState = myLeaveRecordAttachmentsAdapter.getInitialState()
const myLeaveRecordDtlsInitialState = myLeaveRecordDtlsAdapter.getInitialState()
const myLeaveRecordCommentsInitialState = myLeaveRecordCommentsAdapter.getInitialState()
const myLeaveTasksInitialState = myLeaveTasksAdapter.getInitialState()
const othersLeaveRecordsInitialState = othersLeaveRecordsAdapter.getInitialState()
const othersBackInDaysInitialState = othersBackInDaysAdapter.getInitialState()
const myCalendarLeaveEventsInitialState = myCalendarLeaveEventsAdapter.getInitialState()
const myCalendarLeaveRecordsInitialState = myCalendarLeaveRecordsAdapter.getInitialState()
const roLeavePeriodYearInitialState = roLeavePeriodYearAdapter.getInitialState()
const roLeaveEntitlementViewInitialState = roLeaveEntitlementViewAdapter.getInitialState()
const roLeEarnedInitialState = roLeEarnedAdapter.getInitialState()
const roLeaveRecordViewInitialState = roLeaveRecordViewAdapter.getInitialState()

// attendance
const realTimeAttendanceInitialState = realTimeAttendanceAdapter.getInitialState()

const initialState: MyRootState = {
  // employee
  myEmployee: { id: '', fullName: '', roles: [], attributes: {} },
  myEmployeeLoading: false,
  birthdays: birthdaysInitialState,
  birthdaysLoading: false,
  upcomingEvents: upcomingEventsInitialState,
  upcomingEventsLoading: false,
  myCalendarViewers: myCalendarViewersInitialState,
  myCalendarViewersLoading: false,
  myCalViewAs: myCalViewAsInitialState,
  myCalViewAsLoading: false,

  // leave
  myLeaveApplyFormInfo: {},
  myLeaveApplyFormInfoLoading: {},
  myLeaveBalances: myLeaveBalancesInitialState,
  myLeaveBalancesLoading: false,
  myLeaveBalancesRefetch: 0,
  myLeaveEntitlements: myLeaveEntitlementsInitialState,
  myLeaveEntitlementsLoading: false,
  myLeaveEntitlementsRefetch: 0,
  myLeaveGrants: myLeaveGrantsInitialState,
  myLeaveGrantsLoading: false,
  myLeaveCalendarEvents: myLeaveCalendarEventsInitialState,
  myLeaveCalendarEventsLoading: false,
  myLeaveCalendarEventsRefetch: 0,
  myLeaveRecords: myLeaveRecordsInitialState,
  myLeaveRecordsLoading: false,
  myLeaveRecordsView: { count: 0, data: [] },
  myLeaveRecordsViewLoading: false,
  myLeaveRecordsViewRefetch: 0,
  myLeaveRecordDtls: {},
  myLeaveRecordDtlsLoading: {},
  myLeaveRecordApprovalHistories: {},
  myLeaveRecordApprovalHistoriesLoading: {},
  myLeaveRecordAttachments: {},
  myLeaveRecordAttachmentsLoading: {},
  myLeaveRecordComments: {},
  myLeaveRecordCommentsLoading: {},
  myLeaveTasks: myLeaveTasksInitialState,
  myLeaveTasksLoading: false,
  myLeaveTasksRefetch: 0,
  othersLeaveRecords: othersLeaveRecordsInitialState,
  othersLeaveRecordsLoading: false,
  othersBackInDays: othersBackInDaysInitialState,
  othersBackInDaysLoading: false,
  myCalendarLeaveEvents: myCalendarLeaveEventsInitialState,
  myCalendarLeaveEventsLoading: false,
  myCalendarLeaveEventsRefetch: 0,
  myCalendarLeaveRecords: myCalendarLeaveRecordsInitialState,
  myCalendarLeaveRecordsLoading: false,
  myCalendarLeaveRecordsRefetch: 0,
  roLeavePeriodYears: roLeavePeriodYearInitialState,
  roLeavePeriodYearsLoading: false,
  roLeaveEntitlementsView: roLeaveEntitlementViewInitialState,
  roLeaveEntitlementsViewLoading: false,
  roLeaveEntitlementsViewRefetch: 0,
  roLeEarned: {},
  roLeEarnedLoading: {},
  roLeaveRecordsView: roLeaveRecordViewInitialState,
  roLeaveRecordsViewLoading: false,
  roLeaveRecordsViewRefetch: 0,

  // attendance
  realTimeAttendance: {},
  realTimeAttendanceLoading: {},
  realTimeAttendanceRefetch: 0,
  realTimeSummary: undefined,
  realTimeSummaryLoading: false,
  realTimeSummaryRefetch: 0
}

const mySlice = createSlice({
  name: 'my',
  initialState,
  reducers: {
    // employee
    setMyEmployeeLoading: (state, action: PayloadAction<boolean>) => {
      state.myEmployeeLoading = action.payload
    },
    replaceMyEmployee: (state, action: PayloadAction<SSEmployeeState>) => {
      state.myEmployee = action.payload
    },
    setBirthdaysLoading: (state, action: PayloadAction<boolean>) => {
      state.birthdaysLoading = action.payload
    },
    replaceBirthdays: (state, action: PayloadAction<SSBirthdayState[]>) => {
      birthdaysAdapter.setAll(state.birthdays, action.payload)
    },
    setUpcomingEventsLoading: (state, action: PayloadAction<boolean>) => {
      state.upcomingEventsLoading = action.payload
    },
    replaceUpcomingEvents: (state, action: PayloadAction<SSUpcomingEventState[]>) => {
      upcomingEventsAdapter.setAll(state.upcomingEvents, action.payload)
    },
    setMyCalendarViewersLoading: (state, action: PayloadAction<boolean>) => {
      state.myCalendarViewersLoading = action.payload
    },
    replaceMyCalendarViewers: (state, action: PayloadAction<SSCalendarViewerState[]>) => {
      myCalendarViewersAdapter.setAll(state.myCalendarViewers, action.payload)
    },
    setMyCalViewAsLoading: (state, action: PayloadAction<boolean>) => {
      state.myCalViewAsLoading = action.payload
    },
    replaceMyCalViewAs: (state, action: PayloadAction<KeyValue[]>) => {
      myCalViewAsAdapter.setAll(state.myCalViewAs, action.payload)
    },

    // leave
    refetchMyLeaveBalances: state => {
      state.myLeaveBalancesRefetch += 1
    },
    setMyLeaveBalancesLoading: (state, action: PayloadAction<boolean>) => {
      state.myLeaveBalancesLoading = action.payload
    },
    replaceMyLeaveBalances: (state, action: PayloadAction<SSLeaveBalanceState[]>) => {
      myLeaveBalancesAdapter.setAll(state.myLeaveBalances, action.payload)
    },
    refetchMyLeaveEntitlements: state => {
      state.myLeaveEntitlementsRefetch += 1
    },
    setMyLeaveEntitlementsLoading: (state, action: PayloadAction<boolean>) => {
      state.myLeaveEntitlementsLoading = action.payload
    },
    replaceMyLeaveEntitlements: (state, action: PayloadAction<SSLeaveEntitlementState[]>) => {
      myLeaveEntitlementsAdapter.setAll(state.myLeaveEntitlements, action.payload)
    },
    setMyLeaveGrantsLoading: (state, action: PayloadAction<boolean>) => {
      state.myLeaveGrantsLoading = action.payload
    },
    setMyLeaveGrants: (state, action: PayloadAction<SSLeaveGrantState[]>) => {
      myLeaveGrantsAdapter.upsertMany(state.myLeaveGrants, action.payload)
    },
    replaceMyLeaveGrants: (state, action: PayloadAction<SSLeaveGrantState[]>) => {
      myLeaveGrantsAdapter.setAll(state.myLeaveGrants, action.payload)
    },
    setMyLeaveCalendarEventsLoading: (state, action: PayloadAction<boolean>) => {
      state.myLeaveCalendarEventsLoading = action.payload
    },
    setMyLeaveCalendarEvents: (state, action: PayloadAction<CalendarEvent[]>) => {
      myLeaveCalendarEventsAdapter.upsertMany(state.myLeaveCalendarEvents, action.payload)
    },
    replaceMyLeaveCalendarEvents: (state, action: PayloadAction<CalendarEvent[]>) => {
      myLeaveCalendarEventsAdapter.setAll(state.myLeaveCalendarEvents, action.payload)
    },
    refetchMyLeaveCalendarEvents: state => {
      state.myLeaveCalendarEventsRefetch += 1
    },
    setMyLeaveRecordsLoading: (state, action: PayloadAction<boolean>) => {
      state.myLeaveRecordsLoading = action.payload
    },
    setMyLeaveRecord: (state, action: PayloadAction<SSLeaveRecordState>) => {
      myLeaveRecordsAdapter.upsertOne(state.myLeaveRecords, action.payload)
    },
    removeMyLeaveRecord: (state, action: PayloadAction<string>) => {
      myLeaveRecordsAdapter.removeOne(state.myLeaveRecords, action.payload)
    },
    refetchMyLeaveRecordsView: state => {
      state.myLeaveRecordsViewRefetch += 1
    },
    setMyLeaveRecordsViewLoading: (state, action: PayloadAction<boolean>) => {
      state.myLeaveRecordsViewLoading = action.payload
    },
    replaceMyLeaveRecordsView: (state, action: PayloadAction<SSLeaveRecordViewState>) => {
      state.myLeaveRecordsView = action.payload
    },
    setMyLeaveRecordDtlsLoading: (state, action: PayloadAction<{ id: string; loading: boolean }>) => {
      const { id, loading } = action.payload
      state.myLeaveRecordDtls[id] = state.myLeaveRecordDtls[id] || myLeaveRecordDtlsInitialState
      state.myLeaveRecordDtlsLoading[id] = loading
    },
    replaceMyLeaveRecordDtls: (state, action: PayloadAction<{ id: string; data: SSLeaveRecordDtlState[] }>) => {
      const { id, data } = action.payload
      state.myLeaveRecordDtls[id] = state.myLeaveRecordDtls[id] || myLeaveRecordDtlsInitialState
      myLeaveRecordDtlsAdapter.setAll(state.myLeaveRecordDtls[id]!, data)
    },
    setMyLeaveRecordApprovalHistoriesLoading: (state, action: PayloadAction<{ id: string; loading: boolean }>) => {
      const { id, loading } = action.payload
      state.myLeaveRecordApprovalHistories[id] =
        state.myLeaveRecordApprovalHistories[id] || myLeaveRecordApprovalHistoriesInitialState
      state.myLeaveRecordApprovalHistoriesLoading[id] = loading
    },
    replaceMyLeaveRecordApprovalHistories: (
      state,
      action: PayloadAction<{ id: string; data: SSLeaveRecordApprovalHistoryState[] }>
    ) => {
      const { id, data } = action.payload
      state.myLeaveRecordApprovalHistories[id] =
        state.myLeaveRecordApprovalHistories[id] || myLeaveRecordApprovalHistoriesInitialState
      myLeaveRecordApprovalHistoriesAdapter.setAll(state.myLeaveRecordApprovalHistories[id]!, data)
    },
    setMyLeaveRecordAttachmentsLoading: (state, action: PayloadAction<{ id: string; loading: boolean }>) => {
      const { id, loading } = action.payload
      state.myLeaveRecordAttachments[id] = state.myLeaveRecordAttachments[id] || myLeaveRecordAttachmentsInitialState
      state.myLeaveRecordAttachmentsLoading[id] = loading
    },
    replaceMyLeaveRecordAttachments: (
      state,
      action: PayloadAction<{ id: string; data: SSLeaveRecordAttachmentState[] }>
    ) => {
      const { id, data } = action.payload
      state.myLeaveRecordAttachments[id] = state.myLeaveRecordAttachments[id] || myLeaveRecordAttachmentsInitialState
      myLeaveRecordAttachmentsAdapter.setAll(state.myLeaveRecordAttachments[id]!, data)
    },
    setMyLeaveRecordCommentsLoading: (state, action: PayloadAction<{ id: string; loading: boolean }>) => {
      const { id, loading } = action.payload
      state.myLeaveRecordComments[id] = state.myLeaveRecordComments[id] || myLeaveRecordCommentsInitialState
      state.myLeaveRecordCommentsLoading[id] = loading
    },
    replaceMyLeaveRecordComments: (state, action: PayloadAction<{ id: string; data: SSLeaveRecordCommentState[] }>) => {
      const { id, data } = action.payload
      state.myLeaveRecordComments[id] = state.myLeaveRecordComments[id] || myLeaveRecordCommentsInitialState
      myLeaveRecordCommentsAdapter.setAll(state.myLeaveRecordComments[id]!, data)
    },
    setMyLeaveRecordComment: (state, action: PayloadAction<{ id: string; data: SSLeaveRecordCommentState }>) => {
      const { id, data } = action.payload
      myLeaveRecordCommentsAdapter.upsertOne(state.myLeaveRecordComments[id]!, data)
    },
    removeMyLeaveRecordComment: (state, action: PayloadAction<{ leaveRecordId: string; id: string }>) => {
      const { leaveRecordId, id } = action.payload
      myLeaveRecordCommentsAdapter.removeOne(state.myLeaveRecordComments[leaveRecordId]!, id)
    },
    setMyLeaveApplyFormInfoLoading: (state, action: PayloadAction<{ leaveTypeId: string; loading: boolean }>) => {
      const { leaveTypeId, loading } = action.payload
      state.myLeaveApplyFormInfo[leaveTypeId] =
        state.myLeaveApplyFormInfo[leaveTypeId] || myLeaveApplyFormInfoInitialState
      state.myLeaveApplyFormInfoLoading[leaveTypeId] = loading
    },
    replaceMyLeaveApplyFormInfo: (
      state,
      action: PayloadAction<{ leaveTypeId: string; data: SSLeaveApplyFormInfoState[] }>
    ) => {
      const { leaveTypeId, data } = action.payload
      state.myLeaveApplyFormInfo[leaveTypeId] =
        state.myLeaveApplyFormInfo[leaveTypeId] || myLeaveApplyFormInfoInitialState
      myLeaveApplyFormInfoAdapter.setAll(state.myLeaveApplyFormInfo[leaveTypeId]!, data)
    },
    refetchMyLeaveTasks: state => {
      state.myLeaveTasksRefetch += 1
    },
    setMyLeaveTasksLoading: (state, action: PayloadAction<boolean>) => {
      state.myLeaveTasksLoading = action.payload
    },
    replaceMyLeaveTasks: (state, action: PayloadAction<SSLeaveTaskState[]>) => {
      myLeaveTasksAdapter.setAll(state.myLeaveTasks, action.payload)
    },
    setMyLeaveTask: (state, action: PayloadAction<SSLeaveTaskState>) => {
      myLeaveTasksAdapter.upsertOne(state.myLeaveTasks, action.payload)
    },
    removeMyLeaveTask: (state, action: PayloadAction<string>) => {
      myLeaveTasksAdapter.removeOne(state.myLeaveTasks, action.payload)
    },
    setOthersLeaveRecordsLoading: (state, action: PayloadAction<boolean>) => {
      state.othersLeaveRecordsLoading = action.payload
    },
    replaceOthersLeaveRecords: (state, action: PayloadAction<SSOthersLeaveRecordState[]>) => {
      othersLeaveRecordsAdapter.setAll(state.othersLeaveRecords, action.payload)
    },
    setOthersBackInDaysLoading: (state, action: PayloadAction<boolean>) => {
      state.othersBackInDaysLoading = action.payload
    },
    replaceOthersBackInDays: (state, action: PayloadAction<SSOthersBackInState[]>) => {
      othersBackInDaysAdapter.setAll(state.othersBackInDays, action.payload)
    },
    setMyCalendarLeaveEventsLoading: (state, action: PayloadAction<boolean>) => {
      state.myCalendarLeaveEventsLoading = action.payload
    },
    setMyCalendarLeaveEvents: (state, action: PayloadAction<CalendarEvent[]>) => {
      myCalendarLeaveEventsAdapter.upsertMany(state.myCalendarLeaveEvents, action.payload)
    },
    replaceMyCalendarLeaveEvents: (state, action: PayloadAction<CalendarEvent[]>) => {
      myCalendarLeaveEventsAdapter.setAll(state.myCalendarLeaveEvents, action.payload)
    },
    refetchMyCalendarLeaveEvents: state => {
      state.myCalendarLeaveEventsRefetch += 1
    },
    setMyCalendarLeaveRecordsLoading: (state, action: PayloadAction<boolean>) => {
      state.myCalendarLeaveRecordsLoading = action.payload
    },
    setMyCalendarLeaveRecords: (state, action: PayloadAction<SSCalendarLeaveRecordState[]>) => {
      myCalendarLeaveRecordsAdapter.upsertMany(state.myCalendarLeaveRecords, action.payload)
    },
    replaceMyCalendarLeaveRecords: (state, action: PayloadAction<SSCalendarLeaveRecordState[]>) => {
      myCalendarLeaveRecordsAdapter.setAll(state.myCalendarLeaveRecords, action.payload)
    },
    refetchMyCalendarLeaveRecords: state => {
      state.myCalendarLeaveRecordsRefetch += 1
    },
    setROLeavePeriodYearsLoading: (state, action: PayloadAction<boolean>) => {
      state.roLeavePeriodYearsLoading = action.payload
    },
    replaceROLeavePeriodYears: (state, action: PayloadAction<ROLeavePeriodYearState[]>) => {
      roLeavePeriodYearAdapter.setAll(state.roLeavePeriodYears, action.payload)
    },
    refetchROLeaveEntitlementsView: state => {
      state.roLeaveEntitlementsViewRefetch += 1
    },
    setROLeaveEntitlementsViewLoading: (state, action: PayloadAction<boolean>) => {
      state.roLeaveEntitlementsViewLoading = action.payload
    },
    setROLeaveEntitlementsView: (state, action: PayloadAction<ROLeaveEntitlementViewState>) => {
      roLeaveEntitlementViewAdapter.upsertOne(state.roLeaveEntitlementsView, action.payload)
    },
    setROLeEarnedLoading: (state, action: PayloadAction<{ entId: string; loading: boolean }>) => {
      const { entId, loading } = action.payload
      state.roLeEarned[entId] = state.roLeEarned[entId] || roLeEarnedInitialState
      state.roLeEarnedLoading[entId] = loading
    },
    replaceROLeEarned: (state, action: PayloadAction<{ entId: string; data: ROLeEarnedState[] }>) => {
      const { entId, data } = action.payload
      state.roLeEarned[entId] = state.roLeEarned[entId] || roLeEarnedInitialState
      roLeEarnedAdapter.setAll(state.roLeEarned[entId], data)
    },
    refetchROLeaveRecordsView: state => {
      state.roLeaveRecordsViewRefetch += 1
    },
    setROLeaveRecordsViewLoading: (state, action: PayloadAction<boolean>) => {
      state.roLeaveRecordsViewLoading = action.payload
    },
    setROLeaveRecordsView: (state, action: PayloadAction<ROLeaveRecordViewState>) => {
      roLeaveRecordViewAdapter.upsertOne(state.roLeaveRecordsView, action.payload)
    },

    // attendance
    refetchRealTimeAttendance: state => {
      state.realTimeAttendanceRefetch += 1
    },
    replaceRealTimeAttendance: (
      state,
      action: PayloadAction<{ locationId: string; data: SSRealTimeAttendanceState[] }>
    ) => {
      const { locationId, data } = action.payload
      state.realTimeAttendance[locationId] = state.realTimeAttendance[locationId] || realTimeAttendanceInitialState
      realTimeAttendanceAdapter.setAll(state.realTimeAttendance[locationId], data)
    },
    setRealTimeAttendanceLoading: (state, action: PayloadAction<{ locationId: string; loading: boolean }>) => {
      const { locationId, loading } = action.payload
      state.realTimeAttendance[locationId] = state.realTimeAttendance[locationId] || realTimeAttendanceInitialState
      state.realTimeAttendanceLoading[locationId] = loading
    },
    setRealTimeSummary: (state, action: PayloadAction<SSRealTimeSummaryState>) => {
      state.realTimeSummary = action.payload
    },
    setRealTimeSummaryLoading: (state, action: PayloadAction<boolean>) => {
      state.realTimeSummaryLoading = action.payload
    },
    refetchRealTimeSummary: state => {
      state.realTimeSummaryRefetch += 1
    }
  }
})

export const {
  // employee
  setMyEmployeeLoading,
  replaceMyEmployee,
  setBirthdaysLoading,
  replaceBirthdays,
  setUpcomingEventsLoading,
  replaceUpcomingEvents,
  setMyCalendarViewersLoading,
  replaceMyCalendarViewers,
  setMyCalViewAsLoading,
  replaceMyCalViewAs,

  // leave
  setMyLeaveApplyFormInfoLoading,
  replaceMyLeaveApplyFormInfo,
  refetchMyLeaveBalances,
  setMyLeaveBalancesLoading,
  replaceMyLeaveBalances,
  refetchMyLeaveEntitlements,
  setMyLeaveEntitlementsLoading,
  replaceMyLeaveEntitlements,
  setMyLeaveGrantsLoading,
  setMyLeaveGrants,
  replaceMyLeaveGrants,
  setMyLeaveCalendarEventsLoading,
  setMyLeaveCalendarEvents,
  replaceMyLeaveCalendarEvents,
  refetchMyLeaveCalendarEvents,
  setMyLeaveRecordsLoading,
  setMyLeaveRecord,
  removeMyLeaveRecord,
  refetchMyLeaveRecordsView,
  setMyLeaveRecordsViewLoading,
  replaceMyLeaveRecordsView,
  setMyLeaveRecordDtlsLoading,
  replaceMyLeaveRecordDtls,
  setMyLeaveRecordApprovalHistoriesLoading,
  replaceMyLeaveRecordApprovalHistories,
  setMyLeaveRecordAttachmentsLoading,
  replaceMyLeaveRecordAttachments,
  setMyLeaveRecordCommentsLoading,
  replaceMyLeaveRecordComments,
  setMyLeaveRecordComment,
  removeMyLeaveRecordComment,
  refetchMyLeaveTasks,
  setMyLeaveTasksLoading,
  replaceMyLeaveTasks,
  setMyLeaveTask,
  removeMyLeaveTask,
  setOthersLeaveRecordsLoading,
  replaceOthersLeaveRecords,
  setOthersBackInDaysLoading,
  replaceOthersBackInDays,
  setMyCalendarLeaveEventsLoading,
  setMyCalendarLeaveEvents,
  replaceMyCalendarLeaveEvents,
  refetchMyCalendarLeaveEvents,
  setMyCalendarLeaveRecordsLoading,
  setMyCalendarLeaveRecords,
  replaceMyCalendarLeaveRecords,
  refetchMyCalendarLeaveRecords,
  setROLeavePeriodYearsLoading,
  replaceROLeavePeriodYears,
  refetchROLeaveEntitlementsView,
  setROLeaveEntitlementsViewLoading,
  setROLeaveEntitlementsView,
  setROLeEarnedLoading,
  replaceROLeEarned,
  refetchROLeaveRecordsView,
  setROLeaveRecordsViewLoading,
  setROLeaveRecordsView,

  // attendance
  refetchRealTimeAttendance,
  replaceRealTimeAttendance,
  setRealTimeAttendanceLoading,
  setRealTimeSummary,
  setRealTimeSummaryLoading,
  refetchRealTimeSummary
} = mySlice.actions

export const myReducers = { my: persistReducer<MyRootState>(myPersistConfig, mySlice.reducer) }
