import { request, getBaseUrl, timeout } from '~/utils/request'
import { ClockRecordImportState, ClockRecordImportValidateState, IClockRecordColumnMap } from '../types'
import { ImportClockRecordFormData } from '../containers/ClockRecords/components/ImportClockRecord/ImportClockRecordDrawer'

const baseUrl = getBaseUrl('/attendance/clockrecordimport')

export const apiGetClockRecordImportTemplateExcel = () =>
  request('get', `${baseUrl}/template/excel`, undefined, { responseType: 'blob', timeout })

export const apiUploadClockRecordImport = (data: ImportClockRecordFormData) => {
  let formData = new FormData()
  if (data.file instanceof File) formData.append('file', data.file, data.file.name)
  formData.append('locationId', data.locationId)
  formData.append('importType', data.importType)
  formData.append('hasHeader', data.hasHeader.toString())
  formData.append('delimiter', data.delimiter)

  return request<ClockRecordImportState>('post', `${baseUrl}/upload`, formData)
}

export const apiValidateClockRecordImport = (
  locationId: string,
  clockRecordColumnMap: IClockRecordColumnMap[],
  importType: string
) =>
  request<ClockRecordImportValidateState>('post', `${baseUrl}/validate`, {
    locationId,
    clockRecordColumnMap,
    importType
  })

export const apiGetClockRecordErrorReportExcel = () =>
  request('get', `${baseUrl}/errorreport/excel`, undefined, { responseType: 'blob', timeout })

export const apiExecuteClockRecordImport = (locationId: string, importType: string) =>
  request<void>('post', `${baseUrl}/execute`, { locationId, importType })
