import { setRealTimeSummary, setRealTimeSummaryLoading } from '../../reducers'
import { apiGetRealTimeSummary } from '../../api/ssclock-record.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'

export const fetchRealTimeSummary = (
  locationId?: string,
  options: FetchOptions = DEFAULT_FETCH_OPTIONS
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!locationId) return

    const loading = getState().my.realTimeSummaryLoading
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().my.realTimeSummary != null
      if (hasData) return
    }

    try {
      dispatch(setRealTimeSummaryLoading(true))
      const { result, status } = await apiGetRealTimeSummary(locationId)
      if (status) dispatch(setRealTimeSummary(result))
    } finally {
      dispatch(setRealTimeSummaryLoading(false))
    }
  }
}
