import React, { ChangeEvent, FC, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Col, Row } from '~/components'
import { Badge, Button, Drawer, Form, Input, Space, Tabs } from '~/core-components'
import { useClaimType } from '~/features/claim'
import { ClaExpenseSubmissionType } from '~/constants'
import { useFocus } from '~/hooks'
import { dispatch } from '~/stores/store'
import { Errors, StoreState } from '~/types/store'
import { addClaimRecordComment } from '../../../actions'
import { useMyClaimTask } from '../../../hooks'
import { SSClaimRecordComments } from '../../SSClaimRecordComments/SSClaimRecordComments'
import { MyClaimTaskForm } from './MyClaimTaskForm'
import { MyClaimTaskActionModal } from './MyClaimTaskActionModal'
import './MyClaimTaskDrawer.less'

interface MyClaimTaskDrawerProps {
  id: string
  visible: boolean
  onClose: (success?: boolean) => void
}

interface ActionModalState {
  id: string
  visible: boolean
  action: 'approve' | 'reject'
}

const DEFAULT_ACTION_MODAL_STATE: ActionModalState = { id: '', visible: false, action: 'approve' }

export const MyClaimTaskDrawer: FC<MyClaimTaskDrawerProps> = ({ id, visible, onClose }) => {
  const [actionModalState, setActionModalState] = useState<ActionModalState>(DEFAULT_ACTION_MODAL_STATE)
  const commentCount = useSelector((state: StoreState) => state.myClaim.myClaimRecordComments[id || '']?.ids.length)
  const [commenting, setCommenting] = useState(false)
  const [comment, setComment] = useState('')
  const [errors, setErrors] = useState<Errors>()
  const [focusRef, setFocus] = useFocus(true)

  const [claimTask, fetching] = useMyClaimTask(id)
  const [claimType] = useClaimType(claimTask?.claimTypeId || '')
  const isMultiple = claimType?.expenseSubmissionType === ClaExpenseSubmissionType.Multiple

  useEffect(() => {
    setTimeout(() => visible && setFocus(), 100)
    setErrors(undefined)
  }, [visible, setFocus])

  const handleCloseDrawer = useCallback(
    (success?: boolean) => {
      typeof onClose === 'function' && onClose(success)
    },
    [onClose]
  )

  const handleApprove = useCallback(async () => {
    setActionModalState({ id, visible: true, action: 'approve' })
  }, [id])

  const handleReject = useCallback(async () => {
    setActionModalState({ id, visible: true, action: 'reject' })
  }, [id])

  const handleCloseAction = useCallback(
    (success: boolean) => {
      setActionModalState(DEFAULT_ACTION_MODAL_STATE)

      if (success) {
        handleCloseDrawer(true)
      }
    },
    [handleCloseDrawer]
  )

  const handleComment = useCallback(async () => {
    if (!id) return

    try {
      setCommenting(true)
      const result = await dispatch(addClaimRecordComment(id, comment))
      if (result?.errors) {
        setErrors(result.errors)
      }

      if (!result?.errors) {
        setComment('')
        setErrors(undefined)
      }
    } finally {
      setCommenting(false)
    }
  }, [id, comment])

  return (
    <Drawer
      open={visible}
      title="Claim approval"
      onClose={() => handleCloseDrawer()}
      width={isMultiple ? 1200 : 600}
      className="my-claim-task-drawer"
      footer={
        <Space className="my-claim-task-drawer__action-bar-buttons">
          <Button type="primary" onClick={handleApprove}>
            Approve
          </Button>
          <Button onClick={handleReject}>Reject</Button>
        </Space>
      }
    >
      <Tabs
        defaultActiveKey="request"
        {...(id ? {} : { renderTabBar: () => <></> })}
        items={[
          { key: 'request', label: 'Request', children: <MyClaimTaskForm data={claimTask} loading={fetching} /> },
          {
            key: 'comment',
            label: (
              <>
                Comments
                <Badge count={commentCount} type="primary" />
              </>
            ),
            children: (
              <Form>
                <Row align="top" gutter={15}>
                  <Col flex={1}>
                    <Form.Item
                      label="Enter your comment"
                      validateStatus={errors?.comment ? 'error' : ''}
                      help={errors?.comment}
                    >
                      <Input.TextArea
                        ref={focusRef}
                        rows={2}
                        value={comment}
                        onChange={(event: ChangeEvent<HTMLTextAreaElement>) => setComment(event.target.value)}
                      />
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item label="&nbsp;">
                      <Button onClick={handleComment} loading={commenting}>
                        Send
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
                <SSClaimRecordComments id={id} />
              </Form>
            )
          }
        ]}
      />
      <MyClaimTaskActionModal {...actionModalState} onClose={handleCloseAction} />
    </Drawer>
  )
}
