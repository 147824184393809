import React, { FC, useCallback, useMemo } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { LoadingOutlined } from '@ant-design/icons'
import { Spin, Tab, Tabs } from '~/core-components'
import { DocumentTitle } from '~/components'
import { SETTINGS_ROUTES } from '~/routes/routes'
import { useSysWfFlowTypes, useWorkflow } from '../hooks'
import { WfCategory } from '../types'
import { config } from '../config'
import { WfEvents } from './WfEvents'
import './Workflow.less'

interface WorkflowProps {
  category: WfCategory
}

export type TriggerFlowType = { [flowType: string]: number }

export const Workflow: FC<WorkflowProps> = ({ category }) => {
  const match = useRouteMatch<{ id: string; tab: string }>()
  const history = useHistory()
  const [workflow, loading] = useWorkflow(category, match.params.id)
  const [flowTypes] = useSysWfFlowTypes(category)

  const routes = useMemo(
    () => [
      {
        path: SETTINGS_ROUTES.main,
        breadcrumbName: 'Settings'
      },
      {
        path: config[category].routePath,
        breadcrumbName: config[category].pageTitle
      },
      {
        path: '',
        breadcrumbName: 'Scenario'
      }
    ],
    [category]
  )

  const handleTabChange = useCallback(
    (activeKey: string) => {
      history.push(`${config[category].routeTabPath.replace(':id', match.params.id).replace(':tab?', activeKey)}`)
    },
    [history, category, match.params.id]
  )

  const tabItems = useMemo(() => {
    let result: Tab[] = []

    if (workflow?.id) {
      result = result.concat(
        flowTypes.map(f => ({
          key: f.code,
          label: f.name,
          children: <WfEvents workflowId={workflow.id} category={category} flowType={f} />
        }))
      )
    }
    return result
  }, [workflow, category, flowTypes])

  if (loading) {
    return (
      <div className="workflow__loading">
        <Spin indicator={<LoadingOutlined spin />} />
      </div>
    )
  }

  return (
    <div className="workflow">
      <DocumentTitle title={workflow?.name || 'Workflow'} />
      <div className="workflow__body">
        <Tabs breadcrumb={{ routes }} defaultActiveKey={match.params.tab} onChange={handleTabChange} items={tabItems} />
      </div>
    </div>
  )
}

export const LveApprovalWorkflow = () => <Workflow category="lve_approval" />

export const ClaApprovalWorkflow = () => <Workflow category="cla_approval" />
