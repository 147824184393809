import React, { FC } from 'react'
import { Tag } from '~/core-components'
import { ClaRecordStatus } from '~/constants'

interface ClaimStatusTagProps {
  status: string | undefined
}

export const ClaimStatusTag: FC<ClaimStatusTagProps> = ({ status }) => {
  return (
    <>
      {status === ClaRecordStatus.Draft ? (
        <Tag type="white">Draft</Tag>
      ) : status === ClaRecordStatus.Pending ? (
        <Tag type="secondary">Pending for approval</Tag>
      ) : status === ClaRecordStatus.PendingCancel ? (
        <Tag type="secondary">Pending for cancellation</Tag>
      ) : status === ClaRecordStatus.Approved ? (
        <Tag type="success">Approved</Tag>
      ) : status === ClaRecordStatus.Paid ? (
        <Tag type="success">Paid</Tag>
      ) : status === ClaRecordStatus.PendingPayment ? (
        <Tag type="tertiary">Pending for payment</Tag>
      ) : status === ClaRecordStatus.Rejected ? (
        <Tag type="danger">Rejected</Tag>
      ) : status === ClaRecordStatus.Cancelled ? (
        <Tag type="danger">Cancelled</Tag>
      ) : null}
    </>
  )
}
