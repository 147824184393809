import React, { CSSProperties, FC, useMemo } from 'react'
import moment from 'moment-timezone'
import { Form, SecondaryText, Space, UploadFile } from '~/core-components'
import { AmountDisplay, Col, Row, UploadFileSelectorAuth } from '~/components'
import { ClaAttachment } from '~/constants'
import { getBaseUrl } from '~/utils'
import { SSClaimTaskTokenState } from '../../types'

interface MyClaimTaskTokenSingleFormProps {
  token: string
  data: SSClaimTaskTokenState
}

const baseUrl = getBaseUrl('/claim')
const taxStyle: CSSProperties = { fontWeight: 'normal', fontStyle: 'italic' }

export const MyClaimTaskTokenSingleForm: FC<MyClaimTaskTokenSingleFormProps> = ({ token, data }) => {
  const attachments = useMemo(
    () =>
      data?.attachments
        .filter(att => !att.claimRecordExpenseId)
        .map(
          att =>
            ({
              uid: att?.fileId || '',
              name: att?.fileName || '',
              size: att?.fileSize || 0,
              type: att?.fileFormat || '',
              url: `${baseUrl}/ssclaimtasktoken/attachment/${token}/${att.id}/downloadfile`
            } as UploadFile)
        ),
    [data, token]
  )

  return (
    <>
      <Row gutter={[15, 0]}>
        <Col span={12}>
          <Form.Item label="Receipt no.">{data.task.receiptNo || '-'}</Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Expense date">
            {data.task.expenseDate ? moment(data.task.expenseDate).format('DD MMM YYYY') : '-'}
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[15, 0]}>
        <Col span={12}>
          <Form.Item label="Expense amount">
            <Space direction="vertical" size={0}>
              <AmountDisplay symbol={data.task.expenseCurrencyCode} value={data.task.expenseAmount} />
              {data.task.isTaxable && (
                <SecondaryText size="small" style={taxStyle}>
                  Inclusive of GST{' '}
                  <AmountDisplay symbol={data.claimType.currencyCode} value={data.task.claimAmountTax} />
                </SecondaryText>
              )}
            </Space>
          </Form.Item>
        </Col>
        <Col
          span={12}
          hidden={
            !data.claimType.currencyCode ||
            !data.task.expenseCurrencyCode ||
            data.claimType.currencyCode === data.task.expenseCurrencyCode
          }
        >
          <Form.Item label="Exchange rate">
            {
              <Space>
                {data.task.exchangeRate}
                {data.task.exchangeUnit > 1 && <SecondaryText size="small">/ {data.task.exchangeUnit}</SecondaryText>}
              </Space>
            }
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item label="Vendor / Provider">{data.task.providerName || '-'}</Form.Item>
        </Col>
      </Row>
      {data.claimType.attachment !== ClaAttachment.notApplicable && (
        <Row>
          <Col span={24}>
            <Form.Item label="Attachment">
              <UploadFileSelectorAuth
                fileList={attachments}
                listType="picture-card"
                disabled={true}
                onChange={() => {}}
              />
            </Form.Item>
          </Col>
        </Row>
      )}
      <Row>
        <Col span={24}>
          <Form.Item label="Notes">{data.task.notes || '-'}</Form.Item>
        </Col>
      </Row>
    </>
  )
}
