import { showSuccess, showError } from '~/utils'
import { ActionResult, ThunkResult } from '~/types/store'
import { apiAddClockRecord } from '../api/clock-record.api'
import { refetchClockRecordsView } from '../reducers'
import { ISaveClockRecord } from '../types'
import { emptyGuid } from '~/constants'

export const addClockRecord = (clockRecord: ISaveClockRecord): ThunkResult<void> => {
  return async (dispatch): Promise<ActionResult> => {
    const { status, result, errors, message, errorData } = await apiAddClockRecord({
      ...clockRecord,
      employeeId: clockRecord.employeeId || emptyGuid
    })
    if (status) {
      dispatch(refetchClockRecordsView())
      showSuccess('Added')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { result, errors }
  }
}
