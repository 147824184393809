import { request, getBaseUrl } from '~/utils'
import { Pagination } from '~/types/common'
import {
  AttendancePeriodState,
  AttendancePeriodViewState,
  IAddAttendancePeriod,
  IUpdateAttendancePeriod
} from '../types'

const baseUrl = getBaseUrl('/attendance/attendanceperiod')

export const apiGetAttendancePeriodsView = (viewId: string, periodYear: number, params: Pagination) =>
  request<AttendancePeriodViewState>('get', `${baseUrl}/view/${viewId}/${periodYear}`, params)

export const apiGetAttendancePeriods = () => request<AttendancePeriodState[]>('get', baseUrl)

export const apiGetAttendancePeriodsByPayPeriod = (payPeriod: string, payGroupId: string) =>
  request<AttendancePeriodState[]>('get', baseUrl, { payPeriod, payGroupId })

export const apiGetAttendancePeriod = (id: string) => request<AttendancePeriodState>('get', `${baseUrl}/${id}`)

export const apiAddAttendancePeriod = (attendancePeriod: IAddAttendancePeriod) =>
  request<{ id: string }>('post', baseUrl, attendancePeriod)

export const apiUpdateAttendancePeriod = (id: string, attendancePeriod: IUpdateAttendancePeriod) =>
  request<void>('put', `${baseUrl}/${id}`, attendancePeriod)

export const apiDeleteAttendancePeriod = (id: string) => request<void>('delete', `${baseUrl}/${id}`)

export const apiCompleteAttendancePeriod = (attendancePeriodId: string) =>
  request<void>('post', `${baseUrl}/to-complete`, { attendancePeriodId })

export const apiRevertToDraftAttendancePeriod = (attendancePeriodId: string) =>
  request<void>('post', `${baseUrl}/revert-to-draft`, { attendancePeriodId })
