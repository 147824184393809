import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { FetchStrategy } from '~/types/common'
import { StoreState } from '~/types/store'
import { fetchMyClaimTask } from '../actions'
import { SSClaimTaskState } from '../types'

export const useMyClaimTask = (
  id?: string,
  fetchStrategy: FetchStrategy = 'always'
): [SSClaimTaskState | undefined, boolean] => {
  const claimTask = useSelector((state: StoreState) => state.myClaim.myClaimTasks?.entities[id || ''])
  const loading = useSelector((state: StoreState) => state.myClaim.myClaimTasksLoading)

  useEffect(() => {
    if (id) dispatch(fetchMyClaimTask(id, { strategy: fetchStrategy }))
  }, [id, fetchStrategy])

  return [claimTask, loading]
}
