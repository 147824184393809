import React, { FC, useCallback } from 'react'
import { useSelector } from 'react-redux'
import classNames from 'classnames'
import { Avatar, Card, CardProps, Tag } from '~/core-components'
import { Col, ImageAuth, Row } from '~/components'
import { ADMIN_PATH, SELF_SERVICE_PATH } from '~/routes/routes'
import { getBaseUrl, getInitials } from '~/utils'
import { authInstance } from '~/auth'
import { StoreState } from '~/types/store'
import { MyTenantState, LoginRoleType } from '../../types'
import './TenantCard.less'

interface TenantCardProps extends CardProps {
  tenant: MyTenantState
  role: LoginRoleType
}

const activeTenantCode = localStorage.getItem('tenant')
const AVATAR_SIZE = 48

const baseUrl = getBaseUrl('/host')

export const TenantCard: FC<TenantCardProps> = ({ tenant, role }) => {
  const isAdmin = window.location.pathname.startsWith('/admin/')
  const activeRole = isAdmin ? 'admin' : 'employee'
  const isSelected = tenant.tenantCode === activeTenantCode && role === (isAdmin ? 'admin' : 'employee')
  const description = role === 'admin' ? 'Admin portal' : role === 'employee' ? 'Employee portal' : undefined
  const myTenantPhotoId = useSelector((state: StoreState) => state.iam.myTenantPhotoId)
  const thumbnailPhoto = tenant.tenantCode
    ? `${baseUrl}/tenant/${tenant.tenantCode}/logo/thumbnail/${AVATAR_SIZE}${
        myTenantPhotoId ? `?id=${myTenantPhotoId}` : ''
      }`
    : undefined

  const handleSwitchTenant = useCallback(
    (tenantCode: string, role: LoginRoleType) => {
      if (tenantCode === activeTenantCode && role === activeRole) return

      authInstance.clearLocalStorage({ switchTenant: tenantCode })

      setTimeout(() => {
        // redirect to landing page
        const path = role === 'admin' ? ADMIN_PATH : SELF_SERVICE_PATH
        window.location.href = `${window.location.origin}${path}`
      }, 0)
    },
    [activeRole]
  )

  return (
    <Card
      hoverable={!isSelected}
      bordered={false}
      onClick={() => handleSwitchTenant(tenant.tenantCode, role)}
      className={classNames('tenant-card', { 'tenant-card--selected': isSelected })}
    >
      <Row>
        <Col>
          <i
            className={classNames(
              'role-icon',
              'fal',
              { 'fa-user-gear': role === 'admin' },
              { 'fa-user-group': role === 'employee' }
            )}
          />
        </Col>
        <Col>
          <Avatar
            className="tenant-logo"
            size={AVATAR_SIZE}
            shape="square"
            src={
              <ImageAuth
                src={thumbnailPhoto}
                width={AVATAR_SIZE}
                preview={false}
                defaultImage={
                  <Avatar size={AVATAR_SIZE} shape="square">
                    {getInitials(tenant.tenantName, 'tenant')}
                  </Avatar>
                }
              />
            }
          />
        </Col>
        <Col className="card-info-col" flex="auto">
          <div className="card-info">
            <div className="card-info-title">
              {tenant.tenantName}
              <Tag type="original">{tenant.workspaceCode}</Tag>
            </div>
            <div className="card-info-description">{description}</div>
          </div>
        </Col>
      </Row>
      {isSelected && <i className="fal fa-circle-check selected-icon" />}
    </Card>
  )
}
