import React, { FC } from 'react'
import { LoadingOutlined } from '@ant-design/icons'
import { Spin } from '~/core-components'
import { ApprovalTargets, Col, Row } from '~/components'
import { Delimiter } from '~/constants'
import { useSysWfActionTypes, useWfActions } from '../../hooks'
import { WfEventState } from '../../types'
import { WfActionType } from '~/constants/workflow'
import './WfActions.less'

export interface WfActionsProps {
  wfEvent: WfEventState
}

export const NO_TARGET_ACTION = [WfActionType.approve]

export const WfActions: FC<WfActionsProps> = ({ wfEvent }) => {
  const [wfActions, loading] = useWfActions(wfEvent.id)
  const [wfActionTypes] = useSysWfActionTypes(wfEvent.flowType, wfEvent.eventType)

  if (loading) {
    return <Spin indicator={<LoadingOutlined spin />} />
  }

  return (
    <div className="wf-actions">
      <label>Actions</label>
      {wfActions.map(action => (
        <Row align="middle">
          <Col flex="150px">{wfActionTypes[action.actionType]?.name}</Col>
          <Col flex="1" hidden={NO_TARGET_ACTION.includes(action.actionType)}>
            <ApprovalTargets
              value={action.target ? action.target.split(Delimiter.pipe) : undefined}
              readOnly
              showAsTag
            />
          </Col>
        </Row>
      ))}
    </div>
  )
}
