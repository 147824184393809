import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { AttendancePeriodState } from '../types'

export const selectAttendancePeriods = createSelector(
  (state: StoreState) => state.attendance.attendancePeriods,
  attendancePeriodsState =>
    memoize(() => {
      return (Object.values(attendancePeriodsState?.entities || {}) as AttendancePeriodState[]).sort((a, b) =>
        b.payPeriod.localeCompare(a.payPeriod)
      )
    })
)
