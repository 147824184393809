import React, { FC } from 'react'
import { Layout } from 'antd'
import { useMyTenant } from '~/features/iam'
import { FeatureFlag, SsRole, Tenant } from '~/constants'
import { MY_ROUTES } from '~/routes/routes'
import { SelfServiceSidebarLink } from './SelfServiceSidebarLink/SelfServiceSidebarLink'
import { MySsAccessGate, useMyCalViewAs, useMyEmployee } from '~/features/my'
import './SelfServiceSidebar.less'

const { Sider } = Layout

interface SelfServiceSidebarProps {}

const ALLOWED_ROLES = [SsRole.ReportingManager]

export const SelfServiceSidebar: FC<SelfServiceSidebarProps> = () => {
  const [me] = useMyEmployee()
  const [myTenant] = useMyTenant()
  const [myCalViewAs] = useMyCalViewAs()
  const hideLeave = myTenant?.tenantCode === Tenant.ACE || myTenant?.tenantCode === Tenant.ACEDEMO

  return (
    <div className="self-service-sidebar">
      <Sider width={80} className="self-service-sidebar__sider" trigger={null}>
        {/* Hide home when no leave, claim or attendance */}
        <MySsAccessGate featureFlag={[FeatureFlag.MyLeave, FeatureFlag.MyClaim, FeatureFlag.MyAttendance]}>
          <SelfServiceSidebarLink to={MY_ROUTES.home} icon={<i className="fal fa-house fa-fw" />} title="Home" />
        </MySsAccessGate>
        {/* Hide me when no profile, payroll, leave or claim */}
        <MySsAccessGate
          featureFlag={[FeatureFlag.MyProfile, FeatureFlag.MyPayroll, FeatureFlag.MyLeave, FeatureFlag.MyClaim]}
        >
          <SelfServiceSidebarLink
            to={MY_ROUTES.self.replace('/:tab?', '')}
            icon={<i className="fal fa-address-card fa-fw" />}
            title="My profile"
          />
        </MySsAccessGate>
        <MySsAccessGate featureFlag={[FeatureFlag.MyProfile]}>
          <SelfServiceSidebarLink
            to={MY_ROUTES.org}
            icon={<i className="fal fa-sitemap fa-fw" />}
            title="Organisation"
          />
        </MySsAccessGate>
        {myCalViewAs.length > 0 && (
          <MySsAccessGate featureFlag={FeatureFlag.MyLeave}>
            <SelfServiceSidebarLink
              to={MY_ROUTES.calendar.replace('/:tab?', '')}
              icon={<i className="fal fa-calendar fa-fw" />}
              title="Calendar"
            />
          </MySsAccessGate>
        )}
        {ALLOWED_ROLES.some(ro => me.roles.includes(ro)) && (
          <>
            <MySsAccessGate featureFlag={[FeatureFlag.MySchedule]}>
              <SelfServiceSidebarLink
                to={MY_ROUTES.roSchedule.replace('/:tab?', '')}
                icon={<i className="fal fa-calendar-check fa-fw" />}
                title="Schedule"
              />
            </MySsAccessGate>
            {!hideLeave && (
              <MySsAccessGate featureFlag={FeatureFlag.MyLeave}>
                <SelfServiceSidebarLink
                  to={MY_ROUTES.roLeave.replace('/:tab?', '')}
                  icon={<i className="fal fa-tree-palm fa-fw" />}
                  title="Leave"
                />
              </MySsAccessGate>
            )}
          </>
        )}
      </Sider>
    </div>
  )
}
