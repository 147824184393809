import { SETTINGS_ROUTES } from '~/routes/routes'
import { WfCategory } from './types'
import { Screen } from '../selection'

interface Config {
  documentTitle: string
  pageTitle: string
  routePath: string
  routeTabPath: string
  screen: Screen
}

export const config: { [category in WfCategory]: Config } = {
  lve_approval: {
    documentTitle: 'Leave Approval',
    pageTitle: 'Leave approval',
    routePath: SETTINGS_ROUTES.leaveApproval,
    routeTabPath: SETTINGS_ROUTES.leaveApprovalTab,
    screen: 'leave_approval_workflow'
  },
  cla_approval: {
    documentTitle: 'Claim Approval',
    pageTitle: 'Claim approval',
    routePath: SETTINGS_ROUTES.claimApproval,
    routeTabPath: SETTINGS_ROUTES.claimApprovalTab,
    screen: 'claim_approval_workflow'
  }
}
