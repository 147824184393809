import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment-timezone'
import { Button, Card, ColumnsType, Form, Space, Table, TableRowSelection } from '~/core-components'
import { DateCustomPicker, DateCustomPickerItem, Person, SearchInput } from '~/components'
import { useSysOptions } from '~/features/employee'
import { Screen, ViewCriteriaSimple, ViewCriteria, updateViewCriteria, useFirstView } from '~/features/selection'
import { usePermissionGate } from '~/features/iam'
import { Permission, PermissionAction } from '~/constants'
import { EMP_ROUTES } from '~/routes/routes'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { selectLeavePendingApprovalsView } from '../../selectors'
import { formatDateRange, getBaseUrl, showError } from '~/utils'
import { LeavePendingApprovalRowState } from '../../types'
import { refetchLeavePendingApprovalsView } from '../../reducers'
import { useLeaveTypesDict } from '../../hooks'
import { LeaveTypeName } from '../LeaveEntitlement/components/LeaveTypeName'
import { LeaveStatusTag } from '../LeaveStatusTag/LeaveStatusTag'
import { MutateLeaveRecordDrawer } from '../LeaveRecord/components/MutateLeaveRecordDrawer'
import { approveLeavePendingApproval, fetchLeavePendingApprovalsView, rejectLeavePendingApproval } from '../../actions'
import { LeavePendingChangeApproverDrawer } from './components/LeavePendingChangeApproverDrawer'
import './LeavePendingApproval.less'

interface LeavePendingApprovalProps {}

interface DrawerState {
  visible: boolean
  employeeId?: string
  id?: string
  editing?: boolean
}

interface ChangeApproverDrawerState {
  leaveRecordIds: string[]
  visible: boolean
}

const SCREEN_CODE: Screen = 'leave_pending_approval'
const DEFAULT_DRAWER_STATE: DrawerState = { visible: false, editing: false }
const DEFAULT_CHANGE_APPROVER_DRAWER_STATE: ChangeApproverDrawerState = { leaveRecordIds: [], visible: false }

const TODAY_YEAR = moment().year()
const START_DATE = moment().startOf('year').format('YYYY-MM-DD')
const END_DATE = moment().endOf('year').format('YYYY-MM-DD')

const PERIOD_OPTIONS: DateCustomPickerItem[] = [
  {
    key: TODAY_YEAR.toString(),
    value: 'This year',
    startDate: START_DATE,
    endDate: END_DATE
  },
  {
    key: (TODAY_YEAR - 1).toString(),
    value: 'Last year',
    startDate: moment().startOf('year').add(-1, 'year').format('YYYY-MM-DD'),
    endDate: moment().endOf('year').add(-1, 'year').format('YYYY-MM-DD')
  }
]

const baseUrl = getBaseUrl('/filestore')

export const LeavePendingApproval: FC<LeavePendingApprovalProps> = () => {
  const [selected, setSelected] = useState<LeavePendingApprovalRowState[]>([])
  const [search, setSearch] = useState<string>('')
  const [drawerState, setDrawerState] = useState<DrawerState>(DEFAULT_DRAWER_STATE)
  const [changeApproverDrawerState, setChangeApproverDrawerState] = useState<ChangeApproverDrawerState>(
    DEFAULT_CHANGE_APPROVER_DRAWER_STATE
  )

  const [leaveTypes] = useLeaveTypesDict()
  const [view, viewLoading] = useFirstView(SCREEN_CODE)
  const viewId = view?.id || ''
  const dataLoading = useSelector((state: StoreState) => state.leave.leavePendingApprovalsViewLoading)
  const data = useSelector(selectLeavePendingApprovalsView)(viewId)
  const [leaveUnits] = useSysOptions('lve_unit')
  const canModify = usePermissionGate(Permission.lveRecord, PermissionAction.Modify)
  const canViewEmployee = usePermissionGate(Permission.employee)
  const refetch = useSelector((state: StoreState) => state.leave.leavePendingApprovalsViewRefetch)
  const [approving, setApproving] = useState(false)
  const [rejecting, setRejecting] = useState(false)

  const [startDate, setStartDate] = useState<string | null>(START_DATE)
  const [endDate, setEndDate] = useState<string | null>(END_DATE)

  useEffect(() => {
    if (viewId) dispatch(fetchLeavePendingApprovalsView(viewId, search, startDate, endDate))
  }, [viewId, search, startDate, endDate, refetch])

  const handleDateRangeChange = useCallback((startDate: string | null, endDate: string | null) => {
    setStartDate(startDate)
    setEndDate(endDate)
  }, [])

  const handleOpenLeaveRecord = useCallback(
    (row: LeavePendingApprovalRowState) => {
      setDrawerState({ visible: true, editing: false, id: row.id, employeeId: row.employeeId })
    },
    [setDrawerState]
  )

  let columns: ColumnsType<LeavePendingApprovalRowState> = [
    {
      title: 'Name',
      key: 'employeeId',
      dataIndex: 'employeeId',
      width: 250,
      render: (value: string, record) => (
        <Person
          name={record.employeeName}
          photo={record.photoId && `${baseUrl}/file/${record.photoId}/thumbnailphoto/18`}
          path={canViewEmployee ? EMP_ROUTES.employee.replace(':id', value) : undefined}
          size={18}
        />
      )
    },
    {
      title: 'Leave type',
      key: 'leaveTypeId',
      dataIndex: 'leaveTypeId',
      width: 200,
      render: (value: string, record) => <LeaveTypeName id={value} path={() => handleOpenLeaveRecord(record)} />
    },
    {
      title: 'Date',
      key: 'startDate',
      dataIndex: 'startDate',
      width: 130,
      render: (value: string, record: LeavePendingApprovalRowState) => formatDateRange(record.startDate, record.endDate)
    },
    {
      title: 'Duration',
      key: 'leaveTotal',
      dataIndex: 'leaveTotal',
      width: 100,
      align: 'right',
      render: (value: string, record: LeavePendingApprovalRowState) =>
        `${value} ${leaveUnits[leaveTypes[record.leaveTypeId]?.unit || '']?.value?.toLowerCase() || ''}`
    },
    {
      title: 'Status',
      key: 'approvalStatuses',
      dataIndex: 'approvalStatuses',
      width: 150,
      render: (value: string[]) => <LeaveStatusTag statuses={value} />
    },
    {
      title: <i className="fal fa-paperclip-vertical att-icon-header" />,
      key: 'attachmentCount',
      dataIndex: 'attachmentCount',
      width: 50,
      render: (value: number) => {
        return (
          value > 0 && (
            <>
              <i className="fal fa-paperclip-vertical" />
              <span className="att-count">{value}</span>
            </>
          )
        )
      }
    },
    {
      title: 'Current approver',
      key: 'currentApprover',
      dataIndex: 'currentApprover',
      width: 200
    },
    {
      title: 'Notes',
      key: 'notes',
      dataIndex: 'notes',
      width: 300
    }
  ]

  const handleCriteriaApply = useCallback(
    async (criteria: ViewCriteria[]) => {
      if (viewId) {
        await dispatch(updateViewCriteria(SCREEN_CODE, viewId, { id: viewId, criteria }))
        dispatch(refetchLeavePendingApprovalsView())
      }
    },
    [viewId]
  )

  const handleSearch = useCallback((value: string) => {
    setSearch(value)
  }, [])

  const handleCloseDrawer = useCallback(
    (action: 'saved' | 'cancelled') => {
      if (action === 'saved' && viewId) {
        dispatch(refetchLeavePendingApprovalsView())
      }
      setDrawerState(DEFAULT_DRAWER_STATE)
    },
    [viewId]
  )

  const handleApprove = useCallback(async () => {
    try {
      setApproving(true)
      await dispatch(approveLeavePendingApproval({ leaveRecordIds: selected.map(s => s.id) }))
      setSelected([])
    } finally {
      setApproving(false)
    }
  }, [selected])

  const handleReject = useCallback(async () => {
    try {
      setRejecting(true)
      await dispatch(rejectLeavePendingApproval({ leaveRecordIds: selected.map(s => s.id) }))
      setSelected([])
    } finally {
      setRejecting(false)
    }
  }, [selected])

  const handleChangeApprover = useCallback(async () => {
    if (selected.length > 0) {
      setChangeApproverDrawerState({ visible: true, leaveRecordIds: selected.map(s => s.id) })
    } else {
      showError('Please select at least one leave record')
    }
  }, [selected])

  const handleCloseChangeApproverDrawer = useCallback(() => {
    setChangeApproverDrawerState(DEFAULT_CHANGE_APPROVER_DRAWER_STATE)
  }, [])

  const rowSelection: TableRowSelection<LeavePendingApprovalRowState> = useMemo(
    () => ({
      type: 'checkbox',
      selectedRowKeys: selected.map(s => s.id),
      hideSelectAll: false,
      onChange: (_selectedRowKeys, rows) => {
        setSelected(rows)
      }
    }),
    [selected]
  )

  return (
    <div className="leave-pending-approval">
      <div className="leave-pending-approval__body">
        <div className="leave-pending-approval__action-bar">
          <Form.Item label="">
            <SearchInput onSearch={handleSearch} />
          </Form.Item>
          <ViewCriteriaSimple screenCode={SCREEN_CODE} viewId={viewId} onApply={handleCriteriaApply} label="" />
          {canModify && (
            <Space align="start">
              <DateCustomPicker
                defaultValue={TODAY_YEAR.toString()}
                options={PERIOD_OPTIONS}
                onChange={handleDateRangeChange}
              />
              <Button onClick={handleChangeApprover}>
                <Space>
                  <i className="fa-light fa-right-left" />
                  Change approver
                </Space>
              </Button>
              <Button onClick={handleReject} loading={rejecting}>
                <Space>
                  <i className="fa-light fa-times" />
                  Reject
                </Space>
              </Button>
              <Button onClick={handleApprove} loading={approving}>
                <Space>
                  <i className="fa-light fa-check" />
                  Approve
                </Space>
              </Button>
            </Space>
          )}
        </div>
        <Card fitParent table>
          <Table
            rowSelection={rowSelection}
            rowKey="id"
            dataSource={data?.data}
            columns={columns}
            fitParent
            loading={dataLoading || viewLoading}
            scroll={{ y: 1000 }}
            pagination={false}
          />
        </Card>
      </div>
      <MutateLeaveRecordDrawer {...drawerState} onClose={handleCloseDrawer} />
      <LeavePendingChangeApproverDrawer {...changeApproverDrawerState} onClose={handleCloseChangeApproverDrawer} />
    </div>
  )
}
