import { showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiValidateClockRecordImport } from '../api/clock-record-import.api'
import { replaceClockRecordImportValidate } from '../reducers'
import { IClockRecordColumnMap } from '../types'

export const validateClockRecordImport = (
  locationId: string,
  clockRecordColumnMap: IClockRecordColumnMap[],
  importType: string
): ThunkResult<void> => {
  return async dispatch => {
    const { status, result, errors, message, errorData } = await apiValidateClockRecordImport(
      locationId,
      clockRecordColumnMap,
      importType
    )
    if (status) {
      dispatch(replaceClockRecordImportValidate(result))
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors, result }
  }
}
