import React, { FC, useCallback, useEffect, useState } from 'react'
import { Alert, Button, Form, Link } from '~/core-components'
import { ClaKeyValues, Col, EditableCard, EditableCardState, Row } from '~/components'
import { usePermissionGate } from '~/features/iam'
import { dispatch } from '~/stores/store'
import { Errors, ActionResult } from '~/types/store'
import { Permission, PermissionAction, emptyGuid } from '~/constants'
import { CtExpenseTypeState } from '../../../types'
import { useCtExpenseTypes } from '~/features/claim/hooks'
import { updateCtExpenseTypes } from '~/features/claim/actions'
import './ClaimTypeExpenseType.less'

interface CtExpenseTypeProps {
  claimTypeId: string
}

const RELEVANT_ERRORS = ['expenseTypeId']

interface FormData {
  expenseTypes: CtExpenseTypeState[]
}

const EMPTY_FORM_DATA: FormData = {
  expenseTypes: []
}

export const ClaimTypeExpenseType: FC<CtExpenseTypeProps> = ({ claimTypeId }) => {
  const [cardState, setCardState] = useState<EditableCardState>()
  const [formData, setFormData] = useState<FormData>(EMPTY_FORM_DATA)
  const [errors, setErrors] = useState<Errors>()
  const relevantErrors = Object.keys(errors || {}).filter(e => RELEVANT_ERRORS.includes(e))
  const [ctExpenseTypes] = useCtExpenseTypes(claimTypeId)
  const canModify = usePermissionGate(Permission.claType, PermissionAction.Modify)
  const readOnly = cardState !== 'editing' && cardState !== 'saving'

  const NEW_CT_EXPENSE_TYPE: CtExpenseTypeState[] = [
    {
      id: emptyGuid,
      claimTypeId: claimTypeId,
      expenseTypeId: ''
    }
  ]

  const mapToState = (formData: FormData): CtExpenseTypeState[] => {
    return [...formData.expenseTypes]
  }

  const mapToFormData = (dataTakens: CtExpenseTypeState[]): FormData => ({
    expenseTypes: dataTakens
  })

  useEffect(() => {
    if (ctExpenseTypes.length > 0) {
      setFormData(mapToFormData(ctExpenseTypes))
    } else {
      setFormData(EMPTY_FORM_DATA)
    }
  }, [ctExpenseTypes])

  const handleFormDataChange = useCallback((updates: { [field: string]: any }) => {
    setErrors(undefined)
    setFormData(data => ({ ...data, ...updates }))
  }, [])

  const handleEdit = useCallback(() => {
    setCardState('editing')
  }, [])

  const handleSave = useCallback(async () => {
    if (claimTypeId) {
      setCardState('saving')
      setErrors(undefined)

      let result: ActionResult | undefined
      try {
        result = await dispatch(updateCtExpenseTypes(claimTypeId, mapToState(formData)))
      } catch {
        setCardState('editing')
      }

      if (result?.errors) {
        setCardState('editing')
        setErrors(result.errors)
      }

      if (!result?.errors) {
        setCardState(undefined)
      }
    }
  }, [claimTypeId, formData])

  const handleCancel = useCallback(() => {
    setCardState(undefined)
    setErrors(undefined)

    if (ctExpenseTypes.length > 0) {
      setFormData(mapToFormData(ctExpenseTypes))
    } else {
      setFormData(EMPTY_FORM_DATA)
    }
  }, [ctExpenseTypes])

  return (
    <EditableCard
      title="Expense setting"
      bodyStyle={{ paddingBottom: claimTypeId ? 6 : 24, paddingTop: 6 }}
      state={canModify ? cardState : 'readonly'}
      formId="form-ct-expense-type"
      onEdit={handleEdit}
      onSave={handleSave}
      onCancel={handleCancel}
    >
      <Row>
        <Col span={24}>
          {errors && relevantErrors.length > 0 && (
            <Alert
              className="ct-expense-type__error"
              type="error"
              message={
                <>
                  {relevantErrors.map((error, index) => (
                    <div key={index}>{errors[error]}</div>
                  ))}
                </>
              }
            />
          )}
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          {formData.expenseTypes.map((et, index) => (
            <Row gutter={6} key={index} className="ct-expense-type__row">
              <Col span={9}>
                {index === 0 && <div className="ct-expense-type__row-title">Expense type</div>}
                <ClaKeyValues
                  id="expenseType"
                  value={et.expenseTypeId}
                  showSearch
                  allowClear={false}
                  readOnly={readOnly}
                  dropdownMatchSelectWidth={false}
                  onChange={(expenseTypeId: string) =>
                    handleFormDataChange({
                      expenseTypes: [
                        ...formData.expenseTypes.slice(0, index),
                        { ...formData.expenseTypes[index], expenseTypeId },
                        ...formData.expenseTypes.slice(index + 1, formData.expenseTypes.length)
                      ]
                    })
                  }
                />
              </Col>
              <Col span={1} style={{ whiteSpace: 'nowrap' }}>
                <Button
                  size="small"
                  type="text"
                  icon={<i className="fal fa-xmark" />}
                  hidden={readOnly}
                  onClick={() =>
                    handleFormDataChange({
                      expenseTypes: [
                        ...formData.expenseTypes.slice(0, index),
                        ...formData.expenseTypes.slice(index + 1, formData.expenseTypes.length)
                      ]
                    })
                  }
                />
              </Col>
            </Row>
          ))}
          <Form.Item hidden={readOnly}>
            <Link
              onClick={() => handleFormDataChange({ expenseTypes: [...formData.expenseTypes, ...NEW_CT_EXPENSE_TYPE] })}
            >
              add more
            </Link>
          </Form.Item>
        </Col>
      </Row>
    </EditableCard>
  )
}
