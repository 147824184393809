import React, { FC, useState, useCallback, useEffect, ChangeEvent, useMemo } from 'react'
import moment from 'moment-timezone'
import confirm from 'antd/lib/modal/confirm'
import { RangeValue } from 'rc-picker/lib/interface.d'
import { Checkbox, CheckboxChangeEvent, Form, Input } from '~/core-components'
import { Col, DrawerForm, Row } from '~/components'
import { useFocus } from '~/hooks/use-focus'
import { dispatch } from '~/stores/store'
import { ActionResult, Errors } from '~/types/store'
import { AttendancePeriodState, IUpdateAttendancePeriod } from '../../../types'
import { processDaily, updateAttendancePeriod } from '~/features/attendance/actions'

interface EditAttendancePeriodDrawerProps {
  visible: boolean
  data?: AttendancePeriodState
  onClose: () => void
}

type FormData = IUpdateAttendancePeriod & { processAll: boolean }

const EMPTY_FORM_DATA: FormData = {
  name: '',
  startDate: '',
  endDate: '',
  processAll: true
}

export const EditAttendancePeriodDrawer: FC<EditAttendancePeriodDrawerProps> = ({ visible, data, onClose }) => {
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState<FormData>(EMPTY_FORM_DATA)
  const [errors, setErrors] = useState<Errors>()
  const [focusRef, setFocus] = useFocus(true)

  const isWiderRange = useMemo(
    () =>
      data && (moment(data.startDate).isAfter(formData.startDate) || moment(data.endDate).isBefore(formData.endDate)),
    [data, formData]
  )

  useEffect(() => {
    setTimeout(() => visible && setFocus(), 100)
    setErrors(undefined)
  }, [visible, setFocus])

  useEffect(() => {
    if (data) {
      const { name, startDate, endDate } = data
      setFormData({ name, startDate, endDate, processAll: true })
    } else {
      setFormData(EMPTY_FORM_DATA)
    }
  }, [data])

  const handleFormDataChange = useCallback((updates: { [field: string]: any }) => {
    setFormData(formData => {
      const updated = { ...formData, ...updates }
      return updated
    })
  }, [])

  const handleUpdateAttendancePeriod = useCallback(async () => {
    let result: ActionResult | undefined
    setLoading(true)
    try {
      if (data) {
        result = await dispatch(updateAttendancePeriod(data.id, formData))

        if (isWiderRange && formData.processAll) {
          dispatch(processDaily(formData.startDate, formData.endDate, undefined, 'all', data.payGroupId))
        }
      }
    } finally {
      setLoading(false)
    }

    if (result?.errors) {
      setErrors(result.errors)
    }

    if (!result?.errors) {
      typeof onClose === 'function' && onClose()
      setFormData(EMPTY_FORM_DATA)
    }
  }, [data, formData, isWiderRange, onClose])

  const handleOk = useCallback(async () => {
    if (data) {
      const isShorterRange =
        moment(data.startDate).isBefore(formData.startDate) || moment(data.endDate).isAfter(formData.endDate)
      if (isShorterRange) {
        confirm({
          title: 'Update attendance period',
          content:
            'Changing the period to a shorter range will result in the deletion of daily records that fall outside the new range. Are you sure you want to proceed?',
          onOk: async () => await handleUpdateAttendancePeriod(),
          okText: 'Update'
        })
      } else {
        await handleUpdateAttendancePeriod()
      }
    }
  }, [data, formData, handleUpdateAttendancePeriod])

  return (
    <DrawerForm
      open={visible}
      title="Edit attendance period"
      onClose={onClose}
      confirmLoading={loading}
      width={500}
      formId="form-edit-attendance-period"
    >
      <Form id="form-edit-attendance-period" onFinish={handleOk}>
        <Row>
          <Col span={24}>
            <Form.Item label="Name" validateStatus={errors?.name ? 'error' : ''} help={errors?.name}>
              <Input
                ref={focusRef}
                value={formData.name}
                onChange={(event: ChangeEvent<HTMLInputElement>) => handleFormDataChange({ name: event.target.value })}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              label="Period"
              validateStatus={errors?.startDate || errors?.endDate ? 'error' : ''}
              help={errors?.startDate || errors?.endDate}
            >
              <Input.DateRange
                value={[
                  formData.startDate ? moment(formData.startDate) : null,
                  formData.endDate ? moment(formData.endDate) : null
                ]}
                onCalendarChange={(dates: RangeValue<moment.Moment>) => {
                  const startDate = dates && dates[0] ? dates[0].format('YYYY-MM-DD') : null
                  const endDate = dates && dates[1] ? dates[1].format('YYYY-MM-DD') : null
                  handleFormDataChange({ startDate, endDate })
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row hidden={!isWiderRange}>
          <Col span={24}>
            <Form.Item label="">
              <Checkbox
                checked={formData.processAll}
                onChange={(event: CheckboxChangeEvent) => {
                  handleFormDataChange({ processAll: event.target.checked })
                }}
              >
                Calculate for all employees
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </DrawerForm>
  )
}
