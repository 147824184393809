import React, { FC, useCallback, useMemo } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { LoadingOutlined } from '@ant-design/icons'
import classNames from 'classnames'
import { Spin, Tab, Tabs } from '~/core-components'
import { DocumentTitle } from '~/components'
import { useEmPublic } from '~/features/employee'
import { MyClaim } from '~/features/my-claim'
import { MyPayroll } from '~/features/my-payroll'
import { MY_ROUTES } from '~/routes/routes'
import { FeatureFlag } from '~/constants'
import { MyProfile } from './components/MyProfile/MyProfile'
import { MyLeave } from './components/MyLeave/MyLeave'
import { SSEmPublicSelfState } from '../../types'
import { useMyEmployee, useHasMySsAccess } from '../../hooks'
import './Me.less'

interface MeProps {}

export const Me: FC<MeProps> = () => {
  const [me] = useMyEmployee()
  const myId = me.id
  const [myEm, loading] = useEmPublic(myId, 'always')
  const match = useRouteMatch<{ tab: string }>()
  const history = useHistory()

  const hasSsAccessMyProfile = useHasMySsAccess(FeatureFlag.MyProfile)
  const hasSsAccessMyPayroll = useHasMySsAccess(FeatureFlag.MyPayroll)
  const hasSsAccessMyLeave = useHasMySsAccess(FeatureFlag.MyLeave)
  const hasSsAccessMyClaim = useHasMySsAccess(FeatureFlag.MyClaim)

  const isOneTab =
    (hasSsAccessMyProfile && !hasSsAccessMyPayroll && !hasSsAccessMyLeave && !hasSsAccessMyClaim) ||
    (!hasSsAccessMyProfile && hasSsAccessMyPayroll && !hasSsAccessMyLeave && !hasSsAccessMyClaim) ||
    (!hasSsAccessMyProfile && !hasSsAccessMyPayroll && hasSsAccessMyLeave && !hasSsAccessMyClaim) ||
    (!hasSsAccessMyProfile && !hasSsAccessMyPayroll && !hasSsAccessMyLeave && hasSsAccessMyClaim)

  const classes = classNames({ 'ant-tabs--single-nav': isOneTab })

  const handleTabChange = useCallback(
    (activeKey: string) => {
      history.push(`${MY_ROUTES.self.replace(':tab?', activeKey)}`)
    },
    [history]
  )

  const tabItems = useMemo(() => {
    const result: Tab[] = []

    if (hasSsAccessMyProfile) {
      // || match.params.tab === 'profile') {
      result.push({ key: 'profile', label: 'Profile', children: <MyProfile data={myEm as SSEmPublicSelfState} /> })
    }

    if (hasSsAccessMyPayroll) {
      // || match.params.tab === 'payroll') {
      result.push({ key: 'payroll', label: 'Payroll', children: <MyPayroll /> })
    }

    if (hasSsAccessMyLeave) {
      // || match.params.tab === 'leave') {
      result.push({ key: 'leave', label: 'Leave', children: <MyLeave /> })
    }

    if (hasSsAccessMyClaim) {
      // || match.params.tab === 'claim') {
      result.push({ key: 'claim', label: 'Claim', children: <MyClaim /> })
    }

    return result
  }, [hasSsAccessMyProfile, hasSsAccessMyPayroll, hasSsAccessMyLeave, hasSsAccessMyClaim, myEm])

  if (loading) return <Spin indicator={<LoadingOutlined spin />} />

  return (
    <div className="me">
      <DocumentTitle title="Profile" />
      <Tabs
        defaultActiveKey={match.params.tab || 'profile'}
        onChange={handleTabChange}
        className={classes}
        items={tabItems}
      />
    </div>
  )
}
