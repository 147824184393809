import { showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiUploadClockRecordImport } from '../api/clock-record-import.api'
import { replaceClockRecordImport } from '../reducers'
import { ImportClockRecordFormData } from '../containers/ClockRecords/components/ImportClockRecord/ImportClockRecordDrawer'

export const uploadClockRecordImport = (formData: ImportClockRecordFormData): ThunkResult<void> => {
  return async dispatch => {
    const { status, result, errors, message, errorData } = await apiUploadClockRecordImport(formData)
    if (status) {
      dispatch(replaceClockRecordImport(result))
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }

    return { errors, result }
  }
}
