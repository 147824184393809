import { replaceRealTimeAttendance, setRealTimeAttendanceLoading } from '../../reducers'
import { apiGetRealTimeAttendance } from '../../api/ssclock-record.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'

export const fetchRealTimeAttendance = (
  locationId?: string,
  options: FetchOptions = DEFAULT_FETCH_OPTIONS
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!locationId) return

    const loading = getState().my.realTimeAttendanceLoading[locationId]
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().my.realTimeAttendance[locationId]?.ids?.length > 0
      if (hasData) return
    }

    try {
      dispatch(setRealTimeAttendanceLoading({ locationId, loading: true }))
      const { result, status } = await apiGetRealTimeAttendance(locationId)
      if (status) dispatch(replaceRealTimeAttendance({ locationId, data: result }))
    } finally {
      dispatch(setRealTimeAttendanceLoading({ locationId, loading: false }))
    }
  }
}
