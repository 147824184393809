import React, { FC } from 'react'
import classNames from 'classnames'
import Decimal from 'decimal.js'
import padStart from 'lodash/padStart'
import { LoadingOutlined } from '@ant-design/icons'
import { Spin } from '~/core-components'
import { formatMoney } from '~/utils'
import './AmountDisplay.less'

interface AmountDisplayProps {
  symbol?: string
  value: number
  size?: 'small' | 'default' | 'large'
  className?: string
  block?: boolean
  loading?: boolean
}

export const AmountDisplay: FC<AmountDisplayProps> = ({ symbol, value, size, block, loading, className }) => {
  const classes = classNames(
    'amount-display',
    { [`amount-display--${size}`]: size !== 'default' },
    { 'amount-display--block': block },
    { [`${className}`]: className }
  )

  let val = new Decimal(!isNaN(value) ? value : 0).toDecimalPlaces(2)
  const negative = val.isNegative() ? -1 : 1
  const dollar = !isNaN(value) ? negative * val.abs().times(100).div(100).floor().toNumber() : 0
  const cent = !isNaN(value) ? padStart(`${val.abs().times(100).mod(100)}`, 2, '0') : '00'

  if (loading) {
    return <Spin indicator={<LoadingOutlined spin />} />
  }

  return (
    <div className={classes}>
      {symbol && <span className="amount-display__symbol">{symbol}</span>}
      <span className="amount-display__dollar">{formatMoney(dollar, 0)}</span>
      <span className="amount-display__cent">.{cent}</span>
    </div>
  )
}
