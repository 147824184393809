import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { ClockRecordViewState } from '../types'

export const selectClockRecordsView = createSelector(
  (state: StoreState) => state.attendance.clockRecordsView,
  clockRecordsState =>
    memoize((viewId: string): ClockRecordViewState => {
      return (clockRecordsState?.entities[viewId] || {}) as ClockRecordViewState
    })
)
