import React, { FC } from 'react'
import { Tag } from '~/core-components'
import { LveApprovalStatus } from '~/constants'

interface LeaveStatusTagProps {
  statuses: string[]
}

export const LeaveStatusTag: FC<LeaveStatusTagProps> = ({ statuses }) => {
  return (
    <>
      {statuses.includes(LveApprovalStatus.approved) ? (
        <Tag type="success">Approved</Tag>
      ) : statuses.includes(LveApprovalStatus.cancelled) ? (
        <Tag type="danger">Cancelled</Tag>
      ) : statuses.includes(LveApprovalStatus.rejected) ? (
        <Tag type="danger">Rejected</Tag>
      ) : statuses.includes(LveApprovalStatus.pendingCancel) ? (
        <Tag type="secondary">Pending for cancellation</Tag>
      ) : statuses.includes(LveApprovalStatus.pending) ? (
        <Tag type="secondary">Pending for approval</Tag>
      ) : null}
    </>
  )
}
