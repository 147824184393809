import React, { ChangeEvent, CSSProperties, FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { CloseCircleOutlined, SearchOutlined } from '@ant-design/icons'
import type { SortableContainerProps, SortEnd } from 'react-sortable-hoc'
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc'
import omit from 'lodash/omit'
import { Button, ColumnsType, Input, Table, TableRowSelection } from '~/core-components'
import { useDebounce } from '~/hooks/use-debounce'
import { emptyGuid } from '~/constants'
import { arrayMove } from '~/utils'
import { GroupChildren } from '~/types/common'
import { StoreState } from '~/types/store'
import { useSysSelectionFieldsWGroup } from '../hooks'
import { Screen, SysSelectionFieldState, ViewSelectionField } from '../types'
import './ViewSelection.less'

interface ViewSelectionProps {
  screenCode: Screen
  viewId: string
  title?: string
  description?: string
  onSelect: (value: ViewSelectionField[]) => void
}

const SortableItem = SortableElement((props: React.HTMLAttributes<HTMLTableRowElement>) => <tr {...props} />)
const SortableBody = SortableContainer((props: React.HTMLAttributes<HTMLTableSectionElement>) => <tbody {...props} />)

const rowGroupStyle: CSSProperties = { whiteSpace: 'nowrap' }
const dragHandleStyle: CSSProperties = { cursor: 'pointer', color: '#999' }
const searchInputStyle: CSSProperties = { width: '50%' }
const tableScroll = { y: 1000 }
const expandable = { defaultExpandAllRows: true, expandRowByClick: false }

const DragHandle = SortableHandle(() => <i className="fal fa-grip-dots-vertical" style={dragHandleStyle} />)

export const ViewSelection: FC<ViewSelectionProps> = ({ screenCode, viewId, description, onSelect }) => {
  const [selected, setSelected] = useState<ViewSelectionField[]>([])
  const [searchText, setSearchText] = useState<string>('')
  const [availableSelection, setAvailableSelection] = useState<GroupChildren<SysSelectionFieldState>[]>([])
  const schema = useSelector((state: StoreState) => state.selection.viewSchema[screenCode]?.entities[viewId])
  const selection = useSelector((state: StoreState) => state.selection.sysSelectionFields[screenCode])
  const [selectionWGroup] = useSysSelectionFieldsWGroup(screenCode)

  useEffect(() => {
    if (schema) {
      setSelected(
        schema.selection.map(f => {
          const field = selection?.entities[f.selectionFieldId] || ({} as SysSelectionFieldState)
          return { ...field, ...f }
        })
      )
    }
  }, [schema, selection])

  useEffect(() => {
    setAvailableSelection(selectionWGroup)
  }, [selectionWGroup])

  useEffect(() => {
    typeof onSelect === 'function' && onSelect(selected)
  }, [selected, onSelect])

  const filterAvailableSelection = useCallback(
    (filterText?: string) => {
      const filterGroup = (sel: GroupChildren<SysSelectionFieldState>[]) =>
        sel.filter(s =>
          s.children.some(c => (filterText ? c.description.toLowerCase().includes(filterText.toLowerCase()) : true))
        )

      const filterChildren = (sel: GroupChildren<SysSelectionFieldState>[]) =>
        sel.map(s => ({
          ...s,
          children: s.children
            .filter(c => !selected.some(x => x.selectionFieldId === c.id))
            .filter(c => (filterText ? c.description.toLowerCase().includes(filterText.toLowerCase()) : true))
        }))

      const filtered = filterChildren(filterGroup(selectionWGroup)).filter(s => s.children.length > 0)
      setAvailableSelection(filtered)
    },
    [selected, selectionWGroup]
  )

  useDebounce(() => filterAvailableSelection(searchText), 300, [searchText, filterAvailableSelection])

  const handleRemove = useCallback(
    (record: SysSelectionFieldState) => {
      setSelected(selected.filter(s => s.fieldName !== record.fieldName))
    },
    [selected]
  )

  const columnsSelection: ColumnsType<GroupChildren<SysSelectionFieldState> | SysSelectionFieldState> = useMemo(
    () => [
      {
        title: 'Selections',
        key: 'description',
        dataIndex: 'description',
        render: (value: string, record) =>
          'children' in record ? (
            <div style={rowGroupStyle}>
              {record.name} ({record.children.length})
            </div>
          ) : (
            value
          )
      }
    ],
    []
  )

  const columnsSelected: ColumnsType<SysSelectionFieldState> = useMemo(
    () => [
      {
        dataIndex: 'sort',
        width: 30,
        className: 'drag-visible',
        render: () => <DragHandle />
      },
      {
        title: `${selected.length} selected`,
        key: 'description',
        dataIndex: 'description',
        className: 'drag-visible'
      },
      {
        key: 'action',
        width: 45,
        align: 'right',
        render: (value, record) => (
          <Button
            type="text"
            className="remove-button"
            icon={<CloseCircleOutlined />}
            onClick={() => handleRemove(record)}
          />
        )
      }
    ],
    [handleRemove, selected.length]
  )

  const handleSearchInput = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value)
  }, [])

  const handleSortEnd = useCallback(
    ({ oldIndex, newIndex }: SortEnd) => {
      if (oldIndex !== newIndex) {
        const sorted = arrayMove<ViewSelectionField>(selected, oldIndex, newIndex).map((x, index) => ({
          ...x,
          sequence: index
        }))
        setSelected(sorted)
      }
    },
    [selected]
  )

  const rowSelection: TableRowSelection<SysSelectionFieldState> = useMemo(
    () => ({
      type: 'checkbox',
      selectedRowKeys: selected.map(s => s.fieldName),
      hideSelectAll: true,
      onChange: (rowKeys, rows) => {
        setSelected([
          ...selected,
          ...rows.map((r, index) => ({
            ...omit(r, 'id'),
            id: emptyGuid,
            selectionFieldId: r.id,
            sequence: selected.length + index + 1
          }))
        ])
      }
    }),
    [selected]
  )

  const DraggableContainer: FC<SortableContainerProps> = props => (
    <SortableBody
      useDragHandle
      disableAutoscroll
      helperClass="view-selection__row-dragging"
      onSortEnd={handleSortEnd}
      {...props}
    />
  )

  const DraggableBodyRow: FC<any> = ({ className, style, ...restProps }) => {
    const index = selected.findIndex(x => x.fieldName === (restProps['data-row-key'] as string))
    return <SortableItem index={index} {...restProps} />
  }

  return (
    <div className="view-selection">
      <div className="view-selection__table-header">
        {description && <div className="view-selection__instruction">{description}</div>}
        <Input
          placeholder="Search by name"
          value={searchText}
          onChange={handleSearchInput}
          allowClear
          suffix={<SearchOutlined />}
          style={searchInputStyle}
        />
      </div>
      <div className="view-selection__table">
        <div className="view-selection__table-selection">
          {availableSelection?.length > 0 && (
            <Table
              className="selection"
              rowKey="id"
              rowSelection={rowSelection}
              rowClassName={record => ('children' in record ? 'selection__group' : '')}
              dataSource={availableSelection}
              columns={columnsSelection}
              expandable={expandable}
              indentSize={0}
              pagination={false}
              scroll={tableScroll}
              fitParent
            />
          )}
        </div>
        <div className="view-selection__table-selected">
          <Table
            className="selected"
            rowKey="fieldName"
            dataSource={selected}
            columns={columnsSelected}
            pagination={false}
            scroll={tableScroll}
            fitParent
            components={{
              body: {
                wrapper: DraggableContainer,
                row: DraggableBodyRow
              }
            }}
          />
        </div>
      </div>
    </div>
  )
}
