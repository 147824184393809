import { showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiLockAllDailyRecords } from '../api/daily-record.api'
import { DailyRecordState } from '../types'
import { setDailyRecords, setDailyRecordsLoading, setDailyRecordsView } from '../reducers'

export const lockAllDailyRecords = (attendancePeriodId: string): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!attendancePeriodId) return

    try {
      dispatch(setDailyRecordsLoading(true))
      const { result, status, errors, message, errorData } = await apiLockAllDailyRecords(attendancePeriodId)
      if (status) {
        const attendancePeriod = getState().attendance.attendancePeriods?.entities[attendancePeriodId]

        const dailyRecords = result.map(item => {
          const dailyRecord = getState().attendance.dailyRecords?.entities[item.id]
          return { ...(dailyRecord as DailyRecordState), ...item }
        })
        dispatch(setDailyRecords(dailyRecords))

        const views = getState().attendance.dailyRecordsView
        views?.ids.forEach(viewId => {
          const view = views?.entities[viewId]
          const found = view?.data.find(
            r =>
              r.payGroupId === attendancePeriod?.payGroupId &&
              r.clockDate >= attendancePeriod.startDate &&
              r.clockDate <= attendancePeriod.endDate
          )
          if (found && view) {
            const updates = view.data.map(d => {
              const nd = result.find(r => r.id === d.id)
              return nd ? { ...d, ...nd } : d
            })
            dispatch(setDailyRecordsView({ ...view, data: updates }))
          }
        })
      } else {
        if (Object.keys(errors).length === 0) {
          showError(message, errorData)
        }
      }
      return { errors }
    } finally {
      dispatch(setDailyRecordsLoading(false))
    }
  }
}
