import React, { FC, useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment-timezone'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { Card, SecondaryLink, Tooltip } from '~/core-components'
import { Col, Row } from '~/components'
import { Screen, useFirstView, useViewSchema, ViewSelectionDrawer } from '~/features/selection'
import { maskString } from '~/features/employee'
import { formatMoney, formatMonth, formatYearMonth } from '~/utils'
import { StoreState } from '~/types/store'
import { dispatch } from '~/stores/store'
import { fetchEmPayroll } from '../../actions'
import { selectEmPayrollById } from '../../reducers'
import './EmPayroll.less'

export interface EmPayrollProps {
  employeeId: string
  refreshDate: string
}

interface SelectionDrawerState {
  visible: boolean
  viewId: string
}

const screenCode: Screen = 'em_payroll'

const DEFAULT_SELECTION_DRAWER_STATE: SelectionDrawerState = { visible: false, viewId: '' }

export const EmPayroll: FC<EmPayrollProps> = ({ employeeId, refreshDate }) => {
  const [view, viewLoading] = useFirstView(screenCode)
  const viewId = view?.id || ''
  const [schema] = useViewSchema(screenCode, viewId)
  const loading = useSelector((state: StoreState) => {
    const viewSchemaLoading = state.selection.viewSchemaLoading[screenCode]
    return viewSchemaLoading && viewSchemaLoading[viewId]
  })
  const dataLoading = useSelector((state: StoreState) => state.payroll.emPayrollLoading)
  const data = useSelector((state: StoreState) => selectEmPayrollById(state, employeeId))
  const fields = useSelector((state: StoreState) => state.selection.sysSelectionFields[screenCode])
  const [selectionDrawerState, setSelectionDrawerState] = useState<SelectionDrawerState>(DEFAULT_SELECTION_DRAWER_STATE)

  useDeepCompareEffect(() => {
    if (schema && viewId) dispatch(fetchEmPayroll(viewId, employeeId, refreshDate))
  }, [schema || {}, viewId, employeeId, refreshDate])

  const handleSelectionClick = useCallback(() => {
    if (schema) {
      setSelectionDrawerState({ visible: true, viewId: schema.id })
    }
  }, [setSelectionDrawerState, schema])

  const handleCloseSelectionDrawer = useCallback(() => {
    setSelectionDrawerState(DEFAULT_SELECTION_DRAWER_STATE)
  }, [])

  return (
    <Card className="em-payroll" loading={loading || dataLoading || viewLoading}>
      {schema?.selection.map((s, index) => {
        const field = fields?.entities[s.selectionFieldId]
        if (data && field) {
          const value = data[field?.fieldName]
          let display = value

          if (field?.format === 'date' && value) {
            display = moment(value).format('DD MMM YYYY')
          }
          if (field?.format === 'yearmonth' && value) {
            display = formatYearMonth(value as string)
          }
          if (field?.format === 'month' && value) {
            display = formatMonth(value as number)
          }
          if (field?.format === 'money' && value) {
            display = formatMoney(value as number)
          }

          return (
            <Row key={s.id} className="em-payroll__field">
              <Col span={11}>{field?.description}</Col>
              <Col span={12} className="em-payroll__field-value">
                {field?.fieldName === 'identityNo' ? (
                  <Tooltip title={value}>{maskString(value as string)}</Tooltip>
                ) : (
                  display
                )}
              </Col>
              {index === 0 && (
                <Col span={1}>
                  <Tooltip title="Configure employee information">
                    <SecondaryLink onClick={handleSelectionClick}>
                      <i className="fal fa-gear" />
                    </SecondaryLink>
                  </Tooltip>
                </Col>
              )}
            </Row>
          )
        }
        return null
      })}
      <ViewSelectionDrawer
        {...selectionDrawerState}
        screenCode={screenCode}
        title="Employee information to show in payroll record"
        onClose={handleCloseSelectionDrawer}
      />
    </Card>
  )
}
