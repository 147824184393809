import React, { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { Dictionary } from '@reduxjs/toolkit'
import classNames from 'classnames'
import moment from 'moment-timezone'
import { LoadingOutlined } from '@ant-design/icons'
import { Button, Card, Form, Input, Space, Spin, Tag } from '~/core-components'
import { Col, DocumentTitle, DownloadFileAuth, Person, Row } from '~/components'
import { LeaveTypeNameComponent } from '~/features/leave'
import { ILeaveRecordComment, mapSSLeaveRecordCommentStateToILeaveRecordComment } from '~/features/my'
import { EMP_PATH } from '~/routes/routes'
import { dispatch } from '~/stores/store'
import { Errors } from '~/types/store'
import { KeyValue } from '~/types/common'
import { LveAttachment, LveBuddyEventType, LveRecordType } from '~/constants'
import { decodeJwt, formatDateRange, formatNumberUnit, getBaseUrl, UnitType } from '~/utils'
import {
  addLeaveRecordCommentByToken,
  approveLeaveByToken,
  fetchMyLeaveTaskByToken,
  rejectLeaveByToken
} from '../../../../../actions'
import { SSLeaveRecordCommentsComponent } from '../../../../Leave/SSLeaveRecordComments/SSLeaveRecordComments'
import { SSLeaveRecordDtlsComponent } from '../../../../Leave/SSLeaveRecordDtls/SSLeaveRecordDtls'
import { SSLeaveApplyFormInfoState, SSLeaveTaskTokenState } from '../../../../../types'
import { LeaveBuddyConflicts } from '../../../../Leave/MyLeaveApply/components/LeaveBuddyConflicts'
import './MyLeaveTaskToken.less'

interface MyLeaveTaskProps {}

type ActionType = 'approve' | 'reject' | 'comment'

const baseUrl = getBaseUrl('/leave')
const fileBaseUrl = getBaseUrl('/filestore')

export const MyLeaveTaskToken: FC<MyLeaveTaskProps> = () => {
  const history = useHistory()
  const match = useRouteMatch<{ token: string }>()
  const token = match.params.token
  const [fetching, setFetching] = useState(true)
  const [data, setData] = useState<SSLeaveTaskTokenState>()
  const [comments, setComments] = useState<ILeaveRecordComment[]>([])
  const [forbidden, setForbidden] = useState(false)
  const [completed, setCompleted] = useState<'approved' | 'rejected'>()
  const classes = classNames('my-leave-task-token', { 'my-leave-task-token--completed': !!completed })

  const durationsShort: Dictionary<KeyValue> = useMemo(
    () =>
      !data?.durationsShort
        ? {}
        : Object.fromEntries(data.durationsShort?.map(x => [x.code, { key: x.code, value: x.name }])),
    [data]
  )
  const formInfo: Dictionary<SSLeaveApplyFormInfoState> = useMemo(
    () => (!data?.formInfo ? {} : Object.fromEntries(data.formInfo?.map(x => [x.code, x]))),
    [data]
  )
  const formInfoRefName = useMemo(() => formInfo && formInfo['relation_type'], [formInfo])
  const formInfoRefDate = useMemo(() => formInfo && formInfo['ref_date_label'], [formInfo])

  const [loading, setLoading] = useState<ActionType>()
  const [comment, setComment] = useState('')
  const [errors, setErrors] = useState<Errors>()

  useEffect(() => {
    const { tenant_code } = decodeJwt(token)
    localStorage.setItem('tenant', tenant_code || '')
  }, [token])

  useEffect(() => {
    const comments = mapSSLeaveRecordCommentStateToILeaveRecordComment(data?.comments)
    setComments(comments)
  }, [data])

  const fetchData = useCallback(async () => {
    if (token) {
      try {
        setFetching(true)
        const { result, status, statusCode } = await dispatch(fetchMyLeaveTaskByToken(token))
        if (status) {
          setData(result)
        } else if (statusCode === 403) {
          setForbidden(true)
        }
      } finally {
        setFetching(false)
      }
    }
  }, [token])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const handleApprove = useCallback(async () => {
    try {
      setLoading('approve')
      setErrors(undefined)

      const result = await dispatch(approveLeaveByToken(token, comment))

      if (result?.errors) {
        setErrors(result.errors)
      }

      if (!result?.errors) {
        setCompleted('approved')
      }
    } finally {
      setLoading(undefined)
    }
  }, [token, comment])

  const handleReject = useCallback(async () => {
    try {
      setLoading('reject')
      setErrors(undefined)

      const result = await dispatch(rejectLeaveByToken(token, comment))

      if (result?.errors) {
        setErrors(result.errors)
      }

      if (!result?.errors) {
        setCompleted('rejected')
      }
    } finally {
      setLoading(undefined)
    }
  }, [token, comment])

  const handleComment = useCallback(async () => {
    try {
      setLoading('comment')
      setErrors(undefined)
      const result = await dispatch(addLeaveRecordCommentByToken(token, comment))
      if (result?.errors) {
        setErrors(result.errors)
      }
      if (!result?.errors) {
        setComment('')

        if (result.result) {
          const mapped = mapSSLeaveRecordCommentStateToILeaveRecordComment([result.result])
          setComments(comments => [...mapped, ...comments])
        }
      }
    } finally {
      setLoading(undefined)
    }
  }, [token, comment])

  const handleLogin = useCallback(() => {
    history.replace(EMP_PATH)
  }, [history])

  return (
    <div className={classes}>
      <DocumentTitle title="Leave approval" />
      {fetching ? (
        <Spin indicator={<LoadingOutlined spin />} />
      ) : (
        <Card style={comments.length > 0 && !completed ? { width: 'calc(100vw - 300px)' } : { width: 'auto' }}>
          <div className="my-leave-task-token__content">
            <Row className="my-leave-task-token__body" gutter={50}>
              <Col span={comments.length > 0 && !completed ? 12 : 24}>
                <div className="my-leave-task-token__form">
                  {!data && !fetching && (
                    <div className="my-leave-task-token__form--empty">{forbidden ? 'Access denied' : 'Not found'}</div>
                  )}
                  {data && !fetching && (
                    <Form>
                      <Row justify="center" hidden={!completed}>
                        <Col style={{ textAlign: 'center' }}>
                          <Form.Item>
                            <h2>Leave {completed}</h2>
                            <h4>You have successfully {completed} the leave.</h4>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <Form.Item>
                            <Person
                              name={data.employee.name}
                              description={data.employee.description}
                              size={36}
                              photo={
                                data.employee.photoId &&
                                `${fileBaseUrl}/publicfile/${data.employee.photoId}/thumbnailphoto/${36}`
                              }
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <Form.Item label="Leave type">
                            <LeaveTypeNameComponent color={data.leaveType.color} name={data.leaveType.name} />
                          </Form.Item>
                        </Col>
                      </Row>
                      {data.task.recordType === LveRecordType.cancellation && (
                        <Row>
                          <Col span={24}>
                            <Form.Item label="Application type">
                              <Tag type="secondary">Cancellation</Tag>
                            </Form.Item>
                          </Col>
                        </Row>
                      )}
                      <Row>
                        <Col span={24}>
                          <Form.Item label="Dates">
                            {data.dtls?.length === 1 && data.task.leaveTotal === 0.5 ? (
                              <Space>
                                {data.task.startDate
                                  ? moment(data.task.startDate).format('DD MMM YYYY (ddd)')
                                  : undefined}
                                <Tag type="original">{durationsShort[data.dtls[0]?.leaveDuration || '']?.value}</Tag>
                              </Space>
                            ) : (
                              `${formatDateRange(data.task.startDate, data.task.endDate)} - ${formatNumberUnit(
                                data.task.leaveTotal,
                                data.leaveType?.unit as UnitType
                              )}`
                            )}
                            <SSLeaveRecordDtlsComponent dtls={data.dtls} durationsShort={durationsShort} />
                          </Form.Item>
                        </Col>
                      </Row>
                      {formInfoRefName && (
                        <Row>
                          <Col span={24}>
                            <Form.Item label={formInfoRefName?.label}>{data.task.referenceName}</Form.Item>
                          </Col>
                        </Row>
                      )}
                      {formInfoRefDate && (
                        <Row>
                          <Col span={24}>
                            <Form.Item label={formInfoRefDate?.label}>
                              {data.task.referenceDate ? moment(data.task.referenceDate).format('DD MMM YYYY') : ''}
                            </Form.Item>
                          </Col>
                        </Row>
                      )}
                      {data.leaveType?.attachment !== LveAttachment.notApplicable && (
                        <Row>
                          <Col span={24}>
                            <Form.Item label="Attachment">
                              {data.attachments?.length > 0
                                ? data.attachments.map(
                                    att =>
                                      att && (
                                        <div key={att.id}>
                                          <DownloadFileAuth
                                            url={`${baseUrl}/ssleavetasktoken/attachment/${token}/${att.id}/downloadfile`}
                                          >
                                            {att.fileName}
                                          </DownloadFileAuth>
                                        </div>
                                      )
                                  )
                                : '-'}
                            </Form.Item>
                          </Col>
                        </Row>
                      )}
                      <Row>
                        <Col span={24}>
                          <Form.Item label="Notes">{data.task.notes || '-'}</Form.Item>
                        </Col>
                      </Row>
                      <Row hidden={!data.buddies || data.buddies.length === 0}>
                        <Col span={24}>
                          <LeaveBuddyConflicts buddies={data.buddies} eventType={LveBuddyEventType.Approval} />
                        </Col>
                      </Row>
                    </Form>
                  )}
                </div>
              </Col>
              <Col span={12} hidden={comments.length === 0 || !!completed}>
                <SSLeaveRecordCommentsComponent comments={comments} showHeader />
              </Col>
            </Row>
            {data && !fetching && (
              <Form className="my-leave-task-token__footer">
                {completed ? (
                  <Row justify="center">
                    <Col>
                      <Space direction="vertical">
                        <div>You may close the tab or login to continue with Zealys.</div>
                        <Button type="primary" onClick={handleLogin}>
                          Login
                        </Button>
                      </Space>
                    </Col>
                  </Row>
                ) : (
                  <>
                    <Row>
                      <Col span={24}>
                        <Form.Item
                          label="Enter your comment"
                          validateStatus={errors?.comment ? 'error' : ''}
                          help={errors?.comment}
                        >
                          <Input.TextArea
                            rows={2}
                            value={comment}
                            onChange={(event: ChangeEvent<HTMLTextAreaElement>) => setComment(event.target.value)}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row justify="center" className="button-group">
                      <Col>
                        <Space>
                          <Button
                            type="primary"
                            onClick={handleApprove}
                            loading={loading === 'approve'}
                            disabled={loading && loading !== 'approve'}
                          >
                            Approve
                          </Button>
                          <Button
                            onClick={handleReject}
                            loading={loading === 'reject'}
                            disabled={loading && loading !== 'reject'}
                          >
                            Reject
                          </Button>
                          <Button
                            onClick={handleComment}
                            loading={loading === 'comment'}
                            disabled={loading && loading !== 'comment'}
                          >
                            Send comment
                          </Button>
                        </Space>
                      </Col>
                    </Row>
                  </>
                )}
              </Form>
            )}
          </div>
        </Card>
      )}
    </div>
  )
}
