import React, { CSSProperties, FC } from 'react'
import memoize from 'lodash/memoize'
import moment from 'moment-timezone'
import { Form, Tooltip } from '~/core-components'
import { Col, Row } from '~/components'
import { SSLeaveRecordApprovalHistoryState } from '~/features/my'
import { LveApprovalStatus } from '~/constants'

interface LeaveRecordApprovalHistoriesProps {
  histories: SSLeaveRecordApprovalHistoryState[]
  statuses: string[]
  hidden: boolean
  creatorName: string
  createdDate: string
}

const divStyles: CSSProperties = { marginTop: 8 }

export const LeaveRecordApprovalHistories: FC<LeaveRecordApprovalHistoriesProps> = ({
  histories,
  statuses,
  hidden,
  creatorName,
  createdDate
}) => {
  return (
    <Row hidden={hidden}>
      <Col span={24}>
        <Form.Item label="Approval history">
          {histories.map(h => (
            <div key={h.id} style={divStyles}>
              <b>{h.approverName} </b>
              {h.approverEmployeeNo ? `(${h.approverEmployeeNo})` : ''}{' '}
              {h.approvalStatus === LveApprovalStatus.approved ? 'approved' : 'rejected'} the leave request{' '}
              {getEventDate(h.approvedDate)}
              {h.remarks && (
                <Tooltip title={h.remarks}>
                  <i className="fal fa-comment-dots" style={{ marginLeft: 8, fontWeight: 'bold' }} />
                </Tooltip>
              )}
            </div>
          ))}
          <div style={divStyles}>
            <b>{creatorName}</b> created the leave request {getEventDate(createdDate)}
            {[LveApprovalStatus.approved, LveApprovalStatus.cancelled].some(s => statuses.includes(s)) &&
              histories.length === 0 && <b> (Auto approved)</b>}
          </div>
        </Form.Item>
      </Col>
    </Row>
  )
}

const getEventDate = memoize(date => (date ? ` on ${moment(date).format('DD MMM YYYY hh:mm A')}` : ''))
