import React, { FC, useCallback, useMemo } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { Tab, TabTitle, Tabs } from '~/core-components'
import { DocumentTitle } from '~/components'
import { usePermissionGate } from '~/features/iam'
import { dispatch } from '~/stores/store'
import { ATT_ROUTES } from '~/routes/routes'
import { Permission } from '~/constants'
import { refetchDailyRecordsView } from '../../reducers'
import { AttendancePeriods } from '../AttendancePeriods/AttendancePeriods'
import { ClockRecords } from '../ClockRecords/ClockRecords'
import './AttendanceOverview.less'

export const AttendanceOverview: FC = () => {
  const match = useRouteMatch<{ tab: string }>()
  const history = useHistory()
  const canViewDailyRecord = usePermissionGate(Permission.attDailyRecord)
  const canViewClockRecord = usePermissionGate(Permission.attClockRecord)

  const handleTabChange = useCallback(
    (activeKey: string) => {
      history.push(`${ATT_ROUTES.tab.replace(':tab?', activeKey)}`)

      if (activeKey === 'period') {
        dispatch(refetchDailyRecordsView())
      }
    },
    [history]
  )

  const tabItems = useMemo(() => {
    const result: Tab[] = []
    if (canViewDailyRecord) {
      result.push({
        key: 'period',
        label: 'Attendance periods',
        children: <AttendancePeriods />
      })
    }

    if (canViewClockRecord) {
      result.push({
        key: 'clock',
        label: 'Clock records',
        children: <ClockRecords />
      })
    }
    return result
  }, [canViewDailyRecord, canViewClockRecord])

  return (
    <div className="attendance-overview">
      <DocumentTitle title="Attendance Overview" />
      <div className="attendance-overview__body">
        <Tabs
          defaultActiveKey={match.params.tab || 'period'}
          onChange={handleTabChange}
          tabBarExtraContent={{
            left: <TabTitle title="Attendance overview" />
          }}
          items={tabItems}
        />
      </div>
    </div>
  )
}
