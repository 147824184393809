import React, { FC, useCallback, useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { Form, TabTitle, Tabs } from '~/core-components'
import { DocumentTitle, SSRoleKeyValues } from '~/components'
import { dispatch } from '~/stores/store'
import { ATT_ROUTES } from '~/routes/routes'
import { SsRole } from '~/constants'
import { ROScheduleRecords } from '../ROScheduleRecords/ROScheduleRecords'
import { refetchROScheduleRecordsView } from '../../reducers'
import './ROScheduleOverview.less'

export const ROScheduleOverview: FC = () => {
  const match = useRouteMatch<{ tab: string }>()
  const history = useHistory()
  const [role, setRole] = useState<SsRole>(SsRole.ReportingManager)

  const handleTabChange = useCallback(
    (activeKey: string) => {
      history.push(`${ATT_ROUTES.tab.replace(':tab?', activeKey)}`)

      if (activeKey === 'records') {
        dispatch(refetchROScheduleRecordsView())
      }
    },
    [history]
  )

  return (
    <div className="ro-schedule-overview">
      <DocumentTitle title="Schedule Overview" />
      <div className="ro-schedule-overview__body">
        <Tabs
          defaultActiveKey={match.params.tab || 'records'}
          onChange={handleTabChange}
          tabBarExtraContent={{
            left: <TabTitle title="Schedule overview" />,
            right: (
              <Form.Item label="View as">
                <SSRoleKeyValues value={role} onChange={setRole} size="small" />
              </Form.Item>
            )
          }}
          items={[{ key: 'records', label: 'Records', children: <ROScheduleRecords role={role} /> }]}
        />
      </div>
    </div>
  )
}
