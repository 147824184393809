import { request, getBaseUrl } from '~/utils'

const baseUrl = getBaseUrl('/attendance/attendance')

export const apiProcessDaily = (
  startDate: string,
  endDate: string,
  employeeScope: 'existing' | 'all',
  employeeIds: string[],
  payGroupId: string
) => request<void>('post', `${baseUrl}/process`, { startDate, endDate, employeeScope, employeeIds, payGroupId })

export const apiValidateDailyPolicyRow = (fieldName: string, expression: string, criteriaExpr: string) =>
  request<boolean>('post', `${baseUrl}/validate`, { fieldName, expression, criteriaExpr })
