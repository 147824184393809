import React, { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { EditOutlined } from '@ant-design/icons'
import confirm from 'antd/lib/modal/confirm'
import { Button, Form, Input, PageHeader, SecondaryLink, Space, Tab, Tabs } from '~/core-components'
import { DocumentTitle } from '~/components'
import { useCompany, usePayGroup } from '~/features/master'
import { SelectView, useFirstView } from '~/features/selection'
import { usePermissionGate } from '~/features/iam'
import { ATT_ROUTES } from '~/routes/routes'
import { formatDateRange } from '~/utils'
import { dispatch } from '~/stores/store'
import { ActionResult } from '~/types/store'
import { AttendancePeriodStatus, Permission, PermissionAction } from '~/constants'
import { useAttendancePeriod } from '../../hooks'
import { clearDailyRecordsView, refetchAttendancePeriodsView } from '../../reducers'
import { completeAttendancePeriod, deleteAttendancePeriod, revertToDraftAttendancePeriod } from '../../actions'
import { AttendancePeriodState } from '../../types'
import { DailyRecords } from '../DailyRecords/DailyRecords'
import { EditAttendancePeriodDrawer } from './components/EditAttendancePeriodDrawer'
import './AttendancePeriod.less'

interface AttendancePeriodProps {}

interface AttendancePeriodParams {
  id: string
  tab: string
}

interface DrawerState {
  visible: boolean
  data?: AttendancePeriodState
}

const DEFAULT_DRAWER_STATE: DrawerState = { visible: false }

const routes = [
  {
    path: ATT_ROUTES.tab.replace(':tab?', 'period'),
    breadcrumbName: 'Overview'
  },
  {
    path: '',
    breadcrumbName: 'Attendance period'
  }
]

export const AttendancePeriod: FC<AttendancePeriodProps> = () => {
  const { id, tab } = useParams<AttendancePeriodParams>()
  const [attendancePeriod] = useAttendancePeriod(id, 'always')
  const [payGroup] = usePayGroup(attendancePeriod?.payGroupId || '', 'when-empty')
  const [company] = useCompany(payGroup?.companyId || '', 'when-empty')
  const startDate = attendancePeriod?.startDate
  const endDate = attendancePeriod?.endDate
  const [updating, setUpdating] = useState(false)
  const history = useHistory()

  const canDelete = usePermissionGate(Permission.attDailyRecord, PermissionAction.Delete)
  const canModify = usePermissionGate(Permission.attDailyRecord, PermissionAction.Modify)

  const [view] = useFirstView('daily_record')
  const firstViewId = view?.id || ''
  const [viewId, setViewId] = useState<string>()

  const [drawerState, setDrawerState] = useState<DrawerState>(DEFAULT_DRAWER_STATE)

  useEffect(() => {
    if (!viewId && firstViewId) {
      setViewId(firstViewId)
      dispatch(clearDailyRecordsView())
    }
  }, [firstViewId, viewId])

  const handleViewChange = useCallback((viewId?: string) => {
    setViewId(viewId)
  }, [])

  const handleTabChange = useCallback(
    (activeKey: string) => {
      history.push(`${ATT_ROUTES.period.replace(':id', id).replace(':tab?', activeKey)}`)
    },
    [id, history]
  )

  const handleEditAttendancePeriod = useCallback(() => {
    setDrawerState({ visible: true, data: attendancePeriod })
  }, [attendancePeriod])

  const handleCloseDrawer = useCallback(() => {
    setDrawerState(DEFAULT_DRAWER_STATE)
  }, [])

  const handleDeleteAttendancePeriod = useCallback(() => {
    const modal = confirm({
      title: 'Delete attendance period',
      content: (
        <>
          <div>Do you want to delete attendance period "{attendancePeriod?.name || ''}"?</div>
          <br />
          <div>This will also delete the daily records.</div>
          <Form>
            <Form.Item
              className="payrun__confirm-delete"
              label={
                <>
                  Type "<b>{attendancePeriod?.name}</b>" to confirm
                </>
              }
            >
              <Input
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  modal.update({ okButtonProps: { disabled: event.target.value !== attendancePeriod?.name } })
                }}
              />
            </Form.Item>
          </Form>
        </>
      ),
      onOk: async () => {
        const result: ActionResult | undefined = await dispatch(deleteAttendancePeriod(id))
        if (!result?.errors) {
          history.push(ATT_ROUTES.tab.replace(':tab?', 'period'))
        }
      },
      width: 550,
      okText: 'Delete',
      okType: 'danger',
      okButtonProps: {
        disabled: true
      }
    })
  }, [id, attendancePeriod, history])

  const handleCompleteAttendancePeriod = useCallback(async () => {
    setUpdating(true)
    try {
      await dispatch(completeAttendancePeriod(id))
    } finally {
      setUpdating(false)
    }
  }, [id])

  const handleRevertToDraftAttendancePeriod = useCallback(async () => {
    setUpdating(true)
    try {
      await dispatch(revertToDraftAttendancePeriod(id))
      dispatch(refetchAttendancePeriodsView())
    } finally {
      setUpdating(false)
    }
  }, [id])

  const tabItems = useMemo(() => {
    const result: Tab[] = [
      {
        key: 'records',
        label: 'Records',
        children: (
          <DailyRecords viewId={viewId} attendancePeriodId={id} defaultStartDate={startDate} defaultEndDate={endDate} />
        )
      }
    ]
    return result
  }, [viewId, id, startDate, endDate])

  return (
    <div className="attendance-period">
      <DocumentTitle title="Attendance Period" />
      <PageHeader
        title={
          <div className="attendance-period__header">
            <div className="attendance-period__header-title">
              {attendancePeriod?.name} <span className="attendance-period__header-dot">&#9675;</span> {company?.name}
            </div>
            <div>
              Period {formatDateRange(attendancePeriod?.startDate, attendancePeriod?.endDate)}
              <SecondaryLink className="attendance-period__header-edit" onClick={handleEditAttendancePeriod}>
                <EditOutlined />
              </SecondaryLink>
            </div>
          </div>
        }
        breadcrumb={{ routes }}
        extra={
          <Space>
            {canDelete ? (
              <Button size="small" onClick={handleDeleteAttendancePeriod}>
                Delete attendance period
              </Button>
            ) : null}
            {attendancePeriod?.status === AttendancePeriodStatus.Draft && canModify && (
              <Button type="primary" size="small" onClick={handleCompleteAttendancePeriod} loading={updating}>
                Mark as complete
              </Button>
            )}
            {attendancePeriod?.status === AttendancePeriodStatus.Completed && canModify && (
              <Button size="small" onClick={handleRevertToDraftAttendancePeriod} loading={updating}>
                Revert completion
              </Button>
            )}
          </Space>
        }
      />
      <div className="attendance-period__body">
        <Tabs
          defaultActiveKey={tab || 'records'}
          onChange={handleTabChange}
          tabBarExtraContent={{
            right: (
              <>
                {tab === 'records' && (
                  <SelectView screenCode="daily_record" viewId={viewId} size="small" onChange={handleViewChange} />
                )}
              </>
            )
          }}
          items={tabItems}
        />
      </div>
      <EditAttendancePeriodDrawer {...drawerState} onClose={handleCloseDrawer} />
    </div>
  )
}
