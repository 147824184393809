import React, { CSSProperties, FC, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'
import { Card, ColumnsType, Space, Table, Tag, Tooltip } from '~/core-components'
import { fetchLeaveTypes, LeaveTypeName, LeaveStatusTag } from '~/features/leave'
import { useSysOptions } from '~/features/employee'
import { formatDateRange } from '~/utils'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { fetchMyLeaveRecordsView } from '../../../../../../actions'
import { SSLeaveRecordViewColumnState } from '../../../../../../types'
import { MyLeaveApplyDrawer } from '../../../../../Leave/MyLeaveApply/MyLeaveApplyDrawer'
import { LveApprovalStatus, LveDuration } from '~/constants'
import './MyLeaveRecords.less'

interface MyLeaveRecordsProps {}

interface DrawerState {
  visible: boolean
  id?: string
  editing?: boolean
}

const DEFAULT_DRAWER_STATE: DrawerState = { visible: false }
const PAGE_SIZE_OPTIONS = ['20', '50', '100']

const cardBodyStyle: CSSProperties = { padding: 0 }
const paginationStyle: CSSProperties = { marginRight: 20 }
const tooltipOverlayStyle: CSSProperties = { whiteSpace: 'pre-line', maxWidth: 400 }
const approverIconStyle: CSSProperties = { fontSize: 12 }

export const MyLeaveRecords: FC<MyLeaveRecordsProps> = () => {
  const match = useRouteMatch<{ id: string }>()
  const data = useSelector((state: StoreState) => state.my.myLeaveRecordsView)
  const loading = useSelector((state: StoreState) => state.my.myLeaveRecordsViewLoading)
  const leaveTypes = useSelector((state: StoreState) => state.leave.leaveTypes?.entities || {})
  const [leaveUnits] = useSysOptions('lve_unit')
  const refetch = useSelector((state: StoreState) => state.my.myLeaveRecordsViewRefetch)
  const [page, setPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(20)
  const [drawerState, setDrawerState] = useState<DrawerState>(DEFAULT_DRAWER_STATE)
  const [durationShort] = useSysOptions('lve_duration_short')

  useEffect(() => {
    dispatch(fetchLeaveTypes({ strategy: 'when-empty' }))
  }, [])

  useEffect(() => {
    if (match.params.id) {
      setDrawerState({
        visible: true,
        id: match.params.id,
        editing: false
      })
    }
  }, [match.params.id])

  useEffect(() => {
    dispatch(fetchMyLeaveRecordsView({ offset: pageSize * (page - 1), limit: pageSize }))
  }, [page, pageSize, refetch])

  const handleOpenLeaveRecord = useCallback(
    (leaveRecord: SSLeaveRecordViewColumnState) => {
      setDrawerState({
        visible: true,
        id: leaveRecord.id,
        editing: false
      })
    },
    [setDrawerState]
  )

  const handleCloseDrawer = useCallback(() => setDrawerState(DEFAULT_DRAWER_STATE), [])

  const handlePaginationChange = useCallback((page: number, pageSize?: number) => {
    setPage(page)
    setPageSize(pageSize || 20)
  }, [])

  const columns: ColumnsType<SSLeaveRecordViewColumnState> = [
    {
      title: 'Leave type',
      key: 'leaveTypeId',
      dataIndex: 'leaveTypeId',
      render: (value: string, record) => <LeaveTypeName id={value} path={() => handleOpenLeaveRecord(record)} />
    },
    {
      title: 'Date',
      key: 'startDate',
      dataIndex: 'startDate',
      width: 200,
      render: (value: string, record: SSLeaveRecordViewColumnState) => (
        <Space>
          {formatDateRange(record.startDate, record.endDate)}
          {[LveDuration.firstHalf, LveDuration.secondHalf].includes(record.leaveDuration) && (
            <Tag type="original">{durationShort[record.leaveDuration]?.value}</Tag>
          )}
        </Space>
      )
    },
    {
      title: 'Taken',
      key: 'leaveTotal',
      dataIndex: 'leaveTotal',
      width: 80,
      align: 'right',
      render: (value: string, record: SSLeaveRecordViewColumnState) =>
        `${value} ${leaveUnits[leaveTypes[record.leaveTypeId]?.unit || '']?.value?.toLowerCase() || ''}`
    },
    {
      title: 'Status',
      key: 'approvalStatuses',
      dataIndex: 'approvalStatuses',
      width: 120,
      render: (value: string[], record: SSLeaveRecordViewColumnState) => (
        <Space>
          <LeaveStatusTag statuses={value} />
          {[LveApprovalStatus.pending, LveApprovalStatus.pendingCancel].some(s => value.includes(s)) && (
            <Tooltip
              title={`Current approver: \n ${record.currentApprovers?.join('\n')}`}
              overlayStyle={tooltipOverlayStyle}
            >
              <i className="fal fa-user" style={approverIconStyle}></i>
            </Tooltip>
          )}
        </Space>
      )
    },
    {
      title: <i className="fal fa-paperclip-vertical att-icon-header" />,
      key: 'attachmentCount',
      dataIndex: 'attachmentCount',
      width: 50,
      align: 'center',
      render: (value: number) => {
        return (
          value > 0 && (
            <>
              <i className="fal fa-paperclip-vertical" />
              <span className="att-count">{value}</span>
            </>
          )
        )
      }
    },
    {
      title: 'Notes',
      key: 'notes',
      dataIndex: 'notes',
      render: (value: string) =>
        value && (
          <Tooltip title={value}>
            <i className="fal fa-note" />
          </Tooltip>
        )
    }
  ]

  return (
    <Card className="my-leave-records" bodyStyle={cardBodyStyle}>
      <Table
        rowKey="id"
        dataSource={data?.data}
        columns={columns}
        fitParent
        loading={loading}
        pagination={{
          total: data?.count,
          current: page,
          pageSize,
          pageSizeOptions: PAGE_SIZE_OPTIONS,
          showSizeChanger: true,
          onChange: handlePaginationChange,
          style: paginationStyle
        }}
      />
      <MyLeaveApplyDrawer {...drawerState} onClose={handleCloseDrawer} />
    </Card>
  )
}
