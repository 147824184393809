import React, { CSSProperties, FC, useCallback, useEffect, useState } from 'react'
import { useRouteMatch } from 'react-router-dom'
import moment from 'moment-timezone'
import { Card, ColumnsType, Link, SecondaryText, Space, Table, Tooltip } from '~/core-components'
import { AmountDisplay } from '~/components'
import { ClaCurrencyCode, ClaExpenseSubmissionType, ClaRecordStatus } from '~/constants'
import { dispatch } from '~/stores/store'
import { useClaimEntitlementRecords, useClaimTypesDict } from '../../../hooks'
import { refetchClaimEntitlementRecords, refetchClaimEntitlements } from '../../../reducers'
import { ClaimEntitlementRecordState } from '../../../types'
import { MutateClaimRecordDrawer } from '../../ClaimRecords/components/MutateClaimRecordDrawer'
import { ClaimStatusTag } from '../../ClaimStatusTag/ClaimStatusTag'
import './ClaimEntitlementRecords.less'

interface ClaimEntitlementRecordsProps {
  periodYear: number
  claimTypeId: string
  employeeId: string
  paymentStatuses: string[]
}

interface DrawerState {
  visible: boolean
  id?: string
  employeeId?: string
  editing?: boolean
  editable?: boolean
}

const DEFAULT_DRAWER_STATE: DrawerState = { visible: false }

const cardBodyStyle: CSSProperties = { padding: 0 }
const tooltipOverlayStyle: CSSProperties = { whiteSpace: 'pre-line', maxWidth: 400 }
const approverIconStyle: CSSProperties = { fontSize: 12 }

export const ClaimEntitlementRecords: FC<ClaimEntitlementRecordsProps> = ({
  periodYear,
  claimTypeId,
  employeeId,
  paymentStatuses
}) => {
  const match = useRouteMatch<{ id: string }>()
  const [data, loading] = useClaimEntitlementRecords(periodYear, claimTypeId, employeeId, paymentStatuses)
  const [claimTypes] = useClaimTypesDict()
  const [drawerState, setDrawerState] = useState<DrawerState>(DEFAULT_DRAWER_STATE)

  const isEditing = useCallback((status: string) => {
    return status === ClaRecordStatus.Pending
  }, [])

  const isEditable = useCallback((status: string) => {
    return status === ClaRecordStatus.Pending || status === ClaRecordStatus.Approved
  }, [])

  useEffect(() => {
    if (match.params.id) {
      const status = data.find(d => d.id === match.params.id)?.recordStatus || ''
      setDrawerState({
        visible: true,
        id: match.params.id,
        employeeId,
        editing: isEditing(status),
        editable: isEditable(status)
      })
    }
  }, [match.params.id, data, employeeId, isEditing, isEditable])

  const handleOpenClaimRecord = useCallback(
    (claimRecord: ClaimEntitlementRecordState) => {
      setDrawerState({
        visible: true,
        id: claimRecord.id,
        employeeId: claimRecord.employeeId,
        editing: isEditing(claimRecord.recordStatus),
        editable: isEditable(claimRecord.recordStatus)
      })
    },
    [isEditing, isEditable]
  )

  const handleCloseDrawer = useCallback((action: 'saved' | 'cancelled') => {
    if (action === 'saved') {
      dispatch(refetchClaimEntitlements())
      dispatch(refetchClaimEntitlementRecords())
    }
    setDrawerState(DEFAULT_DRAWER_STATE)
  }, [])

  const columns: ColumnsType<ClaimEntitlementRecordState> = [
    {
      title: 'Claim',
      key: 'claimNo',
      dataIndex: 'claimNo',
      width: 100,
      render: (value: string, record) => (
        <Link size="small" onClick={() => handleOpenClaimRecord(record)}>
          #{value}
        </Link>
      )
    },
    {
      title: 'Provider name',
      key: 'providerName',
      dataIndex: 'providerName',
      width: 200,
      render: (value: string, record) => {
        const RenderReceipt = (
          <Space size="large">
            {record.receiptNo && (
              <Space>
                <i className="fal fa-receipt" />
                {record.receiptNo}
              </Space>
            )}
            {record.attachmentCount > 0 && (
              <Space>
                <i className="fal fa-paperclip" />
                {record.attachmentCount}
              </Space>
            )}
          </Space>
        )

        return (
          <>
            <div>{value || '-'}</div>
            {(record.receiptNo || record.attachmentCount > 0) && (
              <SecondaryText block size="small">
                <Space size="large">{RenderReceipt}</Space>
              </SecondaryText>
            )}
          </>
        )
      }
    },
    {
      title: 'Expense date',
      key: 'expenseDate',
      dataIndex: 'expenseDate',
      width: 180,
      render: (value: string, record) => (
        <>
          <div>{value ? moment(value).format('DD MMM YYYY') : ''}</div>
          {record.recordStatus !== ClaRecordStatus.Draft && (
            <SecondaryText size="small">
              Submitted on {record.submittedDate ? moment(record.submittedDate).format('DD MMM YYYY') : ''}
            </SecondaryText>
          )}
        </>
      )
    },
    {
      title: 'Amount',
      key: 'claimAmount',
      dataIndex: 'claimAmount',
      width: 125,
      render: (value: number, record) => (
        <>
          <AmountDisplay
            block
            symbol={claimTypes[record.claimTypeId]?.currencyCode || ClaCurrencyCode.sgd}
            value={value}
          />
          {value !== record.expenseAmount &&
            claimTypes[record.claimTypeId]?.expenseSubmissionType === ClaExpenseSubmissionType.Single && (
              <SecondaryText block size="small">
                <AmountDisplay
                  size="small"
                  symbol={record.expenseCurrencyCode || ClaCurrencyCode.sgd}
                  value={record.expenseAmount}
                />
              </SecondaryText>
            )}
        </>
      )
    },
    {
      title: 'Status',
      key: 'recordStatus',
      dataIndex: 'recordStatus',
      width: 120,
      render: (value: string, record) => (
        <Space>
          <ClaimStatusTag status={value} />
          {[ClaRecordStatus.Pending, ClaRecordStatus.PendingCancel].some(s => value === s) && (
            <Tooltip
              title={`Current approver: \n ${record.currentApprovers?.join('\n')}`}
              overlayStyle={tooltipOverlayStyle}
            >
              <i className="fal fa-user" style={approverIconStyle}></i>
            </Tooltip>
          )}
        </Space>
      )
    },
    {
      title: 'Notes',
      key: 'notes',
      dataIndex: 'notes'
    }
  ]

  return (
    <Card className="my-claim-entitlement-records" bodyStyle={cardBodyStyle}>
      <Table rowKey="id" dataSource={data} columns={columns} loading={loading} pagination={false} />
      <MutateClaimRecordDrawer {...drawerState} onClose={handleCloseDrawer} />
    </Card>
  )
}
