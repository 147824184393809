import React, { FC, useCallback, useEffect, useState } from 'react'
import { Button, Form, Select, Space, Switch, Tag, UploadFile } from '~/core-components'
import { Col, ErrorDisplay, Row, UploadFileSelectorAuth } from '~/components'
import { Errors } from '~/types/store'
import { downloadWithDom, showError } from '~/utils'
import { useIsMountedRef } from '~/hooks/use-is-mounted-ref'
import { ImportClockRecordFormData } from '../ImportClockRecordDrawer'
import { apiGetClockRecordImportTemplateExcel } from '~/features/attendance/api/clock-record-import.api'
import { LocationKeyValues } from '../../../../Locations/components/LocationKeyValues'
import { ClockRecordImportDelimiter, ClockRecordImportType } from '~/constants'
import './UploadClockRecordFile.less'

interface UploadClockRecordFileProps {
  visible: boolean
  data: Partial<ImportClockRecordFormData>
  errors?: Errors
  onChange: (data: Partial<ImportClockRecordFormData>) => void
}

const ACCEPT = '.xls,.xlsx,.csv,.txt,.dat'

const IMPORT_DELIMTIER: Record<string, string> = {
  comma: ClockRecordImportDelimiter.Comma,
  tab: ClockRecordImportDelimiter.Tab
}

export const UploadClockRecordFile: FC<UploadClockRecordFileProps> = ({ visible, data, errors, onChange }) => {
  const isMountedRef = useIsMountedRef()
  const [templateDownloading, setTemplateDownloading] = useState(false)
  const [showAdditionalInput, setShowAdditionalInput] = useState(false)

  useEffect(() => {
    if (!data.file) {
      setShowAdditionalInput(false)
    }
  }, [data.file])

  const handleFileChange = useCallback(
    (files?: UploadFile[]) => {
      if (files && files.length > 0) {
        const fileName = files[0].name.toLowerCase()
        if (['.txt', '.dat'].some(ext => fileName.endsWith(ext))) {
          setShowAdditionalInput(true)
        } else {
          setShowAdditionalInput(false)
          typeof onChange === 'function' && onChange({ hasHeader: true, delimiter: ClockRecordImportDelimiter.Comma })
        }

        typeof onChange === 'function' && onChange({ file: files[0] })
      }
    },
    [onChange]
  )

  const handleFileRemove = useCallback(() => {
    typeof onChange === 'function' && onChange({ file: undefined })
  }, [onChange])

  const handleDownloadTemplate = useCallback(async () => {
    try {
      setTemplateDownloading(true)
      const { status, result, errors, message, errorData } = await apiGetClockRecordImportTemplateExcel()

      if (status) {
        const fileName = `upload_clock_record_template.xlsx`
        downloadWithDom(result, fileName)
      } else {
        console.error('Error while downloading', errors)
        showError(message, errorData)
      }
    } finally {
      if (isMountedRef.current) setTemplateDownloading(false)
    }
  }, [isMountedRef])

  return (
    <div className="upload-clock-record-file" hidden={!visible}>
      <Row>
        <Col span={24}>
          <Form.Item label="Location" validateStatus={errors?.locationId ? 'error' : ''} help={errors?.locationId}>
            <LocationKeyValues
              style={{ width: '200px' }}
              allowClear={false}
              value={data.locationId}
              onChange={(locationId: string) => typeof onChange === 'function' && onChange({ locationId })}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24} className="upload-clock-record-file__legends">
          <div>
            Mandatory:{' '}
            <Space>
              <Tag>Clock employee ID</Tag>
              <Tag>Time in</Tag>
            </Space>
          </div>
          <div>
            Supported file type:{' '}
            <Space>
              <Tag>.xls</Tag>
              <Tag>.xlsx</Tag>
              <Tag>.csv</Tag>
              <Tag>.txt</Tag>
              <Tag>.dat</Tag>
            </Space>
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item label="Clock record template">
            <Button onClick={handleDownloadTemplate} loading={templateDownloading}>
              Download template
            </Button>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item label="File" validateStatus={errors?.file ? 'error' : ''} help={errors?.file}>
            <UploadFileSelectorAuth
              multiple={false}
              accept={ACCEPT}
              fileList={data.file ? [data.file] : []}
              onChange={handleFileChange}
              onRemove={handleFileRemove}
            >
              <Button size="small">choose file</Button>
            </UploadFileSelectorAuth>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label="Does the file contains time in and time out?">
            <Switch
              checkedChildren="Yes"
              unCheckedChildren="No"
              checked={data.importType === ClockRecordImportType.Pair}
              onChange={(isPair: boolean) =>
                typeof onChange === 'function' &&
                onChange({ importType: isPair ? ClockRecordImportType.Pair : ClockRecordImportType.Single })
              }
            />
          </Form.Item>
        </Col>
        {showAdditionalInput && (
          <>
            <Col span={24}>
              <Form.Item label="Has header?">
                <Switch
                  checkedChildren="Yes"
                  unCheckedChildren="No"
                  checked={data.hasHeader}
                  onChange={(hasHeader: boolean) => typeof onChange === 'function' && onChange({ hasHeader })}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="Delimiter">
                <Select
                  allowClear={false}
                  style={{ width: '200px' }}
                  value={data.delimiter}
                  onChange={(delimiter: string) => typeof onChange === 'function' && onChange({ delimiter })}
                >
                  {Object.keys(IMPORT_DELIMTIER).map(x => (
                    <Select.Option key={IMPORT_DELIMTIER[x]} value={IMPORT_DELIMTIER[x]}>
                      <span style={{ textTransform: 'capitalize' }}>{x}</span>
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </>
        )}
      </Row>
      <ErrorDisplay errors={errors} />
    </div>
  )
}
