import React, { FC, useState, useCallback, useEffect, CSSProperties } from 'react'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { LoadingOutlined } from '@ant-design/icons'
import confirm from 'antd/lib/modal/confirm'
import { Checkbox, CheckboxChangeEvent, Form, Spin, Text } from '~/core-components'
import { Col, DrawerForm, EmSelect, InfoTooltip, Row } from '~/components'
import { ViewCriteria, ViewCriteriaAdvanced, useViewSchemaByName } from '~/features/selection'
import { dispatch } from '~/stores/store'
import { useFocus } from '~/hooks/use-focus'
import { Delimiter } from '~/constants'
import { apiGetEmSelect } from '~/features/employee/api/employee.api'
import { ActionResult, Errors } from '~/types/store'
import { CalendarViewerCategory, CalendarViewerState, ICalendarViewer } from '../../types'
import { deleteCalendarViewer, saveCalendarViewer } from '../../actions'
import { config } from '../../config'
import './MutateCalendarViewerDrawer.less'

export interface MutateCalendarViewerDrawerProps {
  category: CalendarViewerCategory
  visible: boolean
  data?: CalendarViewerState
  onClose: () => void
}

const EMPTY_FORM_DATA: ICalendarViewer = {
  id: '',
  category: '',
  employeeId: '',
  viewId: '',
  scope: '',
  criteria: []
}

const descriptionStyle: CSSProperties = { display: 'block', fontSize: 13 }

export const MutateCalendarViewerDrawer: FC<MutateCalendarViewerDrawerProps> = ({
  category,
  visible,
  data,
  onClose
}) => {
  const [saving, setSaving] = useState(false)
  const [formData, setFormData] = useState<ICalendarViewer>(EMPTY_FORM_DATA)
  const [focusRef, setFocus] = useFocus(true)
  const [errors, setErrors] = useState<Errors>()

  const screen = config[category].screen
  const viewName = formData?.id ? `${screen}_${formData.id}` : undefined
  const [view, viewLoading] = useViewSchemaByName(screen, viewName, false)

  const [scopes, setScopes] = useState<string[]>([])

  useEffect(() => {
    setTimeout(() => visible && setFocus(), 100)
    setErrors(undefined)
  }, [visible, setFocus])

  useDeepCompareEffect(() => {
    if (data) {
      const { id, category, employeeId, scope } = data
      if (scope) {
        const newScope = scope.split(Delimiter.pipe)
        setScopes(newScope)
      }

      setFormData({
        id,
        category,
        employeeId,
        scope,
        viewId: view?.id,
        criteria: view?.criteria || []
      })
    } else {
      setFormData({ ...EMPTY_FORM_DATA, viewId: view?.id })
      setScopes([])
    }
  }, [data || {}, view || {}])

  const handleFormDataChange = useCallback((updates: { [field: string]: any }) => {
    setErrors(undefined)
    setFormData(data => ({ ...data, ...updates }))
  }, [])

  const handleOk = useCallback(async () => {
    let result: ActionResult | undefined
    setSaving(true)
    try {
      result = await dispatch(saveCalendarViewer(screen, category, formData))
    } finally {
      setSaving(false)
    }

    if (result?.errors) {
      setErrors(result.errors)
    }

    if (!result?.errors) {
      typeof onClose === 'function' && onClose()
      setFormData(EMPTY_FORM_DATA)
    }
  }, [category, screen, formData, onClose])

  const handleDelete = useCallback(
    (calendarViewer: CalendarViewerState | undefined) => {
      if (calendarViewer) {
        const { id, employeeName } = calendarViewer
        confirm({
          title: 'Delete viewer',
          content: `Do you want to delete this viewer "${employeeName}"?`,
          onOk: async () => {
            const result: ActionResult | undefined = await dispatch(deleteCalendarViewer(category, id))
            if (result?.errors) {
              setErrors(result.errors)
            }
            if (!result?.errors) {
              typeof onClose === 'function' && onClose()
            }
          },
          okText: 'Delete',
          okType: 'danger'
        })
      }
    },
    [onClose, category]
  )

  const handleFetchEmployees = useCallback(async () => {
    const { status, result } = await apiGetEmSelect('past3mth')
    if (status) {
      return result
    }
    return []
  }, [])

  const handleScopeCheck = useCallback(
    (e: CheckboxChangeEvent) => {
      const scope = e.target.value
      if (e.target.checked) {
        scopes.push(scope)
      } else {
        scopes.splice(scopes.indexOf(scope), 1)
      }

      handleFormDataChange({ scope: scopes.join(Delimiter.pipe) })
    },
    [handleFormDataChange, scopes]
  )

  return (
    <DrawerForm
      visible={visible}
      title={data ? `Edit viewer` : `Add viewer`}
      onClose={onClose}
      confirmLoading={saving}
      width={800}
      className="mutate-calendar-viewer-drawer"
      showDelete={data ? true : false}
      onDelete={() => handleDelete(data)}
      formId={`form-${category}-calendar-viewer`}
    >
      <Form id={`form-${category}-calendar-viewer`} onFinish={handleOk}>
        <Row>
          <Col span={24}>
            <Form.Item label="Employee" validateStatus={errors?.employeeId ? 'error' : ''} help={errors?.employeeId}>
              <EmSelect
                ref={focusRef}
                value={formData.employeeId}
                onFetch={handleFetchEmployees}
                onChange={(employeeId: string) => handleFormDataChange({ employeeId })}
              />
            </Form.Item>
          </Col>
        </Row>
        {viewLoading ? (
          <Spin indicator={<LoadingOutlined spin />} />
        ) : (
          <>
            <Row>
              <Col span={24}>
                <div className="mutate-calendar-viewer-drawer__section">Criteria</div>
              </Col>
              <Col span={24} style={{ marginBottom: 10 }}>
                <Text type="secondary" style={descriptionStyle}>
                  The viewer will be able to access the leave records of employees who meet the specified criteria
                  below.
                </Text>
              </Col>
              <Col span={24}>
                <ViewCriteriaAdvanced
                  label=""
                  screenCode={screen}
                  viewId={formData.viewId}
                  onChange={(criteria: ViewCriteria[]) => handleFormDataChange({ criteria })}
                />
              </Col>
            </Row>
          </>
        )}
        <Row>
          <Col span={24}>
            <div className="mutate-calendar-viewer-drawer__section">Information</div>
          </Col>
          <Col span={24} style={{ marginBottom: 10 }}>
            <Text type="secondary" style={descriptionStyle}>
              The viewer will have access to the following information.
            </Text>
          </Col>
          <Col span={24}>
            <Checkbox checked={true} disabled>
              Leave dates
            </Checkbox>
          </Col>
          <Col span={24}>
            <Checkbox value="leave_type" onChange={handleScopeCheck} checked={scopes.includes('leave_type')}>
              Leave type
            </Checkbox>
          </Col>
          <Col span={24}>
            <Checkbox
              value="lve_approval_status"
              onChange={handleScopeCheck}
              checked={scopes.includes('lve_approval_status')}
            >
              Approval status
              <InfoTooltip title="When the approval status option is unchecked, the viewer will only have access to approved leave records." />
            </Checkbox>
          </Col>
        </Row>
      </Form>
    </DrawerForm>
  )
}
