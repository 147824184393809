import React, { FC, useCallback, useEffect, useState } from 'react'
import { LoadingOutlined } from '@ant-design/icons'
import { Spin } from '../../core-components/Spin/Spin'
import { Image, ImageProps } from '../../core-components'
import { request, timeout } from '../../utils/request'
import { useIsMountedRef } from '~/hooks/use-is-mounted-ref'
import './ImageAuth.less'

interface ImageAuthProps extends ImageProps {
  showLoading?: boolean
  defaultImage?: React.ReactNode
  backgroundSize?: 'cover' | 'contain'
  withCache?: boolean
}

export const ImageAuth: FC<ImageAuthProps> = ({
  showLoading = false,
  src,
  defaultImage,
  backgroundSize = 'cover',
  withCache = true,
  width,
  style,
  ...props
}: ImageAuthProps) => {
  const [blobUrl, setBlobUrl] = useState<any>(null)
  const [loading, setLoading] = useState(false)
  const isMountedRef = useIsMountedRef()

  const fetchImage = useCallback(async () => {
    if (src) {
      if (withCache) {
        const cache = localStorage.getItem(src)
        if (cache) {
          setBlobUrl(cache)
          return
        }
      }

      try {
        setLoading(true)
        const { result, status } = await request('get', src, undefined, {
          responseType: 'blob',
          timeout
        })
        if (status) {
          if (isMountedRef.current) setBlobUrl(URL.createObjectURL(result))

          const reader = new FileReader()
          reader.onloadend = () => typeof reader.result == 'string' && localStorage.setItem(src, reader.result)
          reader.readAsDataURL(result)
        }
      } finally {
        if (isMountedRef.current) setLoading(false)
      }
    }
  }, [src, isMountedRef, withCache])

  useEffect(() => {
    fetchImage()
  }, [fetchImage])

  return (
    <div className="image__wrapper">
      {showLoading && loading && (
        <Spin className="image__loading" indicator={<LoadingOutlined className="image__loading-icon" spin />} />
      )}
      {blobUrl ? (
        <Image
          style={{
            backgroundImage: `url('${blobUrl}')`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize,
            ...(style || {})
          }}
          width={width}
          height={width}
          preview={false}
          {...props}
        />
      ) : (
        defaultImage
      )}
    </div>
  )
}
