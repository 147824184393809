import { request, getBaseUrl } from '~/utils/request'
import { SSRealTimeAttendanceState, SSRealTimeSummaryState } from '../types'

const baseUrl = getBaseUrl('/attendance/ssclockrecord')

export const apiGetRealTimeAttendance = (locationId: string) =>
  request<SSRealTimeAttendanceState[]>('get', `${baseUrl}/realtime/${locationId}/list`)

export const apiGetRealTimeSummary = (locationId: string) =>
  request<SSRealTimeSummaryState>('get', `${baseUrl}/realtime/${locationId}/summary`)
