import React, { FC, useState, useCallback, useEffect } from 'react'
import { useHistory, useParams, useRouteMatch } from 'react-router-dom'
import { useSelector } from 'react-redux'
import confirm from 'antd/lib/modal/confirm'
import { Button, PageHeader, Tabs } from '~/core-components'
import { DocumentTitle } from '~/components'
import { usePermissionGate } from '~/features/iam/hooks'
import { Permission, PermissionAction } from '~/constants'
import { SETTINGS_ROUTES } from '~/routes/routes'
import { dispatch } from '~/stores/store'
import { MenuItem } from '~/types/common'
import { Rules } from '../../../rule/containers/Rules/Rules'
import { deleteMaster, fetchMaster } from '../../actions'
import { PayGroupState } from '../../types'
import { payrollMenus } from '../../configs'
import { selectMasterById, selectPayGroups } from '../../selectors'
import { useMasterMenu } from '../MasterMenuContext'
import { PayGroupInfo } from './components/PayGroupInfo'
import { PayGroupSalaryCalculation } from './components/PayGroupSalaryCalculation'
import { PayslipConfig } from './components/PayslipConfig'
import { PayslipItemConfig } from './components/PayslipItemConfig'
import { PayGroupIntegration } from './components/PayGroupIntegration'
import { AddPayGroupDrawer } from './components/AddPayGroupDrawer'
import { PayslipAutomation } from './components/PayslipAutomation'
import './PayGroup.less'

export interface PayGroupProps {}

interface PayGroupParams {
  id: string
}

interface DrawerState {
  visible: boolean
}

const DEFAULT_DRAWER_STATE: DrawerState = { visible: false }

const routes = [
  {
    path: SETTINGS_ROUTES.main,
    breadcrumbName: 'Settings'
  },
  {
    path: SETTINGS_ROUTES.payGroups,
    breadcrumbName: 'Overview'
  },
  {
    path: '',
    breadcrumbName: 'Payroll group'
  }
]

export const PayGroup: FC<PayGroupProps> = () => {
  const { id } = useParams<PayGroupParams>()
  const payGroups = useSelector(selectPayGroups)
  const payGroup = useSelector(selectMasterById)('payGroup', id) as PayGroupState | undefined
  const [editing, setEditing] = useState(false)
  const match = useRouteMatch<{ tab: string }>()
  const tab = match.params.tab
  const history = useHistory()
  const [drawerState, setDrawerState] = useState<DrawerState>(DEFAULT_DRAWER_STATE)
  const canModify = usePermissionGate(Permission.payMaster, PermissionAction.Modify)
  const { setMenus } = useMasterMenu()

  const handleAddPayGroup = useCallback(() => {
    setDrawerState({ visible: true })
  }, [setDrawerState])

  const handleCloseDrawer = useCallback(() => {
    setDrawerState(DEFAULT_DRAWER_STATE)
  }, [])

  useEffect(() => {
    dispatch(fetchMaster('payGroup', id))
  }, [id])

  useEffect(() => {
    const menus: MenuItem[] = payGroups.map(pg => ({
      path: SETTINGS_ROUTES.payGroup.replace(':id', pg!.id).replace(':tab?', tab || 'general'),
      value: pg!.name,
      sysPermissionId: Permission.payMaster
    }))

    if (canModify)
      menus.push({
        path: (
          <Button type="dashed" onClick={handleAddPayGroup} block>
            Add payroll group
          </Button>
        ),
        value: '',
        sysPermissionId: ''
      })

    setMenus(menus)
    return () => {
      setMenus(payrollMenus)
    }
  }, [setMenus, payGroups, tab, canModify, handleAddPayGroup])

  const handleEdit = useCallback(() => setEditing(true), [])
  const handleView = useCallback(() => setEditing(false), [])

  const handleDelete = useCallback(() => {
    if (id && payGroup) {
      confirm({
        title: 'Delete payroll group',
        content: `Do you want to delete payroll group "${payGroup?.name}"?`,
        onOk: () => {
          dispatch(deleteMaster('payGroup', id))
          history.push(SETTINGS_ROUTES.payGroups)
        },
        okText: 'Delete',
        okType: 'danger'
      })
    }
  }, [id, payGroup, history])

  const handleTabChange = useCallback(
    (activeKey: string) => {
      history.push(`${SETTINGS_ROUTES.payGroup.replace(':id', id).replace(':tab?', activeKey)}`)
    },
    [id, history]
  )

  return (
    <div id="paygroup" className="paygroup">
      <DocumentTitle title="Payroll Group" />
      <PageHeader
        title="Payroll group"
        containerId="paygroup"
        breadcrumb={{ routes }}
        extra={canModify && editing && <Button onClick={handleDelete}>Delete</Button>}
      />
      <div className="paygroup__body">
        <Tabs
          defaultActiveKey={tab || 'general'}
          onChange={handleTabChange}
          items={[
            {
              key: 'general',
              label: 'General',
              children: (
                <>
                  <PayGroupInfo payGroup={payGroup} onEdit={handleEdit} onSave={handleView} onCancel={handleView} />
                  {payGroup?.isMidMonth && (
                    <PayGroupSalaryCalculation
                      payGroup={payGroup}
                      onEdit={handleEdit}
                      onSave={handleView}
                      onCancel={handleView}
                    />
                  )}
                </>
              )
            },
            {
              key: 'rules',
              label: 'Payroll warnings',
              children: <Rules screenCode="pay_warning" referenceId={payGroup?.id || ''} readOnly={!canModify} />
            },
            {
              key: 'payslip',
              label: 'Payslip',
              children: (
                <>
                  <PayslipAutomation payGroup={payGroup} />
                  <PayslipConfig payGroupId={payGroup?.id || ''} />
                  <PayslipItemConfig payGroupId={payGroup?.id || ''} />
                </>
              )
            },
            {
              key: 'integration',
              label: 'Integration',
              children: <PayGroupIntegration payGroupId={payGroup?.id || ''} />
            }
          ]}
        />
        {canModify && <AddPayGroupDrawer {...drawerState} onClose={handleCloseDrawer} />}
      </div>
    </div>
  )
}
