import React, { CSSProperties, FC, useCallback, useEffect, useMemo, useState } from 'react'
import type { SortableContainerProps, SortEnd } from 'react-sortable-hoc'
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc'
import { Button, Card, ColumnsType, Link, Space, Switch, Table } from '~/core-components'
import { ViewCriteriaAdvanced } from '~/features/selection'
import { usePermissionGate } from '~/features/iam'
import { dispatch } from '~/stores/store'
import { Permission, PermissionAction } from '~/constants'
import { arrayMove } from '~/utils'
import { useSysWfEventTypes, useWfEvents } from '../hooks'
import { sortWfScenario } from '../actions'
import { config } from '../config'
import { SysWfFlowTypeState, WfCategory, WfEventState } from '../types'
import { MutateWfEventDrawer } from './components/MutateWfEventDrawer'
import { WfActions } from './components/WfActions'
import './WfEvents.less'

type WfEventTable = WfEventState

interface DrawerState {
  visible: boolean
  workflowId: string
  flowType: string
  data?: WfEventState
}
const DEFAULT_DRAWER_STATE: DrawerState = { visible: false, workflowId: '', flowType: '' }

interface WfEventsProps {
  workflowId: string
  category: WfCategory
  flowType: SysWfFlowTypeState
}

const SortableItem = SortableElement((props: React.HTMLAttributes<HTMLTableRowElement>) => <tr {...props} />)
const SortableBody = SortableContainer((props: React.HTMLAttributes<HTMLTableSectionElement>) => <tbody {...props} />)

const dragHandleStyle: CSSProperties = { cursor: 'pointer', color: '#999' }
const DragHandle = SortableHandle(() => <i className="fal fa-grip-dots-vertical" style={dragHandleStyle} />)

export const WfEvents: FC<WfEventsProps> = ({ category, flowType, workflowId }) => {
  const [drawerState, setDrawerState] = useState<DrawerState>(DEFAULT_DRAWER_STATE)
  const [eventTypes] = useSysWfEventTypes(flowType.code)
  const [wfEvents, loading] = useWfEvents(workflowId, flowType.code)
  const [data, setData] = useState<WfEventState[]>([])
  const [sortable, setSortable] = useState(false)
  const canModify = usePermissionGate(Permission.lveApproval, PermissionAction.Modify)

  useEffect(() => {
    setData(wfEvents)
  }, [wfEvents])

  const handleAddScenario = useCallback(() => {
    setDrawerState({ visible: true, workflowId, flowType: flowType.code, data: undefined })
  }, [workflowId, flowType.code])

  const handleEditScenario = useCallback(
    (wfEvent: WfEventState) => {
      setDrawerState({ visible: true, workflowId, flowType: wfEvent.flowType, data: wfEvent })
    },
    [workflowId]
  )

  const handleCloseDrawer = useCallback(() => {
    setDrawerState(DEFAULT_DRAWER_STATE)
  }, [])

  const columns = useMemo(() => {
    let columns: ColumnsType<WfEventTable> = []

    if (sortable) {
      columns.push({
        dataIndex: 'sort',
        width: 30,
        className: 'drag-visible',
        render: () => <DragHandle />
      })
    }

    columns.push(
      {
        title: 'Scenario',
        key: 'name',
        dataIndex: 'name',
        className: 'drag-visible',
        render: (value: string, record) => (
          <>
            <div className="wf-events__table-row__title">{value}</div>
            <ViewCriteriaAdvanced viewId={record.viewId} screenCode={config[category].screen} label="" readOnly />
          </>
        )
      },
      {
        title: 'Event',
        key: 'eventType',
        dataIndex: 'eventType',
        width: 150,
        render: (value: string) => eventTypes[value]?.name
      }
    )

    if (!sortable) {
      columns.push({
        key: 'action',
        align: 'right',
        width: 70,
        render: (value: string, record) =>
          canModify && (
            <Link size="small" onClick={() => handleEditScenario(record)}>
              edit
            </Link>
          )
      })
    }

    return columns
  }, [sortable, canModify, eventTypes, category, handleEditScenario])

  const handleSortEnd = useCallback(
    ({ oldIndex, newIndex }: SortEnd) => {
      if (oldIndex !== newIndex) {
        const sorted = arrayMove<WfEventTable>(data, oldIndex, newIndex).map((x, index) => ({
          ...x,
          sequence: index
        }))
        setData(sorted)

        dispatch(
          sortWfScenario(
            category,
            flowType.code,
            workflowId,
            sorted.map(({ id, sequence }) => ({ id, sequence }))
          )
        )
      }
    },
    [category, flowType, workflowId, data]
  )

  const DraggableContainer: FC<SortableContainerProps> = props => (
    <SortableBody
      useDragHandle
      disableAutoscroll
      helperClass="view-selection__row-dragging"
      onSortEnd={handleSortEnd}
      {...props}
    />
  )

  const DraggableBodyRow: FC<any> = ({ className, style, ...restProps }) => {
    const index = data.findIndex(x => x.id === (restProps['data-row-key'] as string))
    return <SortableItem index={index} {...restProps} />
  }

  return (
    <div className="wf-events">
      <div className="wf-events__header">
        <Space size="middle">
          <Space>
            <label>Sort</label>
            <Switch size="small" checked={sortable} onChange={setSortable} />
          </Space>
          <Button onClick={handleAddScenario}>Add scenario</Button>
        </Space>
      </div>
      <div className="wf-events__body">
        <Card fitParent table>
          <Table
            rowKey="id"
            dataSource={data}
            pagination={false}
            columns={columns}
            loading={loading}
            fitParent
            components={
              sortable
                ? {
                    body: {
                      wrapper: DraggableContainer,
                      row: DraggableBodyRow
                    }
                  }
                : undefined
            }
            expandable={
              !sortable
                ? {
                    expandedRowRender: (record: WfEventTable) => <WfActions wfEvent={record} />
                  }
                : undefined
            }
          />
        </Card>
      </div>
      {canModify && <MutateWfEventDrawer {...drawerState} category={category} onClose={handleCloseDrawer} />}
    </div>
  )
}
