import { createSelector } from '@reduxjs/toolkit'
import { StoreState } from '~/types/store'
import { SSEntitledClaimType } from '../types'

export const selectMyEntitledClaimTypes = createSelector(
  (state: StoreState) => state.myClaim.myClaimBalances,
  (state: StoreState) => state.claim.claimTypes,
  (myClaimBalancesState, claimTypesState) =>
    Object.values(myClaimBalancesState?.entities || {})
      .map(e => {
        const claimType = claimTypesState.entities[e?.claimTypeId || '']

        return {
          id: claimType?.id,
          name: claimType?.name
        } as SSEntitledClaimType
      })
      .sort((a, b) => {
        return a.name?.localeCompare(b.name)
      })
      .filter((e, idx, self) => idx === self.findIndex(s => s.id === e.id))
)
