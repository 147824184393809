import { Operation } from 'fast-json-patch'
import { request, getBaseUrl, timeout } from '~/utils'
import { DateRange, Pagination, Search } from '~/types/common'
import { EmSelectState } from '~/components'
import { EmSelectionViewState } from '~/features/employee'
import { DailyRecordState, DailyRecordViewState, IDailyRecordLock } from '../types'

const baseUrl = getBaseUrl('/attendance/dailyrecord')

export const apiGetDailyRecordsView = (
  viewId: string,
  params: Pagination & Search & DateRange & { payGroupId?: string }
) => request<DailyRecordViewState>('get', `${baseUrl}/view/${viewId}`, params)

export const apiGetDailyRecordsExcel = (viewId: string, params: Search & DateRange & { payGroupId?: string }) =>
  request('get', `${baseUrl}/view/${viewId}/excel`, params, { responseType: 'blob', timeout })

export const apiGetDailyEmSelections = (
  payGroupId: string,
  startDate: string,
  endDate: string,
  viewId: string,
  params: Pagination & Search
) =>
  request<EmSelectionViewState>(
    'get',
    `${baseUrl}/${payGroupId}/${startDate}/${endDate}/emselections/${viewId}`,
    params
  )

export const apiGetDailyNewEmSelections = (
  payGroupId: string,
  startDate: string,
  endDate: string,
  viewId: string,
  params: Pagination & Search
) =>
  request<EmSelectionViewState>(
    'get',
    `${baseUrl}/${payGroupId}/${startDate}/${endDate}/emselections/new/${viewId}`,
    params
  )

export const apiGetDailyRecord = (id: string) => request<DailyRecordState>('get', `${baseUrl}/${id}`)

export const apiGetDailyRecordByEmployeeDate = (clockDate: string, employeeId: string) =>
  request<DailyRecordState[]>('get', `${baseUrl}/${clockDate}/${employeeId}`)

export const apiGetDailyRecordEmployees = (clockDate: string) =>
  request<EmSelectState[]>('get', `${baseUrl}/${clockDate}/employees`)

export const apiUpdateDailyRecord = (id: string, dailyRecord: Operation[]) =>
  request<void>('patch', `${baseUrl}/${id}`, dailyRecord)

export const apiDeleteDailyRecord = (id: string) => request<void>('delete', `${baseUrl}/${id}`)

export const apiLockDailyRecord = (id: string) => request<IDailyRecordLock>('post', `${baseUrl}/${id}/lock`)

export const apiUnlockDailyRecord = (id: string) => request<void>('post', `${baseUrl}/${id}/unlock`)

export const apiLockAllDailyRecords = (attendancePeriodId: string) =>
  request<IDailyRecordLock[]>('post', `${baseUrl}/${attendancePeriodId}/lockall`)

export const apiUnlockAllDailyRecords = (attendancePeriodId: string) =>
  request<IDailyRecordLock[]>('post', `${baseUrl}/${attendancePeriodId}/unlockall`)
