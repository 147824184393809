import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit'
import persistReducer from 'redux-persist/es/persistReducer'
import { StoreState } from '~/types/store'
import {
  claimPersistConfig,
  ClaimEntitlementState,
  ClaimEntitlementViewState,
  ClaimEntitlementRecordState,
  ClaimRecordViewState,
  ClaimRecordState,
  ClaimRecordAttachmentState,
  ClaimRootState,
  ClaimTypeState,
  CtPolicyState,
  CtProrationMState,
  EntitledClaimTypeState,
  CePolicyState,
  CurrencyState,
  CurrencyExchangeViewState,
  ClaimRecordApprovalHistoryState,
  ClaimPendingApprovalViewState,
  ClaimPendingPaymentViewState,
  ExpenseTypeState,
  CtExpenseTypeState,
  CtPolicyEtState,
  CfConfigState
} from './types'

const claimTypesAdapter = createEntityAdapter<ClaimTypeState>()
const ctExpenseTypesAdapter = createEntityAdapter<CtExpenseTypeState>()
const ctPoliciesAdapter = createEntityAdapter<CtPolicyState>()
const ctPolicyEtAdapter = createEntityAdapter<CtPolicyEtState>()
const ctProrationMAdapter = createEntityAdapter<CtProrationMState>()
const expenseTypesAdapter = createEntityAdapter<ExpenseTypeState>()
const cfConfigsAdapter = createEntityAdapter<CfConfigState>({ selectId: model => model.code })
const currenciesAdapter = createEntityAdapter<CurrencyState>({ selectId: model => model.code })
const currencyExchangeViewAdapter = createEntityAdapter<CurrencyExchangeViewState>({ selectId: model => model.viewId })
const claimEntitlementViewAdapter = createEntityAdapter<ClaimEntitlementViewState>({ selectId: model => model.viewId })
const claimEntitlementAdapter = createEntityAdapter<ClaimEntitlementState>()
const cePoliciesAdapter = createEntityAdapter<CePolicyState>()
const claimRecordAttachmentAdapter = createEntityAdapter<ClaimRecordAttachmentState>()
const entitledClaimTypeAdapter = createEntityAdapter<EntitledClaimTypeState>({ selectId: model => model.claimTypeId })
const claimRecordViewAdapter = createEntityAdapter<ClaimRecordViewState>({ selectId: model => model.viewId })
const claimRecordAdapter = createEntityAdapter<ClaimRecordState>()
const claimEntitlementRecordAdapter = createEntityAdapter<ClaimEntitlementRecordState>()
const claimRecordApprovalHistoriesAdapter = createEntityAdapter<ClaimRecordApprovalHistoryState>()
const claimPendingApprovalsViewAdapter = createEntityAdapter<ClaimPendingApprovalViewState>({
  selectId: model => model.viewId
})
const claimPendingPaymentsViewAdapter = createEntityAdapter<ClaimPendingPaymentViewState>({
  selectId: model => model.viewId
})

const claimTypeInitialState = claimTypesAdapter.getInitialState()
const ctExpenseTypesInitialState = ctExpenseTypesAdapter.getInitialState()
const ctPolicyInitialState = ctPoliciesAdapter.getInitialState()
const ctPolicyEtInitialState = ctPolicyEtAdapter.getInitialState()
const ctProrationMInitialState = ctProrationMAdapter.getInitialState()
const expenseTypesInitialState = expenseTypesAdapter.getInitialState()
const cfConfigsInitialState = cfConfigsAdapter.getInitialState()
const currenciesInitialState = currenciesAdapter.getInitialState()
const currencyExchangeViewInitialState = currencyExchangeViewAdapter.getInitialState()
const claimEntitlementViewInitialState = claimEntitlementViewAdapter.getInitialState()
const claimEntitlementInitialState = claimEntitlementAdapter.getInitialState()
const cePolicyInitialState = cePoliciesAdapter.getInitialState()
const entitledClaimTypeIntitalState = entitledClaimTypeAdapter.getInitialState()
const claimRecordsViewInitialState = claimRecordViewAdapter.getInitialState()
const claimRecordInitialState = claimRecordAdapter.getInitialState()
const claimEntitlementRecordInitialState = claimEntitlementRecordAdapter.getInitialState()
const claimRecordAttachmentInitialState = claimRecordAttachmentAdapter.getInitialState()
const claimRecordApprovalHistoriesInitialState = claimRecordApprovalHistoriesAdapter.getInitialState()
const claimPendingApprovalsViewIntitalState = claimPendingApprovalsViewAdapter.getInitialState()
const claimPendingPaymentsViewIntitalState = claimPendingPaymentsViewAdapter.getInitialState()

const initialState: ClaimRootState = {
  claimTypes: claimTypeInitialState,
  claimTypesLoading: false,
  claimTypesView: claimTypeInitialState,
  claimTypesViewLoading: false,
  ctExpenseTypes: {},
  ctExpenseTypesLoading: {},
  ctPolicies: {},
  ctPoliciesLoading: {},
  ctPolicyEt: {},
  ctPolicyEtLoading: {},
  ctProrationM: {},
  ctProrationMLoading: {},
  expenseTypes: expenseTypesInitialState,
  expenseTypesLoading: false,

  cfConfigs: cfConfigsInitialState,
  cfConfigsLoading: false,

  currencies: currenciesInitialState,
  currenciesLoading: false,
  currencyExchangesView: currencyExchangeViewInitialState,
  currencyExchangesViewLoading: false,
  currencyExchangesViewRefetch: 0,

  claimExpanded: false,
  claimLoading: false,

  claimEntitlementsView: claimEntitlementViewInitialState,
  claimEntitlementsViewLoading: false,
  claimEntitlementsViewRefetch: 0,

  claimEntitlements: {},
  claimEntitlementsLoading: {},
  claimEntitlementsRefetch: 0,

  cePolicies: {},
  cePoliciesLoading: {},

  entitledClaimTypes: {},
  entitledClaimTypesLoading: {},

  claimRecordsView: claimRecordsViewInitialState,
  claimRecordsViewLoading: false,
  claimRecordsViewRefetch: 0,

  claimRecords: claimRecordInitialState,
  claimRecordsLoading: false,

  claimEntitlementRecords: {},
  claimEntitlementRecordsLoading: {},
  claimEntitlementRecordsRefetch: 0,

  claimRecordAttachments: claimRecordAttachmentInitialState,
  claimRecordAttachmentsLoading: false,

  claimRecordApprovalHistories: {},
  claimRecordApprovalHistoriesLoading: {},

  claimPendingApprovalsView: claimPendingApprovalsViewIntitalState,
  claimPendingApprovalsViewLoading: false,
  claimPendingApprovalsViewRefetch: 0,

  claimPendingPaymentsView: claimPendingPaymentsViewIntitalState,
  claimPendingPaymentsViewLoading: false,
  claimPendingPaymentsViewRefetch: 0
}

const claimSlice = createSlice({
  name: 'claim',
  initialState,
  reducers: {
    // claimTypes
    setClaimType: (state, action: PayloadAction<ClaimTypeState>) => {
      claimTypesAdapter.upsertOne(state.claimTypes, action.payload)
    },
    replaceClaimTypes: (state, action: PayloadAction<ClaimTypeState[]>) => {
      claimTypesAdapter.setAll(state.claimTypes, action.payload)
    },
    removeClaimType: (state, action: PayloadAction<string>) => {
      claimTypesAdapter.removeOne(state.claimTypes, action.payload)
    },
    setClaimTypesLoading: (state, action: PayloadAction<boolean>) => {
      state.claimTypesLoading = action.payload
    },

    // claimTypesView
    replaceClaimTypesView: (state, action: PayloadAction<ClaimTypeState[]>) => {
      claimTypesAdapter.setAll(state.claimTypesView, action.payload)
    },
    setClaimTypesViewLoading: (state, action: PayloadAction<boolean>) => {
      state.claimTypesViewLoading = action.payload
    },

    // ctExpenseTypes
    replaceCtExpenseTypes: (state, action: PayloadAction<{ id: string; data: CtExpenseTypeState[] }>) => {
      state.ctExpenseTypes[action.payload.id] = state.ctExpenseTypes[action.payload.id] || ctExpenseTypesInitialState
      ctExpenseTypesAdapter.setAll(state.ctExpenseTypes[action.payload.id]!, action.payload.data)
    },
    setCtExpenseTypesLoading: (state, action: PayloadAction<{ id: string; loading: boolean }>) => {
      state.ctExpenseTypes[action.payload.id] = state.ctExpenseTypes[action.payload.id] || ctExpenseTypesInitialState
      state.ctExpenseTypesLoading[action.payload.id] = action.payload.loading
    },

    // cfConfigs
    replaceCfConfigs: (state, action: PayloadAction<CfConfigState[]>) => {
      cfConfigsAdapter.setAll(state.cfConfigs, action.payload)
    },
    setCfConfig: (state, action: PayloadAction<CfConfigState>) => {
      cfConfigsAdapter.upsertOne(state.cfConfigs, action.payload)
    },
    setCfConfigsLoading: (state, action: PayloadAction<boolean>) => {
      state.cfConfigsLoading = action.payload
    },

    // ctPolicies
    replaceCtPolicies: (state, action: PayloadAction<{ claimTypeId: string; data: CtPolicyState[] }>) => {
      const { claimTypeId, data } = action.payload
      state.ctPolicies[claimTypeId] = state.ctPolicies[claimTypeId] || ctPolicyInitialState
      ctPoliciesAdapter.setAll(state.ctPolicies[claimTypeId]!, data)
    },
    setCtPolicy: (state, action: PayloadAction<{ claimTypeId: string; data: CtPolicyState }>) => {
      const { claimTypeId, data } = action.payload
      state.ctPolicies[claimTypeId] = state.ctPolicies[claimTypeId] || ctPolicyInitialState
      ctPoliciesAdapter.upsertOne(state.ctPolicies[claimTypeId]!, data)
    },
    removeCtPolicy: (state, action: PayloadAction<{ claimTypeId: string; id: string }>) => {
      ctPoliciesAdapter.removeOne(state.ctPolicies[action.payload.claimTypeId]!, action.payload.id)
    },
    setCtPoliciesLoading: (state, action: PayloadAction<{ claimTypeId: string; loading: boolean }>) => {
      const { claimTypeId, loading } = action.payload
      state.ctPolicies[claimTypeId] = state.ctPolicies[claimTypeId] || ctPolicyInitialState
      state.ctPoliciesLoading[claimTypeId] = loading
    },

    // ctPolicyEt
    replaceCtPolicyEt: (state, action: PayloadAction<{ id: string; data: CtPolicyEtState[] }>) => {
      state.ctPolicyEt[action.payload.id] = state.ctPolicyEt[action.payload.id] || ctPolicyEtInitialState
      ctPolicyEtAdapter.setAll(state.ctPolicyEt[action.payload.id]!, action.payload.data)
    },
    removeCtPolicyEtByPolicy: (state, action: PayloadAction<string>) => {
      const ctPolicyId = action.payload
      ctPolicyEtAdapter.removeAll(state.ctPolicyEt[ctPolicyId]!)
    },
    setCtPolicyEtLoading: (state, action: PayloadAction<{ id: string; loading: boolean }>) => {
      state.ctPolicyEt[action.payload.id] = state.ctPolicyEt[action.payload.id] || ctPolicyEtInitialState
      state.ctPolicyEtLoading[action.payload.id] = action.payload.loading
    },

    // ctProrationM
    replaceCtProrationM: (state, action: PayloadAction<{ claimTypeId: string; data: CtProrationMState[] }>) => {
      const { claimTypeId, data } = action.payload
      state.ctProrationM[claimTypeId] = state.ctProrationM[claimTypeId] || ctProrationMInitialState
      ctProrationMAdapter.setAll(state.ctProrationM[claimTypeId]!, data)
    },
    setCtProrationM: (state, action: PayloadAction<{ claimTypeId: string; data: CtProrationMState }>) => {
      const { claimTypeId, data } = action.payload
      state.ctProrationM[claimTypeId] = state.ctProrationM[claimTypeId] || ctProrationMInitialState
      ctProrationMAdapter.upsertOne(state.ctProrationM[claimTypeId]!, data)
    },
    removeCtProrationM: (state, action: PayloadAction<{ claimTypeId: string; id: string }>) => {
      ctProrationMAdapter.removeOne(state.ctProrationM[action.payload.claimTypeId]!, action.payload.id)
    },
    setCtProrationMLoading: (state, action: PayloadAction<{ claimTypeId: string; loading: boolean }>) => {
      const { claimTypeId, loading } = action.payload
      state.ctProrationM[claimTypeId] = state.ctProrationM[claimTypeId] || ctProrationMInitialState
      state.ctProrationMLoading[claimTypeId] = loading
    },

    // expense types
    replaceExpenseTypes: (state, action: PayloadAction<ExpenseTypeState[]>) => {
      expenseTypesAdapter.setAll(state.expenseTypes, action.payload)
    },
    setExpenseType: (state, action: PayloadAction<ExpenseTypeState>) => {
      expenseTypesAdapter.upsertOne(state.expenseTypes, action.payload)
    },
    removeExpenseType: (state, action: PayloadAction<string>) => {
      expenseTypesAdapter.removeOne(state.expenseTypes, action.payload)
    },
    setExpenseTypesLoading: (state, action: PayloadAction<boolean>) => {
      state.expenseTypesLoading = action.payload
    },

    // currencies
    replaceCurrencies: (state, action: PayloadAction<CurrencyState[]>) => {
      currenciesAdapter.setAll(state.currencies, action.payload)
    },
    setCurrency: (state, action: PayloadAction<CurrencyState>) => {
      currenciesAdapter.upsertOne(state.currencies, action.payload)
    },
    removeCurrency: (state, action: PayloadAction<string>) => {
      currenciesAdapter.removeOne(state.currencies, action.payload)
    },
    setCurrenciesLoading: (state, action: PayloadAction<boolean>) => {
      state.currenciesLoading = action.payload
    },

    // currencyExchange
    refetchCurrencyExchangesView: state => {
      state.currencyExchangesViewRefetch += 1
    },
    setCurrencyExchangesViewLoading: (state, action: PayloadAction<boolean>) => {
      state.currencyExchangesViewLoading = action.payload
    },
    setCurrencyExchangesView: (state, action: PayloadAction<CurrencyExchangeViewState>) => {
      currencyExchangeViewAdapter.upsertOne(state.currencyExchangesView, action.payload)
    },

    // claimExpanded
    setClaimExpanded: (state, action: PayloadAction<boolean>) => {
      state.claimExpanded = action.payload
    },
    setClaimLoading: (state, action: PayloadAction<boolean>) => {
      state.claimLoading = action.payload
    },

    // claimEntitlementsView
    refetchClaimEntitlementsView: state => {
      state.claimEntitlementsViewRefetch += 1
    },
    setClaimEntitlementsViewLoading: (state, action: PayloadAction<boolean>) => {
      state.claimEntitlementsViewLoading = action.payload
    },
    setClaimEntitlementsView: (state, action: PayloadAction<ClaimEntitlementViewState>) => {
      claimEntitlementViewAdapter.upsertOne(state.claimEntitlementsView, action.payload)
    },

    // claimEntitlements
    refetchClaimEntitlements: state => {
      state.claimEntitlementsRefetch += 1
    },
    setClaimEntitlementsLoading: (state, action: PayloadAction<{ emYear: string; loading: boolean }>) => {
      const { emYear, loading } = action.payload
      state.claimEntitlements[emYear] = state.claimEntitlements[emYear] || claimEntitlementInitialState
      state.claimEntitlementsLoading[emYear] = loading
    },
    replaceClaimEntitlements: (state, action: PayloadAction<{ emYear: string; data: ClaimEntitlementState[] }>) => {
      const { emYear, data } = action.payload
      state.claimEntitlements[emYear] = state.claimEntitlements[emYear] || claimEntitlementInitialState
      claimEntitlementAdapter.setAll(state.claimEntitlements[emYear], data)
    },

    // cePolicies
    setCePoliciesLoading: (state, action: PayloadAction<{ entId: string; loading: boolean }>) => {
      const { entId, loading } = action.payload
      state.cePolicies[entId] = state.cePolicies[entId] || cePolicyInitialState
      state.cePoliciesLoading[entId] = loading
    },
    replaceCePolicies: (state, action: PayloadAction<{ entId: string; data: CePolicyState[] }>) => {
      const { entId, data } = action.payload
      state.cePolicies[entId] = state.cePolicies[entId] || cePolicyInitialState
      cePoliciesAdapter.setAll(state.cePolicies[entId], data)
    },

    // entitledClaimTypes
    setEntitledClaimTypesLoading: (state, action: PayloadAction<{ employeeId: string; loading: boolean }>) => {
      const { employeeId, loading } = action.payload
      state.entitledClaimTypes[employeeId] = state.entitledClaimTypes[employeeId] || entitledClaimTypeIntitalState
      state.entitledClaimTypesLoading[employeeId] = loading
    },
    replaceEntitledClaimTypes: (
      state,
      action: PayloadAction<{ employeeId: string; data: EntitledClaimTypeState[] }>
    ) => {
      const { employeeId, data } = action.payload
      state.entitledClaimTypes[employeeId] = state.entitledClaimTypes[employeeId] || entitledClaimTypeIntitalState
      entitledClaimTypeAdapter.setAll(state.entitledClaimTypes[employeeId]!, data)
    },

    // claimRecordsView
    refetchClaimRecordsView: state => {
      state.claimRecordsViewRefetch += 1
    },
    setClaimRecordsViewLoading: (state, action: PayloadAction<boolean>) => {
      state.claimRecordsViewLoading = action.payload
    },
    setClaimRecordsView: (state, action: PayloadAction<ClaimRecordViewState>) => {
      claimRecordViewAdapter.upsertOne(state.claimRecordsView, action.payload)
    },

    // claimRecords
    setClaimRecordsLoading: (state, action: PayloadAction<boolean>) => {
      state.claimRecordsLoading = action.payload
    },
    setClaimRecords: (state, action: PayloadAction<ClaimRecordState>) => {
      claimRecordAdapter.upsertOne(state.claimRecords, action.payload)
    },
    removeClaimRecord: (state, action: PayloadAction<string>) => {
      claimRecordAdapter.removeOne(state.claimRecords, action.payload)
    },

    // claimEntitlementRecords
    refetchClaimEntitlementRecords: state => {
      state.claimEntitlementRecordsRefetch += 1
    },
    setClaimEntitlementRecordsLoading: (state, action: PayloadAction<{ emCtId: string; loading: boolean }>) => {
      const { emCtId, loading } = action.payload
      state.claimEntitlementRecords[emCtId] =
        state.claimEntitlementRecords[emCtId] || claimEntitlementRecordInitialState
      state.claimEntitlementRecordsLoading[emCtId] = loading
    },
    replaceClaimEntitlementRecords: (
      state,
      action: PayloadAction<{ emCtId: string; data: ClaimEntitlementRecordState[] }>
    ) => {
      const { emCtId, data } = action.payload
      state.claimEntitlementRecords[emCtId] =
        state.claimEntitlementRecords[emCtId] || claimEntitlementRecordInitialState
      claimEntitlementRecordAdapter.setAll(state.claimEntitlementRecords[emCtId]!, data)
    },

    // claimRecordAttachments
    setClaimRecordAttachmentsLoading: (state, action: PayloadAction<boolean>) => {
      state.claimRecordAttachmentsLoading = action.payload
    },
    replaceClaimRecordAttachments: (state, action: PayloadAction<ClaimRecordAttachmentState[]>) => {
      claimRecordAttachmentAdapter.setAll(state.claimRecordAttachments, action.payload)
    },

    // claimRecordApprovalHistories
    setClaimRecordApprovalHistoriesLoading: (state, action: PayloadAction<{ id: string; loading: boolean }>) => {
      const { id, loading } = action.payload
      state.claimRecordApprovalHistories[id] =
        state.claimRecordApprovalHistories[id] || claimRecordApprovalHistoriesInitialState
      state.claimRecordApprovalHistoriesLoading[id] = loading
    },
    replaceClaimRecordApprovalHistories: (
      state,
      action: PayloadAction<{ id: string; data: ClaimRecordApprovalHistoryState[] }>
    ) => {
      const { id, data } = action.payload
      state.claimRecordApprovalHistories[id] =
        state.claimRecordApprovalHistories[id] || claimRecordApprovalHistoriesInitialState
      claimRecordApprovalHistoriesAdapter.setAll(state.claimRecordApprovalHistories[id]!, data)
    },

    // claimPendingApprovalsView
    refetchClaimPendingApprovalsView: state => {
      state.claimPendingApprovalsViewRefetch += 1
    },
    setClaimPendingApprovalsViewLoading: (state, action: PayloadAction<boolean>) => {
      state.claimPendingApprovalsViewLoading = action.payload
    },
    setClaimPendingApprovalsView: (state, action: PayloadAction<ClaimPendingApprovalViewState>) => {
      claimPendingApprovalsViewAdapter.upsertOne(state.claimPendingApprovalsView, action.payload)
    },

    // claimPendingPaymentsView
    refetchClaimPendingPaymentsView: state => {
      state.claimPendingPaymentsViewRefetch += 1
    },
    setClaimPendingPaymentsViewLoading: (state, action: PayloadAction<boolean>) => {
      state.claimPendingPaymentsViewLoading = action.payload
    },
    setClaimPendingPaymentsView: (state, action: PayloadAction<ClaimPendingPaymentViewState>) => {
      claimPendingPaymentsViewAdapter.upsertOne(state.claimPendingPaymentsView, action.payload)
    }
  }
})

export const {
  // claimTypes
  setClaimType,
  replaceClaimTypes,
  removeClaimType,
  setClaimTypesLoading,

  // claimTypesView
  replaceClaimTypesView,
  setClaimTypesViewLoading,

  // ctExpenseTypes
  replaceCtExpenseTypes,
  setCtExpenseTypesLoading,

  // cfConfigs
  replaceCfConfigs,
  setCfConfig,
  setCfConfigsLoading,

  // ctPolicies
  replaceCtPolicies,
  setCtPolicy,
  removeCtPolicy,
  setCtPoliciesLoading,

  // ctPolicyEt
  replaceCtPolicyEt,
  removeCtPolicyEtByPolicy,
  setCtPolicyEtLoading,

  // ctProrationM
  replaceCtProrationM,
  setCtProrationM,
  removeCtProrationM,
  setCtProrationMLoading,

  // expense types
  replaceExpenseTypes,
  setExpenseType,
  removeExpenseType,
  setExpenseTypesLoading,

  // currencies
  replaceCurrencies,
  setCurrency,
  removeCurrency,
  setCurrenciesLoading,

  // currencyExchangesView
  refetchCurrencyExchangesView,
  setCurrencyExchangesViewLoading,
  setCurrencyExchangesView,

  // claimExpanded
  setClaimExpanded,
  setClaimLoading,

  // claimEntitlementsView
  refetchClaimEntitlementsView,
  setClaimEntitlementsViewLoading,
  setClaimEntitlementsView,

  // claimEntitlements
  refetchClaimEntitlements,
  setClaimEntitlementsLoading,
  replaceClaimEntitlements,

  // cePolicies
  setCePoliciesLoading,
  replaceCePolicies,

  // entitledClaimTypes
  replaceEntitledClaimTypes,
  setEntitledClaimTypesLoading,

  // claimRecordsView
  refetchClaimRecordsView,
  setClaimRecordsViewLoading,
  setClaimRecordsView,

  // claimRecords
  setClaimRecords,
  setClaimRecordsLoading,
  removeClaimRecord,

  // claimEntitlementRecords
  refetchClaimEntitlementRecords,
  setClaimEntitlementRecordsLoading,
  replaceClaimEntitlementRecords,

  // claimRecordAttachments
  setClaimRecordAttachmentsLoading,
  replaceClaimRecordAttachments,

  // claimRecordApprovalHistories
  setClaimRecordApprovalHistoriesLoading,
  replaceClaimRecordApprovalHistories,

  // claimPendingApprovalsView
  refetchClaimPendingApprovalsView,
  setClaimPendingApprovalsViewLoading,
  setClaimPendingApprovalsView,

  // claimPendingPaymentsView
  refetchClaimPendingPaymentsView,
  setClaimPendingPaymentsViewLoading,
  setClaimPendingPaymentsView
} = claimSlice.actions

export const claimReducers = { claim: persistReducer<ClaimRootState>(claimPersistConfig, claimSlice.reducer) }

export const { selectById: selectClaimTypeById } = claimTypesAdapter.getSelectors(
  (state: StoreState) => state.claim.claimTypes
)

export const { selectById: selectCurrencyById } = currenciesAdapter.getSelectors(
  (state: StoreState) => state.claim.currencies
)
