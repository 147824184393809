import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useRouteMatch } from 'react-router-dom'
import classNames from 'classnames'
import { AmountDisplay, Col, DocumentTitle, EmSelect, InfoTooltip, Row, YearKeyValues } from '~/components'
import { ClaCurrencyCode, ClaEntitlementType, ClaPaymentStatus, Permission, PermissionAction } from '~/constants'
import {
  Card,
  Checkbox,
  CheckboxValueType,
  ColumnsType,
  Divider,
  Link,
  PageHeader,
  SecondaryText,
  Space,
  Table,
  TableRowSelection
} from '~/core-components'
import { useSysOptions } from '~/features/employee'
import { usePermissionGate } from '~/features/iam'
import { CLA_ROUTES } from '~/routes/routes'
import { StoreState } from '~/types/store'
import { dispatch } from '~/stores/store'
import { formatDateRange, formatMoney } from '~/utils'
import { apiGetClaimEntitlementEmSelect } from '../../api/claim-entitlement.api'
import { useClaimEntitlements, useClaimTypesDict } from '../../hooks'
import { fetchClaimEntitlements } from '../../actions'
import { ClaimEntitlementState } from '../../types'
import { formatClaimLimit } from '../../util'
import { refetchClaimEntitlements } from '../../reducers'
import { ClaimTypeName } from '../ClaimEntitlements/components/ClaimTypeName'
import { EditClaimEntitlementDrawer } from './components/EditClaimEntitlementDrawer'
import { ClaimEntitlementRecords } from './components/ClaimEntitlementRecords'
import { AddClaimRecordButton } from '../ClaimRecords/components/AddClaimRecordButton'
import { CePoliciesInfo } from '../CePolicies/CePoliciesInfo'
import './ClaimEntitlement.less'

interface ClaimEntitlementProps {}

interface DrawerState {
  data?: ClaimEntitlementState
  visible: boolean
}

const DEFAULT_DRAWER_STATE: DrawerState = { visible: false }

const routes = [
  {
    path: CLA_ROUTES.tab.replace(':tab?', 'entitlement'),
    breadcrumbName: 'Overview'
  },
  {
    path: '',
    breadcrumbName: 'Claim entitlement'
  }
]

export const buildAccruedTooltip = (data: ClaimEntitlementState): React.ReactNode[] => {
  let toolTipMessages: string[] = []

  if (data.entitlement !== data.earned && !!data.entitlement) {
    toolTipMessages.push(`Entitlement (before proration): ${formatMoney(data.entitlement, 2)}`)
  }
  if (data.earnedAdj !== 0 && !!data.earnedAdj) {
    toolTipMessages.push(`Adjustment: ${formatMoney(data.earnedAdj, 2)}`)
  }
  return toolTipMessages
}

export const ClaimEntitlement: FC<ClaimEntitlementProps> = () => {
  const history = useHistory()
  const match = useRouteMatch<{ periodYear: string; employeeId: string; claimTypeId: string }>()
  const periodYear = parseInt(match.params.periodYear)
  const employeeId = match.params.employeeId
  const claimTypeId = match.params.claimTypeId
  const [drawerState, setDrawerState] = useState<DrawerState>(DEFAULT_DRAWER_STATE)
  const [data, loading] = useClaimEntitlements(periodYear, employeeId)
  const firstClaimTypeId = data.length > 0 ? data[0].claimTypeId : undefined
  const entitlementCount = data.length
  const current = data.find(d => d.claimTypeId === claimTypeId)
  const [claimTypes] = useClaimTypesDict()
  const claimType = claimTypes[claimTypeId]
  const canModify = usePermissionGate(Permission.claEntitlement, PermissionAction.Modify)
  const canModifyClaRecord = usePermissionGate(Permission.claRecord, PermissionAction.Modify)
  const refetch = useSelector((state: StoreState) => state.claim.claimEntitlementsRefetch)
  const [paymentStatuses, setPaymentStatuses] = useState<string[]>([])

  const [maxTimesBasis] = useSysOptions('cla_max_times_basis')
  const limitText = useMemo(
    () =>
      current
        ? formatClaimLimit(
            current.currencyCode,
            current.requireConfirm,
            current.requireMonths,
            current.monthlyLimit,
            current.dailyLimit,
            current.perTimeAmount,
            current.perTimePercentage,
            current.perTimeEmAmount,
            current.maxTimes,
            maxTimesBasis && maxTimesBasis[current.maxTimesBasis]?.value
          )
        : [],
    [current, maxTimesBasis]
  )
  const accruedToolTip = useMemo(() => (current ? buildAccruedTooltip(current) : []), [current])
  const isProrated = useMemo(
    () => current && current.entitlement !== current.earned && !!current.entitlement,
    [current]
  )

  useEffect(() => {
    dispatch(fetchClaimEntitlements(periodYear, employeeId))
  }, [periodYear, employeeId, refetch])

  const handleFetchEmployees = useCallback(async () => {
    const { status, result } = await apiGetClaimEntitlementEmSelect(periodYear)
    if (status) {
      return result
    }
    return []
  }, [periodYear])

  const navigateTo = useCallback(
    (periodYear: number, employeeId: string, claimTypeId: string) => {
      history.replace(
        `${CLA_ROUTES.entitlement
          .replace(':periodYear', periodYear.toString())
          .replace(':employeeId', employeeId)
          .replace(':claimTypeId', claimTypeId)}`
      )
    },
    [history]
  )

  const handleEmployeeChange = useCallback(
    async (employeeId: string) => {
      navigateTo(periodYear, employeeId, claimTypeId)
    },
    [navigateTo, periodYear, claimTypeId]
  )

  const handlePeriodChange = useCallback(
    (periodYear: number) => {
      navigateTo(periodYear, employeeId, claimTypeId)
    },
    [navigateTo, employeeId, claimTypeId]
  )

  const handleEditClaimEntitlement = useCallback(() => {
    setDrawerState({ visible: true, data: current })
  }, [current])

  const handleCloseDrawer = useCallback(
    (action: 'saved' | 'deleted' | 'cancelled') => {
      setDrawerState(DEFAULT_DRAWER_STATE)

      if (action === 'saved' || action === 'deleted') {
        dispatch(refetchClaimEntitlements())
      }

      if (action === 'deleted') {
        if (entitlementCount === 1) {
          history.push(`${CLA_ROUTES.tab.replace(':tab?', 'entitlement')}`)
        } else if (firstClaimTypeId) {
          navigateTo(periodYear, employeeId, firstClaimTypeId)
        }
      }
    },
    [navigateTo, periodYear, employeeId, firstClaimTypeId, entitlementCount, history]
  )

  const handleOnChangeStatus = useCallback((checkedValues: CheckboxValueType[]) => {
    setPaymentStatuses(checkedValues as string[])
  }, [])

  const columns: ColumnsType<ClaimEntitlementState> = [
    {
      title: 'Claim type',
      key: 'claimTypeId',
      dataIndex: 'claimTypeId',
      render: (value: string, record: ClaimEntitlementState) => (
        <ClaimTypeName id={value} description={formatDateRange(record.periodStartDate, record.periodEndDate)} />
      )
    },
    {
      title: 'Balance',
      key: 'balance',
      dataIndex: 'balance',
      width: 120,
      render: (value: number, record: ClaimEntitlementState) => {
        if (claimTypes[record.claimTypeId]?.entitlementType === ClaEntitlementType.PerPeriod) {
          return (
            <>
              <SecondaryText size="small">Balance</SecondaryText>
              <AmountDisplay block symbol={record.currencyCode || ClaCurrencyCode.sgd} value={record.poolBalance} />
            </>
          )
        }

        return (
          <>
            <SecondaryText size="small">Taken</SecondaryText>
            <AmountDisplay
              block
              symbol={record.currencyCode || ClaCurrencyCode.sgd}
              value={record.pendingApproval + record.pendingPayment + record.paid + record.paidAdj}
            />
          </>
        )
      }
    }
  ]

  const rowSelection: TableRowSelection<ClaimEntitlementState> = useMemo(
    () => ({
      type: 'radio',
      selectedRowKeys: [claimTypeId],
      onChange: (rowKeys, rows) => {
        if (rows.length > 0) {
          const selected = rows[0]
          navigateTo(periodYear, employeeId, selected.claimTypeId)
        }
      }
    }),
    [navigateTo, periodYear, employeeId, claimTypeId]
  )

  return (
    <div className="claim-entitlement">
      <DocumentTitle title="Claim Entitlement" />
      <PageHeader title="Claim entitlement" breadcrumb={{ routes }} />
      <div className="claim-entitlement__main">
        <Row gutter={30} wrap={false}>
          <Col flex="400px" className="claim-entitlement__sider">
            <Space direction="vertical" size="large">
              <EmSelect
                defaultValue={employeeId}
                size="large"
                onFetch={handleFetchEmployees}
                onChange={handleEmployeeChange}
              />
              <Table
                rowKey="claimTypeId"
                rowSelection={rowSelection}
                dataSource={data}
                columns={columns}
                fitParent
                showHeader={false}
                loading={loading}
                pagination={false}
              />
            </Space>
          </Col>
          <Col flex="auto" style={{ paddingLeft: 0 }}>
            <Row className="claim-entitlement__title">
              <Col flex="auto">
                <h1>{claimType?.name}</h1>
              </Col>
              <Col flex="none">
                <Space align="center">
                  {canModifyClaRecord && <AddClaimRecordButton employeeId={employeeId} />}
                  <YearKeyValues
                    noOfYearBefore={-5}
                    noOfYearAfter={0}
                    value={periodYear}
                    onChange={handlePeriodChange}
                  />
                </Space>
              </Col>
            </Row>
            <div className="claim-entitlement__scrolly">
              {current && (
                <>
                  <Card
                    className="claim-entitlement__current"
                    extra={canModify && <Link onClick={handleEditClaimEntitlement}>edit</Link>}
                  >
                    <Row gutter={15} className="claim-entitlement__current-taken">
                      <Col flex="auto" className="claim-entitlement__current-amount">
                        <label>
                          Accrued
                          {accruedToolTip.length > 0 && (
                            <CePoliciesInfo
                              tooltip={accruedToolTip.join(' ')}
                              periodCode={isProrated ? current.periodCode : undefined}
                              employeeId={isProrated ? current.employeeId : undefined}
                              claimTypeId={isProrated ? current.claimTypeId : undefined}
                            />
                          )}
                        </label>
                        <AmountDisplay block size="large" value={current.earned + current.earnedAdj} />
                      </Col>
                      <Col flex="40px" className="claim-entitlement__current-operator">
                        <label>&nbsp;</label>
                        <div>-</div>
                      </Col>
                      <Col flex="auto" className="claim-entitlement__current-amount">
                        <label>
                          Taken
                          {current.paidAdj !== 0 && !!current.paidAdj && (
                            <InfoTooltip title={`Adjustment: ${formatMoney(current.paidAdj, 2)}`} />
                          )}
                        </label>
                        <AmountDisplay
                          block
                          size="large"
                          value={current.pendingApproval + current.pendingPayment + current.paid + current.paidAdj}
                        />
                      </Col>
                      <Col flex="40px" className="claim-entitlement__current-operator">
                        <label>&nbsp;</label>
                        <div>=</div>
                      </Col>
                      <Col flex="auto" className="claim-entitlement__current-amount">
                        <label>Balance</label>
                        <AmountDisplay block size="large" value={current.poolBalance} />
                      </Col>
                    </Row>
                    <Divider />
                    <>
                      {limitText.map((l, index) => (
                        <div key={index}>
                          <Space>
                            <i className="fal fa-circle-info" />
                            <span>{l}</span>
                          </Space>
                        </div>
                      ))}
                    </>
                    {current.notes && (
                      <>
                        <Divider />
                        <Space>
                          <i className="fal fa-note" />
                          {current.notes}
                        </Space>
                      </>
                    )}
                  </Card>
                  <div className="claim-entitlement__taken-choices">
                    <Checkbox.Group onChange={handleOnChangeStatus}>
                      <Row gutter={15}>
                        <Col>
                          <div
                            className={classNames('claim-entitlement__taken-choices-item', {
                              selected: paymentStatuses.includes(ClaPaymentStatus.pendingApproval)
                            })}
                          >
                            <Checkbox value={ClaPaymentStatus.pendingApproval}>
                              <div className="status-label">Pending for approval</div>
                              <AmountDisplay block value={current.pendingApproval} />
                            </Checkbox>
                          </div>
                        </Col>
                        <Col>
                          <div
                            className={classNames('claim-entitlement__taken-choices-item', {
                              selected: paymentStatuses.includes(ClaPaymentStatus.pendingPayment)
                            })}
                          >
                            <Checkbox value={ClaPaymentStatus.pendingPayment}>
                              <div className="status-label">Pending for payment</div>
                              <AmountDisplay block value={current.pendingPayment} />
                            </Checkbox>
                          </div>
                        </Col>
                        <Col>
                          <div
                            className={classNames('claim-entitlement__taken-choices-item', {
                              selected: paymentStatuses.includes(ClaPaymentStatus.paid)
                            })}
                          >
                            <Checkbox value={ClaPaymentStatus.paid}>
                              <div className="status-label">Paid</div>
                              <AmountDisplay block value={current.paid + current.paidAdj} />
                            </Checkbox>
                          </div>
                        </Col>
                      </Row>
                    </Checkbox.Group>
                  </div>
                  <ClaimEntitlementRecords
                    periodYear={periodYear}
                    claimTypeId={claimTypeId}
                    employeeId={employeeId}
                    paymentStatuses={paymentStatuses}
                  />
                </>
              )}
            </div>
          </Col>
        </Row>
        {canModify && <EditClaimEntitlementDrawer {...drawerState} onClose={handleCloseDrawer} />}
      </div>
    </div>
  )
}
