import { RefObject, useEffect, useState } from 'react'
import useInView from 'react-cool-inview'

interface Options<T> {
  ref?: RefObject<T>
  root?: HTMLElement
  rootMargin?: string
  threshold?: number | number[]
  trackVisibility?: boolean
  delay?: number
  unobserveOnEnter?: boolean
}

export const useFirstInView = <T extends HTMLElement>(options?: Options<T>) => {
  const { ref, inView, ...rest } = useInView<T>(options)
  const [first, setFirst] = useState(false)

  useEffect(() => {
    if (!first && inView) {
      setFirst(true)
    }
  }, [inView, first])

  return { ref, inView: first, ...rest }
}
