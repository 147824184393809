import React, { CSSProperties, FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import moment from 'moment-timezone'
import { Card, ColumnsType, Form, Space, Table } from '~/core-components'
import { YearKeyValues } from '~/components'
import { Screen, ViewCriteria, ViewCriteriaSimple, updateViewCriteria, useFirstView } from '~/features/selection'
import { usePermissionGate } from '~/features/iam'
import { StoreState } from '~/types/store'
import { ATT_ROUTES } from '~/routes/routes'
import { dispatch } from '~/stores/store'
import { formatDateRange } from '~/utils'
import { Permission, PermissionAction } from '~/constants'
import { selectAttendancePeriodsView } from '../../selectors'
import { fetchAttendancePeriodsView } from '../../actions'
import { refetchAttendancePeriodsView } from '../../reducers'
import { AttendancePeriodRowState } from '../../types'
import { AddAttendancePeriodButton } from './components/AddAttendancePeriodButton'
import { AttendancePeriodStatusTag } from '../AttendancePeriodStatusTag/AttendancePeriodStatusTag'
import './AttendancePeriods.less'

interface AttendancePeriodsProps {}

const paginationStyle: CSSProperties = { marginRight: 20 }

const TODAY_YEAR = moment().year()
const SCREEN_CODE: Screen = 'attendance_period'
const PAGE_SIZE_OPTIONS = ['20', '50', '100']

export const AttendancePeriods: FC<AttendancePeriodsProps> = () => {
  const [page, setPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(20)
  const [periodYear, setPeriodYear] = useState<number>(TODAY_YEAR)

  const [view, viewLoading] = useFirstView(SCREEN_CODE)
  const viewId = view?.id || ''
  const dataLoading = useSelector((state: StoreState) => state.attendance.attendancePeriodsViewLoading)
  const data = useSelector(selectAttendancePeriodsView)(viewId || '')
  const refetch = useSelector((state: StoreState) => state.attendance.attendancePeriodsViewRefetch)

  const canModify = usePermissionGate(Permission.attDailyRecord, PermissionAction.Modify)

  useEffect(() => {
    if (viewId) {
      dispatch(fetchAttendancePeriodsView(viewId, periodYear, { offset: pageSize * (page - 1), limit: pageSize }))
    }
  }, [viewId, periodYear, page, pageSize, refetch])

  const columns: ColumnsType<AttendancePeriodRowState> = useMemo(() => {
    let columns: ColumnsType<AttendancePeriodRowState> = [
      {
        title: 'Payment month',
        key: 'payPeriod',
        dataIndex: 'payPeriod',
        width: 160
      },
      {
        title: 'Name',
        key: 'name',
        dataIndex: 'name',
        render: (value: string, record) => (
          <Link to={ATT_ROUTES.period.replace(':id', record.id).replace(':tab?', 'records')}>{value}</Link>
        )
      },
      {
        title: 'Period',
        key: 'startDate',
        dataIndex: 'startDate',
        render: (value: string, record) => <>{formatDateRange(record.startDate, record.endDate)}</>
      },
      {
        title: 'Status',
        key: 'status',
        dataIndex: 'status',
        render: (status: string) => <AttendancePeriodStatusTag status={status} />
      },
      {
        title: 'Employees',
        key: 'employeeCount',
        dataIndex: 'employeeCount',
        width: 80
      }
    ]
    return columns
  }, [])

  const handleCriteriaApply = useCallback(
    async (criteria: ViewCriteria[]) => {
      if (viewId) {
        setPage(1)
        await dispatch(updateViewCriteria(SCREEN_CODE, viewId, { id: viewId, criteria }))
        dispatch(refetchAttendancePeriodsView())
      }
    },
    [viewId]
  )

  const handlePaginationChange = useCallback((page: number, pageSize?: number) => {
    setPage(page)
    setPageSize(pageSize || 20)
  }, [])

  const handlePeriodChange = useCallback(periodYear => {
    setPage(1)
    setPeriodYear(periodYear)
  }, [])

  return (
    <div className="attendance-periods">
      <div className="attendance-periods__body">
        <div className="attendance-periods__action-bar">
          <Space>
            <Form.Item label="">
              <YearKeyValues
                noOfYearBefore={-5}
                noOfYearAfter={0}
                value={periodYear}
                onChange={handlePeriodChange}
                allowClear={false}
              />
            </Form.Item>
          </Space>
          <ViewCriteriaSimple screenCode={SCREEN_CODE} viewId={viewId} onApply={handleCriteriaApply} label="" />
          <Space align="start">{canModify && <AddAttendancePeriodButton />}</Space>
        </div>
        <Card fitParent table>
          <Table
            rowKey="id"
            dataSource={data?.data}
            columns={columns}
            fitParent
            loading={dataLoading || viewLoading}
            pagination={{
              total: data?.count,
              current: page,
              pageSize,
              pageSizeOptions: PAGE_SIZE_OPTIONS,
              showSizeChanger: true,
              onChange: handlePaginationChange,
              style: paginationStyle
            }}
          />
        </Card>
      </div>
    </div>
  )
}
