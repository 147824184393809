import React, { FC } from 'react'
import { Redirect, Route, RouteProps, Switch } from 'react-router'
import { ATT_PATH, ATT_ROUTES } from '~/routes/routes'
import { AttendanceOverview } from './AttendanceOverview/AttendanceOverview'
import { AttendancePeriod } from './AttendancePeriod/AttendancePeriod'
import { DailyRecord } from './DailyRecord/DailyRecord'
import './AttendanceLayout.less'

const routeList: RouteProps[] = [
  {
    path: ATT_ROUTES.dailyRecord,
    component: DailyRecord,
    exact: true
  },
  {
    path: ATT_ROUTES.period,
    component: AttendancePeriod,
    exact: true
  },
  {
    path: ATT_ROUTES.tab,
    component: AttendanceOverview,
    exact: true
  }
]

export const AttendanceLayout: FC = () => {
  return (
    <div className="attendance-layout">
      <Switch>
        <Redirect exact from={ATT_PATH} to={ATT_ROUTES.tab.replace(':tab?', 'period')} />
        {routeList.map(route => (
          <Route key={route.path as string} {...route} />
        ))}
      </Switch>
    </div>
  )
}
