import React, { FC, useCallback, useState } from 'react'
import { LinkProps, SecondaryLink, Tooltip } from '~/core-components'
import { ViewClockRecordsDrawer } from './ViewClockRecordsDrawer'
import { useClockRecordsByDaily } from '../../../hooks'

export interface ViewClockRecordsLinkProps extends LinkProps {
  dailyRecordId?: string
}

interface DrawerState {
  visible: boolean
}
const DEFAULT_DRAWER_STATE: DrawerState = { visible: false }

export const ViewClockRecordsLink: FC<ViewClockRecordsLinkProps> = ({ dailyRecordId, ...props }) => {
  const [drawerState, setDrawerState] = useState<DrawerState>(DEFAULT_DRAWER_STATE)
  const [data] = useClockRecordsByDaily(dailyRecordId)

  const handleOpenDrawer = useCallback(() => {
    setDrawerState({ visible: true })
  }, [])

  const handleCloseDrawer = useCallback(() => {
    setDrawerState(DEFAULT_DRAWER_STATE)
  }, [])

  if (!dailyRecordId) return null
  if (!data || data.length === 0) return null

  return (
    <>
      <Tooltip title="View clock records">
        <SecondaryLink className="view-clock-records-link" onClick={handleOpenDrawer} {...props}>
          <i className="fal fa-clock" />
        </SecondaryLink>
      </Tooltip>
      <ViewClockRecordsDrawer {...drawerState} dailyRecordId={dailyRecordId} onClose={handleCloseDrawer} />
    </>
  )
}
