import { Permission } from './permission'

export class FeatureFlagPermission {
  static get payroll() {
    return [
      Permission.employeePayGroup,
      Permission.employeePayRecurring,
      Permission.employeePayment,
      Permission.employeeStatutory,
      Permission.employeeStatExempt,
      Permission.payMaster,
      Permission.payRun,
      Permission.ytd
    ]
  }
  static get leave() {
    return [
      Permission.employeeLeaveGroup,
      Permission.lveGroup,
      Permission.lveType,
      Permission.lveEntitlement,
      Permission.lveRecord,
      Permission.lveApproval
    ]
  }
  static get claim() {
    return [
      Permission.claType,
      Permission.cfConfig,
      Permission.claCurrency,
      Permission.claEntitlement,
      Permission.claRecord,
      Permission.claApproval
    ]
  }
  static get attendance() {
    return [
      Permission.location,
      Permission.break,
      Permission.otConfig,
      Permission.teConfig,
      Permission.shift,
      Permission.shiftCategory,
      Permission.shiftRole
      //Enable this after the permission id is no longer share with master
      //Permission.calendar
    ]
  }
}
