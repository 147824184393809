import { setDailyRecordsView, setDailyRecordsViewLoading } from '../reducers'
import { apiGetDailyRecordsView } from '../api/daily-record.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, DateRange, FetchOptions, Pagination } from '~/types/common'

export const fetchDailyRecordsView = (
  viewId: string,
  pagination: Pagination,
  search: string,
  payGroupId: string | undefined,
  startDate: string | undefined,
  endDate: string | undefined,
  options: FetchOptions = DEFAULT_FETCH_OPTIONS
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!viewId || !startDate || !endDate) return

    const loading = getState().attendance.dailyRecordsViewLoading
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().attendance.dailyRecordsView?.entities[viewId]
      if (hasData) return
    }

    try {
      dispatch(setDailyRecordsViewLoading(true))
      const { result, status } = await apiGetDailyRecordsView(viewId, {
        ...pagination,
        search,
        ...({ startDate, endDate } as DateRange),
        payGroupId
      })
      if (status) {
        dispatch(setDailyRecordsView(result))
      }
    } finally {
      dispatch(setDailyRecordsViewLoading(false))
    }
  }
}
