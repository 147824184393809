import { EventInput } from '@fullcalendar/react'
import { ReactNode } from 'react'

export type Country = 'sg'

export interface KeyValue {
  key: string
  value: string
  inactiveDate?: string
  sequence?: number
  isShown?: boolean
}

export interface IdValue {
  id: string
  value: string
}

export interface MenuItem {
  value: string
  path: string | ReactNode
  sysPermissionId: string | string[]
  internal?: boolean
}

export interface FetchOptions {
  strategy: FetchStrategy
}

export const DEFAULT_FETCH_OPTIONS: FetchOptions = {
  strategy: 'always'
}

export type FetchStrategy = 'when-empty' | 'always'

export interface HookOptions {
  enabled: boolean
}

export const DEFAULT_HOOK_OPTIONS: HookOptions = {
  enabled: true
}

export interface Pagination {
  offset: number
  limit: number
}

export interface SearchLimit {
  limit: number
}

export interface Search {
  search: string
}

export interface DateRange {
  startDate: string
  endDate: string
}

export interface GroupChildren<T> {
  id: string
  name: string
  children: T[]
}

export interface OptionGroup {
  id: string
  label: string
  options: OptionItem[]
}

export interface OptionItem {
  id: string
  value: string
  label: string
}

export type DrawerCloseReason = 'add' | 'edit' | 'delete' | 'cancel'

export type BasePath = 'employee' | 'payroll-sg' | 'leave' | 'claim' | 'attendance'

export interface Audit {
  createdBy: string
  createdDate: string
  updatedBy: string
  updatedDate: string
}

export interface UserInfo {
  loginId: string
  isSuperUser: string[]
  isOwner: boolean
}

export interface CalendarEvent extends EventInput {}
