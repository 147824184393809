import { showError, showSuccess } from '~/utils'
import { ThunkResult } from '~/types/store'
import { generateJsonPatch } from '../../../utils/generateJsonPatch'
import { apiUpdateClockRecord } from '../api/clock-record.api'
import { ISaveClockRecord } from '../types'
import { refetchClockRecordsView } from '../reducers'

export const updateClockRecord = (
  id: string,
  before: ISaveClockRecord,
  request: ISaveClockRecord
): ThunkResult<void> => {
  return async dispatch => {
    if (!id) return

    const after = { ...before, ...request } as ISaveClockRecord
    const patch = generateJsonPatch<ISaveClockRecord>(before, after)
    const { status, errors, message, errorData } = await apiUpdateClockRecord(id, patch)
    if (status) {
      dispatch(refetchClockRecordsView())
      showSuccess('Saved')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
