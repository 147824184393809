import React, { FC, useCallback } from 'react'
import moment from 'moment-timezone'
import { Button, Descriptions, Space, Spin, Tooltip } from '~/core-components'
import { DayIndicator, TimeDayInput } from '~/components'
import { LoadingOutlined } from '@ant-design/icons'
import { refetchDailyRecords, useDailyRecord } from '~/features/attendance'
import { ATT_ROUTES } from '~/routes/routes'
import { dispatch } from '~/stores/store'
import { convertToTimeDay, formatHourMinute } from '~/utils'
import './DailyRecordPreview.less'

export interface DailyRecordPreviewProps {
  id?: string
}

export const DailyRecordPreview: FC<DailyRecordPreviewProps> = ({ id }) => {
  const [dailyRecord, loading] = useDailyRecord(id || '')
  const startTimeDay = convertToTimeDay(dailyRecord?.clockInTimeAdj || dailyRecord?.clockInTime, dailyRecord?.clockDate)
  const endTimeDay = convertToTimeDay(dailyRecord?.clockOutTimeAdj || dailyRecord?.clockOutTime, dailyRecord?.clockDate)

  const handleOpen = useCallback(() => {
    if (!dailyRecord) return

    window.open(
      ATT_ROUTES.dailyRecord
        .replace(':attendancePeriodId', dailyRecord.attendancePeriodId)
        .replace(':clockDate', moment(dailyRecord.clockDate).format('YYYY-MM-DD'))
        .replace(':employeeId', dailyRecord.employeeId)
        .replace(':shiftId?', dailyRecord.shiftId || ''),
      '_blank'
    )
  }, [dailyRecord])

  const handleRefresh = useCallback(() => {
    dispatch(refetchDailyRecords())
  }, [])

  if (!dailyRecord) {
    if (loading) {
      return <Spin size="small" indicator={<LoadingOutlined spin />} />
    } else {
      return null
    }
  }

  return (
    <div className="daily-record-preview">
      <Descriptions colon={false} column={1}>
        <Descriptions.Item label="Time">
          {startTimeDay.time === '' ? (
            '-'
          ) : (
            <Space>
              <TimeDayInput
                readOnly
                readOnlyBorderless
                time={startTimeDay.time ? moment(startTimeDay.time, 'HH:mm') : undefined}
                day={startTimeDay.day as DayIndicator}
                date={dailyRecord.clockDate}
              />
              <>-</>
              <TimeDayInput
                readOnly
                readOnlyBorderless
                time={endTimeDay.time ? moment(endTimeDay.time, 'HH:mm') : undefined}
                day={endTimeDay.day as DayIndicator}
                date={dailyRecord.clockDate}
              />
            </Space>
          )}
        </Descriptions.Item>
        <Descriptions.Item label="Working hours">
          <Space>
            <Tooltip title="Paid hours">{formatHourMinute(dailyRecord.workHourPaid)}</Tooltip>
            <>/</>
            <Tooltip title="Actual hours">{formatHourMinute(dailyRecord.workHourActual)}</Tooltip>
          </Space>
        </Descriptions.Item>
        {dailyRecord.latenessActual > 0 && (
          <Descriptions.Item label="Lateness">
            <Space>
              <Tooltip title="Deductible">{formatHourMinute(dailyRecord.latenessUnpaid, 'minute')}</Tooltip>
              <>/</>
              <Tooltip title="Actual">{formatHourMinute(dailyRecord.latenessActual, 'minute')}</Tooltip>
            </Space>
          </Descriptions.Item>
        )}
        {dailyRecord.undertimeActual > 0 && (
          <Descriptions.Item label="Undertime">
            <Space>
              <Tooltip title="Deductible">{formatHourMinute(dailyRecord.undertimeUnpaid, 'minute')}</Tooltip>
              <>/</>
              <Tooltip title="Actual">{formatHourMinute(dailyRecord.undertimeActual, 'minute')}</Tooltip>
            </Space>
          </Descriptions.Item>
        )}
        {dailyRecord.otActual > 0 && (
          <Descriptions.Item label="Overtime">{formatHourMinute(dailyRecord.otActual)}</Descriptions.Item>
        )}
      </Descriptions>
      <div className="daily-record-preview__action">
        <Tooltip title="Refresh daily record" placement="topRight">
          <Button
            type="link"
            size="small"
            icon={<i className="fal fa-refresh" />}
            loading={loading}
            onClick={handleRefresh}
          />
        </Tooltip>
        <Tooltip title="Open daily record in new tab" placement="topRight">
          <Button
            type="link"
            size="small"
            icon={<i className="fal fa-arrow-up-right-from-square" />}
            onClick={handleOpen}
          />
        </Tooltip>
      </div>
    </div>
  )
}
