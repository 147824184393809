import React, { FC, KeyboardEvent, useCallback, useEffect, useMemo, useState } from 'react'
import moment from 'moment-timezone'
import classNames from 'classnames'
import confirm from 'antd/lib/modal/confirm'
import { LoadingOutlined, WarningOutlined } from '@ant-design/icons'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import {
  Button,
  Card,
  Form,
  Input,
  Link,
  PageHeader,
  Radio,
  RadioChangeEvent,
  Select,
  Space,
  Tag,
  Tooltip
} from '~/core-components'
import {
  Col,
  DayIndicator,
  DocumentTitle,
  EditableCardState,
  InfoTooltip,
  NewTabLinkIcon,
  Person,
  Row,
  TimeDayInput
} from '~/components'
import { useSysOptions } from '~/features/employee'
import { usePermissionGate } from '~/features/iam'
import { useLeaveTypes, useLeaveTypesDict } from '~/features/leave'
import { useCompany, usePayGroup } from '~/features/master'
import { AttendancePeriodStatus, Permission, PermissionAction, WorkSchedule, emptyGuid } from '~/constants'
import { ATT_ROUTES, SETTINGS_ROUTES } from '~/routes/routes'
import { ActionResult, Errors, StoreState } from '~/types/store'
import { dispatch } from '~/stores/store'
import { convertToTimeDay, formatDateRange, formatHourMinute, getBaseUrl } from '~/utils'
import {
  useAttendancePeriod,
  useBreaksDict,
  useCalendarsDict,
  useDailyRecordEmployees,
  useDailyRecordsByEmployeeDate,
  useOtConfigsDict,
  useTeConfigsDict
} from '../../hooks'
import { selectDayCode } from '../../selectors'
import { deleteDailyRecord, lockDailyRecord, processDaily, unlockDailyRecord, updateDailyRecord } from '../../actions'
import { refetchDailyRecords } from '../../reducers'
import { DailyRecordState, IUpdateDailyRecord } from '../../types'
import { ShiftName } from '../Shifts/components/ShiftName'
import { ShiftDayTime } from '../Shifts/components/ShiftDayTime'
import { ViewClockRecordsLink } from './components/ViewClockRecordsLink'
import './DailyRecord.less'

export interface DailyRecordProps {}

interface DailyRecordParams {
  attendancePeriodId: string
  clockDate: string
  employeeId: string
  shiftId?: string
}

interface FormData {
  id: string
  startTime: string
  startDay: number
  endTime: string
  endDay: number

  // for work_schedule = 'calendar', allow overwrite
  workStatusType: string
  shiftId?: string
  breakId?: string
  locationId?: string

  latenessUnpaid: number
  latenessPaid: number
  latenessLeaveTypeId?: string
  undertimeUnpaid: number
  undertimePaid: number
  undertimeLeaveTypeId?: string
  ot01: number
  ot02: number
  ot03: number
  ot04: number
  ot05: number
  ot06: number
  ot07: number
  ot08: number
  ot09: number
  ot10: number
  te01: number
  te02: number
  te03: number
  te04: number
  te05: number
  te06: number
  te07: number
  te08: number
  te09: number
  te10: number
  notes?: string
}

const EMPTY_FORM_DATA: FormData = {
  id: '',
  startTime: '',
  startDay: 0,
  endTime: '',
  endDay: 0,
  workStatusType: '',
  shiftId: '',
  breakId: '',
  locationId: '',
  latenessUnpaid: 0,
  latenessPaid: 0,
  latenessLeaveTypeId: undefined,
  undertimeUnpaid: 0,
  undertimePaid: 0,
  undertimeLeaveTypeId: undefined,
  ot01: 0,
  ot02: 0,
  ot03: 0,
  ot04: 0,
  ot05: 0,
  ot06: 0,
  ot07: 0,
  ot08: 0,
  ot09: 0,
  ot10: 0,
  te01: 0,
  te02: 0,
  te03: 0,
  te04: 0,
  te05: 0,
  te06: 0,
  te07: 0,
  te08: 0,
  te09: 0,
  te10: 0,
  notes: ''
}

const OT_LIST = ['ot01', 'ot02', 'ot03', 'ot04', 'ot05', 'ot06', 'ot07', 'ot08', 'ot09', 'ot10']
const TE_LIST = ['te01', 'te02', 'te03', 'te04', 'te05', 'te06', 'te07', 'te08', 'te09', 'te10']
const baseUrl = getBaseUrl('/filestore')

export const DailyRecord: FC<DailyRecordProps> = () => {
  const { attendancePeriodId, employeeId, clockDate, shiftId } = useParams<DailyRecordParams>()

  const history = useHistory()
  const [cardState, setCardState] = useState<EditableCardState>()
  const [errors, setErrors] = useState<Errors>()
  const [formData, setFormData] = useState<FormData>(EMPTY_FORM_DATA)
  const canModify = usePermissionGate(Permission.attDailyRecord, PermissionAction.Modify)
  const readOnly = cardState !== 'editing' && cardState !== 'saving'
  const [activeShiftId, setActiveShiftId] = useState<string | null>(null)
  const [locking, setLocking] = useState<string>()

  const [attendancePeriod] = useAttendancePeriod(attendancePeriodId, 'always')
  const payGroupId = attendancePeriod?.payGroupId
  const [payGroup] = usePayGroup(attendancePeriod?.payGroupId || '', 'when-empty')
  const [company] = useCompany(payGroup?.companyId || '', 'when-empty')

  const processing = useSelector((state: StoreState) => state.attendance.dailyProcessing)
  const [employees, employeesLoading] = useDailyRecordEmployees(clockDate)
  const [dailyRecords, loading] = useDailyRecordsByEmployeeDate(clockDate, employeeId)
  const isEmpty = !loading && dailyRecords.length === 0
  const dailyRecord = useMemo(() => dailyRecords.find(d => d.shiftId === activeShiftId), [dailyRecords, activeShiftId])
  const dayCode = useSelector(selectDayCode)(dailyRecord?.id)
  const isLocked = Boolean(dailyRecord?.lockedBy)

  const [breakDict] = useBreaksDict()
  const [workStatusTypeDict] = useSysOptions('work_status_type')
  const [workScheduleDict] = useSysOptions('work_schedule')
  const [calendarDict] = useCalendarsDict()
  const [leaveTypes] = useLeaveTypes()
  const [leaveTypesDict] = useLeaveTypesDict()
  const [otConfigs] = useOtConfigsDict()
  const [teConfigs] = useTeConfigsDict()

  const canModifyShift = usePermissionGate(Permission.shift, PermissionAction.Modify)
  const canModifyBreak = usePermissionGate(Permission.break, PermissionAction.Modify)
  const canModifyCalendar = usePermissionGate(Permission.calendar, PermissionAction.Modify)

  const routes = useMemo(
    () => [
      {
        path: ATT_ROUTES.tab.replace(':tab?', 'period'),
        breadcrumbName: 'Overview'
      },
      {
        path: ATT_ROUTES.period.replace(':id', attendancePeriodId).replace(':tab?', 'records'),
        breadcrumbName: 'Attendance period'
      },
      {
        path: '',
        breadcrumbName: 'Daily record'
      }
    ],
    [attendancePeriodId]
  )

  useEffect(() => {
    if (dailyRecords.length > 0) {
      const isShiftExist = dailyRecords.some(da => da.shiftId === shiftId)
      if (!isShiftExist) {
        setActiveShiftId(dailyRecords[0].shiftId ?? null)
      } else if (!activeShiftId) {
        setActiveShiftId(shiftId ?? null)
      }
    }
  }, [activeShiftId, shiftId, dailyRecords])

  useEffect(() => {
    if (activeShiftId) {
      history.push(
        ATT_ROUTES.dailyRecord
          .replace(':attendancePeriodId', attendancePeriodId)
          .replace(':clockDate', clockDate)
          .replace(':employeeId', employeeId)
          .replace(':shiftId?', activeShiftId)
      )
    }
  }, [history, attendancePeriodId, clockDate, employeeId, activeShiftId])

  const resetFormData = useCallback(() => {
    if (dailyRecord) {
      setCardState(undefined)
      setErrors(undefined)

      const {
        id,
        clockDate,
        clockInTime,
        clockInTimeAdj,
        clockOutTime,
        clockOutTimeAdj,
        workStatusType,
        shiftId,
        breakId,
        locationId,
        latenessUnpaid,
        latenessPaid,
        latenessLeaveTypeId,
        undertimeUnpaid,
        undertimePaid,
        undertimeLeaveTypeId,
        ot01,
        ot02,
        ot03,
        ot04,
        ot05,
        ot06,
        ot07,
        ot08,
        ot09,
        ot10,
        te01,
        te02,
        te03,
        te04,
        te05,
        te06,
        te07,
        te08,
        te09,
        te10,
        notes
      } = dailyRecord

      const startTime = clockInTimeAdj || clockInTime
      const endTime = clockOutTimeAdj || clockOutTime

      const startTimeDay = convertToTimeDay(startTime, clockDate)
      const endTimeDay = convertToTimeDay(endTime, clockDate)

      setFormData({
        id,
        startTime: startTimeDay.time,
        startDay: startTimeDay.day,
        endTime: endTimeDay.time,
        endDay: endTimeDay.day,
        workStatusType,
        shiftId,
        breakId,
        locationId,
        latenessUnpaid,
        latenessPaid,
        latenessLeaveTypeId,
        undertimeUnpaid,
        undertimePaid,
        undertimeLeaveTypeId,
        ot01,
        ot02,
        ot03,
        ot04,
        ot05,
        ot06,
        ot07,
        ot08,
        ot09,
        ot10,
        te01,
        te02,
        te03,
        te04,
        te05,
        te06,
        te07,
        te08,
        te09,
        te10,
        notes
      })
    }
  }, [dailyRecord])

  useEffect(() => {
    if (dailyRecord) {
      resetFormData()
    } else {
      setFormData(EMPTY_FORM_DATA)
    }
  }, [dailyRecord, resetFormData])

  const handleShiftChange = useCallback((event: RadioChangeEvent) => {
    setActiveShiftId(event.target.value)
  }, [])

  const handleCancel = useCallback(() => {
    if (dailyRecord) {
      resetFormData()
    }
  }, [dailyRecord, resetFormData])

  const handleKeyDown = useCallback(
    (event: KeyboardEvent<HTMLDivElement>) => {
      if (event.key === 'Escape') {
        handleCancel()
      }
    },
    [handleCancel]
  )

  const handleFormDataChange = useCallback((updates: { [field: string]: any }) => {
    setErrors(undefined)
    setFormData(data => ({ ...data, ...updates }))
  }, [])

  const handleDateChange = useCallback(
    (value: moment.Moment | null) => {
      setActiveShiftId(null)
      history.push(
        ATT_ROUTES.dailyRecord
          .replace(':attendancePeriodId', attendancePeriodId)
          .replace(':clockDate', value?.format('YYYY-MM-DD') || '')
          .replace(':employeeId', employeeId)
          .replace(':shiftId?', '')
      )
    },
    [history, attendancePeriodId, employeeId]
  )

  const handlePrevDay = useCallback(() => {
    setActiveShiftId(null)
    history.push(
      ATT_ROUTES.dailyRecord
        .replace(':attendancePeriodId', attendancePeriodId)
        .replace(':clockDate', moment(clockDate).add(-1, 'day').format('YYYY-MM-DD'))
        .replace(':employeeId', employeeId)
        .replace(':shiftId?', '')
    )
  }, [history, attendancePeriodId, clockDate, employeeId])

  const handleNextDay = useCallback(() => {
    setActiveShiftId(null)
    history.push(
      ATT_ROUTES.dailyRecord
        .replace(':attendancePeriodId', attendancePeriodId)
        .replace(':clockDate', moment(clockDate).add(1, 'day').format('YYYY-MM-DD'))
        .replace(':employeeId', employeeId)
        .replace(':shiftId?', '')
    )
  }, [history, attendancePeriodId, clockDate, employeeId])

  const handleProcessNew = useCallback(async () => {
    await dispatch(processDaily(clockDate, clockDate, [employeeId], 'all', payGroupId))
    dispatch(refetchDailyRecords())
  }, [clockDate, employeeId, payGroupId])

  const handleProcessExisting = useCallback(async () => {
    await dispatch(processDaily(clockDate, clockDate, [employeeId], 'existing', payGroupId))
    dispatch(refetchDailyRecords())
  }, [clockDate, employeeId, payGroupId])

  const handleLock = useCallback(async () => {
    if (dailyRecord) {
      setLocking(dailyRecord.id)
      try {
        if (dailyRecord.lockedBy) {
          await dispatch(unlockDailyRecord(dailyRecord.id))
        } else {
          await dispatch(lockDailyRecord(dailyRecord.id))
        }
      } finally {
        setLocking(undefined)
      }
    }
  }, [dailyRecord])

  const handleEdit = useCallback(() => {
    if (isLocked) return
    setCardState('editing')
  }, [isLocked])

  const handleSave = useCallback(async () => {
    if (dailyRecord) {
      setCardState('saving')
      setErrors(undefined)

      let result: ActionResult | undefined
      try {
        const saved = mapStateToDto(dailyRecord)
        const changes = mapFormToDto(dailyRecord, formData)
        const clockTimeChanged =
          (changes.clockInTimeAdj &&
            saved.clockInTimeAdj &&
            !moment(changes.clockInTimeAdj).isSame(saved.clockInTimeAdj, 'minute')) ||
          (saved.clockInTimeAdj == null && changes.clockInTimeAdj != null) ||
          (changes.clockOutTimeAdj &&
            saved.clockOutTimeAdj &&
            !moment(changes.clockOutTimeAdj).isSame(saved.clockOutTimeAdj, 'minute')) ||
          (saved.clockOutTimeAdj == null && changes.clockOutTimeAdj != null)

        result = await dispatch(updateDailyRecord(dailyRecord.id, saved, changes))

        if (!result?.errors) {
          if (clockTimeChanged) {
            confirm({
              title: 'Recalculate daily record',
              content:
                'Changes have been made to the start time or end time. Would you like to recalculate the record to ensure that lateness, undertime, and overtime are adjusted accordingly?',
              onOk: async () => {
                await handleProcessExisting()
              },
              okText: 'Recalculate'
            })
          } else {
            confirm({
              title: 'Lock daily record',
              content:
                'Do you want to lock the attendance record? If you choose not to lock it, subsequent recalculations may overwrite the changes you made.',
              onOk: async () => {
                await handleLock()
              },
              okText: 'Lock'
            })
          }
        }
      } catch {
        setCardState('editing')
      }

      if (result?.errors) {
        setCardState('editing')
        setErrors(result.errors)
      }

      if (!result?.errors) {
        setCardState(undefined)
      }
    }
  }, [dailyRecord, formData, handleProcessExisting, handleLock])

  const handleDelete = useCallback(() => {
    if (dailyRecord?.id) {
      confirm({
        title: 'Delete daily record',
        content: `Do you want to delete daily record "${moment(dailyRecord.clockDate).format('DD MMM YYYY (ddd)')}"?`,
        onOk: async () => {
          const result: ActionResult | undefined = await dispatch(deleteDailyRecord(dailyRecord.id))
          if (!result?.errors) {
            history.push(ATT_ROUTES.period.replace(':id', attendancePeriodId).replace(':tab?', 'records'))
          }
        },
        okText: 'Delete',
        okType: 'danger'
      })
    }
  }, [history, dailyRecord, attendancePeriodId])

  return (
    <div id="daily-record" className="daily-record">
      <DocumentTitle title="Daily Record" />
      <PageHeader
        title={
          <div className="daily-record__page-header">
            <div className="daily-record__page-header-title">
              {attendancePeriod?.name} <span className="daily-record__page-header-dot">&#9675;</span> {company?.name}
            </div>
            <div>Period {formatDateRange(attendancePeriod?.startDate, attendancePeriod?.endDate)}</div>
          </div>
        }
        containerId="daily-record"
        breadcrumb={{ routes }}
        extra={
          canModify &&
          cardState === 'editing' && (
            <Button key="delete" onClick={handleDelete}>
              Delete
            </Button>
          )
        }
      />
      <div className="daily-record__header">
        <Row gutter={15} align="bottom">
          <Col flex="280px" className="daily-record__header-left">
            <Space direction="vertical">
              <div className="daily-record__header-date">
                <Input.Date
                  className="daily-record__header-date-select"
                  allowClear={false}
                  format="DD MMM YYYY (ddd)"
                  value={moment(clockDate)}
                  onChange={handleDateChange}
                  disabledDate={current =>
                    current &&
                    attendancePeriod != null &&
                    (current > moment(attendancePeriod.endDate) || current < moment(attendancePeriod.startDate))
                  }
                />
                <Tooltip title="Previous day">
                  <Button
                    className="daily-record__header-date-prev"
                    icon={<i className="fal fa-chevron-left" />}
                    onClick={handlePrevDay}
                  />
                </Tooltip>
                <Tooltip title="Next day">
                  <Button
                    className="daily-record__header-date-next"
                    icon={<i className="fal fa-chevron-right" />}
                    onClick={handleNextDay}
                  />
                </Tooltip>
              </div>
              <Select
                className="daily-record__header-em-select"
                showSearch
                allowClear={false}
                loading={employeesLoading}
                optionFilterProp="title"
                onChange={(id: string) => {
                  setActiveShiftId(null)
                  history.push(
                    ATT_ROUTES.dailyRecord
                      .replace(':attendancePeriodId', attendancePeriodId)
                      .replace(':clockDate', clockDate)
                      .replace(':employeeId', id)
                      .replace(':shiftId?', '')
                  )
                }}
                value={employeeId}
              >
                {employees?.map(em => (
                  <Select.Option key={em.id} value={em.id} title={em.name}>
                    <Person
                      name={em.name}
                      description={em.description}
                      photo={
                        em.photoId && em.photoId !== emptyGuid
                          ? `${baseUrl}/file/${em.photoId}/thumbnailphoto/36`
                          : undefined
                      }
                    />
                  </Select.Option>
                ))}
              </Select>
            </Space>
          </Col>
          <Col flex="1">
            <Space direction="vertical">
              {dailyRecords.length > 1 && (
                <Radio.Group value={activeShiftId} onChange={handleShiftChange}>
                  <Space direction="vertical">
                    {dailyRecords.map(d => (
                      <Radio value={d.shiftId}>
                        <Space>
                          <ShiftName id={d.shiftId} hideNewTab />
                          <ShiftDayTime shiftId={d.shiftId} dayCode={dayCode} />
                        </Space>
                      </Radio>
                    ))}
                  </Space>{' '}
                </Radio.Group>
              )}
              <Row className="daily-record__header-summary">
                <Col className="daily-record__header-summary-box">
                  <NewTabLinkIcon
                    path={SETTINGS_ROUTES.shift.replace(':id', dailyRecord?.shiftId || '')}
                    hidden={!canModifyShift}
                  >
                    <label>Shift</label>
                  </NewTabLinkIcon>
                  <div className="daily-record__header-summary-value">
                    <ShiftName id={dailyRecord?.shiftId} hideNewTab />
                    <ShiftDayTime shiftId={dailyRecord?.shiftId} dayCode={dayCode} />
                  </div>
                </Col>
                <Col className="daily-record__header-summary-box">
                  <NewTabLinkIcon path={SETTINGS_ROUTES.breaks} hidden={!canModifyBreak}>
                    <label>Break</label>
                  </NewTabLinkIcon>
                  <div className="daily-record__header-summary-value">
                    {breakDict[dailyRecord?.breakId || '']?.name}
                  </div>
                </Col>
                <Col className="daily-record__header-summary-box">
                  <label>Working status</label>
                  <div className="daily-record__header-summary-value">
                    <div>{workStatusTypeDict[dailyRecord?.workStatusType || '']?.value}</div>
                    {dailyRecord?.isHoliday && (
                      <div>
                        <Tag color="#f2bd3a">Holiday</Tag>
                      </div>
                    )}
                  </div>
                </Col>
                <Col className="daily-record__header-summary-box">
                  <NewTabLinkIcon
                    path={SETTINGS_ROUTES.workCalendar.replace(':id', dailyRecord?.calendarId || '')}
                    hidden={!canModifyCalendar}
                  >
                    <label>Calendar</label>
                  </NewTabLinkIcon>
                  <div className="daily-record__header-summary-value">
                    {dailyRecord?.calendarId ? (
                      <>
                        <div>{calendarDict[dailyRecord?.calendarId || '']?.name}</div>
                        {dailyRecord?.workSchedule === WorkSchedule.schedule && (
                          <div>
                            <Tag color="#d7c7f6">{workScheduleDict[dailyRecord?.workSchedule || '']?.value}</Tag>
                          </div>
                        )}
                      </>
                    ) : (
                      <>{workScheduleDict[dailyRecord?.workSchedule || '']?.value}</>
                    )}
                  </div>
                </Col>
                <Col className="daily-record__header-summary-lock">
                  {canModify && attendancePeriod?.status !== AttendancePeriodStatus.Completed && (
                    <Tooltip title={isLocked ? 'Locked' : 'Unlocked'}>
                      <Link
                        className={classNames('daily-record__lock', {
                          'daily-record__lock--locked': !!dailyRecord?.lockedBy
                        })}
                        onClick={handleLock}
                      >
                        {dailyRecord && locking === dailyRecord?.id ? (
                          <LoadingOutlined />
                        ) : isLocked ? (
                          <i className="fal fa-lock" />
                        ) : (
                          <i className="fal fa-lock-open" />
                        )}
                      </Link>
                    </Tooltip>
                  )}
                </Col>
              </Row>
            </Space>
          </Col>
          <Col hidden={!canModify || isEmpty || isLocked}>
            <Space direction="vertical" align="end" className="daily-record__header-actions">
              {cardState === 'editing' || cardState === 'saving' ? (
                <>
                  <Button type="primary" loading={cardState === 'saving'} onClick={handleSave}>
                    Save
                  </Button>
                  <Button onClick={handleCancel}>Cancel</Button>
                </>
              ) : (
                <>
                  <Button type="primary" onClick={handleEdit}>
                    Edit
                  </Button>
                  {dailyRecord?.isDirty ? (
                    <Tooltip title="This record has not been recalculated after changes made to the start time or end time. Lateness, undertime, and overtime values may be inaccurate.">
                      <Button onClick={handleProcessExisting} loading={processing} icon={<WarningOutlined />}>
                        Recalculate
                      </Button>
                    </Tooltip>
                  ) : (
                    <Button onClick={handleProcessExisting} loading={processing}>
                      Recalculate
                    </Button>
                  )}
                </>
              )}
            </Space>
          </Col>
        </Row>
      </div>
      <div className="daily-record__body">
        {isEmpty && (
          <div className="daily-record__body-empty">
            <Card>No daily record found</Card>
            <Button type="primary" onClick={handleProcessNew} loading={processing}>
              Add daily record
            </Button>
          </div>
        )}
        <Form className="daily-record__body-form" hidden={isEmpty}>
          <div className="daily-record__body-left">
            <Card onKeyDown={handleKeyDown} onDoubleClick={handleEdit}>
              <div className="section-title">
                <Space>
                  Working hours
                  {dailyRecord?.clockInTime && <ViewClockRecordsLink dailyRecordId={dailyRecord?.id} />}
                </Space>
              </div>
              <Row gutter={15}>
                <Col flex="126px">
                  <Form.Item
                    label={
                      <>
                        Start
                        {dailyRecord?.clockInTimeAdj && dailyRecord?.clockInTime && (
                          <InfoTooltip
                            title={`Original start time: ${
                              dailyRecord?.clockInTime ? moment(dailyRecord.clockInTime).format('HH:mm') : ''
                            }`}
                          />
                        )}
                      </>
                    }
                    validateStatus={errors?.clockInTimeAdj ? 'error' : ''}
                    help={errors?.clockInTimeAdj}
                  >
                    <Space size={0}>
                      <TimeDayInput
                        time={formData.startTime ? moment(formData.startTime, 'HH:mm') : undefined}
                        day={formData.startDay as DayIndicator}
                        readOnly={readOnly}
                        readOnlyBorderless
                        onChange={(startTime, startDay) =>
                          handleFormDataChange({ startTime: startTime?.format('HH:mm'), startDay })
                        }
                      />
                    </Space>
                  </Form.Item>
                </Col>
                <Col flex="126px">
                  <Form.Item
                    label={
                      <>
                        End
                        {dailyRecord?.clockOutTimeAdj && dailyRecord?.clockOutTime && (
                          <InfoTooltip
                            title={`Original end time: ${
                              dailyRecord?.clockOutTime ? moment(dailyRecord.clockOutTime).format('HH:mm') : ''
                            }`}
                          />
                        )}
                      </>
                    }
                    validateStatus={errors?.clockOutTimeAdj ? 'error' : ''}
                    help={errors?.clockOutTimeAdj}
                  >
                    <Space size={0}>
                      <TimeDayInput
                        time={formData.endTime ? moment(formData.endTime, 'HH:mm') : undefined}
                        day={formData.endDay as DayIndicator}
                        readOnly={readOnly}
                        readOnlyBorderless
                        onChange={(endTime, endDay) =>
                          handleFormDataChange({ endTime: endTime?.format('HH:mm'), endDay })
                        }
                      />
                    </Space>
                  </Form.Item>
                </Col>
                <Col flex="126px">
                  <Form.Item label="Actual hours">{formatHourMinute(dailyRecord?.workHourActual)}</Form.Item>
                </Col>
                <Col flex="126px">
                  <Form.Item label="Paid hours">{formatHourMinute(dailyRecord?.workHourPaid)}</Form.Item>
                </Col>
              </Row>
              <div className="section-title">Lateness</div>
              <Row gutter={15}>
                <Col flex="126px">
                  <Form.Item label="Actual">{formatHourMinute(dailyRecord?.latenessActual, 'minute')}</Form.Item>
                </Col>
                <Col flex="126px">
                  <Form.Item
                    label="Non-deductible"
                    validateStatus={errors?.latenessPaid ? 'error' : ''}
                    help={errors?.latenessPaid}
                  >
                    {readOnly ? (
                      <>{formatHourMinute(formData.latenessPaid, 'minute')}</>
                    ) : (
                      <Space>
                        <Input.Number
                          value={formData.latenessPaid}
                          readOnly={readOnly}
                          onChange={latenessPaid => {
                            const actual = dailyRecord?.latenessActual || 0
                            const paid = (latenessPaid as number) || 0
                            handleFormDataChange({
                              latenessPaid,
                              latenessUnpaid: actual > paid ? actual - paid : 0
                            })
                          }}
                        />
                        <span>m</span>
                      </Space>
                    )}
                  </Form.Item>
                </Col>
                <Col flex="126px">
                  <Form.Item
                    label="Deductible"
                    validateStatus={errors?.latenessUnpaid ? 'error' : ''}
                    help={errors?.latenessUnpaid}
                  >
                    {readOnly ? (
                      <>{formatHourMinute(formData.latenessUnpaid, 'minute')}</>
                    ) : (
                      <Space>
                        <Input.Number
                          value={formData.latenessUnpaid}
                          readOnly={readOnly}
                          onChange={latenessUnpaid => {
                            const actual = dailyRecord?.latenessActual || 0
                            const unpaid = (latenessUnpaid as number) || 0
                            handleFormDataChange({
                              latenessUnpaid,
                              latenessPaid: actual > unpaid ? actual - unpaid : 0
                            })
                          }}
                        />
                        <span>m</span>
                      </Space>
                    )}
                  </Form.Item>
                </Col>
                <Col flex="1">
                  <Form.Item
                    label="Leave"
                    validateStatus={errors?.latenessLeaveTypeId ? 'error' : ''}
                    help={errors?.latenessLeaveTypeId}
                  >
                    {readOnly ? (
                      <>{leaveTypesDict[formData.latenessLeaveTypeId || '']?.name || '-'}</>
                    ) : (
                      <Select
                        showSearch
                        optionFilterProp="title"
                        value={formData.latenessLeaveTypeId}
                        readOnly={readOnly}
                        onChange={latenessLeaveTypeId => handleFormDataChange({ latenessLeaveTypeId })}
                      >
                        {leaveTypes.map(lt => (
                          <Select.Option key={lt.id} value={lt.id} title={lt.name}>
                            {lt.name}
                          </Select.Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <div className="section-title">Undertime</div>
              <Row gutter={15}>
                <Col flex="126px">
                  <Form.Item label="Actual">{formatHourMinute(dailyRecord?.undertimeActual, 'minute')}</Form.Item>
                </Col>
                <Col flex="126px">
                  <Form.Item
                    label="Non-deductible"
                    validateStatus={errors?.undertimePaid ? 'error' : ''}
                    help={errors?.undertimePaid}
                  >
                    {readOnly ? (
                      <>{formatHourMinute(formData.undertimePaid, 'minute')}</>
                    ) : (
                      <Space>
                        <Input.Number
                          value={formData.undertimePaid}
                          readOnly={readOnly}
                          onChange={undertimePaid => {
                            const actual = dailyRecord?.undertimeActual || 0
                            const paid = (undertimePaid as number) || 0
                            handleFormDataChange({
                              undertimePaid,
                              undertimeUnpaid: actual > paid ? actual - paid : 0
                            })
                          }}
                        />
                        <span>m</span>
                      </Space>
                    )}
                  </Form.Item>
                </Col>
                <Col flex="126px">
                  <Form.Item
                    label="Deductible"
                    validateStatus={errors?.undertimeUnpaid ? 'error' : ''}
                    help={errors?.undertimeUnpaid}
                  >
                    {readOnly ? (
                      <>{formatHourMinute(formData.undertimeUnpaid, 'minute')}</>
                    ) : (
                      <Space>
                        <Input.Number
                          value={formData.undertimeUnpaid}
                          readOnly={readOnly}
                          onChange={undertimeUnpaid => {
                            const actual = dailyRecord?.undertimeActual || 0
                            const unpaid = (undertimeUnpaid as number) || 0
                            handleFormDataChange({
                              undertimeUnpaid,
                              undertimePaid: actual > unpaid ? actual - unpaid : 0
                            })
                          }}
                        />
                        <span>m</span>
                      </Space>
                    )}
                  </Form.Item>
                </Col>
                <Col flex="1">
                  <Form.Item
                    label="Leave"
                    validateStatus={errors?.undertimeLeaveTypeId ? 'error' : ''}
                    help={errors?.undertimeLeaveTypeId}
                  >
                    {readOnly ? (
                      <>{leaveTypesDict[formData.undertimeLeaveTypeId || '']?.name || '-'}</>
                    ) : (
                      <Select
                        showSearch
                        optionFilterProp="title"
                        value={formData.undertimeLeaveTypeId}
                        readOnly={readOnly}
                        onChange={undertimeLeaveTypeId => handleFormDataChange({ undertimeLeaveTypeId })}
                      >
                        {leaveTypes.map(lt => (
                          <Select.Option key={lt.id} value={lt.id} title={lt.name}>
                            {lt.name}
                          </Select.Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <div className="section-title">Overtime</div>
              <Row gutter={15}>
                <Col flex="126px">
                  <Form.Item label="Before">{formatHourMinute(dailyRecord?.otActualBefore)}</Form.Item>
                </Col>
                <Col flex="126px">
                  <Form.Item label="Within">{formatHourMinute(dailyRecord?.otActualWithin)}</Form.Item>
                </Col>
                <Col flex="126px">
                  <Form.Item label="After">{formatHourMinute(dailyRecord?.otActualAfter)}</Form.Item>
                </Col>
              </Row>
            </Card>
          </div>
          <div className="daily-record__body-right">
            <Card onKeyDown={handleKeyDown} onDoubleClick={handleEdit}>
              <Row gutter={15}>
                <Col flex="1">
                  {OT_LIST.map(
                    ot =>
                      otConfigs[ot]?.isShown && (
                        <Form.Item
                          key={ot}
                          label={otConfigs[ot]?.name}
                          validateStatus={errors && errors[ot] ? 'error' : ''}
                          help={errors && errors[ot]}
                        >
                          {readOnly ? (
                            <>{formData[ot as keyof FormData]}</>
                          ) : (
                            <Input.Number
                              value={formData[ot as keyof FormData] as number}
                              readOnly={readOnly}
                              onChange={value => handleFormDataChange({ [ot]: value })}
                            />
                          )}
                        </Form.Item>
                      )
                  )}
                </Col>
                <Col flex="1">
                  {TE_LIST.map(
                    te =>
                      teConfigs[te]?.isShownInDaily && (
                        <Form.Item
                          key={te}
                          label={teConfigs[te]?.name}
                          validateStatus={errors && errors[te] ? 'error' : ''}
                          help={errors && errors[te]}
                        >
                          {readOnly ? (
                            <>{formData[te as keyof FormData]}</>
                          ) : (
                            <Input.Number
                              value={formData[te as keyof FormData] as number}
                              readOnly={readOnly}
                              onChange={value => handleFormDataChange({ [te]: value })}
                            />
                          )}
                        </Form.Item>
                      )
                  )}
                </Col>
              </Row>
            </Card>
          </div>
        </Form>
      </div>
    </div>
  )
}

const mapStateToDto = (state: DailyRecordState): IUpdateDailyRecord => ({
  id: state.id,
  employeeId: state.employeeId,
  clockDate: state.clockDate,
  clockInTimeAdj: state.clockInTimeAdj,
  clockOutTimeAdj: state.clockOutTimeAdj,
  workStatusType: state.workStatusType,
  shiftId: state.shiftId,
  breakId: state.breakId,
  locationId: state.locationId,
  latenessLeaveTypeId: state.latenessLeaveTypeId,
  latenessPaid: state.latenessPaid,
  latenessUnpaid: state.latenessUnpaid,
  undertimeLeaveTypeId: state.undertimeLeaveTypeId,
  undertimePaid: state.undertimePaid,
  undertimeUnpaid: state.undertimeUnpaid,
  ot01: state.ot01,
  ot02: state.ot02,
  ot03: state.ot03,
  ot04: state.ot04,
  ot05: state.ot05,
  ot06: state.ot06,
  ot07: state.ot07,
  ot08: state.ot08,
  ot09: state.ot09,
  ot10: state.ot10,
  te01: state.te01,
  te02: state.te02,
  te03: state.te03,
  te04: state.te04,
  te05: state.te05,
  te06: state.te06,
  te07: state.te07,
  te08: state.te08,
  te09: state.te09,
  te10: state.te10,
  notes: state.notes
})

const mapFormToDto = (dailyRecord: DailyRecordState, form: FormData): IUpdateDailyRecord => {
  let clockInTimeAdj: string | null = form.startTime
    ? moment(dailyRecord.clockDate)
        .add(form.startDay, 'day')
        .add(moment.duration(form.startTime))
        .format('YYYY-MM-DD HH:mm:ss')
    : null

  if (clockInTimeAdj === moment(dailyRecord.clockInTime).format('YYYY-MM-DD HH:mm:ss')) {
    clockInTimeAdj = null
  }

  let clockOutTimeAdj: string | null = form.endTime
    ? moment(dailyRecord.clockDate)
        .add(form.endDay, 'day')
        .add(moment.duration(form.endTime))
        .format('YYYY-MM-DD HH:mm:ss')
    : null

  if (clockOutTimeAdj === moment(dailyRecord.clockOutTime).format('YYYY-MM-DD HH:mm:ss')) {
    clockOutTimeAdj = null
  }

  return {
    id: form.id,
    employeeId: dailyRecord.employeeId,
    clockDate: dailyRecord.clockDate,
    clockInTimeAdj,
    clockOutTimeAdj,
    workStatusType: form.workStatusType,
    shiftId: form.shiftId,
    breakId: form.breakId,
    locationId: form.locationId,
    latenessLeaveTypeId: form.latenessLeaveTypeId,
    latenessPaid: form.latenessPaid,
    latenessUnpaid: form.latenessUnpaid,
    undertimeLeaveTypeId: form.undertimeLeaveTypeId,
    undertimePaid: form.undertimePaid,
    undertimeUnpaid: form.undertimeUnpaid,
    ot01: form.ot01,
    ot02: form.ot02,
    ot03: form.ot03,
    ot04: form.ot04,
    ot05: form.ot05,
    ot06: form.ot06,
    ot07: form.ot07,
    ot08: form.ot08,
    ot09: form.ot09,
    ot10: form.ot10,
    te01: form.te01,
    te02: form.te02,
    te03: form.te03,
    te04: form.te04,
    te05: form.te05,
    te06: form.te06,
    te07: form.te07,
    te08: form.te08,
    te09: form.te09,
    te10: form.te10,
    notes: form.notes
  }
}
