import React, { ChangeEvent, FC, useCallback, useEffect, useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import classNames from 'classnames'
import { LoadingOutlined } from '@ant-design/icons'
import { Button, Card, Form, Input, Space, Spin } from '~/core-components'
import { Col, DocumentTitle, Row } from '~/components'
import { dispatch } from '~/stores/store'
import { Errors } from '~/types/store'
import { decodeJwt } from '~/utils'
import { EMP_PATH } from '~/routes/routes'
import { ClaExpenseSubmissionType } from '~/constants'
import {
  addClaimRecordCommentByToken,
  approveClaimByToken,
  fetchMyClaimTaskByToken,
  rejectClaimByToken
} from '../../actions'
import { mapSSClaimRecordCommentStateToIClaimRecordComment } from '../../selectors'
import { IClaimRecordComment, SSClaimTaskTokenState } from '../../types'
import { SSClaimRecordCommentsComponent } from '../SSClaimRecordComments/SSClaimRecordComments'
import { MyClaimTaskTokenHeader } from './MyClaimTaskTokenHeader'
import { MyClaimTaskTokenSingleForm } from './MyClaimTaskTokenSingleForm'
import { MyClaimTaskTokenMultipleFormRow } from './MyClaimTaskTokenMultipleFormRow'
import './MyClaimTaskToken.less'

interface MyClaimTaskProps {}

type ActionType = 'approve' | 'reject' | 'comment'

export const MyClaimTaskToken: FC<MyClaimTaskProps> = () => {
  const history = useHistory()
  const match = useRouteMatch<{ token: string }>()
  const token = match.params.token
  const [fetching, setFetching] = useState(true)
  const [data, setData] = useState<SSClaimTaskTokenState>()
  const [comments, setComments] = useState<IClaimRecordComment[]>([])
  const [forbidden, setForbidden] = useState(false)
  const [completed, setCompleted] = useState<'approved' | 'rejected'>()
  const classes = classNames('my-claim-task-token', { 'my-claim-task-token--completed': !!completed })

  const [loading, setLoading] = useState<ActionType>()
  const [comment, setComment] = useState('')
  const [errors, setErrors] = useState<Errors>()

  const isMultiple = data?.claimType?.expenseSubmissionType === ClaExpenseSubmissionType.Multiple

  useEffect(() => {
    const { tenant_code } = decodeJwt(token)
    localStorage.setItem('tenant', tenant_code || '')
  }, [token])

  useEffect(() => {
    const comments = mapSSClaimRecordCommentStateToIClaimRecordComment(data?.comments)
    setComments(comments)
  }, [data])

  const fetchData = useCallback(async () => {
    if (token) {
      try {
        setFetching(true)
        const { result, status, statusCode } = await dispatch(fetchMyClaimTaskByToken(token))
        if (status) {
          setData(result)
        } else if (statusCode === 403) {
          setForbidden(true)
        }
      } finally {
        setFetching(false)
      }
    }
  }, [token])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const handleApprove = useCallback(async () => {
    try {
      setLoading('approve')
      setErrors(undefined)
      const result = await dispatch(approveClaimByToken(token, comment))
      if (result?.errors) {
        setErrors(result.errors)
      }
      if (!result?.errors) {
        setCompleted('approved')
      }
    } finally {
      setLoading(undefined)
    }
  }, [token, comment])

  const handleReject = useCallback(async () => {
    try {
      setLoading('reject')
      setErrors(undefined)
      const result = await dispatch(rejectClaimByToken(token, comment))
      if (result?.errors) {
        setErrors(result.errors)
      }
      if (!result?.errors) {
        setCompleted('rejected')
      }
    } finally {
      setLoading(undefined)
    }
  }, [token, comment])

  const handleComment = useCallback(async () => {
    try {
      setLoading('comment')
      setErrors(undefined)
      const result = await dispatch(addClaimRecordCommentByToken(token, comment))
      if (result?.errors) {
        setErrors(result.errors)
      }
      if (!result?.errors) {
        setComment('')
        if (result.result) {
          const mapped = mapSSClaimRecordCommentStateToIClaimRecordComment([result.result])
          setComments(comments => [...mapped, ...comments])
        }
      }
    } finally {
      setLoading(undefined)
    }
  }, [token, comment])

  const handleLogin = useCallback(() => {
    history.replace(EMP_PATH)
  }, [history])

  return (
    <div className={classes}>
      <DocumentTitle title="Claim approval" />
      {fetching ? (
        <Spin indicator={<LoadingOutlined spin />} />
      ) : (
        <Card
          style={{
            width: comments.length > 0 && !completed ? 'calc(100vw - 300px)' : '100vw',
            maxWidth: isMultiple ? 1200 : 800
          }}
        >
          <div className="my-claim-task-token__content">
            <Row justify="center" hidden={!completed}>
              <Col style={{ textAlign: 'center' }}>
                <Form.Item>
                  <h2>Claim {completed}</h2>
                  <h4>You have successfully {completed} the claim.</h4>
                </Form.Item>
              </Col>
            </Row>
            {!isMultiple && (
              <Row className="my-claim-task-token__body" gutter={50}>
                <Col span={comments.length > 0 && !completed ? 12 : 24}>
                  <div className="my-claim-task-token__form">
                    {!data && !fetching && (
                      <div className="my-claim-task-token__form--empty">
                        {forbidden ? 'Access denied' : 'Not found'}
                      </div>
                    )}
                    {data && !fetching && (
                      <Form>
                        <MyClaimTaskTokenHeader token={token} data={data} />
                        <MyClaimTaskTokenSingleForm token={token} data={data} />
                      </Form>
                    )}
                  </div>
                </Col>
                <Col span={12} hidden={comments.length === 0 || !!completed}>
                  <SSClaimRecordCommentsComponent comments={comments} showHeader />
                </Col>
              </Row>
            )}
            {isMultiple && (
              <Row className="my-claim-task-token__body" gutter={50}>
                <Col span={24}>
                  <div className="my-claim-task-token__form">
                    {!data && !fetching && (
                      <div className="my-claim-task-token__form--empty">
                        {forbidden ? 'Access denied' : 'Not found'}
                      </div>
                    )}
                    {data && !fetching && (
                      <Form>
                        <MyClaimTaskTokenHeader token={token} data={data} />
                        {data.expenses?.map((exp, index) => (
                          <>
                            <MyClaimTaskTokenMultipleFormRow
                              key={exp.id}
                              token={token}
                              index={index}
                              claimType={data.claimType}
                              data={exp}
                              attachments={data.attachments}
                              taxRate={data.taxRate}
                            />
                            {!(index === data.expenses.length - 1) && (
                              <div className="expense-divider">
                                <hr />
                              </div>
                            )}
                          </>
                        ))}
                      </Form>
                    )}
                  </div>
                </Col>
                <Col span={24} hidden={!!completed}>
                  <SSClaimRecordCommentsComponent comments={comments} showHeader />
                </Col>
              </Row>
            )}
            {data && !fetching && (
              <Form className="my-claim-task-token__footer">
                {completed ? (
                  <Row justify="center">
                    <Col>
                      <Space direction="vertical">
                        <div>You may close the tab or login to continue with Zealys.</div>
                        <Button type="primary" onClick={handleLogin}>
                          Login
                        </Button>
                      </Space>
                    </Col>
                  </Row>
                ) : (
                  <>
                    <Row>
                      <Col span={24}>
                        <Form.Item
                          label="Enter your comment"
                          validateStatus={errors?.comment ? 'error' : ''}
                          help={errors?.comment}
                        >
                          <Input.TextArea
                            rows={2}
                            value={comment}
                            onChange={(event: ChangeEvent<HTMLTextAreaElement>) => setComment(event.target.value)}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row justify="center" className="button-group">
                      <Col>
                        <Space>
                          <Button
                            type="primary"
                            onClick={handleApprove}
                            loading={loading === 'approve'}
                            disabled={loading && loading !== 'approve'}
                          >
                            Approve
                          </Button>
                          <Button
                            onClick={handleReject}
                            loading={loading === 'reject'}
                            disabled={loading && loading !== 'reject'}
                          >
                            Reject
                          </Button>
                          <Button
                            onClick={handleComment}
                            loading={loading === 'comment'}
                            disabled={loading && loading !== 'comment'}
                          >
                            Send comment
                          </Button>
                        </Space>
                      </Col>
                    </Row>
                  </>
                )}
              </Form>
            )}
          </div>
        </Card>
      )}
    </div>
  )
}
