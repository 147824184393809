import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { AttendancePeriodState } from '../types'

export const selectAttendancePeriodsByPayPeriod = createSelector(
  (state: StoreState) => state.attendance.attendancePeriods,
  attendancePeriodsState =>
    memoize(
      (payPeriod?: string, payGroupId?: string): AttendancePeriodState[] => {
        return Object.values(attendancePeriodsState?.entities || {}).filter(
          p => p?.payPeriod === payPeriod && p?.payGroupId === payGroupId
        ) as AttendancePeriodState[]
      },
      (payPeriod?: string, payGroupId?: string) => `${payPeriod}|${payGroupId}`
    )
)
