import { setClockRecordsByDailyLoading } from '../reducers'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'
import { apiGetClockRecordsByDaily } from '../api/clock-record.api'
import { replaceClockRecordsByDaily } from '../reducers'

export const fetchClockRecordsByDaily = (
  dailyRecordId?: string,
  options: FetchOptions = DEFAULT_FETCH_OPTIONS
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!dailyRecordId) return

    const loading = getState().attendance.clockRecordsByDailyLoading[dailyRecordId]
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().attendance.clockRecordsByDaily[dailyRecordId]?.entities
      if (hasData) return
    }

    try {
      dispatch(setClockRecordsByDailyLoading({ dailyRecordId, loading: true }))
      const { result, status } = await apiGetClockRecordsByDaily(dailyRecordId)
      if (status) {
        dispatch(replaceClockRecordsByDaily({ dailyRecordId, data: result }))
      }
    } finally {
      dispatch(setClockRecordsByDailyLoading({ dailyRecordId, loading: false }))
    }
  }
}
