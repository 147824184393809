import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { FetchStrategy } from '~/types/common'
import { StoreState } from '~/types/store'
import { fetchAttendancePeriodsByPayPeriod } from '../actions'
import { AttendancePeriodState } from '../types'
import { selectAttendancePeriodsByPayPeriod } from '../selectors'

export const useAttendancePeriodsByPayPeriod = (
  payPeriod?: string,
  payGroupId?: string,
  fetchStrategy: FetchStrategy = 'when-empty'
): [AttendancePeriodState[], boolean] => {
  const periods = useSelector(selectAttendancePeriodsByPayPeriod)(payPeriod, payGroupId)
  const loading = useSelector((state: StoreState) => state.attendance.attendancePeriodsLoading)

  useEffect(() => {
    dispatch(fetchAttendancePeriodsByPayPeriod(payPeriod, payGroupId, { strategy: fetchStrategy }))
  }, [payPeriod, payGroupId, fetchStrategy])

  return [periods, loading]
}
