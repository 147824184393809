import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { Select } from '~/core-components'
import { Col, ErrorDisplay, Row } from '~/components'
import { Errors, StoreState } from '~/types/store'
import { ImportClockRecordFormData } from '../ImportClockRecordDrawer'
import './ClockRecordColumnMapping.less'

interface ClockRecordColumnMappingProps {
  visible: boolean
  data: Partial<ImportClockRecordFormData>
  errors?: Errors
  onChange: (data: Partial<ImportClockRecordFormData>) => void
}

export const ClockRecordColumnMapping: FC<ClockRecordColumnMappingProps> = ({ visible, data, errors, onChange }) => {
  const sourceColumns = useSelector((store: StoreState) => store.attendance.clockRecordImport?.sourceColumns || [])
  const importColumns = useSelector((store: StoreState) => store.attendance.clockRecordImport?.importColumns || [])

  return (
    <div className="clock-record-column-mapping" hidden={!visible}>
      <ErrorDisplay errors={errors} />
      {data.clockRecordColumnMap?.map((col, index) => (
        <Row gutter={30} key={col.importColumn} className="clock-record-column-row">
          <Col span={12}>
            {index === 0 && <div className="clock-record-column-row__title">Column in Zealys</div>}
            <Select value={col.importColumn} readOnly>
              {importColumns.map(t => (
                <Select.Option key={t.fieldName} value={t.fieldName}>
                  {t.displayName}
                </Select.Option>
              ))}
            </Select>
          </Col>
          <Col span={12}>
            {index === 0 && <div className="clock-record-column-row__title">Column in Source file</div>}
            <Select
              value={col.sourceColumn}
              onChange={(sourceColumn: string) =>
                onChange({
                  clockRecordColumnMap: [
                    ...data.clockRecordColumnMap!.slice(0, index),
                    { importColumn: col.importColumn, sourceColumn },
                    ...data.clockRecordColumnMap!.slice(index + 1, data.clockRecordColumnMap!.length)
                  ]
                })
              }
            >
              {sourceColumns.map(t => (
                <Select.Option key={t.fieldName} value={t.fieldName}>
                  {t.displayName}
                </Select.Option>
              ))}
            </Select>
          </Col>
        </Row>
      ))}
    </div>
  )
}
