import React, { FC, useCallback } from 'react'
import { Screen, ViewCriteria, ViewCriteriaAdvanced, ViewCriteriaSingle } from '~/features/selection'
import { useGetViewIdByName } from '~/features/selection/hooks'

export interface PermissionDatasetProps {
  loginId: string
  screenCode: Screen
  readOnly: boolean
  singleFieldId?: string
  onChange: (criteria: ViewCriteria[]) => void
}

export const PermissionDataset: FC<PermissionDatasetProps> = ({
  loginId,
  screenCode,
  readOnly,
  singleFieldId,
  onChange
}) => {
  const [viewId] = useGetViewIdByName(screenCode, loginId ? `login_${loginId}` : undefined)

  const handleCriteriaChange = useCallback(
    (criteria: ViewCriteria[]) => {
      typeof onChange === 'function' && onChange(criteria)
    },
    [onChange]
  )

  return (
    <div className="permission-dataset">
      {singleFieldId ? (
        <ViewCriteriaSingle
          screenCode={screenCode}
          viewId={viewId}
          fieldId={singleFieldId}
          readOnly={readOnly}
          onChange={handleCriteriaChange}
        />
      ) : (
        <ViewCriteriaAdvanced
          label=""
          screenCode={screenCode}
          viewId={viewId}
          readOnly={readOnly}
          onChange={handleCriteriaChange}
        />
      )}
    </div>
  )
}
