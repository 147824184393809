import { setAttendancePeriods, setAttendancePeriodsLoading } from '../reducers'
import { apiGetAttendancePeriodsByPayPeriod } from '../api/attendance-period.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'

export const fetchAttendancePeriodsByPayPeriod = (
  payPeriod?: string,
  payGroupId?: string,
  options: FetchOptions = DEFAULT_FETCH_OPTIONS
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!payPeriod || !payGroupId) return

    const loading = getState().attendance.attendancePeriodsLoading
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = Object.values(getState().attendance.attendancePeriods?.entities || {}).some(
        p => p?.payPeriod === payPeriod
      )
      if (hasData) return
    }

    try {
      dispatch(setAttendancePeriodsLoading(true))
      const { result, status } = await apiGetAttendancePeriodsByPayPeriod(payPeriod, payGroupId)
      if (status) {
        dispatch(setAttendancePeriods(result))
      }
    } finally {
      dispatch(setAttendancePeriodsLoading(false))
    }
  }
}
