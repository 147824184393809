import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { ClockRecordState } from '../types'

export const selectClockRecordsByDaily = createSelector(
  (state: StoreState) => state.attendance.clockRecordsByDaily,
  clockRecordsByDailyState =>
    memoize((dailyRecordId: string): ClockRecordState[] => {
      return Object.values(clockRecordsByDailyState[dailyRecordId]?.entities || {}) as ClockRecordState[]
    })
)
