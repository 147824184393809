import React, { ChangeEvent, FC, useCallback, useEffect, useState } from 'react'
import moment from 'moment-timezone'
import confirm from 'antd/lib/modal/confirm'
import { Form, Input, Space, Switch } from '~/core-components'
import { Col, DayIndicator, DrawerForm, EmSelect, Row, TimeDayInput } from '~/components'
import { apiGetEmSelect, EmPublicPerson } from '~/features/employee'
import { dispatch } from '~/stores/store'
import { useFocus } from '~/hooks/use-focus'
import { ActionResult, Errors } from '~/types/store'
import { formatDate } from '~/utils'
import { addClockRecord, updateClockRecord, deleteClockRecord } from '../../../actions'
import { ClockRecordRowState, ISaveClockRecord } from '../../../types'
import { LocationKeyValues } from '../../Locations/components/LocationKeyValues'
import { ShiftKeyValues } from '../../Shift/components/ShiftKeyValues'
import { ShiftDayTime } from '../../Shifts/components/ShiftDayTime'
import './MutateClockRecordDrawer.less'

export interface MutateClockRecordDrawerProps {
  visible: boolean
  data?: ClockRecordRowState
  onClose: () => void
}

type FormData = ISaveClockRecord

const EMPTY_FORM_DATA: FormData = {
  employeeId: '',
  clockInTime: '',
  clockOutTime: '',
  locationId: '',
  outLocationId: '',
  shiftId: '',
  notes: ''
}

const TODAY = moment().format('YYYY-MM-DD')

export const MutateClockRecordDrawer: FC<MutateClockRecordDrawerProps> = ({
  visible,
  data,
  onClose
}: MutateClockRecordDrawerProps) => {
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState<FormData>(EMPTY_FORM_DATA)
  const [focusRef, setFocus] = useFocus(true)
  const [errors, setErrors] = useState<Errors>()
  const isOutLocation = formData.outLocationId && formData.outLocationId !== formData.locationId
  const [autoShift, setAutoShift] = useState(true)

  useEffect(() => {
    setTimeout(() => visible && setFocus(), 100)
    setErrors(undefined)
  }, [visible, setFocus])

  useEffect(() => {
    if (data) {
      const { id, employeeId, clockInTime, clockOutTime, locationId, outLocationId, shiftId, notes } = data
      setFormData({ id, employeeId, clockInTime, clockOutTime, locationId, outLocationId, shiftId, notes })
      setAutoShift(!shiftId)
    } else {
      setFormData(EMPTY_FORM_DATA)
    }
  }, [data])

  const handleFormDataChange = useCallback((updates: { [field: string]: any }) => {
    setFormData(formData => ({ ...formData, ...updates }))
  }, [])

  const handleOk = useCallback(async () => {
    let result: ActionResult | undefined
    setLoading(true)
    try {
      if (data) {
        result = await dispatch(updateClockRecord(data.id, mapToRequest(data), formData))
      } else {
        result = await dispatch(addClockRecord(formData))
      }
    } finally {
      setLoading(false)
    }

    if (result?.errors) {
      setErrors(result.errors)
    }

    if (!result?.errors) {
      typeof onClose === 'function' && onClose()
      setFormData(EMPTY_FORM_DATA)
    }
  }, [data, formData, onClose])

  const handleDelete = useCallback(
    (clockRecord: ClockRecordRowState | undefined) => {
      if (clockRecord) {
        const { id, clockInTime } = clockRecord
        confirm({
          title: 'Delete clock record',
          content: `Do you want to delete clock record dated "${formatDate(clockInTime)}"?`,
          onOk: async () => {
            const result: ActionResult | undefined = await dispatch(deleteClockRecord(id))
            if (result?.errors) {
              setErrors(result.errors)
            }

            if (!result?.errors) {
              typeof onClose === 'function' && onClose()
            }
          },
          okText: 'Delete',
          okType: 'danger'
        })
      }
    },
    [onClose]
  )

  const handleFetchEmployees = useCallback(async () => {
    const { status, result } = await apiGetEmSelect('past3mth')
    if (status) {
      return result
    }
    return []
  }, [])

  const handleAutoShiftChange = useCallback(
    (autoShift: boolean) => {
      if (autoShift) handleFormDataChange({ shiftId: null })
      setAutoShift(autoShift)
    },
    [handleFormDataChange]
  )

  return (
    <DrawerForm
      open={visible}
      title={data ? 'Edit clock record' : 'Add clock record'}
      onClose={onClose}
      confirmLoading={loading}
      width={500}
      showDelete={data ? true : false}
      onDelete={() => handleDelete(data)}
      className="mutate-clock-record-drawer"
      formId="form-clock-record"
    >
      <Form id="form-clock-record" onFinish={handleOk}>
        <Row>
          <Col span={24}>
            {data ? (
              <Form.Item label="">
                <EmPublicPerson id={formData.employeeId} />
              </Form.Item>
            ) : (
              <Form.Item label="Employee" validateStatus={errors?.employeeId ? 'error' : ''} help={errors?.employeeId}>
                <EmSelect
                  ref={focusRef}
                  value={formData.employeeId}
                  onFetch={handleFetchEmployees}
                  onChange={(value: string) => handleFormDataChange({ ...EMPTY_FORM_DATA, employeeId: value })}
                />
              </Form.Item>
            )}
          </Col>
        </Row>
        <Row>
          <Col flex="200px">
            <Form.Item label="Date" validateStatus={errors?.clockInTime ? 'error' : ''}>
              <Input.Date
                allowClear={false}
                value={formData.clockInTime ? moment(formData.clockInTime) : undefined}
                onChange={(value: moment.Moment | null) => {
                  const outTime = moment(formData.clockOutTime)
                  const hour = outTime.hour()
                  const minute = outTime.minute()

                  handleFormDataChange({
                    clockInTime: formData.clockInTime
                      ? value?.format('YYYY-MM-DDTHH:mm')
                      : value?.format('YYYY-MM-DDT00:00'),
                    clockOutTime: value
                      ?.hour(0)
                      .minute(0)
                      ?.add(hour, 'hour')
                      ?.add(minute, 'minute')
                      ?.format('YYYY-MM-DDT00:00')
                  })
                }}
                disabledDate={current => current && current.isAfter(moment())}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={30}>
          <Col span={12}>
            <Form.Item label="Time (in)" validateStatus={errors?.clockInTime ? 'error' : ''} help={errors?.clockInTime}>
              <Input.Time
                allowClear
                value={formData.clockInTime ? moment(formData.clockInTime) : undefined}
                onChange={(value: moment.Moment | null) =>
                  handleFormDataChange({
                    clockInTime: `${moment(formData.clockInTime || TODAY).format('YYYY-MM-DD')}T${
                      value?.format('HH:mm') || '00:00'
                    }`
                  })
                }
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Time (out)"
              validateStatus={errors?.clockOutTime ? 'error' : ''}
              help={errors?.clockOutTime}
            >
              <TimeDayInput
                date={formData.clockOutTime ? moment(formData.clockOutTime).format('YYYY-MM-DD') : undefined}
                time={formData.clockOutTime ? moment(formData.clockOutTime) : undefined}
                day={
                  formData.clockOutTime
                    ? (moment(formData.clockOutTime)
                        .startOf('day')
                        .diff(moment(formData.clockInTime).startOf('day'), 'day') as DayIndicator)
                    : 0
                }
                onChange={(value, day) =>
                  handleFormDataChange({
                    clockOutTime: moment(
                      `${moment(formData.clockInTime).format('YYYY-MM-DD')}T${value?.format('HH:mm') || '00:00'}`
                    )
                      .add(day, 'day')
                      .format('YYYY-MM-DDTHH:mm')
                  })
                }
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item>
              <Space>
                Auto assigned shift based on time (in)
                <Switch size="small" checked={autoShift} onChange={handleAutoShiftChange} />
              </Space>
              <Row hidden={autoShift}>
                <Col span={24}>
                  <Form.Item label="Shift" validateStatus={errors?.shiftId ? 'error' : ''} help={errors?.shiftId}>
                    <Row gutter={15} align="middle">
                      <Col span={12}>
                        <ShiftKeyValues
                          value={formData.shiftId}
                          onChange={(shiftId: string) => handleFormDataChange({ shiftId })}
                        />
                      </Col>
                      <Col span={12}>
                        <ShiftDayTime
                          shiftId={formData.shiftId}
                          dayCode={moment(formData.clockInTime).format('ddd').toLowerCase()}
                        />
                      </Col>
                    </Row>
                  </Form.Item>
                </Col>
              </Row>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={30}>
          <Col span={isOutLocation ? 12 : 24}>
            <Form.Item
              label={`Location${isOutLocation ? ' (in)' : ''}`}
              validateStatus={errors?.locationId ? 'error' : ''}
              help={errors?.locationId}
            >
              <LocationKeyValues
                value={formData.locationId}
                onChange={locationId => handleFormDataChange({ locationId })}
              />
            </Form.Item>
          </Col>
          <Col span={12} hidden={!isOutLocation}>
            <Form.Item
              label="Location (out)"
              validateStatus={errors?.outLocationId ? 'error' : ''}
              help={errors?.outLocationId}
            >
              <LocationKeyValues
                value={formData.outLocationId}
                onChange={outLocationId => handleFormDataChange({ outLocationId })}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item label="Notes" validateStatus={errors?.notes ? 'error' : ''} help={errors?.notes}>
              <Input.TextArea
                rows={5}
                value={formData.notes}
                onChange={(value?: ChangeEvent<HTMLTextAreaElement>) =>
                  handleFormDataChange({ notes: value?.target.value })
                }
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </DrawerForm>
  )
}

const mapToRequest = (data: ClockRecordRowState): ISaveClockRecord => ({
  id: data.id,
  employeeId: data.employeeId,
  clockInTime: data.clockInTime,
  clockOutTime: data.clockOutTime,
  locationId: data.locationId,
  outLocationId: data.outLocationId,
  shiftId: data.shiftId,
  notes: data.notes
})
