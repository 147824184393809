import { setUser, setUserLoading } from '../reducers'
import { apiGetUser } from '../api/login.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'

export const fetchUser = (userId: string, options: FetchOptions = DEFAULT_FETCH_OPTIONS): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!userId) return

    const loading = getState().temp.usersLoading
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().temp.users?.entities[userId]
      if (hasData) return
    }

    try {
      dispatch(setUserLoading(true))
      const { result, status } = await apiGetUser(userId)
      if (status) {
        dispatch(setUser(result))
      }
    } finally {
      dispatch(setUserLoading(false))
    }
  }
}
