import React, { FC, useCallback, useMemo, useState } from 'react'
import { Button, Card, ColumnsType, Link, PageHeader, Space, Table, Tooltip } from '~/core-components'
import { DocumentTitle } from '~/components'
import { SETTINGS_ROUTES } from '~/routes/routes'
import { usePermissionGate } from '~/features/iam'
import { Permission, PermissionAction } from '~/constants'
import { useLeaveBuddies } from '../../hooks'
import { LeaveBuddyState } from '../../types'
import { dispatch } from '~/stores/store'
import { fetchLeaveBuddies } from '../../actions'
import { MutateLeaveBuddyDrawer } from './components/MutateLeaveBuddyDrawer'
import './LeaveBuddies.less'

interface LeaveBuddiesProps {}

type LeaveBuddyTable = LeaveBuddyState

interface DrawerState {
  visible: boolean
  data?: LeaveBuddyState
}
const DEFAULT_DRAWER_STATE: DrawerState = { visible: false }

export const LeaveBuddies: FC<LeaveBuddiesProps> = () => {
  const [drawerState, setDrawerState] = useState<DrawerState>(DEFAULT_DRAWER_STATE)
  const canModify = usePermissionGate(Permission.lveBuddy, PermissionAction.Modify)
  const [leaveBuddies, loading] = useLeaveBuddies()

  const routes = useMemo(
    () => [
      {
        path: SETTINGS_ROUTES.main,
        breadcrumbName: 'Settings'
      },
      {
        path: '',
        breadcrumbName: 'Overview'
      }
    ],
    []
  )

  const handleAddBuddy = useCallback(() => {
    setDrawerState({ visible: true, data: undefined })
  }, [])

  const handleEditBuddy = useCallback((leaveBuddy: LeaveBuddyState) => {
    setDrawerState({ visible: true, data: leaveBuddy })
  }, [])

  const handleCloseDrawer = useCallback(() => {
    setDrawerState(DEFAULT_DRAWER_STATE)
  }, [])

  const handleRefresh = useCallback(() => {
    dispatch(fetchLeaveBuddies())
  }, [])

  const columns = useMemo(() => {
    let columns: ColumnsType<LeaveBuddyTable> = []

    columns.push(
      {
        title: 'Name',
        key: 'name',
        dataIndex: 'name',
        width: 300
      },
      {
        title: 'Buddy members',
        key: 'employees',
        dataIndex: 'employees',
        align: 'center'
      },
      {
        key: 'action',
        align: 'right',
        width: 70,
        render: (value: string, record) =>
          canModify && (
            <Link size="small" onClick={() => handleEditBuddy(record)}>
              edit
            </Link>
          )
      }
    )

    return columns
  }, [canModify, handleEditBuddy])

  return (
    <div className="leave-buddies">
      <DocumentTitle title="Leave buddy" />
      <PageHeader
        title="Leave buddy"
        breadcrumb={{ routes }}
        extra={
          <Space>
            {canModify && (
              <Button key="add" onClick={handleAddBuddy}>
                Add leave buddy
              </Button>
            )}
            <Tooltip title="Refresh">
              <Button icon={<i className="fal fa-refresh" />} onClick={handleRefresh} />
            </Tooltip>
          </Space>
        }
      />
      <div className="leave-buddies__body">
        <Card table>
          <Table rowKey="id" dataSource={leaveBuddies} pagination={false} columns={columns} loading={loading} />
        </Card>
        {canModify && <MutateLeaveBuddyDrawer {...drawerState} onClose={handleCloseDrawer} />}
      </div>
    </div>
  )
}
