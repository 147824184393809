import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { Button, Drawer, Space, Steps, UploadFile } from '~/core-components'
import { downloadWithDom, showError } from '~/utils'
import { dispatch } from '~/stores/store'
import { useIsMountedRef } from '~/hooks/use-is-mounted-ref'
import { ActionResult, Errors, StoreState } from '~/types/store'
import {
  ClockRecordImportState,
  ClockRecordImportValidateState,
  IClockRecordColumnMap
} from '~/features/attendance/types'
import {
  executeClockRecordImport,
  uploadClockRecordImport,
  validateClockRecordImport
} from '~/features/attendance/actions'
import { ClockRecordImportType, ClockRecordImportDelimiter } from '~/constants'
import { apiGetClockRecordErrorReportExcel } from '~/features/attendance/api/clock-record-import.api'
import { ClockRecordColumnMapping } from './components/ClockRecordColumnMapping'
import { UploadClockRecordFile } from './components/UploadClockRecordFile'
import { ValidationClockRecordFile } from './components/ValidationClockRecordFile'
import { ImportClockRecord } from './components/ImportClockRecord'
import './ImportClockRecordDrawer.less'

interface ImportClockRecordDrawerProps {
  visible: boolean
  onClose: (success?: boolean) => void
}

export interface ImportClockRecordFormData {
  locationId: string
  file?: UploadFile
  importType: string
  hasHeader: boolean
  delimiter: string
  clockRecordColumnMap: IClockRecordColumnMap[]
}

const EMPTY_FORM_DATA: ImportClockRecordFormData = {
  locationId: '',
  importType: ClockRecordImportType.Pair,
  hasHeader: true,
  delimiter: ClockRecordImportDelimiter.Comma,
  clockRecordColumnMap: []
}

const { Step } = Steps

type StepKey = 'upload' | 'map' | 'validate' | 'verify' | 'complete'

interface IStep {
  key: StepKey
  title: string
  nextButton?: string
  backButton?: string
}

const STEPS: IStep[] = [
  {
    key: 'upload',
    title: 'Upload file'
  },
  {
    key: 'map',
    title: 'Mapping'
  },
  {
    key: 'validate',
    title: 'Validate'
  },
  {
    key: 'verify',
    title: 'Verify and import',
    nextButton: 'Import'
  },
  {
    key: 'complete',
    title: 'Done',
    nextButton: 'Close'
  }
]

export const ImportClockRecordDrawer: FC<ImportClockRecordDrawerProps> = ({ visible, onClose }) => {
  const [formData, setFormData] = useState<ImportClockRecordFormData>(EMPTY_FORM_DATA)
  const [step, setStep] = useState(0)
  const [nextLoading, setNextLoading] = useState(false)
  const [errors, setErrors] = useState<Errors>()
  const hasDataToImport = useSelector(
    (state: StoreState) => (state.attendance.clockRecordImportValidate?.summary?.proceed || 0) > 0
  )
  const isMountedRef = useIsMountedRef()
  const [errorDownloading, setErrorDownloading] = useState(false)
  const summary = useSelector((state: StoreState) => state.attendance.clockRecordImportValidate?.summary)
  const hasInvalidRecords = useMemo(() => {
    return step === 2 && summary && summary?.error > 0
  }, [step, summary])

  useEffect(() => {
    setStep(0)
    setErrors(undefined)
    setFormData(EMPTY_FORM_DATA)
  }, [visible])

  const handleFormDataChange = useCallback((updates: { [field: string]: any }) => {
    setErrors(undefined)
    setFormData(formData => ({ ...formData, ...updates }))
  }, [])

  const uploadFile = useCallback(async () => {
    const errorMessages: string[] = []
    if (!formData.file) {
      errorMessages.push('Choose the file to import')
    }

    if (errorMessages.length > 0) {
      setErrors({ '*': errorMessages })
      return
    }

    if (formData.file) {
      let result: ActionResult<ClockRecordImportState> | undefined
      try {
        setNextLoading(true)
        result = await dispatch(uploadClockRecordImport(formData))

        const importColumns = result?.result?.importColumns || []
        const clockRecordColumnMap: IClockRecordColumnMap[] = importColumns.map(col => ({
          importColumn: col.fieldName,
          sourceColumn: ''
        }))

        handleFormDataChange({ clockRecordColumnMap })
      } finally {
        setNextLoading(false)
      }

      if (result?.errors) {
        if (Object.keys(result.errors).length === 0) {
          setErrors({ '*': ['Something went wrong. You might have modified the file content. Please choose again'] })
        } else {
          setErrors(result.errors)
        }
      }

      if (!result?.errors) {
        setErrors(undefined)
        setStep(step => step + 1)
      }
    }
  }, [formData, handleFormDataChange])

  const validateSource = useCallback(async () => {
    const errorMessages: string[] = []

    if (formData.clockRecordColumnMap.some(cr => !cr.sourceColumn)) {
      errorMessages.push('All the columns must be mapped')
    }

    const hasDuplicate = formData.clockRecordColumnMap.some(
      (map, index) =>
        map.sourceColumn !== '' &&
        formData.clockRecordColumnMap.findIndex(cr => cr.sourceColumn === map.sourceColumn) !== index
    )
    if (hasDuplicate) {
      errorMessages.push('There are more than 1 columns mapped to the same field in the system')
    }

    if (errorMessages.length > 0) {
      setErrors({ '*': errorMessages })
      return
    }

    let result: ActionResult<ClockRecordImportValidateState> | undefined
    try {
      setNextLoading(true)
      result = await dispatch(
        validateClockRecordImport(formData.locationId, formData.clockRecordColumnMap, formData.importType)
      )
    } finally {
      setNextLoading(false)
    }

    if (result?.errors) {
      setErrors(result.errors)
    }

    if (!result?.errors) {
      setErrors(undefined)
      setStep(step => step + 1)
    }
  }, [formData])

  const executeImport = useCallback(async () => {
    let result: ActionResult<any> | undefined
    try {
      setNextLoading(true)
      result = await dispatch(executeClockRecordImport(formData.locationId, formData.importType))
    } finally {
      setNextLoading(false)
    }

    if (result?.errors) {
      setErrors(result.errors)
    }

    if (!result?.errors) {
      setErrors(undefined)
      setStep(step => step + 1)
    }
  }, [formData])

  const handlePrev = useCallback(() => {
    setErrors(undefined)
    setStep(step => step - 1)
  }, [])

  const handleCloseDrawer = useCallback(
    (success?: boolean) => {
      typeof onClose === 'function' && onClose(success)
    },
    [onClose]
  )

  const handleNext = useCallback(async () => {
    if (STEPS[step].key === 'upload') {
      await uploadFile()
    } else if (STEPS[step].key === 'map') {
      await validateSource()
    } else if (STEPS[step].key === 'validate') {
      if (hasDataToImport) {
        setStep(step => step + 1)
      } else {
        showError('No row to import')
      }
    } else if (STEPS[step].key === 'verify') {
      await executeImport()
    } else if (STEPS[step].key === 'complete') {
      handleCloseDrawer(true)
    }
  }, [step, uploadFile, validateSource, hasDataToImport, executeImport, handleCloseDrawer])

  const handleDownloadError = useCallback(async () => {
    try {
      setErrorDownloading(true)
      const { status, result, errors, message, errorData } = await apiGetClockRecordErrorReportExcel()

      if (status) {
        const fileName = 'clock_record_import_error_report.xlsx'
        downloadWithDom(result, fileName)
      } else {
        console.error('Error while downloading', errors)
        showError(message, errorData)
      }
    } finally {
      if (isMountedRef.current) setErrorDownloading(false)
    }
  }, [isMountedRef])

  return (
    <Drawer
      open={visible}
      title="Upload clock records from File"
      onClose={() => handleCloseDrawer()}
      width="80%"
      className="import-clock-record-drawer"
      footer={
        <>
          <Space className="import-clock-record-drawer__download-bar-buttons">
            {step === 2 && (summary?.error || 0) > 0 && (
              <Button onClick={handleDownloadError} loading={errorDownloading}>
                Download error report
              </Button>
            )}
          </Space>
          <Space className="import-clock-record-drawer__action-bar-buttons">
            {STEPS[step - 1] && step !== STEPS.length - 1 && (
              <Button onClick={handlePrev}>{STEPS[step].backButton || 'Back'}</Button>
            )}
            {STEPS[step] && (
              <Button type="primary" onClick={handleNext} loading={nextLoading} disabled={hasInvalidRecords}>
                {STEPS[step].nextButton || 'Next'}
              </Button>
            )}
          </Space>
        </>
      }
    >
      <Steps progressDot size="small" current={step}>
        {STEPS.map(s => (
          <Step key={s.key} title={s.title} />
        ))}
      </Steps>
      <UploadClockRecordFile
        visible={STEPS[step].key === 'upload'}
        data={formData}
        onChange={handleFormDataChange}
        errors={errors}
      />
      <ClockRecordColumnMapping
        visible={STEPS[step].key === 'map'}
        data={formData}
        onChange={handleFormDataChange}
        errors={errors}
      />
      <ValidationClockRecordFile visible={STEPS[step].key === 'validate'} summary={summary} />
      <ImportClockRecord
        visible={['verify', 'complete'].includes(STEPS[step].key)}
        success={STEPS[step].key === 'complete'}
      />
    </Drawer>
  )
}
