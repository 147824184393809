export enum AttendancePeriodStatus {
  Draft = 'd',
  Completed = 'c'
}

export class AttScheduleType {
  static get flexi() {
    return 'flexi'
  }
  static get fixed() {
    return 'fixed'
  }
}

export class AttShiftDayCode {
  static get mon() {
    return 'mon'
  }
  static get tue() {
    return 'tue'
  }
  static get wed() {
    return 'wed'
  }
  static get thu() {
    return 'thu'
  }
  static get fri() {
    return 'fri'
  }
  static get sat() {
    return 'sat'
  }
  static get sun() {
    return 'sun'
  }
  static get off() {
    return 'off'
  }
  static get rest() {
    return 'rest'
  }
  static get holiday() {
    return 'hol'
  }
}

export const NON_WORKING_DAY_CODES = [AttShiftDayCode.holiday, AttShiftDayCode.off, AttShiftDayCode.rest]

export enum ClockRecordImportType {
  Pair = 'p',
  Single = 's'
}

export enum ClockRecordImportDelimiter {
  Comma = 'c',
  Tab = 't'
}
