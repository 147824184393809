import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit'
import persistReducer from 'redux-persist/es/persistReducer'
import {
  myClaimPersistConfig,
  SSClaimBalanceState,
  SSClaimRecordState,
  SSClaimRecordViewState,
  SSClaimRecordAttachmentState,
  SSClaimRecordCommentState,
  SSClaimTaskState,
  SSClaimRecordApprovalHistoryState,
  MyClaimRootState
} from './types'

const myClaimBalancesAdapter = createEntityAdapter<SSClaimBalanceState>()
const myClaimRecordsAdapter = createEntityAdapter<SSClaimRecordState>()
const myClaimRecordApprovalHistoriesAdapter = createEntityAdapter<SSClaimRecordApprovalHistoryState>()
const myClaimRecordAttachmentsAdapter = createEntityAdapter<SSClaimRecordAttachmentState>()
const myClaimRecordCommentsAdapter = createEntityAdapter<SSClaimRecordCommentState>()
const myClaimTasksAdapter = createEntityAdapter<SSClaimTaskState>()

const myClaimBalancesInitialState = myClaimBalancesAdapter.getInitialState()
const myClaimRecordsInitialState = myClaimRecordsAdapter.getInitialState()
const myClaimRecordApprovalHistoriesInitialState = myClaimRecordApprovalHistoriesAdapter.getInitialState()
const myClaimRecordAttachmentsInitialState = myClaimRecordAttachmentsAdapter.getInitialState()
const myClaimRecordCommentsInitialState = myClaimRecordCommentsAdapter.getInitialState()
const myClaimTasksInitialState = myClaimTasksAdapter.getInitialState()

const initialState: MyClaimRootState = {
  myClaimBalances: myClaimBalancesInitialState,
  myClaimBalancesLoading: false,
  myClaimBalancesRefetch: 0,
  myClaimRecords: myClaimRecordsInitialState,
  myClaimRecordsLoading: false,
  myClaimRecordsView: { count: 0, data: [] },
  myClaimRecordsViewLoading: false,
  myClaimRecordsViewRefetch: 0,
  myClaimRecordApprovalHistories: {},
  myClaimRecordApprovalHistoriesLoading: {},
  myClaimRecordAttachments: {},
  myClaimRecordAttachmentsLoading: {},
  myClaimRecordComments: {},
  myClaimRecordCommentsLoading: {},
  myClaimTasks: myClaimTasksInitialState,
  myClaimTasksLoading: false,
  myClaimTasksRefetch: 0
}

const myClaimSlice = createSlice({
  name: 'myclaim',
  initialState,
  reducers: {
    refetchMyClaimBalances: state => {
      state.myClaimBalancesRefetch += 1
    },
    setMyClaimBalancesLoading: (state, action: PayloadAction<boolean>) => {
      state.myClaimBalancesLoading = action.payload
    },
    replaceMyClaimBalances: (state, action: PayloadAction<SSClaimBalanceState[]>) => {
      myClaimBalancesAdapter.setAll(state.myClaimBalances, action.payload)
    },
    setMyClaimRecordsLoading: (state, action: PayloadAction<boolean>) => {
      state.myClaimRecordsLoading = action.payload
    },
    setMyClaimRecord: (state, action: PayloadAction<SSClaimRecordState>) => {
      myClaimRecordsAdapter.upsertOne(state.myClaimRecords, action.payload)
    },
    removeMyClaimRecord: (state, action: PayloadAction<string>) => {
      myClaimRecordsAdapter.removeOne(state.myClaimRecords, action.payload)
    },
    refetchMyClaimRecordsView: state => {
      state.myClaimRecordsViewRefetch += 1
    },
    setMyClaimRecordsViewLoading: (state, action: PayloadAction<boolean>) => {
      state.myClaimRecordsViewLoading = action.payload
    },
    replaceMyClaimRecordsView: (state, action: PayloadAction<SSClaimRecordViewState>) => {
      state.myClaimRecordsView = action.payload
    },
    setMyClaimRecordApprovalHistoriesLoading: (state, action: PayloadAction<{ id: string; loading: boolean }>) => {
      const { id, loading } = action.payload
      state.myClaimRecordApprovalHistories[id] =
        state.myClaimRecordApprovalHistories[id] || myClaimRecordApprovalHistoriesInitialState
      state.myClaimRecordApprovalHistoriesLoading[id] = loading
    },
    replaceMyClaimRecordApprovalHistories: (
      state,
      action: PayloadAction<{ id: string; data: SSClaimRecordApprovalHistoryState[] }>
    ) => {
      const { id, data } = action.payload
      state.myClaimRecordApprovalHistories[id] =
        state.myClaimRecordApprovalHistories[id] || myClaimRecordApprovalHistoriesInitialState
      myClaimRecordApprovalHistoriesAdapter.setAll(state.myClaimRecordApprovalHistories[id]!, data)
    },
    setMyClaimRecordAttachmentsLoading: (state, action: PayloadAction<{ id: string; loading: boolean }>) => {
      const { id, loading } = action.payload
      state.myClaimRecordAttachments[id] = state.myClaimRecordAttachments[id] || myClaimRecordAttachmentsInitialState
      state.myClaimRecordAttachmentsLoading[id] = loading
    },
    replaceMyClaimRecordAttachments: (
      state,
      action: PayloadAction<{ id: string; data: SSClaimRecordAttachmentState[] }>
    ) => {
      const { id, data } = action.payload
      state.myClaimRecordAttachments[id] = state.myClaimRecordAttachments[id] || myClaimRecordAttachmentsInitialState
      myClaimRecordAttachmentsAdapter.setAll(state.myClaimRecordAttachments[id]!, data)
    },
    setMyClaimRecordCommentsLoading: (state, action: PayloadAction<{ id: string; loading: boolean }>) => {
      const { id, loading } = action.payload
      state.myClaimRecordComments[id] = state.myClaimRecordComments[id] || myClaimRecordCommentsInitialState
      state.myClaimRecordCommentsLoading[id] = loading
    },
    replaceMyClaimRecordComments: (state, action: PayloadAction<{ id: string; data: SSClaimRecordCommentState[] }>) => {
      const { id, data } = action.payload
      state.myClaimRecordComments[id] = state.myClaimRecordComments[id] || myClaimRecordCommentsInitialState
      myClaimRecordCommentsAdapter.setAll(state.myClaimRecordComments[id]!, data)
    },
    setMyClaimRecordComment: (state, action: PayloadAction<{ id: string; data: SSClaimRecordCommentState }>) => {
      const { id, data } = action.payload
      myClaimRecordCommentsAdapter.upsertOne(state.myClaimRecordComments[id]!, data)
    },
    removeMyClaimRecordComment: (state, action: PayloadAction<{ claimRecordId: string; id: string }>) => {
      const { claimRecordId, id } = action.payload
      myClaimRecordCommentsAdapter.removeOne(state.myClaimRecordComments[claimRecordId]!, id)
    },
    refetchMyClaimTasks: state => {
      state.myClaimTasksRefetch += 1
    },
    setMyClaimTasksLoading: (state, action: PayloadAction<boolean>) => {
      state.myClaimTasksLoading = action.payload
    },
    replaceMyClaimTasks: (state, action: PayloadAction<SSClaimTaskState[]>) => {
      myClaimTasksAdapter.setAll(state.myClaimTasks, action.payload)
    },
    setMyClaimTask: (state, action: PayloadAction<SSClaimTaskState>) => {
      myClaimTasksAdapter.upsertOne(state.myClaimTasks, action.payload)
    },
    removeMyClaimTask: (state, action: PayloadAction<string>) => {
      myClaimTasksAdapter.removeOne(state.myClaimTasks, action.payload)
    }
  }
})

export const {
  refetchMyClaimBalances,
  setMyClaimBalancesLoading,
  replaceMyClaimBalances,
  setMyClaimRecordsLoading,
  setMyClaimRecord,
  removeMyClaimRecord,
  refetchMyClaimRecordsView,
  setMyClaimRecordsViewLoading,
  replaceMyClaimRecordsView,
  setMyClaimRecordApprovalHistoriesLoading,
  replaceMyClaimRecordApprovalHistories,
  setMyClaimRecordAttachmentsLoading,
  replaceMyClaimRecordAttachments,
  setMyClaimRecordCommentsLoading,
  replaceMyClaimRecordComments,
  setMyClaimRecordComment,
  removeMyClaimRecordComment,
  refetchMyClaimTasks,
  setMyClaimTasksLoading,
  replaceMyClaimTasks,
  setMyClaimTask,
  removeMyClaimTask
} = myClaimSlice.actions

export const myClaimReducers = {
  myClaim: persistReducer<MyClaimRootState>(myClaimPersistConfig, myClaimSlice.reducer)
}
