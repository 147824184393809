import React, { CSSProperties, FC } from 'react'
import memoize from 'lodash/memoize'
import moment from 'moment-timezone'
import { Form, Tooltip } from '~/core-components'
import { Col, Row } from '~/components'
import { ClaApprovalStatus, ClaRecordStatus } from '~/constants'
import { ClaimRecordApprovalHistoryState } from '../../types'
import { SSClaimRecordApprovalHistoryState } from '~/features/my-claim'

interface ClaimRecordApprovalHistoriesProps {
  histories: SSClaimRecordApprovalHistoryState[] | ClaimRecordApprovalHistoryState[]
  status: string
  hidden: boolean
  creatorName: string
  createdDate: string
  submitterName?: string
  submittedDate?: string
}

const divStyles: CSSProperties = { marginTop: 8 }

export const ClaimRecordApprovalHistories: FC<ClaimRecordApprovalHistoriesProps> = ({
  histories,
  status,
  hidden,
  creatorName,
  createdDate,
  submitterName,
  submittedDate
}) => {
  return (
    <Row hidden={hidden}>
      <Col span={24}>
        <Form.Item label="Approval history">
          {histories.map(h => (
            <div key={h.id} style={divStyles}>
              <b>{h.approverName} </b>
              {h.approverEmployeeNo ? `(${h.approverEmployeeNo})` : ''}{' '}
              {h.approvalStatus === ClaApprovalStatus.approved ? 'approved' : 'rejected'} the claim request{' '}
              {getEventDate(h.approvedDate)}
              {h.remarks && (
                <Tooltip title={h.remarks}>
                  <i className="fal fa-comment-dots" style={{ marginLeft: 8, fontWeight: 'bold' }} />
                </Tooltip>
              )}
            </div>
          ))}
          {submittedDate && submitterName && (
            <div style={divStyles}>
              <b>{submitterName}</b> submitted the claim request {getEventDate(submittedDate)}
              {[
                ClaRecordStatus.Approved,
                ClaRecordStatus.Cancelled,
                ClaRecordStatus.Paid,
                ClaRecordStatus.PendingPayment
              ].some(s => status === s) &&
                histories.length === 0 && <b> (Auto approved)</b>}
            </div>
          )}
          {!moment(submittedDate).isSame(createdDate, 'second') && (
            <div style={divStyles}>
              <b>{creatorName}</b> created the claim request {getEventDate(createdDate)}
            </div>
          )}
        </Form.Item>
      </Col>
    </Row>
  )
}

const getEventDate = memoize(date => (date ? ` on ${moment(date).format('DD MMM YYYY hh:mm A')}` : ''))
