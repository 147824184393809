import {
  EmIdentityState,
  EmAddressState,
  EmFamilyState,
  EmEducationState,
  EmDocumentState,
  EmCalendarState,
  EmSalaryState,
  EmPayGroupState,
  EmLeaveGroupState,
  EmLocationState,
  EmShiftRoleState,
  EmCompanyState,
  EmDepartmentState,
  EmJobState,
  EmManagerState,
  EmTeamState,
  EmOfficeState,
  EmCostCentreState,
  EmEmploymentTypeState,
  EmStatutoryExemptInfoState,
  EmStatutoryExemptState,
  EmManagerSecondaryState,
  EmOtClassState,
  EmCategoryState,
  EmDivisionState,
  EmGrpState,
  EmSectionState
} from './em-details.state'
import {
  IEmIdentityInfo,
  IEmAddressInfo,
  IEmFamily,
  IEmFamilyInfo,
  IEmEducationInfo,
  IEmDocumentInfo,
  IEmCalendarInfo,
  IEmSalaryInfo,
  IEmPayGroupInfo,
  IEmLeaveGroupInfo,
  IEmLocationInfo,
  IEmShiftRoleInfo,
  IEmCompanyInfo,
  IEmDepartmentInfo,
  IEmJobInfo,
  IEmManagerInfo,
  IEmTeamInfo,
  IEmOfficeInfo,
  IEmCostCentreInfo,
  IEmEmploymentTypeInfo,
  IEmCareerInfo,
  IEmStatutoryExemptInfo,
  IEmStatutoryExempt,
  IEmManagerSecondaryInfo,
  IEmOtClassInfo,
  IEmCategoryInfo,
  IEmDivisionInfo,
  IEmGrpInfo,
  IEmSectionInfo
} from './em-details.dto'
import { EmFamilyInfoState } from '.'

export const mapEmIdentityStateToEmIdentityInfo = (emIdentity: EmIdentityState): IEmIdentityInfo => ({
  identityType: emIdentity.identityType,
  identityNo: emIdentity.identityNo,
  issueDate: emIdentity.issueDate,
  expiryDate: emIdentity.expiryDate,
  cancelledDate: emIdentity.cancelledDate,
  notes: emIdentity.notes,

  workPermitNo: emIdentity.workPermitNo,
  sPassNo: emIdentity.sPassNo,
  cpfType: emIdentity.cpfType,
  fwlCode: emIdentity.fwlCode
})

export const mapEmAddressStateToEmAddressInfo = (emAddress: EmAddressState): IEmAddressInfo => ({
  startDate: emAddress.startDate,
  isMain: emAddress.isMain,
  block: emAddress.block,
  street: emAddress.street,
  level: emAddress.level,
  unit: emAddress.unit,
  city: emAddress.city,
  state: emAddress.state,
  postalCode: emAddress.postalCode,
  countryCode: emAddress.countryCode
})

export const mapEmFamilyStateToEmFamilyInfo = (emFamily: EmFamilyState): IEmFamilyInfo => ({
  name: emFamily.name,
  relation: emFamily.relation,
  gender: emFamily.gender,
  birthDate: emFamily.birthDate,
  nationalityId: emFamily.nationalityId,
  identityType: emFamily.identityType,
  identityNo: emFamily.identityNo,
  phone: emFamily.phone,
  dependant: emFamily.dependant,
  dataJsonb: JSON.stringify({
    firstCclDate: emFamily.firstCclDate
  })
})

export const mapEmFamilyInfoStateToEmFamilyInfo = (emFamilyInfo: EmFamilyInfoState): IEmFamilyInfo => ({
  name: emFamilyInfo.name,
  relation: emFamilyInfo.relation,
  gender: emFamilyInfo.gender,
  birthDate: emFamilyInfo.birthDate,
  nationalityId: emFamilyInfo.nationalityId,
  identityType: emFamilyInfo.identityType,
  identityNo: emFamilyInfo.identityNo,
  phone: emFamilyInfo.phone,
  dependant: emFamilyInfo.dependant,
  dataJsonb: JSON.stringify({
    firstCclDate: emFamilyInfo.firstCclDate
  })
})

export const mapEmFamilyToEmFamilyState = (emFamily: IEmFamily): EmFamilyState => {
  const dataJsonb = JSON.parse(emFamily.dataJsonb || '{}')

  return {
    id: emFamily.id,
    employeeId: emFamily.employeeId,
    name: emFamily.name,
    relation: emFamily.relation,
    gender: emFamily.gender,
    birthDate: emFamily.birthDate,
    nationalityId: emFamily.nationalityId,
    identityType: emFamily.identityType,
    identityNo: emFamily.identityNo,
    phone: emFamily.phone,
    dependant: emFamily.dependant,
    firstCclDate: dataJsonb.firstCclDate
  }
}

export const mapEmFamilyInfoToEmFamilyInfoState = (emFamily: IEmFamilyInfo): EmFamilyInfoState => {
  const dataJsonb = JSON.parse(emFamily.dataJsonb || '{}')

  return {
    name: emFamily.name,
    relation: emFamily.relation,
    gender: emFamily.gender,
    birthDate: emFamily.birthDate,
    nationalityId: emFamily.nationalityId,
    identityType: emFamily.identityType,
    identityNo: emFamily.identityNo,
    phone: emFamily.phone,
    dependant: emFamily.dependant,
    firstCclDate: dataJsonb.firstCclDate
  }
}

export const mapEmEducationStateToEmEducationInfo = (emEducation: EmEducationState): IEmEducationInfo => ({
  isHighest: emEducation.isHighest,
  startDate: emEducation.startDate,
  endDate: emEducation.endDate,
  qualificationId: emEducation.qualificationId,
  major: emEducation.major,
  institute: emEducation.institute,
  graduationYear: emEducation.graduationYear,
  notes: emEducation.notes
})

export const mapEmDocumentStateToEmDocumentInfo = (emDocument: EmDocumentState): IEmDocumentInfo => ({
  date: emDocument.date,
  docTypeId: emDocument.docTypeId,
  name: emDocument.name,
  docFileId: emDocument.docFileId,
  notes: emDocument.notes
})

export const mapEmCalendarStateToEmCalendarInfo = (emCalendar: EmCalendarState): IEmCalendarInfo => ({
  startDate: emCalendar.startDate,
  workSchedule: emCalendar.workSchedule,
  calendarId: emCalendar.calendarId,
  whPerDay: emCalendar.whPerDay,
  whPerWeek: emCalendar.whPerWeek,
  wdPerWeek: emCalendar.wdPerWeek
})

export const mapEmSalaryStateToEmSalaryInfo = (emSalary: EmSalaryState): IEmSalaryInfo => ({
  startDate: emSalary.startDate,
  salaryGradeId: emSalary.salaryGradeId,
  basicSalary: emSalary.basicSalary,
  salaryBasis: emSalary.salaryBasis,
  reasonId: emSalary.reasonId,
  notes: emSalary.notes
})

export const mapEmPayGroupStateToEmPayGroupInfo = (emPayGroup: EmPayGroupState): IEmPayGroupInfo => ({
  startDate: emPayGroup.startDate,
  payGroupId: emPayGroup.payGroupId
})

export const mapEmLeaveGroupStateToEmLeaveGroupInfo = (emLeaveGroup: EmLeaveGroupState): IEmLeaveGroupInfo => ({
  startDate: emLeaveGroup.startDate,
  leaveGroupId: emLeaveGroup.leaveGroupId
})

export const mapEmLocationStateToEmLocationInfo = (emLocation: EmLocationState): IEmLocationInfo => ({
  startDate: emLocation.startDate,
  locationId: emLocation.locationId
})

export const mapEmShiftRoleStateToEmShiftRoleInfo = (emShiftRole: EmShiftRoleState): IEmShiftRoleInfo => ({
  startDate: emShiftRole.startDate,
  shiftRoleId: emShiftRole.shiftRoleId
})

export const mapEmCompanyStateToEmCompanyInfo = (emCompany: EmCompanyState): IEmCompanyInfo => ({
  startDate: emCompany.startDate,
  companyId: emCompany.companyId
})

export const mapEmDepartmentStateToEmDepartmentInfo = (emDepartment: EmDepartmentState): IEmDepartmentInfo => ({
  startDate: emDepartment.startDate,
  departmentId: emDepartment.departmentId
})

export const mapEmDivisionStateToEmDivisionInfo = (emDivision: EmDivisionState): IEmDivisionInfo => ({
  startDate: emDivision.startDate,
  divisionId: emDivision.divisionId
})

export const mapEmSectionStateToEmSectionInfo = (emSection: EmSectionState): IEmSectionInfo => ({
  startDate: emSection.startDate,
  sectionId: emSection.sectionId
})

export const mapEmGrpStateToEmGrpInfo = (emGrp: EmGrpState): IEmGrpInfo => ({
  startDate: emGrp.startDate,
  grpId: emGrp.grpId
})

export const mapEmCategoryStateToEmCategoryInfo = (emCategory: EmCategoryState): IEmCategoryInfo => ({
  startDate: emCategory.startDate,
  categoryId: emCategory.categoryId
})

export const mapEmTeamStateToEmTeamInfo = (emTeam: EmTeamState): IEmTeamInfo => ({
  startDate: emTeam.startDate,
  teamId: emTeam.teamId
})

export const mapEmJobStateToEmJobInfo = (emJob: EmJobState): IEmJobInfo => ({
  startDate: emJob.startDate,
  jobId: emJob.jobId,
  reasonId: emJob.reasonId
})

export const mapEmManagerStateToEmManagerInfo = (emManager: EmManagerState): IEmManagerInfo => ({
  startDate: emManager.startDate,
  managerId: emManager.managerId
})

export const mapEmManagerSecondaryStateToEmManagerSecondaryInfo = (
  emManagerSecondary: EmManagerSecondaryState
): IEmManagerSecondaryInfo => ({
  startDate: emManagerSecondary.startDate,
  managerSecondaryId: emManagerSecondary.managerSecondaryId
})

export const mapEmOfficeStateToEmOfficeInfo = (emOffice: EmOfficeState): IEmOfficeInfo => ({
  startDate: emOffice.startDate,
  officeId: emOffice.officeId
})

export const mapEmCostCentreStateToEmCostCentreInfo = (emCostCentre: EmCostCentreState): IEmCostCentreInfo => ({
  startDate: emCostCentre.startDate,
  costCentreId: emCostCentre.costCentreId
})

export const mapEmEmploymentTypeStateToEmEmploymentTypeInfo = (
  emEmploymentType: EmEmploymentTypeState
): IEmEmploymentTypeInfo => ({
  startDate: emEmploymentType.startDate,
  employmentTypeId: emEmploymentType.employmentTypeId
})

export const mapEmOtClassStateToEmOtClassInfo = (emOtClass: EmOtClassState): IEmOtClassInfo => ({
  startDate: emOtClass.startDate,
  otClassId: emOtClass.otClassId
})

export const mapEmCareerInfoToEmDepartmentState = (
  id: string,
  employeeId: string,
  emCareer: IEmCareerInfo
): EmDepartmentState => ({
  id,
  employeeId,
  startDate: emCareer.startDate,
  departmentId: emCareer.departmentId!
})

export const mapEmCareerInfoToEmDivisionState = (
  id: string,
  employeeId: string,
  emCareer: IEmCareerInfo
): EmDivisionState => ({
  id,
  employeeId,
  startDate: emCareer.startDate,
  divisionId: emCareer.divisionId!
})

export const mapEmCareerInfoToEmSectionState = (
  id: string,
  employeeId: string,
  emCareer: IEmCareerInfo
): EmSectionState => ({
  id,
  employeeId,
  startDate: emCareer.startDate,
  sectionId: emCareer.sectionId!
})

export const mapEmCareerInfoToEmGrpState = (id: string, employeeId: string, emCareer: IEmCareerInfo): EmGrpState => ({
  id,
  employeeId,
  startDate: emCareer.startDate,
  grpId: emCareer.grpId!
})

export const mapEmCareerInfoToEmCategoryState = (
  id: string,
  employeeId: string,
  emCareer: IEmCareerInfo
): EmCategoryState => ({
  id,
  employeeId,
  startDate: emCareer.startDate,
  categoryId: emCareer.categoryId!
})

export const mapEmCareerInfoToEmTeamState = (id: string, employeeId: string, emCareer: IEmCareerInfo): EmTeamState => ({
  id,
  employeeId,
  startDate: emCareer.startDate,
  teamId: emCareer.teamId!
})

export const mapEmCareerInfoToEmJobState = (id: string, employeeId: string, emCareer: IEmCareerInfo): EmJobState => ({
  id,
  employeeId,
  startDate: emCareer.startDate,
  jobId: emCareer.jobId!
})

export const mapEmCareerInfoToEmManagerState = (
  id: string,
  employeeId: string,
  emCareer: IEmCareerInfo
): EmManagerState => ({
  id,
  employeeId,
  startDate: emCareer.startDate,
  managerId: emCareer.managerId!
})

export const mapEmCareerInfoToEmManagerSecondaryState = (
  id: string,
  employeeId: string,
  emCareer: IEmCareerInfo
): EmManagerSecondaryState => ({
  id,
  employeeId,
  startDate: emCareer.startDate,
  managerSecondaryId: emCareer.managerSecondaryId!
})

export const mapEmCareerInfoToEmOfficeState = (
  id: string,
  employeeId: string,
  emCareer: IEmCareerInfo
): EmOfficeState => ({
  id,
  employeeId,
  startDate: emCareer.startDate,
  officeId: emCareer.officeId!
})

export const mapEmCareerInfoToEmCostCentreState = (
  id: string,
  employeeId: string,
  emCareer: IEmCareerInfo
): EmCostCentreState => ({
  id,
  employeeId,
  startDate: emCareer.startDate,
  costCentreId: emCareer.costCentreId!
})

export const mapEmCareerInfoToEmEmploymentTypeState = (
  id: string,
  employeeId: string,
  emCareer: IEmCareerInfo
): EmEmploymentTypeState => ({
  id,
  employeeId,
  startDate: emCareer.startDate,
  employmentTypeId: emCareer.employmentTypeId!
})

export const mapEmCareerInfoToEmOtClassState = (
  id: string,
  employeeId: string,
  emCareer: IEmCareerInfo
): EmOtClassState => ({
  id,
  employeeId,
  startDate: emCareer.startDate,
  otClassId: emCareer.otClassId!
})

export const mapEmStatutoryExemptInfoStateToEmStatutoryExemptInfo = (
  emStatutoryExempt: EmStatutoryExemptInfoState
): IEmStatutoryExemptInfo => ({
  startDate: emStatutoryExempt.startDate,
  endDate: emStatutoryExempt.endDate,
  reasonCode: emStatutoryExempt.reasonCode,
  notes: emStatutoryExempt.notes,
  dataJsonb: JSON.stringify({
    cpf: emStatutoryExempt.cpf,
    sdl: emStatutoryExempt.sdl,
    tax: emStatutoryExempt.tax,
    postingDate: emStatutoryExempt.postingDate
  })
})

export const mapEmStatutoryExemptToEmStatutoryExemptState = (dto: IEmStatutoryExempt): EmStatutoryExemptState => {
  const dataJsonb = JSON.parse(dto.dataJsonb || '{}')
  let arrItems = []
  if (dataJsonb.cpf) arrItems.push('CPF')
  if (dataJsonb.sdl) arrItems.push('SDL')
  if (dataJsonb.tax) arrItems.push('Tax')

  return {
    id: dto.id,
    employeeId: dto.employeeId,
    startDate: dto.startDate,
    endDate: dto.endDate,
    reasonCode: dto.reasonCode,
    notes: dto.notes,
    cpf: dataJsonb.cpf,
    sdl: dataJsonb.sdl,
    tax: dataJsonb.tax,
    postingDate: dataJsonb.postingDate,
    displayedItem: arrItems.join(', ')
  }
}

export const mapEmStatutoryExemptInfoToEmStatutoryExemptInfoState = (
  dto: IEmStatutoryExemptInfo
): EmStatutoryExemptInfoState => {
  const dataJsonb = JSON.parse(dto.dataJsonb || '{}')
  let arrItems = []
  if (dataJsonb.cpf) arrItems.push('CPF')
  if (dataJsonb.sdl) arrItems.push('SDL')
  if (dataJsonb.tax) arrItems.push('Tax')

  return {
    startDate: dto.startDate,
    endDate: dto.endDate,
    reasonCode: dto.reasonCode,
    notes: dto.notes,
    cpf: dataJsonb.cpf,
    sdl: dataJsonb.sdl,
    tax: dataJsonb.tax,
    postingDate: dataJsonb.postingDate,
    displayedItem: arrItems.join(', ')
  }
}
