export class WfActionType {
  static get approval1() {
    return 'approval_1'
  }
  static get approval2() {
    return 'approval_2'
  }
  static get approval3() {
    return 'approval_3'
  }
  static get approve() {
    return 'approve'
  }
  static get emailCc() {
    return 'email_cc'
  }
}

export class WfEventType {
  static get submit() {
    return 'submit'
  }
  static get finalApprove() {
    return 'final_approve'
  }
}

export class WfFlowType {
  static get lveNew() {
    return 'lve_new'
  }
  static get lveCancel() {
    return 'lve_cancel'
  }
}
