import { request, getBaseUrl } from '~/utils/request'
import { ISaveLocation, LocationAdminState, LocationState } from '../types'
import { Pagination, Search } from '~/types/common'
import { EmSelectionViewState } from '~/features/employee'

const baseUrl = getBaseUrl('/attendance/location')

export const apiGetLocations = () => request<LocationState[]>('get', baseUrl)

export const apiGetLocation = (id: string) => request<LocationState>('get', `${baseUrl}/${id}`)

export const apiGetLocationsView = (viewId: string, params: Search) =>
  request<LocationState[]>('get', `${baseUrl}/view/${viewId}`, params)

export const apiSaveLocation = (location: ISaveLocation) => request<{ id: string }>('post', baseUrl, location)

export const apiDeleteLocation = (id: string) => request<void>('delete', `${baseUrl}/${id}`)

export const apiGetLocationAdmins = (locationId: string) =>
  request<LocationAdminState[]>('get', `${baseUrl}/${locationId}/employee`)

export const apiGetLocationAdminEmSelections = (viewId: string, params: Pagination & Search) =>
  request<EmSelectionViewState>('get', `${baseUrl}/emselections/${viewId}`, params)
