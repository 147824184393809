import React, { CSSProperties, FC, useCallback, useEffect, useMemo, useState } from 'react'
import moment from 'moment-timezone'
import classNames from 'classnames'
import { LoadingOutlined } from '@ant-design/icons'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { RangeValue } from 'rc-picker/lib/interface.d'
import {
  AlignType,
  Button,
  Card,
  ColumnsType,
  Dropdown,
  Form,
  Input,
  Link,
  Space,
  Table,
  Tag,
  Tooltip
} from '~/core-components'
import { Col, Person, Row, SearchInput } from '~/components'
import {
  Screen,
  ViewCriteriaSimple,
  ViewCriteria,
  updateViewCriteria,
  useViewSchema,
  ViewSelectionButton
} from '~/features/selection'
import { usePermissionGate } from '~/features/iam'
import { StoreState } from '~/types/store'
import { dispatch } from '~/stores/store'
import { ATT_ROUTES, EMP_ROUTES } from '~/routes/routes'
import { useIsMountedRef } from '~/hooks'
import { DateRange } from '~/types/common'
import { AttendancePeriodStatus, Permission, PermissionAction } from '~/constants'
import {
  formatYearMonth,
  formatMonth,
  formatMoney,
  getFileTimestamp,
  downloadWithDom,
  showError,
  getBaseUrl
} from '~/utils'
import { selectDailyFields, selectDailyRecordsView } from '../../selectors'
import {
  fetchDailyRecordsView,
  lockAllDailyRecords,
  lockDailyRecord,
  processDaily,
  unlockAllDailyRecords,
  unlockDailyRecord
} from '../../actions'
import { apiGetDailyRecordsExcel } from '../../api/daily-record.api'
import { useAttendancePeriod } from '../../hooks'
import { refetchDailyRecordsView } from '../../reducers'
import { DailyRecordRowState } from '../../types'
import { ProcessDailyDrawer } from './components/ProcessDailyDrawer'
import './DailyRecords.less'

interface DailyRecordsProps {
  viewId?: string
  attendancePeriodId?: string
  defaultStartDate?: string
  defaultEndDate?: string
}

interface ProcessDailyDrawerState {
  visible: boolean
  startDate?: string
  endDate?: string
  employeeIds?: string[]
}

const nameColWidth = 250
const dateTimeColWidth = 250
const defaultColWidth = 150
const paginationStyle: CSSProperties = { marginRight: 20 }

const SCREEN_CODE: Screen = 'daily_record'
const PAGE_SIZE_OPTIONS = ['20', '50', '100']
const DEFAULT_PROCESS_DAILY_DRAWER_STATE: ProcessDailyDrawerState = { visible: false }
const baseUrl = getBaseUrl('/filestore')

export const DailyRecords: FC<DailyRecordsProps> = ({
  viewId,
  attendancePeriodId,
  defaultStartDate,
  defaultEndDate
}) => {
  const [page, setPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(20)
  const [search, setSearch] = useState<string>('')
  const history = useHistory()

  const [attendancePeriod] = useAttendancePeriod(attendancePeriodId, 'when-empty')
  const payGroupId = attendancePeriod?.payGroupId

  const canModify = usePermissionGate(Permission.attDailyRecord, PermissionAction.Modify)

  const selection = useSelector((state: StoreState) => state.selection.sysSelectionFields[SCREEN_CODE])
  const viewLoading = useSelector((state: StoreState) => state.selection.viewLoading[SCREEN_CODE]?.[viewId || ''])
  const [schema] = useViewSchema(SCREEN_CODE, viewId)
  const dataLoading = useSelector((state: StoreState) => state.attendance.dailyRecordsViewLoading)
  const data = useSelector(selectDailyRecordsView)(viewId || '')
  const refetch = useSelector((state: StoreState) => state.attendance.dailyRecordsViewRefetch)
  const dailyFields = useSelector(selectDailyFields)
  const processing = useSelector((state: StoreState) => state.attendance.dailyProcessing)

  const [locking, setLocking] = useState<string>()
  const [lockingAll, setLockingAll] = useState(false)
  const [unlockingAll, setUnlockingAll] = useState(false)

  const isMountedRef = useIsMountedRef()
  const [downloading, setDownloading] = useState(false)

  const [startDate, setStartDate] = useState<string>()
  const [endDate, setEndDate] = useState<string>()
  const canViewEmployee = usePermissionGate(Permission.employee)

  const [processDailyDrawerState, setProcessDailyDrawerState] = useState<ProcessDailyDrawerState>(
    DEFAULT_PROCESS_DAILY_DRAWER_STATE
  )

  useEffect(() => {
    if (defaultStartDate) setStartDate(defaultStartDate)
    if (defaultEndDate) setEndDate(defaultEndDate)
  }, [defaultStartDate, defaultEndDate])

  useEffect(() => {
    if (viewId && !processing) {
      dispatch(
        fetchDailyRecordsView(
          viewId,
          { offset: pageSize * (page - 1), limit: pageSize },
          search,
          payGroupId,
          startDate,
          endDate
        )
      )
    }
  }, [viewId, page, pageSize, search, payGroupId, startDate, endDate, processing, refetch])

  const handleOpenDailyRecord = useCallback(
    (clockDate: string, employeeId: string, shiftId: string) => {
      if (!attendancePeriodId) {
        console.warn('Attendance period ID is required')
        return
      }

      history.push(
        ATT_ROUTES.dailyRecord
          .replace(':attendancePeriodId', attendancePeriodId)
          .replace(':clockDate', clockDate)
          .replace(':employeeId', employeeId)
          .replace(':shiftId?', shiftId || '')
      )
    },
    [history, attendancePeriodId]
  )

  const handleLock = useCallback(async (dailyRecord: DailyRecordRowState) => {
    if (dailyRecord) {
      setLocking(dailyRecord.id)
      try {
        if (dailyRecord.lockedBy) {
          await dispatch(unlockDailyRecord(dailyRecord.id))
        } else {
          await dispatch(lockDailyRecord(dailyRecord.id))
        }
      } finally {
        setLocking(undefined)
      }
    }
  }, [])

  const handleLockAll = useCallback(async () => {
    if (attendancePeriodId) {
      setLockingAll(true)
      try {
        await dispatch(lockAllDailyRecords(attendancePeriodId))
      } finally {
        setLockingAll(false)
      }
    }
  }, [attendancePeriodId])

  const handleUnlockAll = useCallback(async () => {
    if (attendancePeriodId) {
      setUnlockingAll(true)
      try {
        await dispatch(unlockAllDailyRecords(attendancePeriodId))
      } finally {
        setUnlockingAll(false)
      }
    }
  }, [attendancePeriodId])

  const tableWidth = useMemo(() => {
    return (
      (schema?.selection.reduce(
        (sum, f) => (sum += selection?.entities[f.selectionFieldId]?.width || defaultColWidth),
        0
      ) || 0) +
      nameColWidth +
      dateTimeColWidth
    )
  }, [schema, selection])

  const columns = useMemo(() => {
    let columns: ColumnsType<DailyRecordRowState> = [
      {
        title: 'Name',
        key: 'employeeId',
        dataIndex: 'employeeId',
        fixed: 'left',
        className: 'first-col',
        width: nameColWidth,
        onCell: (record, index) => {
          const prevId = data.data[(index as number) - 1]?.employeeId
          if (prevId === record.employeeId) {
            return { rowSpan: 0 }
          } else {
            const nextId = data.data[(index as number) + 1]?.employeeId
            if (nextId === record.employeeId) {
              return { rowSpan: record.rowSpan }
            }
          }
          return {}
        },
        render: (_, record) => (
          <Person
            name={record.employeeName}
            description={record.description}
            photo={record.photoId && `${baseUrl}/file/${record.photoId}/thumbnailphoto/36`}
            path={canViewEmployee ? EMP_ROUTES.employee.replace(':id', record.employeeId) : undefined}
            size={36}
          />
        )
      },
      {
        title: 'Date time',
        key: 'clockDate',
        dataIndex: 'clockDate',
        fixed: 'left',
        width: dateTimeColWidth,
        render: (value: string, record) => {
          let startTime: moment.Moment | undefined = undefined,
            endTime: moment.Moment | undefined = undefined,
            isPrevDayInTime = false,
            isNextDayInTime = false,
            isPrevDayOutTime = false,
            isNextDayOutTime = false

          if (record.startTime) {
            startTime = moment(record.startTime)
            isPrevDayInTime = startTime.isBefore(record.clockDate, 'date')
            isNextDayInTime = startTime.isAfter(record.clockDate, 'date')
          }

          if (record.endTime) {
            endTime = moment(record.endTime)
            isPrevDayOutTime = endTime.isBefore(record.clockDate, 'date')
            isNextDayOutTime = endTime.isAfter(record.clockDate, 'date')
          }

          return (
            <Link
              onClick={() =>
                handleOpenDailyRecord(moment(record.clockDate).format('YYYY-MM-DD'), record.employeeId, record.shiftId)
              }
            >
              <Row>
                <Col flex="110px">{value ? moment(value).format('DD MMM YYYY') : ''}</Col>
                <Col flex="1">
                  <Space>
                    {isPrevDayInTime && (
                      <Tooltip title={startTime?.format('DD MMM YYYY')}>
                        <i className="fal fa-calendar-minus" />
                      </Tooltip>
                    )}
                    {isNextDayInTime && (
                      <Tooltip title={startTime?.format('DD MMM YYYY')}>
                        <i className="fal fa-calendar-plus" />
                      </Tooltip>
                    )}
                    {startTime?.format('HH:mm')}
                    <> - </>
                    {isPrevDayOutTime && (
                      <Tooltip title={endTime?.format('DD MMM YYYY')}>
                        <i className="fal fa-calendar-minus" />
                      </Tooltip>
                    )}
                    {isNextDayOutTime && (
                      <Tooltip title={endTime?.format('DD MMM YYYY')}>
                        <i className="fal fa-calendar-plus" />
                      </Tooltip>
                    )}
                    {endTime?.format('HH:mm')}
                  </Space>
                </Col>
              </Row>
            </Link>
          )
        }
      }
    ]

    if (canModify && attendancePeriod?.status !== AttendancePeriodStatus.Completed) {
      columns.push({
        key: 'action',
        width: 20,
        className: 'action-cell',
        render: (value: string, record: DailyRecordRowState) => (
          <Tooltip title={!!record.lockedBy ? 'Locked' : 'Unlocked'}>
            <Link
              className={classNames('daily-records__lock', { 'daily-records__lock--locked': !!record.lockedBy })}
              onClick={() => handleLock(record)}
            >
              {locking === record.id ? (
                <LoadingOutlined />
              ) : !!record.lockedBy ? (
                <i className="fal fa-lock" />
              ) : (
                <i className="fal fa-lock-open" />
              )}
            </Link>
          </Tooltip>
        )
      })
    }

    if (schema) {
      // Configurable employee columns
      columns.push(
        ...schema?.selection.map(f => {
          const field = selection?.entities[f.selectionFieldId]
          const align = field?.format === 'money' ? 'right' : ('left' as AlignType)

          if (field?.fieldName === 'dailyLeaveTypes') {
            return {
              title: field.description,
              key: field.fieldName,
              dataIndex: field.fieldName,
              width: field.width || defaultColWidth,
              align,
              render: (value: string) => {
                const obj = JSON.parse(value || '{}') as {
                  full_leave: string
                  h1_leave: string
                  h2_leave: string
                  hour_leave: string
                }
                return (
                  <Space>
                    {obj.full_leave && <Tag>{obj.full_leave}</Tag>}
                    {obj.h1_leave && <Tag>{obj.h1_leave}</Tag>}
                    {obj.h2_leave && <Tag>{obj.h2_leave}</Tag>}
                    {obj.hour_leave && <Tag>{obj.hour_leave}</Tag>}
                  </Space>
                )
              }
            }
          } else {
            return {
              title: field?.description,
              key: field?.fieldName,
              dataIndex: field?.fieldName,
              width: field?.width || defaultColWidth,
              align,
              onCell: (record: DailyRecordRowState, index?: number) => {
                if (dailyFields.includes(field?.fieldName || '')) {
                  return {}
                }

                const prevId = data.data[(index as number) - 1]?.employeeId
                if (prevId === record.employeeId) {
                  return { rowSpan: 0 }
                } else {
                  const nextId = data.data[(index as number) + 1]?.employeeId
                  if (nextId === record.employeeId) {
                    return { rowSpan: record.rowSpan }
                  }
                }
                return {}
              },
              render: (value: string | number | boolean, record: DailyRecordRowState, index: number) => {
                var display = value
                if (field?.format === 'date' && value) {
                  display = moment(value as string).format('DD MMM YYYY')
                }
                if (field?.format === 'yearmonth' && value) {
                  display = formatYearMonth(value as string)
                }
                if (field?.format === 'month' && value) {
                  display = formatMonth(value as number)
                }
                if (field?.format === 'money' && value) {
                  display = formatMoney(value as number)
                }
                if (field?.format === 'yes_no') {
                  display = (value as boolean) ? 'Yes' : 'No'
                }

                return display
              }
            }
          }
        })
      )
    }

    return columns
  }, [
    schema,
    canViewEmployee,
    dailyFields,
    selection,
    canModify,
    attendancePeriod,
    locking,
    data.data,
    handleLock,
    handleOpenDailyRecord
  ])

  const handleDateRangeChange = useCallback(
    (dates: RangeValue<moment.Moment>) => {
      const start = dates && dates[0] ? dates[0].format('YYYY-MM-DD') : null
      const end = dates && dates[1] ? dates[1].format('YYYY-MM-DD') : null

      if (start !== null && end !== null && (start !== startDate || end !== endDate)) {
        setPage(1)
        setStartDate(start)
        setEndDate(end)
      }
    },
    [startDate, endDate]
  )

  const handleDownloadClick = useCallback(async () => {
    if (viewId) {
      try {
        setDownloading(true)
        const { status, result, errors, message, errorData } = await apiGetDailyRecordsExcel(viewId, {
          search,
          ...({ startDate, endDate } as DateRange),
          payGroupId
        })

        if (status) {
          const fileName = `daily_records_${getFileTimestamp()}.xlsx`
          downloadWithDom(result, fileName)
        } else {
          console.error('Error while downloading', errors)
          showError(message, errorData)
        }
      } finally {
        if (isMountedRef.current) setDownloading(false)
      }
    }
  }, [viewId, search, startDate, endDate, payGroupId, isMountedRef])

  const handleCloseSelectionDrawer = useCallback((changed?: boolean) => {
    if (changed) dispatch(refetchDailyRecordsView())
  }, [])

  const handleCriteriaApply = useCallback(
    async (criteria: ViewCriteria[]) => {
      if (viewId) {
        setPage(1)
        await dispatch(updateViewCriteria(SCREEN_CODE, viewId, { id: viewId, criteria }))
        dispatch(refetchDailyRecordsView())
      }
    },
    [viewId]
  )

  const handlePaginationChange = useCallback((page: number, pageSize?: number) => {
    setPage(page)
    setPageSize(pageSize || 20)
  }, [])

  const handleSearch = useCallback((value: string) => {
    setPage(1)
    setSearch(value)
  }, [])

  const handleCalculateSome = useCallback(() => {
    setProcessDailyDrawerState({
      visible: true,
      startDate,
      endDate,
      employeeIds: []
    })
  }, [startDate, endDate])

  const handleCloseProcessDailyDrawer = useCallback(() => {
    setProcessDailyDrawerState(DEFAULT_PROCESS_DAILY_DRAWER_STATE)
  }, [])

  const handleCalculateAll = useCallback(async () => {
    if (!startDate || !endDate) return

    await dispatch(processDaily(startDate, endDate, undefined, 'all', payGroupId))
    dispatch(refetchDailyRecordsView())
  }, [startDate, endDate, payGroupId])

  return (
    <div className="daily-records">
      <div className="daily-records__body">
        <div className="daily-records__action-bar">
          <Space>
            <Form.Item label="">
              <SearchInput onSearch={handleSearch} />
            </Form.Item>
          </Space>
          <ViewCriteriaSimple screenCode={SCREEN_CODE} viewId={viewId} onApply={handleCriteriaApply} label="" />
          <Space align="start">
            <Input.DateRange
              allowClear={false}
              value={[startDate ? moment(startDate) : null, endDate ? moment(endDate) : null]}
              onCalendarChange={handleDateRangeChange}
              disabledDate={current =>
                current && (current > moment(defaultEndDate) || current < moment(defaultStartDate))
              }
            />
            {attendancePeriod?.status !== AttendancePeriodStatus.Completed && (
              <>
                <Dropdown
                  disabled={lockingAll || unlockingAll}
                  menu={{
                    items: [
                      { key: 'lock', label: 'Lock all employees', onClick: handleLockAll },
                      { key: 'unlock', label: 'Unlock all employees', onClick: handleUnlockAll }
                    ]
                  }}
                >
                  <Button className="daily-records__action-bar-buttons-lock">
                    {lockingAll || unlockingAll ? (
                      <LoadingOutlined />
                    ) : (
                      <Space size={4}>
                        <i className="fal fa-lock" />
                        <i className="fa-light fa-angle-down" />
                      </Space>
                    )}
                  </Button>
                </Dropdown>
                <Dropdown.Button
                  menu={{
                    items: [
                      { key: 'selected', label: 'Recalculate selected employees...', onClick: handleCalculateSome }
                    ]
                  }}
                  icon={<i className="fa-light fa-angle-down" />}
                  onClick={handleCalculateAll}
                  buttonsRender={([leftButton, rightButton]) => [
                    React.cloneElement(leftButton as React.ReactElement, {
                      className: 'ant-btn-icon-only'
                    }),
                    rightButton
                  ]}
                >
                  {processing ? (
                    <LoadingOutlined />
                  ) : (
                    <Tooltip title="Recalculate all employees">
                      <i className="fal fa-refresh" />
                    </Tooltip>
                  )}
                </Dropdown.Button>
              </>
            )}
            <Tooltip title="Download excel">
              <Button
                icon={<i className="fal fa-arrow-down-to-bracket" />}
                onClick={handleDownloadClick}
                loading={downloading}
              />
            </Tooltip>
            <ViewSelectionButton
              screenCode={SCREEN_CODE}
              viewId={viewId || ''}
              title="Configure daily record columns"
              onClose={handleCloseSelectionDrawer}
            />
          </Space>
        </div>
        <Card fitParent table>
          <Table
            rowKey="id"
            dataSource={data?.data}
            columns={columns}
            fitParent
            loading={dataLoading || viewLoading}
            scroll={{ x: tableWidth, y: 1000 }}
            pagination={{
              total: data?.count,
              current: page,
              pageSize,
              pageSizeOptions: PAGE_SIZE_OPTIONS,
              showSizeChanger: true,
              onChange: handlePaginationChange,
              style: paginationStyle
            }}
          />
        </Card>
      </div>
      <ProcessDailyDrawer
        {...processDailyDrawerState}
        attendancePeriodId={attendancePeriodId}
        onClose={handleCloseProcessDailyDrawer}
      />
    </div>
  )
}
