import { RouteProps } from 'react-router-dom'
import { MenuItem } from '~/types/common'
import { Permission } from '~/constants'
import { REPORTS_ROUTES } from '~/routes/routes'
import { RptPayYtd } from './containers/RptPayYtd/RptPayYtd'
import { RptPayMonthly } from './containers/RptPayMonthly/RptPayMonthly'
import { RptBankFile } from './containers/RptBankFile/RptBankFile'
import { RptCpfFile } from './containers/RptCpfFile/RptCpfFile'
import { RptPayRecon } from './containers/RptPayRecon/RptPayRecon'
import { RptEmMovement } from './containers/RptEmMovement/RptEmMovement'
import { RptPayment } from './containers/RptPayment/RptPayment'
import { RptLveEntitlement } from './containers/RptLveEntitlement/RptLveEntitlement'
import { RptLveRecord } from './containers/RptLveRecord/RptLveRecord'
import { RptEmDetail } from './containers/RptEmDetail/RptEmDetail'
import { RptEmFlexi } from './containers/RptEmFlexi/RptEmFlexi'
import { OedSubmissions } from './containers/RptMomOed/Submissions/OedSubmissions'
import { OedSubmission } from './containers/RptMomOed/Submission/OedSubmission'
import { OedRecordForm } from './containers/RptMomOed/Submission/OedRecordForm/OedRecordForm'
import { RptPayVariance } from './containers/RptPayVariance/RptPayVariance'
import { RptClaimRecord } from './containers/RptClaimRecord/RptClaimRecord'
import { RptAttendanceRecord } from './containers/RptAttendanceRecord/RptAttendanceRecord'
import { OedCallback } from './containers/RptMomOed/Submission/OedSummary/OedCallback'

export const peopleMenus: MenuItem[] = [
  {
    value: 'Employee movement report',
    path: REPORTS_ROUTES.emMovement,
    sysPermissionId: Permission.rptEmMovement
  },
  {
    value: 'Employee details report',
    path: REPORTS_ROUTES.emDetail,
    sysPermissionId: Permission.rptEmDetail
  },
  {
    value: 'Employee flexible report',
    path: REPORTS_ROUTES.emFlexi,
    sysPermissionId: Permission.rptEmFlexi
  }
]

export const peopleRoutes: RouteProps[] = [
  {
    path: REPORTS_ROUTES.emMovement,
    component: RptEmMovement,
    exact: true
  },
  {
    path: REPORTS_ROUTES.emDetail,
    component: RptEmDetail,
    exact: true
  },
  {
    path: REPORTS_ROUTES.emFlexi,
    component: RptEmFlexi,
    exact: true
  }
]

export const payMonthlyMenus: MenuItem[] = [
  {
    value: 'Monthly payroll report',
    path: REPORTS_ROUTES.payMonthly,
    sysPermissionId: Permission.rptPayMonthly
  },
  {
    value: 'Payroll reconciliation report',
    path: REPORTS_ROUTES.payRecon,
    sysPermissionId: Permission.rptPayRecon
  },
  {
    value: 'Payroll variance report',
    path: REPORTS_ROUTES.payVariance,
    sysPermissionId: Permission.rptPayVariance
  },
  // {
  //   value: 'Monthly payroll items report',
  //   path: REPORTS_ROUTES.payItemsMonthly,
  //   sysPermissionId: undefined
  // },
  {
    value: 'Bank file',
    path: REPORTS_ROUTES.bankFile,
    sysPermissionId: Permission.rptBankFile
  },
  {
    value: 'Payment report',
    path: REPORTS_ROUTES.payment,
    sysPermissionId: Permission.rptPayment
  },
  {
    value: 'CPF EZPay',
    path: REPORTS_ROUTES.cpfFile,
    sysPermissionId: Permission.rptCpfEzpay
  }
]

export const payMonthlyRoutes: RouteProps[] = [
  {
    path: REPORTS_ROUTES.payMonthly,
    component: RptPayMonthly,
    exact: true
  },
  {
    path: REPORTS_ROUTES.payRecon,
    component: RptPayRecon,
    exact: true
  },
  {
    path: REPORTS_ROUTES.payVariance,
    component: RptPayVariance,
    exact: true
  },
  // {
  //   path: REPORTS_ROUTES.payItemsMonthly,
  //   component: RptPayYtd,
  //   exact: true
  // },
  {
    path: REPORTS_ROUTES.bankFile,
    component: RptBankFile,
    exact: true
  },
  {
    path: REPORTS_ROUTES.payment,
    component: RptPayment,
    exact: true
  },
  {
    path: REPORTS_ROUTES.cpfFile,
    component: RptCpfFile,
    exact: true
  }
]

export const payYtdMenus: MenuItem[] = [
  {
    value: 'Year-to-Date payroll report',
    path: REPORTS_ROUTES.payYtd,
    sysPermissionId: Permission.rptPayYtd
  }
]

export const payYtdRoutes: RouteProps[] = [
  {
    path: REPORTS_ROUTES.payYtd,
    component: RptPayYtd,
    exact: true
  }
]

export const leaveMenus: MenuItem[] = [
  {
    value: 'Leave entitlement report',
    path: REPORTS_ROUTES.lveEntitlement,
    sysPermissionId: Permission.rptLveEntitlement
  },
  {
    value: 'Leave records report',
    path: REPORTS_ROUTES.lveRecord,
    sysPermissionId: Permission.rptLveRecord
  }
]

export const claimMenus: MenuItem[] = [
  {
    value: 'Claim records report',
    path: REPORTS_ROUTES.claimRecord,
    sysPermissionId: Permission.rptClaimRecord
  }
]

export const attendanceMenus: MenuItem[] = [
  {
    value: 'Attendance records report',
    path: REPORTS_ROUTES.attendanceRecord,
    sysPermissionId: Permission.rptAttendanceRecord
  }
]

export const leaveRoutes: RouteProps[] = [
  {
    path: REPORTS_ROUTES.lveEntitlement,
    component: RptLveEntitlement,
    exact: true
  },
  {
    path: REPORTS_ROUTES.lveRecord,
    component: RptLveRecord,
    exact: true
  }
]

export const claimRoutes: RouteProps[] = [
  {
    path: REPORTS_ROUTES.claimRecord,
    component: RptClaimRecord,
    exact: true
  }
]

export const attendanceRoutes: RouteProps[] = [
  {
    path: REPORTS_ROUTES.attendanceRecord,
    component: RptAttendanceRecord,
    exact: true
  }
]

export const momSurveyMenus: MenuItem[] = [
  {
    value: 'OED report',
    path: REPORTS_ROUTES.oedSubmissions,
    sysPermissionId: Permission.rptMomOed
  }
]

export const momSurveyRoutes: RouteProps[] = [
  {
    path: REPORTS_ROUTES.oedSubmissions,
    component: OedSubmissions,
    exact: true
  },
  {
    path: REPORTS_ROUTES.oedSubmissionCallback,
    component: OedCallback,
    exact: true
  },
  {
    path: REPORTS_ROUTES.oedSubmission,
    component: OedSubmission,
    exact: true
  },
  {
    path: REPORTS_ROUTES.oedSubmissionRecord,
    component: OedRecordForm,
    exact: true
  }
]
