import React, { FC, useEffect, useState } from 'react'
import { Redirect, RouteProps, Switch } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { StoreState } from '~/types/store'
import { useHasMySsAccess } from '~/features/my'
import { MyLayout } from '~/features/my/containers/MyLayout'
import { ADMIN_PATH, MY_ROUTES, SELF_SERVICE_PATH } from '~/routes/routes'
import { SelfServiceLayout } from '../layouts/SelfServiceLayout/SelfServiceLayout'
import { PrivateRoute } from './PrivateRoute'
import { FeatureFlag } from '~/constants'

export const routes: RouteProps[] = [
  {
    path: SELF_SERVICE_PATH,
    component: MyLayout,
    exact: false
  }
]

export const MyRoutes: FC = () => {
  const myJwt = useSelector((state: StoreState) => state.iam.myJwt)
  const activeTenantCode = localStorage.getItem('tenant')
  const myTenant = useSelector((state: StoreState) => state.iam.myTenants.entities[activeTenantCode || ''])
  const hasSsAccessMyLeave = useHasMySsAccess(FeatureFlag.MyLeave)
  const hasSsAccessMyClaim = useHasMySsAccess(FeatureFlag.MyClaim)
  const hasSsAccessMyAttendance = useHasMySsAccess(FeatureFlag.MyAttendance)
  const [defaultRoute, setDefaultRoute] = useState<string>('')

  useEffect(() => {
    if (myTenant) {
      setDefaultRoute(MY_ROUTES.home)

      if (!hasSsAccessMyLeave && !hasSsAccessMyClaim && !hasSsAccessMyAttendance) {
        // hide home when no leave, claim or attendance
        setDefaultRoute(MY_ROUTES.self)
      }
    }
  }, [myTenant, hasSsAccessMyLeave, hasSsAccessMyClaim, hasSsAccessMyAttendance])

  return (
    <SelfServiceLayout>
      <Switch>
        {!!myJwt?.isAdmin && !myJwt?.isEmployee && <Redirect to={ADMIN_PATH} />}
        {defaultRoute && <Redirect exact from={SELF_SERVICE_PATH} to={defaultRoute} />}
        {routes.map(({ path, ...props }) => (
          <PrivateRoute key={path as string} exact path={path} {...props} />
        ))}
      </Switch>
    </SelfServiceLayout>
  )
}
