import React, { FC, useEffect } from 'react'
import { Form, Radio, RadioChangeEvent, Space } from '~/core-components'
import { Col, ErrorDisplay, Row } from '~/components'
import { useAttendancePeriodsByPayPeriod } from '~/features/attendance'
import { Errors } from '~/types/store'
import { PayRunState } from '../../../../types'
import { ImportDailyFormData } from '../ImportDailyDrawer'
import './ImportDailyInput.less'

interface ImportDailyInputProps {
  visible: boolean
  payRun?: PayRunState
  data: Partial<ImportDailyFormData>
  errors?: Errors
  onChange: (data: Partial<ImportDailyFormData>) => void
}

export const ImportDailyInput: FC<ImportDailyInputProps> = ({ visible, payRun, data, errors, onChange }) => {
  const [periods, periodsLoading] = useAttendancePeriodsByPayPeriod(payRun?.payPeriod, payRun?.payGroupId, 'always')

  useEffect(() => {
    if (periods.length === 1) {
      onChange({ attendancePeriodId: periods[0].id })
    }
  }, [periods, onChange])

  return (
    <div className="import-daily-input" hidden={!visible}>
      <Row>
        <Col span={24}>
          <Form.Item label="Attendance period">
            <Radio.Group
              loading={periodsLoading}
              value={data.attendancePeriodId}
              onChange={(event: RadioChangeEvent) => onChange({ attendancePeriodId: event.target.value })}
            >
              <Space direction="vertical">
                {periods.map(p => (
                  <Radio key={p.id} value={p.id}>
                    {p.name}
                  </Radio>
                ))}
              </Space>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>
      <ErrorDisplay errors={errors} />
    </div>
  )
}
