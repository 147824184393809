import React, { FC, useCallback, useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { Form, TabTitle, Tabs } from '~/core-components'
import { DocumentTitle, SSRoleKeyValues } from '~/components'
import { dispatch } from '~/stores/store'
import { MY_ROUTES } from '~/routes/routes'
import { SsRole } from '~/constants'
import { refetchROLeaveEntitlementsView, refetchROLeaveRecordsView } from '../../../reducers'
import { ROLeaveEntitlement } from './ROLeaveEntitlement/ROLeaveEntitlement'
import { ROLeaveRecord } from './ROLeaveRecord/ROLeaveRecord'
import './ROLeaveOverview.less'

export interface ROLeaveOverviewProps {}

export const ROLeaveOverview: FC<ROLeaveOverviewProps> = () => {
  const match = useRouteMatch<{ tab: string }>()
  const history = useHistory()
  const [role, setRole] = useState<SsRole>(SsRole.ReportingManager)

  const handleTabChange = useCallback(
    (activeKey: string) => {
      history.push(`${MY_ROUTES.roLeave.replace(':tab?', activeKey)}`)

      if (activeKey === 'entitlement') {
        dispatch(refetchROLeaveEntitlementsView())
      } else if (activeKey === 'records') {
        dispatch(refetchROLeaveRecordsView())
      }
    },
    [history]
  )

  return (
    <div className="roleave-overview">
      <DocumentTitle title="Leave Overview" />
      <div className="roleave-overview__body">
        <Tabs
          defaultActiveKey={match.params.tab || 'entitlement'}
          onChange={handleTabChange}
          tabBarExtraContent={{
            left: <TabTitle title="Leave overview" />,
            right: (
              <Form.Item label="View as">
                <SSRoleKeyValues value={role} onChange={setRole} size="small" />
              </Form.Item>
            )
          }}
          items={[
            { key: 'entitlement', label: 'Entitlement', children: <ROLeaveEntitlement role={role} /> },
            { key: 'records', label: 'Records', children: <ROLeaveRecord role={role} /> }
          ]}
        />
      </div>
    </div>
  )
}
