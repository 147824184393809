import React, { CSSProperties, FC, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment-timezone'
import { Card, ColumnsType, Form, Link, LinkGroup, SecondaryText, Space, Table, Tag } from '~/core-components'
import { Col, EmpKeyValues, InactiveTag, Row } from '~/components'
import { fetchEmpKeyvalues, selectKeyvaluesById } from '~/features/master'
import { usePermissionGate } from '~/features/iam/hooks'
import { Permission, PermissionAction } from '~/constants'
import { useToggle } from '~/hooks/use-toggle'
import { useFirstInView } from '~/hooks/use-first-in-view'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { isInactive } from '~/utils'
import { MutateEmPayGroupDrawer } from './MutateEmPayGroupDrawer'
import { IEmPayGroupInfo, EmPayGroupState } from '../../../types'
import { fetchEmPayGroups } from '../../../actions'
import { selectCurrentEmPayGroup, selectHistoryEmPayGroups } from '../../../selectors'

interface EmPayGroupsProps {
  employeeId?: string
  id?: string
}

type EmPayGroupTable = EmPayGroupState

const EMPTY_FORM_DATA: IEmPayGroupInfo = {
  startDate: '',
  payGroupId: ''
}

interface DrawerState {
  visible: boolean
  employeeId?: string
  data?: EmPayGroupState
  isCurrent: boolean
}

const DEFAULT_MODAL_STATE: DrawerState = { visible: false, isCurrent: false }

const cardStyle: CSSProperties = { margin: 24 }
const cardBodyStyle: CSSProperties = { padding: 0 }
const formStyle: CSSProperties = { padding: '6px 24px' }

export const EmPayGroups: FC<EmPayGroupsProps> = ({ employeeId, id }: EmPayGroupsProps) => {
  const { ref, inView } = useFirstInView<HTMLDivElement>({ threshold: 0.25 })
  const [drawerState, setDrawerState] = useState<DrawerState>(DEFAULT_MODAL_STATE)
  const [formData, setFormData] = useState<IEmPayGroupInfo>(EMPTY_FORM_DATA)
  const current = useSelector(selectCurrentEmPayGroup)(employeeId, id)
  const histories = useSelector(selectHistoryEmPayGroups)(employeeId)
  const payGroups = useSelector(selectKeyvaluesById)('payGroup')
  const [showHistory, toggleShowHistory] = useToggle()
  const canView = usePermissionGate(Permission.employeePayGroup)
  const canModify = usePermissionGate(Permission.employeePayGroup, PermissionAction.Modify)
  const loading = useSelector((state: StoreState) => state.employee.emPayGroupsLoading[employeeId || ''])

  useEffect(() => {
    if (inView) {
      dispatch(fetchEmpKeyvalues('payGroup', undefined, undefined, { strategy: 'when-empty' }))
    }
  }, [inView])

  useEffect(() => {
    if (inView) {
      if (employeeId) {
        dispatch(fetchEmPayGroups(employeeId))
      }
    }
  }, [inView, employeeId])

  useEffect(() => {
    if (showHistory && histories.length === 0) {
      toggleShowHistory()
    }
  }, [histories.length, showHistory, toggleShowHistory])

  useEffect(() => {
    if (current) {
      const { startDate, payGroupId } = current
      setFormData({ startDate, payGroupId })
    } else {
      setFormData(EMPTY_FORM_DATA)
    }
  }, [current])

  const handleEditPayGroup = useCallback(
    (emPayGroup?: EmPayGroupState) => {
      setDrawerState({ visible: true, employeeId, data: emPayGroup, isCurrent: id === emPayGroup?.id })
    },
    [employeeId, id, setDrawerState]
  )

  const handleCloseDrawer = useCallback(() => {
    setDrawerState(DEFAULT_MODAL_STATE)
  }, [])

  const columns: ColumnsType<EmPayGroupTable> = [
    {
      title: 'Effective date',
      key: 'startDate',
      dataIndex: 'startDate',
      width: 150,
      render: (value: string) => value && moment(value).format('DD MMM YYYY')
    },
    {
      title: 'Payroll group',
      key: 'payGroupId',
      dataIndex: 'payGroupId',
      render: (value: string, record) => (
        <Space>
          {payGroups[value]?.value}
          {record.id === id && <Tag type="primary">current</Tag>}
          {isInactive(payGroups[value]?.inactiveDate) && <InactiveTag />}
        </Space>
      )
    },
    {
      key: 'action',
      align: 'right',
      render: (value: string, record) =>
        canModify && (
          <Link size="small" onClick={() => handleEditPayGroup(record)}>
            edit
          </Link>
        )
    }
  ]

  if (!canView) return null

  return (
    <div ref={ref}>
      <Card
        title="Payroll group"
        style={cardStyle}
        bodyStyle={cardBodyStyle}
        loading={loading}
        extra={
          <LinkGroup>
            {canModify && !showHistory && (
              <Link onClick={() => handleEditPayGroup(current)}>{current ? 'edit' : 'add'}</Link>
            )}
            {(histories?.length || 0) > 0 && (
              <Link onClick={toggleShowHistory}>{showHistory ? 'hide history' : 'history'}</Link>
            )}
          </LinkGroup>
        }
      >
        {!showHistory && (
          <Form style={formStyle}>
            <Row gutter={30}>
              <Col span={12}>
                <Form.Item
                  label={
                    <Space>
                      Payroll group
                      {formData.startDate && (
                        <SecondaryText size="small">
                          effective from {moment(formData.startDate).format('DD MMM YYYY')}
                        </SecondaryText>
                      )}
                    </Space>
                  }
                >
                  <EmpKeyValues id="payGroup" value={formData.payGroupId} readOnly />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        )}
        {showHistory && <Table rowKey="id" dataSource={histories} pagination={false} columns={columns} />}
        {canModify && <MutateEmPayGroupDrawer {...drawerState} onClose={handleCloseDrawer} />}
      </Card>
    </div>
  )
}
