import { FC, ChangeEvent, CSSProperties, useCallback, useEffect, useState } from 'react'
import moment from 'moment-timezone'
import confirm from 'antd/lib/modal/confirm'
import { Button, Checkbox, CheckboxChangeEvent, Form, Input, Select, Space, Tooltip } from '~/core-components'
import { Col, DrawerForm, Row, SelectEmployeesTable, SysOptions } from '~/components'
import { dispatch } from '~/stores/store'
import { useFocus } from '~/hooks/use-focus'
import { ActionResult, Errors } from '~/types/store'
import { isInactive } from '~/utils'
import { HELP_ROUTES } from '~/routes/routes'
import { Pagination } from '~/types/common'
import { Delimiter, emptyGuid } from '~/constants'
import { saveLocation, deleteLocation, fetchLocationAdminEmSelections } from '../../../actions'
import { LocationState } from '../../../types'
import { useLocationAdmins } from '../../../hooks'

export interface MutateLocationDrawerProps {
  visible: boolean
  data?: LocationState
  onClose: () => void
}

type FormData = LocationState

const EMPTY_FORM_DATA: FormData = {
  id: emptyGuid,
  name: '',
  inactiveDate: '',
  latitude: 0,
  longitude: 0,
  isGeofencingIn: false,
  isGeofencingOut: false,
  radius: 200,
  wifiSsid: [],
  timeZoneId: '',
  tags: [],
  employeeIds: []
}

const latLngStyle: CSSProperties = { width: 170 }

export const MutateLocationDrawer: FC<MutateLocationDrawerProps> = ({
  visible,
  data,
  onClose
}: MutateLocationDrawerProps) => {
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState<FormData>(EMPTY_FORM_DATA)
  const [focusRef, setFocus] = useFocus(true)
  const [errors, setErrors] = useState<Errors>()
  const [locationAdmins] = useLocationAdmins(data?.id || '')

  useEffect(() => {
    setTimeout(() => visible && setFocus(), 100)
    setErrors(undefined)
  }, [visible, setFocus])

  useEffect(() => {
    if (data) {
      const {
        id,
        name,
        inactiveDate,
        latitude,
        longitude,
        isGeofencingIn,
        isGeofencingOut,
        radius,
        wifiSsid,
        timeZoneId,
        tags
      } = data
      const employeeIds = locationAdmins.map(x => x.employeeId)
      setFormData({
        id,
        name,
        inactiveDate,
        latitude,
        longitude,
        isGeofencingIn,
        isGeofencingOut,
        radius,
        wifiSsid,
        timeZoneId,
        tags,
        employeeIds
      })
    } else {
      setFormData(EMPTY_FORM_DATA)
    }
  }, [data, locationAdmins])

  const handleFormDataChange = useCallback((updates: { [field: string]: any }) => {
    setFormData(formData => ({ ...formData, ...updates }))
  }, [])

  const handleOk = useCallback(async () => {
    let result: ActionResult | undefined
    setLoading(true)
    try {
      result = await dispatch(
        saveLocation({
          ...formData,
          tags: formData.tags?.join(Delimiter.comma) || '',
          wifiSsid: formData.wifiSsid?.join(Delimiter.comma) || ''
        })
      )
    } finally {
      setLoading(false)
    }

    if (result?.errors) {
      setErrors(result.errors)
    }

    if (!result?.errors) {
      typeof onClose === 'function' && onClose()
      setFormData(EMPTY_FORM_DATA)
    }
  }, [formData, onClose])

  const handleDelete = useCallback(
    (location: LocationState | undefined) => {
      if (location) {
        const { id, name } = location
        confirm({
          title: 'Delete location',
          content: `Do you want to delete location record of "${name}"?`,
          onOk: async () => {
            const result: ActionResult | undefined = await dispatch(deleteLocation(id))
            if (result?.errors) {
              setErrors(result.errors)
            }

            if (!result?.errors) {
              typeof onClose === 'function' && onClose()
            }
          },
          okText: 'Delete',
          okType: 'danger'
        })
      }
    },
    [onClose]
  )

  const handleHelpClick = useCallback(() => {
    window.open(HELP_ROUTES.page.replace(':page', 'geolocation'), '_blank')
  }, [])

  const handleFetchEmployees = useCallback((viewId: string, pagination: Pagination, search: string) => {
    dispatch(fetchLocationAdminEmSelections(viewId, pagination, search))
  }, [])

  const handleSelectEmployee = useCallback((employeeIds: string[]) => {
    setFormData(formData => ({ ...formData, employeeIds }))
  }, [])

  return (
    <DrawerForm
      open={visible}
      title={data ? 'Edit location' : 'Add location'}
      onClose={onClose}
      confirmLoading={loading}
      width={500}
      showDelete={data ? true : false}
      onDelete={() => handleDelete(data)}
      formId="form-location"
    >
      <Form id="form-location" onFinish={handleOk}>
        <Row gutter={30}>
          <Col flex="auto">
            <Form.Item label="Name" validateStatus={errors?.name ? 'error' : ''} help={errors?.name}>
              <Input
                ref={focusRef}
                value={formData.name}
                onChange={(event: ChangeEvent<HTMLInputElement>) => handleFormDataChange({ name: event.target.value })}
              />
            </Form.Item>
          </Col>
          <Col flex="none">
            <Form.Item
              label="Inactive"
              validateStatus={errors?.inactiveDate ? 'error' : ''}
              help={errors?.inactiveDate}
            >
              <Checkbox
                checked={isInactive(formData.inactiveDate)}
                onChange={(event: CheckboxChangeEvent) =>
                  handleFormDataChange({ inactiveDate: event.target.checked ? moment().format('YYYY-MM-DD') : '' })
                }
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={30}>
          <Col flex="1">
            <Form.Item label="Latitude" validateStatus={errors?.latitude ? 'error' : ''} help={errors?.latitude}>
              <Input.Number
                value={formData.latitude}
                onChange={(value: number | null) => handleFormDataChange({ latitude: value || 0 })}
                style={latLngStyle}
              />
            </Form.Item>
          </Col>
          <Col flex="1">
            <Form.Item label="Longitude" validateStatus={errors?.longitude ? 'error' : ''} help={errors?.longitude}>
              <Input.Number
                value={formData.longitude}
                onChange={(value: number | null) => handleFormDataChange({ longitude: value || 0 })}
                style={latLngStyle}
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item label=" ">
              <Tooltip title="Guide to obtain latitude and longitude in Google Maps" placement="bottomRight">
                <Button icon={<i className="fal fa-circle-question" />} onClick={handleHelpClick} />
              </Tooltip>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Item
              label="Enable geofencing"
              validateStatus={errors?.isGeofencingIn ? 'error' : ''}
              help={errors?.isGeofencingIn}
            >
              <Checkbox
                checked={formData.isGeofencingIn}
                onChange={(event: CheckboxChangeEvent) =>
                  handleFormDataChange({ isGeofencingIn: event.target.checked })
                }
              >
                In
              </Checkbox>
              <Checkbox
                checked={formData.isGeofencingOut}
                onChange={(event: CheckboxChangeEvent) =>
                  handleFormDataChange({ isGeofencingOut: event.target.checked })
                }
              >
                Out
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>
        <Row hidden={!formData.isGeofencingIn && !formData.isGeofencingOut}>
          <Col>
            <Form.Item label="Radius" validateStatus={errors?.radius ? 'error' : ''} help={errors?.radius}>
              <Space>
                <Input.Number
                  value={formData.radius}
                  onChange={(value: number | null) => handleFormDataChange({ radius: value || 200 })}
                />
                meter
              </Space>
            </Form.Item>
          </Col>
        </Row>
        {/* <Row>
          <Col flex="1">
            <Form.Item label="Wi-Fi SSID" validateStatus={errors?.wifiSsid ? 'error' : ''} help={errors?.wifiSsid}>
              <Select
                mode="tags"
                showSearch
                value={formData.wifiSsid && formData.wifiSsid.length > 0 ? formData.wifiSsid : undefined}
                placeholder="Only allow to clock in/out in the defined Wi-Fi SSID"
                optionFilterProp="title"
                onChange={(wifiSsid: string[]) => handleFormDataChange({ wifiSsid })}
              >
                {formData.wifiSsid?.map(t => (
                  <Select.Option key={t} value={t} title={t}>
                    {t}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row> */}
        <Row>
          <Col flex="1">
            <Form.Item label="Time zone" validateStatus={errors?.timeZoneId ? 'error' : ''} help={errors?.timeZoneId}>
              <SysOptions
                type="time_zone"
                value={formData.timeZoneId}
                onChange={(value: string) => handleFormDataChange({ timeZoneId: value })}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col flex="1">
            <Form.Item label="Tags" validateStatus={errors?.tags ? 'error' : ''} help={errors?.tags}>
              <Select
                mode="tags"
                showSearch
                value={formData.tags && formData.tags.length > 0 ? formData.tags : undefined}
                placeholder="Define the tag for this location"
                optionFilterProp="title"
                onChange={(tags: string[]) => handleFormDataChange({ tags })}
              >
                {formData.tags?.map(t => (
                  <Select.Option key={t} value={t} title={t}>
                    {t}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <SelectEmployeesTable
              label="Administrators"
              viewName="location_admin"
              employeeIds={formData.employeeIds || []}
              onFetchData={handleFetchEmployees}
              onSelect={handleSelectEmployee}
            />
          </Col>
        </Row>
      </Form>
    </DrawerForm>
  )
}
