import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit'
import persistReducer from 'redux-persist/es/persistReducer'
import { EmSelectState } from '~/components'
import {
  WorkCalendarState,
  attendancePersistConfig,
  AttendanceRootState,
  BreakState,
  BreakTimeState,
  LocationState,
  ShiftCategoryState,
  ShiftRoleState,
  WorkCalendarPatternState,
  OtConfigState,
  TeConfigState,
  ClockRecordState,
  ClockRecordViewState,
  ShiftRoleTeState,
  SysDailyFieldState,
  LocationAdminState,
  ClockRecordImportState,
  ClockRecordImportValidateState,
  DailyPolicyRowState,
  SysDailyPolicyFieldState,
  DailyPolicyState,
  SysDailyPolicyExpressionState
} from './types'
import { ShiftDayState, ShiftState, ShiftTeState } from './types/shift.state'
import { DailyRecordState, DailyRecordViewState } from './types'
import { AttendancePeriodState, AttendancePeriodViewState } from './types/attendance-period.state'
import { StoreState } from '~/types/store'

const locationsAdapter = createEntityAdapter<LocationState>()
const locationsViewAdapter = createEntityAdapter<LocationState>()
const locationAdminsAdapter = createEntityAdapter<LocationAdminState>()
const breaksAdapter = createEntityAdapter<BreakState>()
const breakTimesAdapter = createEntityAdapter<BreakTimeState>()
const otConfigsAdapter = createEntityAdapter<OtConfigState>({ selectId: model => model.code })
const teConfigsAdapter = createEntityAdapter<TeConfigState>({ selectId: model => model.code })
const shiftCategoriesAdapter = createEntityAdapter<ShiftCategoryState>()
const shiftRolesAdapter = createEntityAdapter<ShiftRoleState>()
const shiftRoleTesAdapter = createEntityAdapter<ShiftRoleTeState>()
const shiftsAdapter = createEntityAdapter<ShiftState>()
const shiftDaysAdapter = createEntityAdapter<ShiftDayState>()
const shiftDaysAggregatedAdapter = createEntityAdapter<ShiftDayState>({ selectId: model => model.dayCode })
const shiftTesAdapter = createEntityAdapter<ShiftTeState>()
const calendarsAdapter = createEntityAdapter<WorkCalendarState>()
const calendarPatternsAdapter = createEntityAdapter<WorkCalendarPatternState>()
const sysDailyFieldAdapter = createEntityAdapter<SysDailyFieldState>({ selectId: model => model.code })
const sysDailyPolicyFieldAdapter = createEntityAdapter<SysDailyPolicyFieldState>({ selectId: model => model.fieldName })
const sysDailyPolicyExpressionAdapter = createEntityAdapter<SysDailyPolicyExpressionState>({
  selectId: model => model.columnExpression
})
const dailyPoliciesAdapter = createEntityAdapter<DailyPolicyState>()
const dailyPolicyRowsAdapter = createEntityAdapter<DailyPolicyRowState>()
const attendancePeriodsViewAdapter = createEntityAdapter<AttendancePeriodViewState>({ selectId: model => model.viewId })
const attendancePeriodsAdapter = createEntityAdapter<AttendancePeriodState>()
const dailyRecordViewAdapter = createEntityAdapter<DailyRecordViewState>({ selectId: model => model.viewId })
const dailyRecordAdapter = createEntityAdapter<DailyRecordState>()
const dailyRecordEmployeesAdapter = createEntityAdapter<EmSelectState>()
const clockRecordViewAdapter = createEntityAdapter<ClockRecordViewState>({ selectId: model => model.viewId })
const clockRecordsByDailyAdapter = createEntityAdapter<ClockRecordState>()

const locationInitialState = locationsAdapter.getInitialState()
const locationViewInitialState = locationsViewAdapter.getInitialState()
const locationAdminsInitialState = locationAdminsAdapter.getInitialState()
const breakInitialState = breaksAdapter.getInitialState()
const breakTimeInitialState = breakTimesAdapter.getInitialState()
const otConfigInitialState = otConfigsAdapter.getInitialState()
const teConfigInitialState = teConfigsAdapter.getInitialState()
const shiftCategoryInitialState = shiftCategoriesAdapter.getInitialState()
const shiftRoleInitialState = shiftRolesAdapter.getInitialState()
const shiftRoleTeInitialState = shiftRoleTesAdapter.getInitialState()
const shiftInitialState = shiftsAdapter.getInitialState()
const shiftDayInitialState = shiftDaysAdapter.getInitialState()
const shiftDayAggregatedInitialState = shiftDaysAggregatedAdapter.getInitialState()
const shiftTeInitialState = shiftTesAdapter.getInitialState()
const calendarInitialState = calendarsAdapter.getInitialState()
const calendarPatternInitialState = calendarPatternsAdapter.getInitialState()
const sysDailyFieldInitialState = sysDailyFieldAdapter.getInitialState()
const sysDailyPolicyFieldInitialState = sysDailyPolicyFieldAdapter.getInitialState()
const sysDailyPolicyExpressionInitialState = sysDailyPolicyExpressionAdapter.getInitialState()
const dailyPolicyInitialState = dailyPoliciesAdapter.getInitialState()
const dailyPolicyRowInitialState = dailyPolicyRowsAdapter.getInitialState()
const attendancePeriodViewInitialState = attendancePeriodsViewAdapter.getInitialState()
const attendancePeriodInitialState = attendancePeriodsAdapter.getInitialState()
const dailyRecordViewInitialState = dailyRecordViewAdapter.getInitialState()
const dailyRecordInitialState = dailyRecordAdapter.getInitialState()
const dailyRecordEmployeeInitialState = dailyRecordEmployeesAdapter.getInitialState()
const clockRecordViewInitialState = clockRecordViewAdapter.getInitialState()
const clockRecordByDailyInitialState = clockRecordsByDailyAdapter.getInitialState()

const initialState: AttendanceRootState = {
  locations: locationInitialState,
  locationsLoading: false,

  locationAdmins: {},
  locationAdminsLoading: {},

  refetchLocationsView: 0,
  locationsView: locationViewInitialState,
  locationsViewLoading: false,

  breaks: breakInitialState,
  breaksLoading: false,
  breakTimes: {},
  breakTimesLoading: {},

  otConfigs: otConfigInitialState,
  otConfigsLoading: false,
  teConfigs: teConfigInitialState,
  teConfigsLoading: false,

  shiftCategories: shiftCategoryInitialState,
  shiftCategoriesLoading: false,

  shiftRoles: shiftRoleInitialState,
  shiftRolesLoading: false,

  shiftRoleTes: {},
  shiftRoleTesLoading: {},

  shifts: shiftInitialState,
  shiftsLoading: false,

  shiftDaysRefetch: {},
  shiftDays: {},
  shiftDaysLoading: {},
  shiftDaysAggregated: {},
  shiftDaysAggregatedLoading: {},

  shiftTes: {},
  shiftTesLoading: {},

  calendars: calendarInitialState,
  calendarsLoading: false,
  calendarPatterns: {},
  calendarPatternsLoading: {},

  sysDailyFields: sysDailyFieldInitialState,
  sysDailyFieldsLoading: false,
  sysDailyPolicyFields: sysDailyPolicyFieldInitialState,
  sysDailyPolicyFieldsLoading: false,
  sysDailyPolicyExpressions: sysDailyPolicyExpressionInitialState,
  sysDailyPolicyExpressionsLoading: false,

  dailyPolicies: dailyPolicyInitialState,
  dailyPoliciesLoading: false,
  dailyPolicyRows: {},
  dailyPolicyRowsLoading: {},

  clockRecordsView: clockRecordViewInitialState,
  clockRecordsViewLoading: false,
  clockRecordsViewRefetch: 0,

  clockRecordsByDaily: {},
  clockRecordsByDailyLoading: {},
  clockRecordsByDailyRefetch: {},

  attendancePeriodsView: attendancePeriodViewInitialState,
  attendancePeriodsViewLoading: false,
  attendancePeriodsViewRefetch: 0,

  attendancePeriods: attendancePeriodInitialState,
  attendancePeriodsLoading: false,

  dailyRecordsView: dailyRecordViewInitialState,
  dailyRecordsViewLoading: false,
  dailyRecordsViewRefetch: 0,
  dailyRecords: dailyRecordInitialState,
  dailyRecordsLoading: false,
  dailyRecordsRefetch: 0,
  dailyProcessing: false,

  dailyRecordEmployees: dailyRecordEmployeeInitialState,
  dailyRecordEmployeesLoading: false,

  clockRecordImport: undefined,
  clockRecordImportValidate: undefined
}

const attendanceSlice = createSlice({
  name: 'attendance',
  initialState,
  reducers: {
    // locations
    replaceLocations: (state, action: PayloadAction<LocationState[]>) => {
      locationsAdapter.setAll(state.locations, action.payload)
    },
    setLocation: (state, action: PayloadAction<LocationState>) => {
      locationsAdapter.upsertOne(state.locations, action.payload)
    },
    removeLocation: (state, action: PayloadAction<string>) => {
      locationsAdapter.removeOne(state.locations, action.payload)
    },
    setLocationsLoading: (state, action: PayloadAction<boolean>) => {
      state.locationsLoading = action.payload
    },

    // locationsView
    refetchLocationsView: state => {
      state.refetchLocationsView += 1
    },
    replaceLocationsView: (state, action: PayloadAction<LocationState[]>) => {
      locationsAdapter.setAll(state.locationsView, action.payload)
    },
    setLocationsViewLoading: (state, action: PayloadAction<boolean>) => {
      state.locationsViewLoading = action.payload
    },

    // locationAdmins
    replaceLocationAdmins: (state, action: PayloadAction<{ id: string; data: LocationAdminState[] }>) => {
      state.locationAdmins[action.payload.id] = state.locationAdmins[action.payload.id] || locationAdminsInitialState
      locationAdminsAdapter.setAll(state.locationAdmins[action.payload.id]!, action.payload.data)
    },
    removeLocationAdminsByLocation: (state, action: PayloadAction<string>) => {
      const locationId = action.payload
      locationAdminsAdapter.removeAll(state.locationAdmins[locationId]!)
    },
    setLocationAdminsLoading: (state, action: PayloadAction<{ id: string; loading: boolean }>) => {
      state.locationAdmins[action.payload.id] = state.locationAdmins[action.payload.id] || locationAdminsInitialState
      state.locationAdminsLoading[action.payload.id] = action.payload.loading
    },

    // breaks
    replaceBreaks: (state, action: PayloadAction<BreakState[]>) => {
      breaksAdapter.setAll(state.breaks, action.payload)
    },
    setBreak: (state, action: PayloadAction<BreakState>) => {
      breaksAdapter.upsertOne(state.breaks, action.payload)
    },
    removeBreak: (state, action: PayloadAction<string>) => {
      breaksAdapter.removeOne(state.breaks, action.payload)
    },
    setBreaksLoading: (state, action: PayloadAction<boolean>) => {
      state.breaksLoading = action.payload
    },
    replaceBreakTimes: (state, action: PayloadAction<{ breakId: string; data: BreakTimeState[] }>) => {
      const { breakId, data } = action.payload
      breakTimesAdapter.setAll(state.breakTimes[breakId], data)
    },
    setBreakTimesLoading: (state, action: PayloadAction<{ breakId: string; loading: boolean }>) => {
      const { breakId, loading } = action.payload
      state.breakTimes[breakId] = state.breakTimes[breakId] || breakTimeInitialState
      state.breakTimesLoading[breakId] = loading
    },

    // ot configs
    replaceOtConfigs: (state, action: PayloadAction<OtConfigState[]>) => {
      otConfigsAdapter.setAll(state.otConfigs, action.payload)
    },
    setOtConfig: (state, action: PayloadAction<OtConfigState>) => {
      otConfigsAdapter.upsertOne(state.otConfigs, action.payload)
    },
    setOtConfigsLoading: (state, action: PayloadAction<boolean>) => {
      state.otConfigsLoading = action.payload
    },

    // te configs
    replaceTeConfigs: (state, action: PayloadAction<TeConfigState[]>) => {
      teConfigsAdapter.setAll(state.teConfigs, action.payload)
    },
    setTeConfig: (state, action: PayloadAction<TeConfigState>) => {
      teConfigsAdapter.upsertOne(state.teConfigs, action.payload)
    },
    setTeConfigsLoading: (state, action: PayloadAction<boolean>) => {
      state.teConfigsLoading = action.payload
    },

    // shift categories
    replaceShiftCategories: (state, action: PayloadAction<ShiftCategoryState[]>) => {
      shiftCategoriesAdapter.setAll(state.shiftCategories, action.payload)
    },
    setShiftCategory: (state, action: PayloadAction<ShiftCategoryState>) => {
      shiftCategoriesAdapter.upsertOne(state.shiftCategories, action.payload)
    },
    removeShiftCategory: (state, action: PayloadAction<string>) => {
      shiftCategoriesAdapter.removeOne(state.shiftCategories, action.payload)
    },
    setShiftCategoriesLoading: (state, action: PayloadAction<boolean>) => {
      state.shiftCategoriesLoading = action.payload
    },

    // shift roles
    replaceShiftRoles: (state, action: PayloadAction<ShiftRoleState[]>) => {
      shiftRolesAdapter.setAll(state.shiftRoles, action.payload)
    },
    setShiftRole: (state, action: PayloadAction<ShiftRoleState>) => {
      shiftRolesAdapter.upsertOne(state.shiftRoles, action.payload)
    },
    removeShiftRole: (state, action: PayloadAction<string>) => {
      shiftRolesAdapter.removeOne(state.shiftRoles, action.payload)
    },
    setShiftRolesLoading: (state, action: PayloadAction<boolean>) => {
      state.shiftRolesLoading = action.payload
    },

    // shift roles time element
    replaceShiftRoleTes: (state, action: PayloadAction<{ shiftRoleId: string; data: ShiftRoleTeState[] }>) => {
      const { shiftRoleId, data } = action.payload
      shiftRoleTesAdapter.setAll(state.shiftRoleTes[shiftRoleId], data)
    },
    setShiftRoleTe: (state, action: PayloadAction<{ shiftRoleId: string; data: ShiftRoleTeState }>) => {
      const { shiftRoleId, data } = action.payload
      shiftRoleTesAdapter.upsertOne(state.shiftRoleTes[shiftRoleId], data)
    },
    removeShiftRoleTe: (state, action: PayloadAction<{ shiftRoleId: string; id: string }>) => {
      const { shiftRoleId, id } = action.payload
      shiftRoleTesAdapter.removeOne(state.shiftRoleTes[shiftRoleId], id)
    },
    setShiftRoleTesLoading: (state, action: PayloadAction<{ shiftRoleId: string; loading: boolean }>) => {
      const { shiftRoleId, loading } = action.payload
      state.shiftRoleTes[shiftRoleId] = state.shiftRoleTes[shiftRoleId] || shiftRoleTeInitialState
      state.shiftRoleTesLoading[shiftRoleId] = loading
    },

    // shift
    replaceShifts: (state, action: PayloadAction<ShiftState[]>) => {
      shiftsAdapter.setAll(state.shifts, action.payload)
    },
    setShift: (state, action: PayloadAction<ShiftState>) => {
      shiftsAdapter.upsertOne(state.shifts, action.payload)
    },
    removeShift: (state, action: PayloadAction<string>) => {
      shiftsAdapter.removeOne(state.shifts, action.payload)
    },
    setShiftsLoading: (state, action: PayloadAction<boolean>) => {
      state.shiftsLoading = action.payload
    },

    // shift day
    refetchShiftDays: (state, action: PayloadAction<string>) => {
      const shiftId = action.payload
      state.shiftDaysRefetch[shiftId] = state.shiftDaysRefetch[shiftId] || 0
      state.shiftDaysRefetch[shiftId] += 1
    },
    replaceShiftDays: (state, action: PayloadAction<{ shiftId: string; data: ShiftDayState[] }>) => {
      const { shiftId, data } = action.payload
      shiftDaysAdapter.setAll(state.shiftDays[shiftId], data)
    },
    setShiftDay: (state, action: PayloadAction<{ shiftId: string; data: ShiftDayState }>) => {
      const { shiftId, data } = action.payload
      shiftDaysAdapter.upsertOne(state.shiftDays[shiftId], data)
    },
    removeShiftDay: (state, action: PayloadAction<{ shiftId: string; id: string }>) => {
      const { shiftId, id } = action.payload
      shiftDaysAdapter.removeOne(state.shiftDays[shiftId], id)
    },
    setShiftDaysLoading: (state, action: PayloadAction<{ shiftId: string; loading: boolean }>) => {
      const { shiftId, loading } = action.payload
      state.shiftDays[shiftId] = state.shiftDays[shiftId] || shiftDayInitialState
      state.shiftDaysLoading[shiftId] = loading
    },
    replaceShiftDaysAggregated: (state, action: PayloadAction<{ shiftId: string; data: ShiftDayState[] }>) => {
      const { shiftId, data } = action.payload
      shiftDaysAggregatedAdapter.setAll(state.shiftDaysAggregated[shiftId], data)
    },
    setShiftDayAggregated: (state, action: PayloadAction<{ shiftId: string; data: ShiftDayState }>) => {
      const { shiftId, data } = action.payload
      shiftDaysAggregatedAdapter.upsertOne(state.shiftDaysAggregated[shiftId], data)
    },
    setShiftDaysAggregatedLoading: (state, action: PayloadAction<{ shiftId: string; loading: boolean }>) => {
      const { shiftId, loading } = action.payload
      state.shiftDaysAggregated[shiftId] = state.shiftDaysAggregated[shiftId] || shiftDayAggregatedInitialState
      state.shiftDaysAggregatedLoading[shiftId] = loading
    },

    // shift time element
    replaceShiftTes: (state, action: PayloadAction<{ shiftId: string; data: ShiftTeState[] }>) => {
      const { shiftId, data } = action.payload
      shiftTesAdapter.setAll(state.shiftTes[shiftId], data)
    },
    setShiftTe: (state, action: PayloadAction<{ shiftId: string; data: ShiftTeState }>) => {
      const { shiftId, data } = action.payload
      shiftTesAdapter.upsertOne(state.shiftTes[shiftId], data)
    },
    removeShiftTe: (state, action: PayloadAction<{ shiftId: string; id: string }>) => {
      const { shiftId, id } = action.payload
      shiftTesAdapter.removeOne(state.shiftTes[shiftId], id)
    },
    setShiftTesLoading: (state, action: PayloadAction<{ shiftId: string; loading: boolean }>) => {
      const { shiftId, loading } = action.payload
      state.shiftTes[shiftId] = state.shiftTes[shiftId] || shiftTeInitialState
      state.shiftTesLoading[shiftId] = loading
    },

    // calendar
    replaceCalendars: (state, action: PayloadAction<WorkCalendarState[]>) => {
      calendarsAdapter.setAll(state.calendars, action.payload)
    },
    setCalendar: (state, action: PayloadAction<WorkCalendarState>) => {
      calendarsAdapter.upsertOne(state.calendars, action.payload)
    },
    removeCalendar: (state, action: PayloadAction<string>) => {
      calendarsAdapter.removeOne(state.calendars, action.payload)
    },
    setCalendarsLoading: (state, action: PayloadAction<boolean>) => {
      state.calendarsLoading = action.payload
    },
    replaceCalendarPatterns: (
      state,
      action: PayloadAction<{ calendarId: string; data: WorkCalendarPatternState[] }>
    ) => {
      const { calendarId, data } = action.payload
      state.calendarPatterns[calendarId] = state.calendarPatterns[calendarId] || calendarPatternInitialState
      calendarPatternsAdapter.setAll(state.calendarPatterns[calendarId], data)
    },
    setCalendarPatternsLoading: (state, action: PayloadAction<{ calendarId: string; loading: boolean }>) => {
      const { calendarId, loading } = action.payload
      state.calendarPatterns[calendarId] = state.calendarPatterns[calendarId] || calendarPatternInitialState
      state.calendarPatternsLoading[calendarId] = loading
    },

    // sysDailyFields
    replaceSysDailyFields: (state, action: PayloadAction<SysDailyFieldState[]>) => {
      sysDailyFieldAdapter.setAll(state.sysDailyFields, action.payload)
    },
    setSysDailyFieldsLoading: (state, action: PayloadAction<boolean>) => {
      state.sysDailyFieldsLoading = action.payload
    },

    // sysDailyPolicyFields
    replaceSysDailyPolicyFields: (state, action: PayloadAction<SysDailyPolicyFieldState[]>) => {
      sysDailyPolicyFieldAdapter.setAll(state.sysDailyPolicyFields, action.payload)
    },
    setSysDailyPolicyFieldsLoading: (state, action: PayloadAction<boolean>) => {
      state.sysDailyPolicyFieldsLoading = action.payload
    },

    // sysDailyPolicyExpressions
    replaceSysDailyPolicyExpressions: (state, action: PayloadAction<SysDailyPolicyExpressionState[]>) => {
      sysDailyPolicyExpressionAdapter.setAll(state.sysDailyPolicyExpressions, action.payload)
    },
    setSysDailyPolicyExpressionsLoading: (state, action: PayloadAction<boolean>) => {
      state.sysDailyPolicyExpressionsLoading = action.payload
    },

    // dailyPolicies
    replaceDailyPolicies: (state, action: PayloadAction<DailyPolicyState[]>) => {
      dailyPoliciesAdapter.setAll(state.dailyPolicies, action.payload)
    },
    setDailyPolicy: (state, action: PayloadAction<DailyPolicyState>) => {
      dailyPoliciesAdapter.upsertOne(state.dailyPolicies, action.payload)
    },
    setDailyPoliciesLoading: (state, action: PayloadAction<boolean>) => {
      state.dailyPoliciesLoading = action.payload
    },
    removeDailyPolicy: (state, action: PayloadAction<string>) => {
      dailyPoliciesAdapter.removeOne(state.dailyPolicies, action.payload)
    },

    // dailyPolicyRows
    replaceDailyPolicyRows: (state, action: PayloadAction<{ dailyPolicyId: string; data: DailyPolicyRowState[] }>) => {
      const { dailyPolicyId, data } = action.payload
      dailyPolicyRowsAdapter.setAll(state.dailyPolicyRows[dailyPolicyId], data)
    },
    setDailyPolicyRow: (state, action: PayloadAction<{ dailyPolicyId: string; data: DailyPolicyRowState }>) => {
      const { dailyPolicyId, data } = action.payload
      dailyPolicyRowsAdapter.upsertOne(state.dailyPolicyRows[dailyPolicyId], data)
    },
    setDailyPolicyRowsLoading: (state, action: PayloadAction<{ dailyPolicyId: string; loading: boolean }>) => {
      const { dailyPolicyId, loading } = action.payload
      state.dailyPolicyRows[dailyPolicyId] = state.dailyPolicyRows[dailyPolicyId] || dailyPolicyRowInitialState
      state.dailyPolicyRowsLoading[dailyPolicyId] = loading
    },
    removeDailyPolicyRow: (state, action: PayloadAction<{ dailyPolicyId: string; id: string }>) => {
      const { dailyPolicyId, id } = action.payload
      dailyPolicyRowsAdapter.removeOne(state.dailyPolicyRows[dailyPolicyId], id)
    },

    // clockRecordsView
    refetchClockRecordsView: state => {
      state.clockRecordsViewRefetch += 1
    },
    setClockRecordsViewLoading: (state, action: PayloadAction<boolean>) => {
      state.clockRecordsViewLoading = action.payload
    },
    setClockRecordsView: (state, action: PayloadAction<ClockRecordViewState>) => {
      clockRecordViewAdapter.upsertOne(state.clockRecordsView, action.payload)
    },

    // clockRecordsByDaily
    replaceClockRecordsByDaily: (state, action: PayloadAction<{ dailyRecordId: string; data: ClockRecordState[] }>) => {
      const { dailyRecordId, data } = action.payload
      clockRecordsByDailyAdapter.setAll(state.clockRecordsByDaily[dailyRecordId], data)
    },
    setClockRecordsByDailyLoading: (state, action: PayloadAction<{ dailyRecordId: string; loading: boolean }>) => {
      const { dailyRecordId, loading } = action.payload
      state.clockRecordsByDaily[dailyRecordId] =
        state.clockRecordsByDaily[dailyRecordId] || clockRecordByDailyInitialState
      state.clockRecordsByDailyLoading[dailyRecordId] = loading
    },
    refetchClockRecordsByDaily: (state, action: PayloadAction<{ dailyRecordId: string }>) => {
      const { dailyRecordId } = action.payload
      state.clockRecordsByDailyRefetch[dailyRecordId] = state.clockRecordsByDailyRefetch[dailyRecordId] || 0
      state.clockRecordsByDailyRefetch[dailyRecordId] += 1
    },

    // attendancePeriodsView
    refetchAttendancePeriodsView: state => {
      state.attendancePeriodsViewRefetch += 1
    },
    setAttendancePeriodsViewLoading: (state, action: PayloadAction<boolean>) => {
      state.attendancePeriodsViewLoading = action.payload
    },
    setAttendancePeriodsView: (state, action: PayloadAction<AttendancePeriodViewState>) => {
      attendancePeriodsViewAdapter.upsertOne(state.attendancePeriodsView, action.payload)
    },

    // attendancePeriods
    setAttendancePeriodsLoading: (state, action: PayloadAction<boolean>) => {
      state.attendancePeriodsLoading = action.payload
    },
    setAttendancePeriod: (state, action: PayloadAction<AttendancePeriodState>) => {
      attendancePeriodsAdapter.upsertOne(state.attendancePeriods, action.payload)
    },
    setAttendancePeriods: (state, action: PayloadAction<AttendancePeriodState[]>) => {
      attendancePeriodsAdapter.upsertMany(state.attendancePeriods, action.payload)
    },
    replaceAttendancePeriods: (state, action: PayloadAction<AttendancePeriodState[]>) => {
      attendancePeriodsAdapter.setAll(state.attendancePeriods, action.payload)
    },
    removeAttendancePeriod: (state, action: PayloadAction<string>) => {
      attendancePeriodsAdapter.removeOne(state.attendancePeriods, action.payload)
    },

    // dailyRecordsView
    refetchDailyRecordsView: state => {
      state.dailyRecordsViewRefetch += 1
    },
    setDailyRecordsViewLoading: (state, action: PayloadAction<boolean>) => {
      state.dailyRecordsViewLoading = action.payload
    },
    setDailyRecordsView: (state, action: PayloadAction<DailyRecordViewState>) => {
      dailyRecordViewAdapter.upsertOne(state.dailyRecordsView, action.payload)
    },
    clearDailyRecordsView: state => {
      dailyRecordViewAdapter.removeAll(state.dailyRecordsView)
    },

    // dailyRecords
    refetchDailyRecords: state => {
      state.dailyRecordsRefetch += 1
    },
    setDailyRecordsLoading: (state, action: PayloadAction<boolean>) => {
      state.dailyRecordsLoading = action.payload
    },
    setDailyRecord: (state, action: PayloadAction<DailyRecordState>) => {
      dailyRecordAdapter.upsertOne(state.dailyRecords, action.payload)
    },
    setDailyRecords: (state, action: PayloadAction<DailyRecordState[]>) => {
      dailyRecordAdapter.upsertMany(state.dailyRecords, action.payload)
    },
    removeDailyRecord: (state, action: PayloadAction<string>) => {
      dailyRecordAdapter.removeOne(state.dailyRecords, action.payload)
    },

    // dailyProcessing
    setDailyProcessing: (state, action: PayloadAction<boolean>) => {
      state.dailyProcessing = action.payload
    },

    replaceDailyRecordEmployees: (state, action: PayloadAction<EmSelectState[]>) => {
      dailyRecordEmployeesAdapter.setAll(state.dailyRecordEmployees, action.payload)
    },
    setDailyRecordEmployeesLoading: (state, action: PayloadAction<boolean>) => {
      state.dailyRecordEmployeesLoading = action.payload
    },

    // clockRecordImport
    replaceClockRecordImport: (state, action: PayloadAction<ClockRecordImportState>) => {
      state.clockRecordImport = action.payload
    },
    replaceClockRecordImportValidate: (state, action: PayloadAction<ClockRecordImportValidateState>) => {
      state.clockRecordImportValidate = action.payload
    }
  }
})

export const {
  replaceLocations,
  setLocation,
  removeLocation,
  setLocationsLoading,

  refetchLocationsView,
  replaceLocationsView,
  setLocationsViewLoading,

  replaceLocationAdmins,
  removeLocationAdminsByLocation,
  setLocationAdminsLoading,

  replaceBreaks,
  setBreak,
  removeBreak,
  setBreaksLoading,
  replaceBreakTimes,
  setBreakTimesLoading,

  replaceOtConfigs,
  setOtConfig,
  setOtConfigsLoading,

  replaceTeConfigs,
  setTeConfig,
  setTeConfigsLoading,

  replaceShiftCategories,
  setShiftCategory,
  removeShiftCategory,
  setShiftCategoriesLoading,

  replaceShiftRoles,
  setShiftRole,
  removeShiftRole,
  setShiftRolesLoading,

  replaceShiftRoleTes,
  setShiftRoleTe,
  removeShiftRoleTe,
  setShiftRoleTesLoading,

  replaceShifts,
  setShift,
  removeShift,
  setShiftsLoading,

  refetchShiftDays,
  replaceShiftDays,
  setShiftDay,
  removeShiftDay,
  setShiftDaysLoading,
  replaceShiftDaysAggregated,
  setShiftDayAggregated,
  setShiftDaysAggregatedLoading,

  replaceShiftTes,
  setShiftTe,
  removeShiftTe,
  setShiftTesLoading,

  replaceCalendars,
  setCalendar,
  removeCalendar,
  setCalendarsLoading,
  replaceCalendarPatterns,
  setCalendarPatternsLoading,

  replaceSysDailyFields,
  setSysDailyFieldsLoading,
  replaceSysDailyPolicyFields,
  setSysDailyPolicyFieldsLoading,
  replaceSysDailyPolicyExpressions,
  setSysDailyPolicyExpressionsLoading,

  replaceDailyPolicies,
  setDailyPolicy,
  setDailyPoliciesLoading,
  removeDailyPolicy,

  replaceDailyPolicyRows,
  setDailyPolicyRow,
  setDailyPolicyRowsLoading,
  removeDailyPolicyRow,

  refetchClockRecordsView,
  setClockRecordsViewLoading,
  setClockRecordsView,

  replaceClockRecordsByDaily,
  setClockRecordsByDailyLoading,
  refetchClockRecordsByDaily,

  refetchAttendancePeriodsView,
  setAttendancePeriodsViewLoading,
  setAttendancePeriodsView,

  setAttendancePeriodsLoading,
  setAttendancePeriod,
  setAttendancePeriods,
  replaceAttendancePeriods,
  removeAttendancePeriod,

  refetchDailyRecordsView,
  setDailyRecordsViewLoading,
  setDailyRecordsView,
  clearDailyRecordsView,

  refetchDailyRecords,
  setDailyRecordsLoading,
  setDailyRecord,
  setDailyRecords,
  removeDailyRecord,
  setDailyProcessing,
  replaceDailyRecordEmployees,
  setDailyRecordEmployeesLoading,

  replaceClockRecordImport,
  replaceClockRecordImportValidate
} = attendanceSlice.actions

export const attendanceReducers = {
  attendance: persistReducer<AttendanceRootState>(attendancePersistConfig, attendanceSlice.reducer)
}

export const { selectById: selectAttendancePeriodById } = attendancePeriodsAdapter.getSelectors(
  (state: StoreState) => state.attendance.attendancePeriods
)
