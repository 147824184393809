import React, { ChangeEvent, FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import confirm from 'antd/lib/modal/confirm'
import { v4 as uuidv4 } from 'uuid'
import pick from 'lodash/pick'
import { Badge, Button, Form, Input, Select, Skeleton, Space, Tabs, UploadFile } from '~/core-components'
import { Col, DrawerForm, ErrorDisplay, Row } from '~/components'
import { emptyGuid, LveApprovalStatus, LveSysLeaveType } from '~/constants'
import { formatDateRange, getBaseUrl } from '~/utils'
import { ActionResult, Errors } from '~/types/store'
import { useFocus } from '~/hooks/use-focus'
import { dispatch } from '~/stores/store'
import { SSLeaveApply, SSLeaveBuddyConflictState } from '../../../types'
import { addLeaveRecordComment, applyMyLeave, cancelMyLeave, deleteMyLeave } from '../../../actions'
import { MyLeaveApplyForm, MY_LEAVE_APPLY_EMPTY_FORM_DATA } from './MyLeaveApplyForm'
import { SSLeaveRecordComments } from '../SSLeaveRecordComments/SSLeaveRecordComments'
import { LeaveRecordDtlState, LeaveStatusTag, useLeaveType } from '~/features/leave'
import {
  useMyEntitledLeaveTypes,
  useMyLeaveApplyFormInfo,
  useMyLeaveEntitlement,
  useMyLeaveRecord,
  useMyLeaveRecordApprovalHistories,
  useMyLeaveRecordAttachments,
  useMyLeaveRecordComments,
  useMyLeaveRecordDtls
} from '~/features/my/hooks'
import { LeaveCurrentApprover } from '~/features/leave/containers/LeaveCurrentApprover/LeaveCurrentApprover'
import { LeaveRecordApprovalHistories } from '~/features/leave/containers/LeaveRecordApprovalHistories/LeaveRecordApprovalHistories'
import './MyLeaveApplyDrawer.less'

interface MyLeaveApplyDrawerProps {
  visible: boolean
  id?: string
  editing?: boolean
  leaveTypeId?: string
  leaveGrantId?: string
  startDate?: string
  endDate?: string
  resetOnClose?: boolean
  onClose: (action: 'saved' | 'cancelled') => void
}

const baseUrl = getBaseUrl('/leave')

type TabChoice = 'request' | 'comment'

export const MyLeaveApplyDrawer: FC<MyLeaveApplyDrawerProps> = ({
  visible,
  id,
  editing,
  leaveTypeId,
  leaveGrantId,
  startDate,
  endDate,
  resetOnClose,
  onClose
}) => {
  const DEFAULT_FORM_DATA: SSLeaveApply = useMemo(
    () => ({
      ...MY_LEAVE_APPLY_EMPTY_FORM_DATA,
      leaveTypeId: leaveTypeId || '',
      leaveGrantId: leaveGrantId || '',
      startDate: startDate || '',
      endDate: endDate || ''
    }),
    [leaveTypeId, leaveGrantId, startDate, endDate]
  )
  const [loading, setLoading] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [formData, setFormData] = useState<SSLeaveApply>(DEFAULT_FORM_DATA)
  const [errors, setErrors] = useState<Errors>()
  const [focusRef, setFocus] = useFocus(visible)
  const [commenting, setCommenting] = useState(false)
  const [comment, setComment] = useState('')
  const [dtls, setDtls] = useState<LeaveRecordDtlState[]>([])
  const [conflicts, setConflicts] = useState<LeaveRecordDtlState[]>([])
  const [isBlock, setIsBlock] = useState(true)
  const [buddies, setBuddies] = useState<SSLeaveBuddyConflictState[]>([])
  const errorRef = useRef<any>(null)
  const isSubmit = (dtls && dtls.length > 0) || !isBlock
  const formId = useMemo(() => `form-my-leave-apply-${uuidv4()}`, [])
  const [activeTab, setActiveTab] = useState<TabChoice>('request')

  const [entitledLeaveTypes, entitledLeaveTypesLoading] = useMyEntitledLeaveTypes(formData.leaveTypeId)
  const [leaveType] = useLeaveType(formData.leaveTypeId)
  const sysLeaveTypeCode = leaveType?.sysLeaveTypeCode
  const [leaveRecord, fetching] = useMyLeaveRecord(id)
  const [leaveRecordAttachments] = useMyLeaveRecordAttachments(id)
  const [leaveRecordDtls] = useMyLeaveRecordDtls(id)
  const [leaveRecordApprovalHistories] = useMyLeaveRecordApprovalHistories(id)
  const hasPayroll = leaveRecordDtls.some(d => d.payRunPeriod !== null)
  const [comments] = useMyLeaveRecordComments(id)
  const [leaveEntitlement] = useMyLeaveEntitlement(formData.leaveTypeId)
  const maxPerTime = leaveEntitlement?.maxPerTime

  const isNew = !id
  const isDeletable = useMemo(
    () =>
      leaveRecord?.approvalStatuses?.length === 1 &&
      leaveRecord?.approvalStatuses?.includes(LveApprovalStatus.pendingCancel),
    [leaveRecord]
  )
  const isCancellable = useMemo(
    () =>
      leaveRecord?.approvalStatuses?.length === 1 &&
      (leaveRecord?.approvalStatuses?.includes(LveApprovalStatus.pending) ||
        leaveRecord?.approvalStatuses?.includes(LveApprovalStatus.approved)),
    [leaveRecord]
  )
  const isEditable = useMemo(
    () =>
      isNew ||
      (leaveRecord?.approvalStatuses?.length === 1 &&
        (leaveRecord?.approvalStatuses?.includes(LveApprovalStatus.pending) ||
          leaveRecord?.approvalStatuses?.includes(LveApprovalStatus.approved))),
    [isNew, leaveRecord]
  )

  const [formInfo] = useMyLeaveApplyFormInfo(formData.leaveTypeId)
  const formInfoRefDate = formInfo && formInfo['ref_date_label']
  const refDateValue = formInfoRefDate?.value

  useEffect(() => {
    setTimeout(() => visible && setFocus(), 100)
    setErrors(undefined)
    setDtls([])
    setConflicts([])
    setIsBlock(true)
    setBuddies([])

    if (resetOnClose && !visible) {
      setFormData(DEFAULT_FORM_DATA)
    }
  }, [visible, resetOnClose, setFocus, DEFAULT_FORM_DATA])

  useEffect(() => {
    if (visible) {
      setIsEditing(!!isEditable && !!editing)
    }
  }, [visible, isEditable, editing])

  const handleFormDataChange = useCallback((updates: { [field: string]: any }) => {
    setErrors(undefined)
    setDtls([])
    setConflicts([])
    setIsBlock(true)
    setBuddies([])
    setFormData(formData => ({ ...formData, ...updates }))
  }, [])

  useEffect(() => {
    if (isNew) {
      const isAdp = sysLeaveTypeCode === LveSysLeaveType.adp
      const isMat = sysLeaveTypeCode === LveSysLeaveType.mat
      const isPat = sysLeaveTypeCode === LveSysLeaveType.pat
      const updates = {
        takenDuration: isAdp || isMat || isPat ? maxPerTime || 0 : 0,
        referenceDate: refDateValue || ''
      }
      setFormData(formData => ({ ...formData, ...updates }))
    }
  }, [isNew, maxPerTime, sysLeaveTypeCode, refDateValue])

  useEffect(() => {
    if (leaveRecord) {
      const {
        leaveTypeId,
        leaveGrantId,
        startDate,
        endDate,
        startTime,
        endTime,
        startDuration,
        endDuration,
        referenceId,
        referenceDate,
        referenceName,
        referenceSource,
        notes,

        // jsonb
        isShared,
        sharedQuantity,
        takenDuration,
        relationship
      } = leaveRecord

      let attachments: UploadFile[] = []
      let file: UploadFile | undefined
      leaveRecordAttachments.forEach(att => {
        file = {
          uid: att?.fileId || '',
          name: att?.fileName || '',
          size: att?.fileSize || 0,
          type: att?.fileFormat || '',
          url: `${baseUrl}/ssleaverecord/${id}/attachment/${att?.id}/downloadfile`
        }
        attachments.push(file)
      })

      setFormData({
        leaveTypeId,
        leaveGrantId,
        startDate,
        endDate,
        startTime,
        endTime,
        startDuration,
        endDuration,
        referenceId,
        referenceDate,
        referenceName,
        referenceSource,
        notes,

        // jsonb
        isShared,
        sharedQuantity,
        takenDuration,
        relationship,

        attachments,
        deletedAttachmentIds: []
      })
    } else {
      setFormData(DEFAULT_FORM_DATA)
    }
    setComment('')
  }, [leaveRecord, leaveRecordAttachments, id, DEFAULT_FORM_DATA])

  const handleOk = useCallback(async () => {
    let result: ActionResult | undefined
    setLoading(true)
    try {
      formData.isSubmit = isSubmit
      result = await dispatch(applyMyLeave(id ? id : emptyGuid, formData))
    } finally {
      setLoading(false)
    }

    if (result?.result?.dtls) {
      setDtls(result.result.dtls || [])
    }
    if (result?.result?.conflicts) {
      setConflicts(result.result.conflicts || [])
    }

    setIsBlock(result?.result?.isBlock)

    if (result?.result?.buddies) {
      setBuddies(result.result.buddies || [])
    }

    if (result?.errors) {
      setErrors(result.errors)
      errorRef.current?.scrollIntoView()
    }

    if (!result?.errors && !result?.result?.dtls && !result?.result?.buddies) {
      typeof onClose === 'function' && onClose('saved')
      setFormData(DEFAULT_FORM_DATA)
    }
  }, [id, formData, isSubmit, onClose, DEFAULT_FORM_DATA])

  const handleDelete = useCallback(() => {
    if (leaveRecord) {
      const { id, startDate, endDate } = leaveRecord

      if (isDeletable) {
        confirm({
          title: `Delete "${leaveType?.name}"`,
          content: `Do you want to delete "${leaveType?.name}" on ${formatDateRange(startDate, endDate)}?`,
          onOk: async () => {
            const result: ActionResult | undefined = await dispatch(deleteMyLeave(id))
            if (result?.errors) {
              setErrors(result.errors)
            }
            if (!result?.errors) {
              typeof onClose === 'function' && onClose('saved')
            }
          },
          okText: 'Delete',
          okType: 'danger'
        })
      } else {
        confirm({
          title: `Cancel "${leaveType?.name}"`,
          content: `Do you want to cancel this "${leaveType?.name}"?`,
          onOk: async () => {
            const result: ActionResult | undefined = await dispatch(cancelMyLeave(id))
            if (result?.errors) {
              setErrors(result.errors)
            }
            if (!result?.errors) {
              typeof onClose === 'function' && onClose('saved')
            }
          },
          okText: 'Cancel leave',
          okType: 'danger'
        })
      }
    }
  }, [leaveType, leaveRecord, isDeletable, onClose])

  const handleToggleEdit = useCallback(() => {
    setIsEditing(isEditing => !isEditing)
  }, [])

  const handleComment = useCallback(async () => {
    if (!id) return

    try {
      setCommenting(true)
      const result = await dispatch(addLeaveRecordComment(id, comment))
      if (result?.errors) {
        setErrors(result.errors)
      }
      if (!result?.errors) {
        setErrors(undefined)
        setComment('')
      }
    } finally {
      setCommenting(false)
    }
  }, [id, comment])

  const handleTabChange = useCallback((activeKey: string) => setActiveTab(activeKey as TabChoice), [])
  const handleCloseDrawer = useCallback(() => {
    setActiveTab('request')
    onClose('cancelled')
  }, [onClose])

  return (
    <DrawerForm
      className="my-leave-apply-drawer"
      open={visible}
      title={id ? (isEditing ? 'Edit my leave' : 'My leave') : 'Apply leave'}
      okText={
        isEditable && !isEditing && !hasPayroll ? (
          'Edit'
        ) : !isEditing ? (
          ''
        ) : id ? (
          <Space>
            Resend request <i className="fal fa-paper-plane-top" />
          </Space>
        ) : (
          <Space>
            Send request <i className="fal fa-paper-plane-top" />
          </Space>
        )
      }
      onClose={handleCloseDrawer}
      confirmLoading={loading}
      width={500}
      showDelete={!!id && (isDeletable || isCancellable) && !hasPayroll}
      deleteText={isDeletable ? 'Delete' : 'Cancel leave'}
      onDelete={handleDelete}
      formId={formId}
      hideFooter={activeTab === 'comment'}
    >
      <Form id={formId} onFinish={isEditable && !isEditing ? handleToggleEdit : handleOk}>
        {fetching ? (
          <Skeleton active />
        ) : (
          <Tabs
            defaultActiveKey={activeTab || 'request'}
            onChange={handleTabChange}
            {...(id ? {} : { renderTabBar: () => <></> })}
            items={[
              {
                key: 'request',
                label: 'Request',
                children: (
                  <>
                    <Row>
                      <Col span={isNew ? 24 : 12}>
                        <Form.Item
                          label="Leave type"
                          validateStatus={errors?.leaveTypeId ? 'error' : ''}
                          help={errors?.leaveTypeId}
                        >
                          {!isEditing || !isNew ? (
                            <>{leaveType?.name || '-'}</>
                          ) : (
                            <Select
                              ref={focusRef}
                              showSearch
                              allowClear={false}
                              optionFilterProp="title"
                              loading={entitledLeaveTypesLoading}
                              value={formData.leaveTypeId}
                              onChange={(leaveTypeId: string) =>
                                handleFormDataChange({ ...DEFAULT_FORM_DATA, leaveTypeId })
                              }
                            >
                              {entitledLeaveTypes?.map(data => (
                                <Select.Option key={data.id} value={data.id || ''} title={data.name}>
                                  {data.name}
                                </Select.Option>
                              ))}
                            </Select>
                          )}
                        </Form.Item>
                      </Col>
                      {!isNew && (
                        <Col span={12} style={{ textAlign: 'right' }}>
                          <LeaveStatusTag statuses={leaveRecord?.approvalStatuses || []} />
                        </Col>
                      )}
                    </Row>
                    <ErrorDisplay ref={errorRef} errors={errors} />
                    <MyLeaveApplyForm
                      id={id}
                      leaveType={leaveType}
                      formData={formData}
                      availableDates={dtls}
                      conflictDates={conflicts}
                      isBlock={isBlock}
                      conflictBuddies={buddies}
                      errors={errors}
                      readOnly={!isEditing}
                      onChange={data => handleFormDataChange(data)}
                    />
                    <LeaveCurrentApprover
                      statuses={leaveRecord?.approvalStatuses || []}
                      approvers={leaveRecord?.currentApprovers || []}
                      hidden={isEditing || isNew}
                    />
                    <LeaveRecordApprovalHistories
                      histories={leaveRecordApprovalHistories}
                      statuses={leaveRecord?.approvalStatuses || []}
                      hidden={isEditing || isNew}
                      {...pick(leaveRecord!, 'creatorName', 'createdDate')}
                    />
                  </>
                )
              },
              {
                key: 'comment',
                label: (
                  <>
                    Comments
                    <Badge count={comments.length} type="primary" />
                  </>
                ),
                children: (
                  <>
                    <Row align="top" gutter={15}>
                      <Col flex={1}>
                        <Form.Item
                          label="Enter your comment"
                          validateStatus={errors?.comment ? 'error' : ''}
                          help={errors?.comment}
                        >
                          <Input.TextArea
                            rows={2}
                            value={comment}
                            onChange={(event: ChangeEvent<HTMLTextAreaElement>) => setComment(event.target.value)}
                          />
                        </Form.Item>
                      </Col>
                      <Col>
                        <Form.Item label="&nbsp;">
                          <Button onClick={handleComment} loading={commenting}>
                            Send
                          </Button>
                        </Form.Item>
                      </Col>
                    </Row>
                    {id && <SSLeaveRecordComments id={id} />}
                  </>
                )
              }
            ]}
          />
        )}
      </Form>
    </DrawerForm>
  )
}
